import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: '#673ab7',
        // secondary: '#3c3e3f',
        // thirdly: '#ffa411',
        // fourthly: '#727476',
        // fifthly: '#a8aaac',
        // accent: '#673ab7',
        // error: '#f44336',
        // warning: '#ff9800',
        // info: '#4caf50',
        // success: '#3f51b5',
        action: '#EEEEEE'
      }
    }
  }
})
