export const caches = {
  mounted () {
    this.bindFromCache()
  },
  props: {
    comScrNm: {
      type: String,
      required: true
    },
    bindCache: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    // 캐시에서 데이터를 바인딩
    bindFromCache () {
      if (!this.bindCache) {
        return
      }
      if (!this.comScrNm) {
        throw new Error('comScrNm is not exists')
      }
      const item = this.$store.getters.history[this.comScrNm]
      if (this.$isNull(item)) {
        return
      }
      // 캐시 데이터를 바인딩 하도록 설정된 경우
      if (this.bindCache) {
        for (const field in item) {
          this[field] = item[field]
        }
      }
      // 캐시 데이터 삭제
      this.$store.commit('SET_HISTORY', { scrNm: this.comScrNm, item: null })
    },
    // 캐시 데이터 저장
    cacheData (fieldList) {
      if (!this.comScrNm) {
        throw new Error('comScrNm is not exists')
      }
      const item = {}
      // 화면 변수 캐싱
      for (const field of fieldList) {
        item[field] = this[field]
      }
      // 정렬 변수 캐싱
      if (!this.$isNull(this.comSrtBy) && !this.$isNull(this.comSrtDesc)) {
        item.comSrtBy = this.comSrtBy
        item.comSrtDesc = this.comSrtDesc
      }
      // 화면 페이징 변수 캐싱
      if (!this.$isNull(this.comTtlCnt)) {
        item.comTtlCnt = this.comTtlCnt
        item.comItemCntPerPage = this.comItemCntPerPage
        item.comPageNo = this.comPageNo
        item.comPageCnt = this.comPageCnt
      }
      this.$store.commit('SET_HISTORY', { scrNm: this.comScrNm, item: item })
    }
  }
}
