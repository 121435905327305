<template>
  <div style="min-height:44px">
    <label class="caption grey--text text--darken-2">
      {{ label }}
    </label>
    <br/>
    <label class="body-1 mr-1" :class="valueClass">
      <template v-if="Array.isArray(value)">
        {{ value.join(', ') }}
      </template>
      <template v-else>
        {{ $isNull(value) ? '' : value }}
      </template>
    </label>
    <slot name="append">
    </slot>
  </div>
</template>

<script>
export default {
  name: 'LbaelField',
  props: {
    label: {
      type: String,
      required: false
    },
    value: {
      type: [String, Number, Array],
      required: false
    },
    valueClass: {
      type: String,
      required: false,
      default: 'black--text'
    }
  }
}
</script>
