<template>
  <v-dialog v-model="dialogModel" persistent scrollable :max-width="$const.maxWidthLayout.lg" :retain-focus="false">
    <v-card>
      <!-- 타이틀바 -->
      <title-bar :title="$t('word.loadDraftProj')" color="primary" dark>
        <template v-slot:action>
          <v-btn icon large dark @click="handleApplyClicked"><v-icon>mdi-check</v-icon></v-btn>
          <v-btn icon large dark @click="fetchList"><v-icon>mdi-magnify</v-icon></v-btn>
          <v-btn icon large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
        </template>
      </title-bar>
      <!-- 검색조건 -->
      <field-bar :field-size="6" :md="2">
        <template v-slot:field-1>
          <date-field
            v-model="strYmd"
            :label="$t('word.crtYmd') + ' (FROM)'"
            background-color="white"
            outlined
            dense
            hide-details>
          </date-field>
        </template>
        <template v-slot:field-2>
          <date-field
            v-model="endYmd"
            :label="$t('word.crtYmd') + ' (TO)'"
            background-color="white"
            outlined
            dense
            hide-details>
          </date-field>
        </template>
        <template v-slot:field-3>
          <v-text-field
            v-model="ipNm"
            label="IP"
            readonly
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @click="showIpListPopup = true">
            <template v-slot:append>
              <v-btn x-small depressed color="action" @click="showIpListPopup = true">{{ $t('word.choose') }}</v-btn>
            </template>
          </v-text-field>
        </template>
        <template v-slot:field-4>
          <v-text-field
            v-model="cntrNo"
            :label="$t('word.cntr')"
            readonly
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @click="showContractListPopup = true">
            <template v-slot:append>
              <v-btn x-small depressed color="action" @click="showContractListPopup = true">{{ $t('word.choose') }}</v-btn>
            </template>
          </v-text-field>
        </template>
        <template v-slot:field-5>
          <v-select
            v-model="srchDiv"
            :items="srchDivList"
            :label="$t('word.srchDiv')"
            background-color="white"
            outlined
            dense
            hide-details>
          </v-select>
        </template>
        <template v-slot:field-6>
          <v-text-field
            v-model="kwd"
            :label="$t('word.kwd')"
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @keypress.enter="fetchList(true)">
          </v-text-field>
        </template>
      </field-bar>
      <!-- 테이블 -->
      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="items"
        item-key="projCd"
        height="68vh"
        class="text-truncate"
        fixed-header
        show-select
        single-select
        disable-filtering
        disable-pagination
        loading-text=""
        no-data-text=""
        no-results-text=""
        hide-default-footer
        :server-items-length="comTtlCnt"
        :sort-by.sync="comSrtBy"
        :sort-desc.sync="comSrtDesc"
        @click:row="handleRowClicked"
        @update:sort-by="delayedCall(fetchList)"
        @update:sort-desc="delayedCall(fetchList)">
        <template v-slot:item.projCrtDt="{ value }">
          <td>{{ $timeToShortDate(value) }}</td>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn x-small depressed color="secondary" @click="handleInlineApplyClicked(item)">{{ $t('word.apply') }}</v-btn>
        </template>
        <template v-slot:body.append="{ headers }">
          <tr>
            <td :colspan="headers.length">
              {{ $t('word.total') }} {{ $formatNumber(comTtlCnt) }}{{ $t('word.count') }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <!-- 페이징 -->
      <paging-bar
        :page-no.sync="comPageNo"
        :page-cnt.sync="comPageCnt"
        :item-cnt-per-page.sync="comItemCntPerPage"
        @paging-changed="fetchList">
      </paging-bar>
    </v-card>
    <!-- IP 목록 팝업 -->
    <ip-list-popup
      v-if="showIpListPopup"
      :dialog.sync="showIpListPopup"
      :ip-id.sync="ipId"
      :ip-nm.sync="ipNm">
    </ip-list-popup>
    <!-- 계약 목록 팝업 -->
    <contract-list-popup
      v-if="showContractListPopup"
      :dialog.sync="showContractListPopup"
      :cntr-id.sync="cntrId"
      :cntr-no.sync="cntrNo">
    </contract-list-popup>
  </v-dialog>
</template>

<script>
import { sorts } from '@/mixins/sortMixin'
import { pages } from '@/mixins/pageMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'
import FieldBar from '@/components/FieldBar'
import DateField from '@/components/DateField'
import PagingBar from '@/components/PagingBar'
import IpListPopup from '@/views/ip/popup/IpListPopup'
import ContractListPopup from '@/views/contract/popup/ContractListPopup'

export default {
  name: 'DraftProjectListPopup',
  mixins: [sorts, pages, searches, stores],
  components: {
    TitleBar,
    FieldBar,
    DateField,
    PagingBar,
    IpListPopup,
    ContractListPopup
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },
  mounted () {
    this.fetchList()
  },
  data () {
    return {
      // 테이블 헤더
      headers: [
        { text: this.$t('word.projCd'), value: 'projCd' },
        { text: this.$t('word.projNm'), value: 'projNm' },
        { text: this.$t('word.ipNm'), value: 'ipNm' },
        { text: this.$t('word.liceComp'), value: 'compNm' },
        { text: this.$t('word.cntrNo'), value: 'cntrNo' },
        { text: this.$t('word.prodTyp'), value: 'prodTypNm', sortable: false },
        { text: this.$t('word.projStg'), value: 'projStgCdNm', sortable: false },
        { text: this.$t('word.crtDt'), value: 'projCrtDt' },
        { text: '', value: 'action', width: '1px', sortable: false }
      ],
      // 조회결과 목록
      items: [],
      // 검색조건
      strYmd: null,
      endYmd: null,
      ipId: null,
      ipNm: null,
      cntrId: null,
      cntrNo: null,
      srchDiv: 'projNm',
      kwd: null,
      // 선택 아이템
      selectedItems: [],
      // IP 목록 팝업 오픈 여부
      showIpListPopup: false,
      // 계약 목록 팝업 오픈 여부
      showContractListPopup: false
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    },
    srchDivList () {
      return [
        { text: this.$t('word.projCd'), value: 'projCd' },
        { text: this.$t('word.projNm'), value: 'projNm' }
      ]
    }
  },
  methods: {
    // 목록조회
    fetchList (shouldClear) {
      // 검색 초기화
      if (shouldClear) {
        this.comPageNo = 1
        this.selectedItems = []
      }
      // 검색 파라미터 할당
      this.clearComParam()
      this.appendParam('strCrtYmd', this.strYmd)
      this.appendParam('endCrtYmd', this.endYmd)
      this.appendParam('projStsCd', 'PS0100')
      if (!this.$isEmpty(this.ipNm)) {
        this.appendParam('ipId', this.ipId)
      }
      if (!this.$isEmpty(this.cntrNo)) {
        this.appendParam('cntrId', this.cntrId)
      }
      if (!this.$isEmpty(this.kwd)) {
        this.appendParam(this.srchDiv, this.kwd)
      }
      // 검색
      this.$http.get(this.toRequestUrl('/api/1/projects'))
        .then((response) => {
          const page = response.data.result.projPage
          this.bindComPage(page)
          this.items = page.list
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleInlineApplyClicked (item) {
      if (this.$isNull(item) || (item instanceof Event)) {
        return
      }
      this.selectedItems = [item]
      this.$nextTick(() => {
        this.handleApplyClicked()
      })
    },
    handleApplyClicked () {
      if (this.$isEmptyArray(this.selectedItems)) {
        this.$toasted.global.warn(this.$t('DraftProjectListPopup.txt001'))
        return
      }
      const myProj = this.selectedItems[0]
      this.$emit('item-selected', myProj)
      this.dialogModel = false
    },
    // 테이블 로우 클릭시
    handleRowClicked (item) {
      this.selectedItems = [item]
    }
  }
}
</script>
