<template>
  <div>
    <v-card outlined>
      <!-- 타이틀바 -->
      <title-bar :title="$t('word.atchFile')" sub>
        <template v-slot:action>
           <v-btn small tile color="secondary" class="mr-1" v-if="!isLicensee" @click="showNtcFileRegistPopup = true">{{ $t('word.registFile') }}</v-btn>
        </template>
      </title-bar>
      <v-divider></v-divider>
      <!-- 목록 -->
      <v-list class="py-0">
        <v-list-item-group
          v-model="selectedItem"
          color="primary">
          <template v-for="(item, index) in items">
            <v-list-item
              :key="`${item.ntcNo}-${item.ntcFileNo}`"
              :value="item"
              two-line>
              <template v-slot:default>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.mngFileNm }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon rounded v-bind:class="{ move:isLicensee }" @click.stop="handleInlineDownloadClicked(item)"><v-icon>mdi-download</v-icon></v-btn>
                </v-list-item-action>
                <v-list-item-action>
                  <v-btn v-if="!isLicensee" icon rounded :ripple="false" @click.stop="handleInlineDeleteClicked(item)"><v-icon>mdi-delete</v-icon></v-btn>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-divider
              :key="index"
              v-if="index < items.length - 1">
            </v-divider>
          </template>
        </v-list-item-group>
        <template v-if="items.length === 0">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('msg.notExistsFile') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <!-- 페이징 -->
      <paging-bar
        :page-no.sync="comPageNo"
        :page-cnt.sync="comPageCnt"
        :visible-page-cnt="5"
        :item-cnt-per-page.sync="comItemCntPerPage"
        :items-per-page="[5]"
        row-justify="center"
        hide-item-cnt-per-page
        @paging-changed="fetchList">
      </paging-bar>
    </v-card>
    <!-- 첨부 파일 등록 팝업 -->
    <ntc-file-regist-popup
      v-if="showNtcFileRegistPopup"
      :dialog.sync="showNtcFileRegistPopup"
      :ntc-no="ntcNo"
      @item-changed="fetchList">
    </ntc-file-regist-popup>
  </div>
</template>

<script>
import { pages } from '@/mixins/pageMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'
import PagingBar from '@/components/PagingBar'
import NtcFileRegistPopup from '@/views/ntc/popup/NtcFileRegistPopup'
import fileDownload from 'js-file-download'

export default {
  name: 'NtcFileListUnit',
  mixins: [pages, searches, stores],
  components: {
    TitleBar,
    PagingBar,
    NtcFileRegistPopup
  },
  props: {
    ntcNo: {
      type: Number,
      required: false
    }
  },
  mounted () {
    // 페이지별 아이템 건수를 5건으로 조정
    this.comItemCntPerPage = 5
    this.fetchList()
  },
  data () {
    return {
      // 첨부파일 목록
      items: [],
      // 첨부파일 목록에서 선택된 아이템
      selectedItem: null,
      showNtcFileRegistPopup: false
    }
  },
  methods: {
    // 목록 조회
    fetchList (shouldClear) {
      if (this.$isNull(this.ntcNo)) {
        return
      }
      // 페이지번호 초기화
      if (shouldClear) {
        this.comPageNo = 1
      }
      this.selectedItem = null
      this.$http.get(this.toRequestUrl(`/api/1/ntc/${this.ntcNo}/file`))
        .then((response) => {
          const page = response.data.result.ntcFilePage
          this.bindComPage(page)
          this.items = page.list
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleInlineDownloadClicked (item) {
      this.selectedItem = item
      this.$nextTick(() => {
        this.handleDownloadClicked()
      })
    },
    handleDownloadClicked () {
      this.$http.get(`/api/1/ntcFile/${this.ntcNo}/file/${this.selectedItem.ntcFileNo}/download`, { responseType: 'blob' })
        .then((response) => {
          // 파일이름 설정
          const contentDisposition = response.headers['content-disposition']
          let downFileNm = null
          if (!this.$isNull(contentDisposition)) {
            const idx = contentDisposition.indexOf(';filename*=UTF-8\'\'')
            if (idx >= 0) {
              downFileNm = decodeURIComponent(contentDisposition.substring(idx + 18))
            }
          }
          if (this.$isNull(downFileNm)) {
            downFileNm = this.fileNm
          }
          fileDownload(response.data, downFileNm)
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleInlineDeleteClicked (item) {
      this.selectedItem = item
      this.$nextTick(() => {
        this.handleDeleteClicked()
      })
    },
    handleDeleteClicked () {
      if (this.$isNull(this.selectedItem)) {
        this.$toasted.global.warn(this.$t('msg.chooseDeleteFile'))
        return
      }
      if (!confirm(this.$t('msg.confirmDelete', [this.selectedItem.mngFileNm]))) {
        return
      }
      this.$http.delete(`/api/1/ntc/${this.ntcNo}/file/${this.selectedItem.ntcFileNo}`)
        .then(() => {
          this.$toasted.global.success(this.$t('msg.deleted'))
          this.fetchList(true)
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>

<style scoped>
  .move {
    margin-left: 130%;
  }
</style>
