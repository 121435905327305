<template>
  <div>
    <v-divider></v-divider>
    <v-sheet :color="color" outlined class="pa-2">
      <v-row dense justify="end">
        <v-col
          v-for="field in fields"
          :key="field.id"
          cols="12"
          xs="12"
          sm="12"
          :md="md"
          :class="customClass">
          <slot :name="field.id">
            <!-- 검색조건 컴포넌트가 들어갈 위치 -->
          </slot>
        </v-col>
      </v-row>
      <template v-if="showExtension">
        <slot name="extension">
          <!-- 확장 컴포넌트가 들어갈 위치 -->
        </slot>
      </template>
    </v-sheet>
    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  name: 'FiveFieldBar',
  props: {
    color: {
      type: String,
      required: false,
      default: 'grey lighten-3'
    },
    fieldSize: {
      type: Number,
      required: true
    },
    showExtension: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted () {
    for (let i = 0; i < this.fieldSize; i++) {
      this.fields.push({
        id: `field-${(i + 1)}`
      })
    }
  },
  data () {
    return {
      fields: []
    }
  },
  computed: {
    md () {
      const mod = this.fieldSize % 10
      if (mod === 0) {
        return 6
      } else {
        return 4
      }
    },
    customClass () {
      let c
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
        case 'md':
          c = ''
          break
        case 'lg':
        case 'xl':
        default:
          c = 'custom5cols'
          break
      }
      return c
    }
  }
}
</script>

<style scoped>
.custom5cols {
  width: 20%;
  max-width: 20%;
  flex-basis: 20%;
}
</style>
