import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import SecureStorage from 'secure-web-storage'
import CryptoJS from 'crypto-js'
import createPersistedState from 'vuex-persistedstate'
import router from '@/router'
import i18n from '@/i18n/messages'

Vue.use(Vuex)

const SECRET_KEY = process.env.VUE_APP_SECRET_KEY
const secureStorage = new SecureStorage(window.sessionStorage, {
  hash: function hash (key) {
    return CryptoJS.SHA256(key, SECRET_KEY).toString()
  },
  encrypt: function encrypt (data) {
    return CryptoJS.AES.encrypt(data, SECRET_KEY).toString()
  },
  decrypt: function decrypt (data) {
    return CryptoJS.AES.decrypt(data, SECRET_KEY).toString(CryptoJS.enc.Utf8)
  }
})

const renewTokenTime = process.env.VUE_APP_RENEW_TOKEN_SECONDS * 1000
const maxSessionTime = process.env.VUE_APP_MAX_SESSION_SECONDS * 1000
const checkSessionTime = 60000
let sessionTimer
let renewTokenTimer

export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState({
    storage: {
      getItem: (key) => secureStorage.getItem(key),
      setItem: (key, value) => secureStorage.setItem(key, value),
      removeItem: (key) => secureStorage.removeItem(key)
    },
    paths: ['loginUser', 'history', 'i18n']
  })],
  state: {
    i18n: {
      ko: 'ko',
      en: 'en'
    },
    history: {},
    loginUser: {
      compId: null,
      usrId: null,
      usrNm: null,
      email: null,
      auth: null,
      accsToken: null,
      rfshToken: null
    },
    loading: false,
    lastAccessTime: null
  },
  getters: {
    history: state => state.history,
    loginUser: state => state.loginUser,
    loading: state => state.loading,
    lastAccessTime: state => state.lastAccessTime,
    i18n: state => state.i18n
  },
  mutations: {
    SET_HISTORY (state, { scrNm, item }) {
      state.history[scrNm] = item
    },
    SET_LOGIN_USER (state, loginUser) {
      state.loginUser = loginUser
    },
    SET_LOADING (state, loading) {
      state.loading = loading
    },
    SET_LAST_ACCESS_TIME (state, lastAccessTime) {
      state.lastAccessTime = lastAccessTime
    },
    SET_I18N (state, i18n) {
      state.i18n = i18n
    }
  },
  actions: {
    login (context, payload) {
      return axios.post('/api/1/auth/login', payload)
        .then((response) => {
          const loginUser = response.data.result.lgnUsrRslt
          context.commit('SET_LOGIN_USER', loginUser)
          sessionTimer = setTimeout(() => {
            context.dispatch('checkSession')
          }, checkSessionTime)
          renewTokenTimer = setTimeout(() => {
            context.dispatch('renewToken')
          }, renewTokenTime)
          // 로그인 후 이동할 라우터 URL 지정
          const requiredList = response.data.result.requiredList
          let url
          if (requiredList === null || requiredList === undefined || requiredList.length === 0) {
            url = '/main/project/new'
          } else {
            url = '/join/info'
          }
          return Promise.resolve(url)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    logout (context) {
      return axios.delete('/api/1/auth/logout')
        .then(() => {
          context.commit('SET_LOGIN_USER', {
            compId: null,
            usrId: null,
            usrNm: null,
            email: null,
            auth: null,
            accsToken: null,
            rfshToken: null
          })
          if (sessionTimer !== null && sessionTimer !== undefined) {
            clearTimeout(sessionTimer)
          }
          if (renewTokenTimer !== null && renewTokenTimer !== undefined) {
            clearTimeout(renewTokenTimer)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    forceLogout (context) {
      context.commit('SET_LOGIN_USER', {
        compId: null,
        usrId: null,
        usrNm: null,
        email: null,
        auth: null,
        accsToken: null,
        rfshToken: null
      })
      if (sessionTimer !== null || sessionTimer !== undefined) {
        clearTimeout(sessionTimer)
      }
      if (renewTokenTimer !== null || renewTokenTimer !== undefined) {
        clearTimeout(renewTokenTimer)
      }
      return Promise.resolve('SUCCESS')
    },
    checkSession (context) {
      let lastAccessTime = context.getters.lastAccessTime
      if (lastAccessTime === null || lastAccessTime === 0 || lastAccessTime === undefined) {
        lastAccessTime = Date.now()
      }
      const elapsedTime = Date.now() - lastAccessTime
      if (maxSessionTime > 0 && elapsedTime >= maxSessionTime) {
        alert(i18n.t('msg.expiredSession'))
        context.dispatch('forceLogout')
          .then(() => {
            router.push('/login')
          })
          .catch(() => {
          })
      } else {
        sessionTimer = setTimeout(() => {
          context.dispatch('checkSession')
        }, checkSessionTime)
      }
    },
    renewToken (context) {
      const rfshToken = context.getters.loginUser.rfshToken
      if (rfshToken === null || rfshToken === undefined) {
        return
      }
      const item = {
        rfshToken: rfshToken
      }
      return axios.put('/api/1/auth/tokens', item)
        .then((response) => {
          const loginUser = response.data.result.lgnUsrRslt
          context.commit('SET_LOGIN_USER', loginUser)
          console.log('renew : ' + Date.now())
          renewTokenTimer = setTimeout(() => {
            context.dispatch('renewToken')
          }, renewTokenTime)
        })
        .catch(() => {
          alert(i18n.t('msg.expiredToken'))
          context.dispatch('forceLogout')
            .then(() => {
              router.push('/login')
            })
            .catch(() => {
            })
        })
    }
  }
})
