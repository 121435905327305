export const stores = {
  computed: {
    isLicensor () {
      return this.$store.getters.loginUser.auth === 'ROLE_ADMIN' || this.$store.getters.loginUser.auth === 'ROLE_REVIEWER'
    },
    isAdmin () {
      return this.$store.getters.loginUser.auth === 'ROLE_ADMIN'
    },
    isReviewer () {
      return this.$store.getters.loginUser.auth === 'ROLE_REVIEWER'
    },
    isLicensee () {
      return this.$store.getters.loginUser.auth === 'ROLE_LICENSEE'
    }
  },
  methods: {
    getLoginUsrId () {
      return this.$store.getters.loginUser.usrId
    },
    getLoginUsrNm () {
      return this.$store.getters.loginUser.usrNm
    },
    getLoginUsrCompId () {
      return this.$store.getters.loginUser.compId
    },
    getLoginUsrAuth () {
      return this.$store.getters.loginUser.auth
    },
    isLoginUsrSameToUsr (targetUsrId) {
      return this.$store.getters.loginUser.usrId === targetUsrId
    },
    isLoginUsrCompSameToComp (targetCompId) {
      return this.$store.getters.loginUser.compId === targetCompId
    }
  }
}
