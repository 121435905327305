<template>
  <div>
    <v-dialog v-model="dialogModel" persistent :max-width="maxWidth" :retain-focus="false">
      <v-card flat>
        <!-- 타이틀바 -->
        <title-bar :title="title" color="primary" dark>
          <template v-slot:action>
            <v-btn icon large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
          </template>
        </title-bar>
        <!-- 컨텐츠 -->
        <v-card flat>
          <v-card-text class="pb-0">
            <v-container fluid>
              <v-row>
                <v-col cols="12" :md="modifying ? 8 : 12">
                  <v-form ref="frm">
                    <v-row>
                      <v-container>
                        <v-sheet v-if="modifying && isAdmin" color="grey lighten-4">
                          <v-container>
                            <v-row>
                              <v-col cols="12" md="6">
                                <label-field
                                  :label="$t('word.number')"
                                  :value="item.faqNo"
                                  readonly>
                                </label-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <label-field
                                  :label="$t('word.regDt')"
                                  :value="$timeToMediumDate(item.regDt)"
                                  readonly>
                                </label-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-sheet>
                      </v-container>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="item.langVal"
                          :label="$t('word.lang') + ' (*)'"
                          :items="langValList"
                          :rules="[ requiredRule ]"
                          :readonly="!isAdmin"
                          small-chips>
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <label class="caption grey--text text--darken-2">{{ $t('word.postYn') }}</label>
                        <v-radio-group
                          v-model="item.postYn"
                          row
                          mandatory
                          class="mt-0">
                          <v-radio
                            :label="$t('word.yes')"
                            value="Y"></v-radio>
                          <v-radio
                            :label="$t('word.no')"
                            value="N"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="item.faqDivCd"
                          :label="$t('word.faqDiv') + ' (*)'"
                          :items="faqDivCdList"
                          :rules="[ requiredRule ]"
                          :readonly="!isAdmin"
                          small-chips>
                        </v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.qstn"
                          :label="$t('word.qstn') + ' (*)'"
                          filled
                          rows="3"
                          :rules="[ requiredRule, length500Rule ]">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="item.ansr"
                          :label="$t('word.ansr') + ' (*)'"
                          filled
                          rows="7"
                          :rules="[ requiredRule, length2000Rule ]">
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
                <v-col v-if="modifying" cols="12" md="4">
                  <v-row>
                    <v-col cols="12">
                      <faq-file-list-unit
                        :faq-no="$isNull(faqNo) ? item.faqNo : faqNo">
                      </faq-file-list-unit>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text large color="secondary" @click="dialogModel = false">{{ $t('word.close') }}</v-btn>
            <v-btn v-if="isAdmin" text large color="primary" @click="handleSaveClicked">{{ $t('word.save') }}</v-btn>
            <v-btn v-if="modifying && isAdmin" text large color="error" @click="handleDeleteClicked">{{ $t('word.delete') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { forms } from '@/mixins/formMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'
import LabelField from '@/components/LabelField'
import FaqFileListUnit from '@/views/faq/unit/FaqFileListUnit.vue'

export default {
  name: 'FaqDetailPopup',
  mixins: [forms, searches, stores],
  components: {
    TitleBar,
    LabelField,
    FaqFileListUnit
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    faqNo: {
      type: Number,
      required: false
    }
  },
  mounted () {
    this.$getCodes(['FD0000'], (result) => {
      const tmpFaqDivCdList = result.FD0000
      this.faqDivCdList = this.$toSelectableCodes(tmpFaqDivCdList)
      this.item.faqNo = this.faqNo
      this.fetchItem()
    })
  },
  data () {
    return {
      faqDivCdList: [],
      item: {
        langVal: 'ko',
        faqDivCd: null
      }
    }
  },
  computed: {
    langValList () {
      return [
        { text: this.$t('word.- choose -'), value: null },
        { text: this.$t('word.ko'), value: 'ko' },
        { text: this.$t('word.en'), value: 'en' }
      ]
    },
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    },
    modifying () {
      if (!this.$isNull(this.faqNo)) {
        return true
      } else {
        return !this.$isNull(this.item.faqNo)
      }
    },
    title () {
      return this.modifying ? this.$t('word.faqDtl') : this.$t('word.registFaq')
    },
    maxWidth () {
      return this.modifying ? this.$const.maxWidthLayout.lg : this.$const.maxWidthLayout.md
    }
  },
  methods: {
    // 데이터조회
    fetchItem () {
      if (!this.modifying) {
        return
      }
      this.$http.get(`/api/1/faq/${this.faqNo}`)
        .then((response) => {
          this.item = response.data.result.faq
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleSaveClicked () {
      if (!this.$refs.frm.validate(true)) {
        return
      }
      if (this.modifying) {
        this.$http.put(`/api/1/faq/${this.item.faqNo}`, this.item)
          .then((response) => {
            this.$toasted.global.success(this.$t('msg.saved'))
            this.item = response.data.result.faq
            this.$emit('item-changed', this.item)
          })
          .catch((error) => {
            this.$showError(error)
          })
      } else {
        this.$http.post('/api/1/faq', this.item)
          .then((response) => {
            this.$toasted.global.success(this.$t('msg.registed'))
            this.item = response.data.result.faq
            this.$emit('item-changed', this.item)
          })
          .catch((error) => {
            this.$showError(error)
          })
      }
    },
    handleDeleteClicked () {
      if (!this.modifying || this.$isNull(this.item.faqNo)) {
        return
      }
      if (!confirm(this.$t('FaqDetailPopup.txt001', [this.item.faqNo]))) {
        return
      }
      this.$http.delete(`/api/1/faq/${this.item.faqNo}`)
        .then(() => {
          this.$toasted.global.success('삭제 되었습니다.')
          this.dialogModel = false
          this.$emit('item-changed')
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>
