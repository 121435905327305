<template>
  <v-dialog v-model="dialogModel" persistent :max-width="$const.maxWidthLayout.md" :retain-focus="false">
    <v-card flat>
      <!-- 타이틀바 -->
      <title-bar :title="title" color="primary" dark>
        <template v-slot:action>
          <v-btn icon large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
        </template>
      </title-bar>
      <!-- 컨텐츠 -->
      <v-card>
        <v-card-text class="pb-0">
          <v-form ref="frm">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    ref="email"
                    v-model="item.email"
                    :label="$t('word.email') + ' *'"
                    maxlength="100"
                    :readonly="modifying"
                    :rules="[ requiredRule, emailRule ]"
                    @input="emailChecked = false">
                    <template v-if="!modifying" v-slot:prepend>
                      <v-icon :color="emailChecked ? 'success' : 'grey'">mdi-check-circle</v-icon>
                    </template>
                    <template v-if="!modifying" v-slot:append>
                      <v-btn depressed x-small color="action" @click="handleCheckEmailClicked">{{ $t('word.checkDuplicate') }}</v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="item.auth"
                    :label="$t('word.auth') + ' *'"
                    :items="authList"
                    :readonly="!isAdmin"
                    :rules="[ requiredRule ]"
                    small-chips>
                  </v-select>
                </v-col>
                <template v-if="!modifying">
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="item.pwd"
                      type="password"
                      :label="$t('word.pwd') + ' *'"
                      maxlength="20"
                      :rules="[ requiredRule, passwordRule ]">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="item.pwdCnfm"
                      type="password"
                      :label="$t('word.pwdCnfm') + ' *'"
                      maxlength="20"
                      :rules="[ requiredRule, passwordRule ]">
                    </v-text-field>
                  </v-col>
                </template>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="item.usrNm"
                    :label="$t('word.usrNm') + ' *'"
                    maxlength="100"
                    :rules="[ requiredRule, nameRule ]">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="item.usrEngNm"
                    :label="$t('word.usrEngNm')"
                    maxlength="100"
                    :rules="[ nameRule ]">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="item.telno"
                    :label="$t('word.telno')"
                    maxlength="20"
                    persistent-hint
                    :hint="$t('msg.guideTelno')"
                    :rules="[ telnoRule ]">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="item.hnpno"
                    :label="$t('word.hnpno') + ' *'"
                    maxlength="20"
                    persistent-hint
                    :hint="$t('msg.guideHnpno')"
                    :rules="[ requiredRule, telnoRule ]">
                  </v-text-field>
                </v-col>
                <v-col v-if="isLicensor" cols="12">
                  <v-textarea
                    v-model="item.rmk"
                    :label="$t('word.rmk')"
                    filled
                    rows="3"
                    :rules="[ length2000Rule ]">
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text large color="secondary" @click="dialogModel = false">{{ $t('word.close') }}</v-btn>
          <v-btn v-if="isAdmin || (isReviewer && isLoginUsrSameToUsr(usrId))" text large color="primary" @click="handleSaveClicked">{{ $t('word.save') }}</v-btn>
          <!--
          <v-btn v-if="modifying && isAdmin" text large color="error" @click="handleWithdrawlClicked">탈퇴</v-btn>
          -->
          <v-btn v-if="modifying && isAdmin" text large color="error" @click="handleDeleteClicked">{{ $t('word.delete') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { forms } from '@/mixins/formMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'

export default {
  name: 'LicensorUserDetailPopup',
  mixins: [forms, stores],
  components: {
    TitleBar
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    usrId: {
      type: String,
      required: false
    }
  },
  mounted () {
    this.fetchItem()
  },
  data () {
    return {
      item: {
        compId: this.getLoginUsrCompId(),
        auth: null
      },
      emailChecked: false
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    },
    modifying () {
      if (!this.$isNull(this.usrId)) {
        return true
      } else {
        return !this.$isNull(this.item.usrId)
      }
    },
    authList () {
      return [
        { text: this.$t('word.- choose -'), value: null },
        { text: this.$t('word.admin'), value: 'ROLE_ADMIN' },
        { text: this.$t('word.reviewer'), value: 'ROLE_REVIEWER' }
      ]
    },
    title () {
      return this.modifying ? this.$t('word.licrUsrDtl') : this.$t('word.registLicrUsr')
    }
  },
  methods: {
    // 데이터조회
    fetchItem () {
      if (!this.modifying) {
        return
      }
      this.$http.get(`/api/1/users/${this.usrId}`)
        .then((response) => {
          this.item = response.data.result.usr
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 이메일 중복여부 확인
    handleCheckEmailClicked () {
      if (this.modifying || !this.$refs.email.validate(true)) {
        return
      }
      this.$http.get(encodeURI(`/api/1/users/email?email=${this.item.email}`))
        .then((response) => {
          const usr = response.data.result.usr
          if (this.$isNull(usr)) {
            this.emailChecked = true
            this.$toasted.global.success(this.$t('LicensorUserDetailPopup.txt001'))
          } else {
            this.emailChecked = false
            this.$toasted.global.warn(this.$t('LicensorUserDetailPopup.txt002'))
          }
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleSaveClicked () {
      if (!this.$refs.frm.validate(true)) {
        return
      }
      if (this.modifying) {
        this.$http.put(`/api/1/users/${this.item.usrId}/info`, this.item)
          .then((response) => {
            this.$toasted.global.success(this.$t('msg.saved'))
            this.item = response.data.result.usr
            this.$emit('item-changed', this.item)
          })
          .catch((error) => {
            this.$showError(error)
          })
      } else {
        if (!this.emailChecked) {
          this.$toasted.global.warn(this.$t('LicensorUserDetailPopup.txt003'))
          return
        }
        if (this.item.pwd !== this.item.pwdCnfm) {
          this.$toasted.global.warn(this.$t('LicensorUserDetailPopup.txt004'))
          return
        }
        this.$http.post('/api/1/users', this.item)
          .then((response) => {
            this.$toasted.global.success(this.$t('msg.registed'))
            this.item = response.data.result.usr
            this.$emit('item-changed', this.item)
          })
          .catch((error) => {
            this.$showError(error)
          })
      }
    },
    /*
    // 회원 탈퇴 기능은 사용하지 않기로 협의 (2021.06.03)
    handleWithdrawlClicked () {
      if (!this.modifying || this.$isNull(this.item.usrId)) {
        return
      }
      if (!confirm(`라이선서 사용자 [${this.item.usrNm}]님을 탈퇴 처리 합니다. \n이 작업은 취소가 불가능합니다. 정말로 탈퇴 처리 하시겠습니까?`)) {
        return
      }
      this.$http.put(`/api/1/users/${this.item.usrId}/withdrawal`)
        .then(() => {
          this.$toasted.global.success('탈퇴 처리가 완료 되었습니다.')
          this.dialogModel = false
          this.$emit('item-changed', this.item)
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    */
    handleDeleteClicked () {
      if (!this.modifying || this.$isNull(this.item.usrId)) {
        return
      }
      if (!confirm(this.$t('LicensorUserDetailPopup.txt005', [this.item.usrNm]))) {
        return
      }
      this.$http.delete(`/api/1/users/${this.item.usrId}`)
        .then(() => {
          this.$toasted.global.success(this.$t('msg.deleted'))
          this.dialogModel = false
          this.$emit('item-changed')
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>
