import dayjs from 'dayjs'

const CommonPlugin = {
  install (Vue, options) {
    if (options === null || options === undefined || options.store === null || options.store === undefined) {
      throw new Error('plugin must be installed with Vuex store')
    }
    // 상수
    Vue.prototype.$const = {
      maxWidthLayout: {
        xs: 480,
        sm: 720,
        md: 1024,
        lg: 1280,
        xl: 1920
      },
      minWidthButton: 70
    }
    // 입력값이 null 이거나 undefined 이면 true, 아니면 false 리턴
    Vue.prototype.$isNull = function (param) {
      return param === null || param === undefined
    }
    // 입력값이 null 이거나 undefined 이거나 빈문자열(공백포함)이면 true, 아니면 false 리턴
    Vue.prototype.$isEmpty = function (param) {
      if (param === null || param === undefined || param === '') {
        return true
      }
      if (typeof param === 'string' || param instanceof String) {
        return param.trim().length === 0
      } else {
        return false
      }
    }
    // 입력값 배열이 null 이거나 undefined 이거나 배열의 크기가 0 이면 true, 아니면 false 리턴
    Vue.prototype.$isEmptyArray = function (param) {
      if (param === null || param === undefined || param.length === 0) {
        return true
      } else {
        return false
      }
    }
    // 텍스트 필드에 숫자만 입력 가능하게 처리
    Vue.prototype.$numericOnly = function (e) {
      let key
      if (window.event) {
        key = window.event.keyCode // IE
      } else {
        key = e.which // firefox
      }
      let event
      if (key === 0 || key === 8 || key === 46 || key === 9 || (key >= 37 && key <= 40)) {
        event = e || window.event
        if (typeof event.stopPropagation !== 'undefined') {
          event.stopPropagation()
        } else {
          event.cancelBubble = true
        }
        return
      }
      if (key < 48 || (key > 57 && key < 96) || key > 105 || e.shiftKey) {
        if (e.preventDefault) {
          e.preventDefault()
        } else {
          e.returnValue = false
        }
      }
    }
    // 텍스트 필드에 숫자, 컴마만 입력 가능하게 처리
    Vue.prototype.$numericDotOnly = function (e) {
      let key
      if (window.event) {
        key = window.event.keyCode // IE
      } else {
        key = e.which // firefox
      }
      let event
      if (key === 0 || key === 8 || key === 46 || key === 9 || (key >= 37 && key <= 40)) {
        event = e || window.event
        if (typeof event.stopPropagation !== 'undefined') {
          event.stopPropagation()
        } else {
          event.cancelBubble = true
        }
        return
      }
      if (key < 48 || (key > 57 && key < 96) || (key > 105 && key !== 190) || e.shiftKey) {
        if (e.preventDefault) {
          e.preventDefault()
        } else {
          e.returnValue = false
        }
      }
    }
    // LPAD
    Vue.prototype.$lpad = function (str, len, ch) {
      if (str === null || str === undefined) {
        return str
      }
      str = '' + str
      while (str.length < len) {
        str = ch + str
      }
      return str
    }
    // 에러 메세지를 alert 로 디스플레이
    Vue.prototype.$showError = function (error) {
      let message
      if (error && error.response && error.response.data) {
        // api 서버에서 리턴된 에러 오브젝트인 경우 처리
        if (error.response.data.errorMessage) {
          message = error.response.data.errorMessage
        } else {
          message = error.response.data.errorCode
        }
      } else if (error && error.message) {
        // 자바스크립트 에러 오브젝트인 경우 처리
        message = error.message
      }
      if (message === undefined) {
        message = this.$t('msg.unknownError')
      }
      alert(message)
    }
    Vue.prototype.$timeToShortDate = function (value) {
      if (value === null || value === undefined || isNaN(value)) {
        return value
      }
      return dayjs(value).format('YYYY-MM-DD')
    }
    Vue.prototype.$timeToMediumDate = function (value) {
      if (value === null || value === undefined || isNaN(value)) {
        return value
      }
      return dayjs(value).format('YYYY-MM-DD HH:mm')
    }
    Vue.prototype.$timeToLongDate = function (value) {
      if (value === null || value === undefined || isNaN(value)) {
        return value
      }
      return dayjs(value).format('YYYY-MM-DD HH:mm:ss')
    }
    Vue.prototype.$trimDash = function (value) {
      if (value === null || value === undefined) {
        return value
      }
      return value.replace(/-/g, '')
    }
    Vue.prototype.$formatNumber = function (value) {
      if (value === null || value === undefined) {
        return value
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    Vue.prototype.$formatYm = function (value) {
      if (value === null || value === undefined || value.length < 6) {
        return value
      }
      return value.substring(0, 4) + '-' + value.substring(4, 6)
    }
    Vue.prototype.$formatYmd = function (value) {
      if (value === null || value === undefined || value.length < 8) {
        return value
      }
      return value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8)
    }
    Vue.prototype.$formatFileSize = function (value) {
      const s = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
      const e = Math.floor(Math.log(value) / Math.log(1024))
      return (value / Math.pow(1024, e)).toFixed(2) + s[e]
    }
    Vue.prototype.$arrayItemMove = function (arr, from, to) {
      if (arr === null || arr === undefined || from < 0 || to < 0 || from >= arr.length || to >= arr.length) {
        return
      }
      arr.splice(to, 0, arr.splice(from, 1)[0])
      return arr
    }
    Vue.prototype.$getAuthNm = function (value) {
      if (value === 'ROLE_ADMIN') {
        return this.$t('word.admin')
      } else if (value === 'ROLE_REVIEWER') {
        return this.$t('word.reviewer')
      } else if (value === 'ROLE_LICENSEE') {
        return this.$t('word.licensee')
      } else {
        return ''
      }
    }
    Vue.prototype.$getLangNm = function (value) {
      if (value === 'ko') {
        return this.$t('word.ko')
      } else if (value === 'en') {
        return this.$t('word.en')
      } else {
        return ''
      }
    }
    Vue.prototype.$toSelectableCodes = function (value, withoutHeader) {
      const codes = []
      if (withoutHeader === null || withoutHeader === undefined || !withoutHeader) {
        codes.push({
          value: null,
          text: this.$t('word.- choose -')
        })
      }
      const locale = this.$i18n.locale
      if (value !== null && value !== undefined && value.length > 0) {
        for (const comCd of value) {
          codes.push({
            value: comCd.comCd,
            text: locale === 'ko' ? comCd.comCdNm : comCd.comCdEngNm
          })
        }
      }
      return codes
    }
    Vue.prototype.$toSelectableProductTypes = function (value) {
      const prodTyps = [{
        value: null,
        text: this.$t('word.- choose -')
      }]
      const locale = this.$i18n.locale
      if (value !== null && value !== undefined && value.length > 0) {
        for (const prodTyp of value) {
          prodTyps.push({
            value: prodTyp.prodTypId,
            text: locale === 'ko' ? prodTyp.prodTypNm : prodTyp.prodTypEngNm
          })
        }
      }
      return prodTyps
    }
    Vue.prototype.$getCodes = function (codes, callback) {
      if (codes == null || codes === undefined || !Array.isArray(codes) || codes.length === 0) {
        throw new Error('codes is required')
      }
      if (callback === null || callback === undefined) {
        throw new Error('callback is required')
      }
      const codeString = codes.join(',')
      this.$http.get(encodeURI(`/api/1/codes?comCds=${codeString}`))
        .then((response) => {
          callback(response.data.result)
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
    Vue.prototype.$getProductTypes = function (prodTypLv, topProdTypId, callback) {
      if (prodTypLv === null || prodTypLv === undefined || (prodTypLv !== 1 && prodTypLv !== 2)) {
        throw new Error('prodTypLv is required')
      }
      if (callback === null || callback === undefined) {
        throw new Error('callback is required')
      }
      let url = `/api/1/products/available?prodTypLv=${prodTypLv}`
      if (topProdTypId !== null && topProdTypId !== undefined) {
        url += `&topProdTypId=${topProdTypId}`
      }
      this.$http.get(encodeURI(url))
        .then((response) => {
          callback(response.data.result)
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
    Vue.prototype.$getYesOrNoCodes = function () {
      return [
        { text: this.$t('word.- choose -'), value: null },
        { text: this.$t('word.yes'), value: 'Y' },
        { text: this.$t('word.no'), value: 'N' }
      ]
    }
  }
}

export default CommonPlugin
