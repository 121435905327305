<template>
  <v-dialog v-model="dialogModel" persistent :max-width="$const.maxWidthLayout.sm" :retain-focus="false">
    <v-card flat>
      <!-- 타이틀바 -->
      <title-bar :title="$t('word.registFile')" color="primary" dark>
        <template v-slot:action>
          <v-btn icon rounded large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
        </template>
      </title-bar>
      <!-- 컨텐츠 -->
      <v-card>
        <v-card-text class="pb-0">
          <v-form ref="frm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="fileDivCd"
                    :label="$t('word.fileDiv') + ' *'"
                    :items="[$t('word.deposit')]"
                    :rules="[ requiredRule ]"
                    small-chips>
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="item"
                    :label="$t('word.atchFile') + ' *'"
                    :prepend-icon="null"
                    truncate-length="100"
                    persistent-hint
                    :hint="$t('msg.maxFileSize')"
                    :rules="[ requiredRule ]"
                    clearable>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text large color="secondary" @click="dialogModel = false">{{ $t('word.close') }}</v-btn>
          <v-btn text large color="primary" @click="handleSaveClicked">{{ $t('word.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { forms } from '@/mixins/formMixin'
import TitleBar from '@/components/TitleBar'

export default {
  name: 'StampFileRegistPopup',
  mixins: [forms],
  components: {
    TitleBar
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    stmpApplNo: {
      type: Number,
      required: false
    },
    projCd: {
      type: String,
      required: false
    }
  },
  mounted () {
    this.stmpAppl.projCd = this.projCd
    this.stmpAppl.stmpApplNo = this.stmpApplNo
  },
  data () {
    return {
      stmpAppl: {},
      fileDivCdList: [],
      fileDivCd: null,
      item: null
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      },
      modifying () {
        if (!this.$isNull(this.stmpApplNo)) {
          return true
        } else {
          return !this.$isNull(this.stmpAppl.stmpApplNo)
        }
      }
    }
  },
  methods: {
    handleSaveClicked () {
      if (!this.$refs.frm.validate(true)) {
        return
      }
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      const formData = new FormData()
      formData.append('file', this.item)
      formData.append('fileDivCd', this.fileDivCd)
      this.$http.post(`/api/1/projects/${this.stmpAppl.projCd}/stamps/${this.stmpAppl.stmpApplNo}/file`, formData, config)
        .then((response) => {
          this.$toasted.global.success(this.$t('msg.registed'))
          this.$emit('item-changed', response.data.result.stmpApplFile)
          this.dialogModel = false
          this.$router.go()
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>
