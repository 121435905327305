<template>
<v-container fluid class="fill-height">
  <v-row justify="center">
    <v-col cols="12" sm="8" lg="4">
      <div class="text-center">
        <v-chip small color="primary" class="font-weight-bold">IPTOWN</v-chip>
        <div class="mt-2">
          <h1>{{ $t('word.requestForResetPassword') }}</h1>
        </div>
        <p class="mx-6 mt-4 mb-8 grey--text text--darken-1">
          {{ $t('PasswordFindView.txt001') }} <br/>
          {{ $t('PasswordFindView.txt002') }}
        </p>
      </div>
      <v-card outlined elevation="0" class="pa-8">
        <v-card-title class="justify-center">
          <v-avatar size="60" color="grey lighten-1">
            <v-icon x-large dark>
              mdi-lock-open
            </v-icon>
          </v-avatar>
        </v-card-title>
        <v-card-text class="text-center mt-10">
          <v-form ref="frm">
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="item.hnpno"
                  :label="$t('word.hnpno')"
                  :maxlength="20"
                  filled
                  :rules="[ requiredRule, telnoRule ]"
                  @keypress.enter="handleConfirmClicked()">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.email"
                  :label="$t('word.email')"
                  :maxlength="100"
                  filled
                  :rules="[ requiredRule, emailRule ]"
                  @keypress.enter="handleConfirmClicked()">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.usrNm"
                  :label="$t('word.usrNm')"
                  :maxlength="100"
                  filled
                  :rules="[ requiredRule, nameRule ]"
                  @keypress.enter="handleConfirmClicked()">
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end mr-2">
          <v-btn tile large min-width="180" color="primary" @click="handleConfirmClicked">
            {{ $t('word.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <div class="ma-4">
        <router-link to="/login" class="grey--text text--darken-1">{{ $t('word.doLogin') }}</router-link>
        <span class="grey--text text--lighten-1"> | </span>
        <router-link to="/find/email" class="grey--text text--darken-1">{{ $t('word.findEmail') }}</router-link>
      </div>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import { forms } from '@/mixins/formMixin'

export default {
  name: 'PasswordFindView',
  mixins: [forms],
  components: {
  },
  data () {
    return {
      item: {
        hnpno: null,
        email: null,
        usrNm: null
      }
    }
  },
  computed: {
  },
  methods: {
    handleConfirmClicked () {
      if (!this.$refs.frm.validate(true)) {
        return
      }
      this.$http.post('/api/1/users/password/miss', this.item)
        .then(() => {
          this.$toasted.global.success(this.$t('PasswordFindView.txt003'))
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>

<style lang="css" scoped>
a { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:focus { text-decoration: none; }
a:hover, a:active { text-decoration: none; }
</style>
