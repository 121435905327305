export const forms = {
  data () {
    return {
    }
  },
  computed: {
    requiredRule () {
      return (v) => (v !== null && v !== undefined && v !== '' && ('' + v).trim() !== '') || this.$t('rule.required')
    },
    compCdRule () {
      return (v) => /^[A-Z]{3}$/.test(v) || this.$t('rule.compCd')
    },
    ipCdRule () {
      return (v) => /^[0-9A-Z]{3}$/.test(v) || this.$t('rule.ipCd')
    },
    nameRule () {
      return (v) => (v === null || v === undefined || v.length === 0 || v.length <= 100) || this.$t('rule.name')
    },
    fileNameRule () {
      return (v) => (v === null || v === undefined || v.length === 0 || v.length <= 300) || this.$t('rule.fileName')
    },
    passwordRule () {
      return (v) => /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,15}$|null|undefined|^$/.test(v) || this.$t('rule.password')
    },
    telnoRule () {
      return (v) => ((v === null || v === undefined || v.length === 0) || (/^[0-9\s-]*$/.test(v) && ('' + v).replace(/-/gi, '').replace(/ /gi, '').length >= 9)) || this.$t('rule.telno')
    },
    emailRule () {
      return (v) => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$|null|undefined|^$/.test(v) || this.$t('rule.email')
    },
    biznoRule () {
      return (v) => /^[0-9]{10}$|null|undefined|^$/.test(v) || this.$t('rule.bizno')
    },
    numericRule () {
      return (v) => /^[0-9]*$|null|undefined|^$/.test(v) || this.$t('rule.numeric')
    },
    numericDotRule () {
      return (v) => /^\d+(\.\d+)*$/.test(v) || this.$t('rule.numericDot')
    },
    cntrNoRule () {
      return (v) => (v === null || v === undefined || v.length === 0 || v.length <= 50) || this.$t('rule.cntrNo')
    },
    length100Rule () {
      return (v) => (v === null || v === undefined || v.length === 0 || v.length <= 100) || this.$t('rule.length100')
    },
    length500Rule () {
      return (v) => (v === null || v === undefined || v.length === 0 || v.length <= 500) || this.$t('rule.length500')
    },
    length2000Rule () {
      return (v) => (v === null || v === undefined || v.length === 0 || v.length <= 2000) || this.$t('rule.length2000')
    },
    prodTypLv1Rule () {
      return (v) => /^[A-Z]{2}$/.test(v) || this.$t('rule.prodTypCd1')
    },
    prodTypLv2Rule () {
      return (v) => /^[A-Z]{2}[0-9]{4}$/.test(v) || this.$t('rule.prodTypCd2')
    },
    ryltRtRule () {
      return (v) => (v === null || v === undefined || v < 100 || this.$t('rule.ryltRt'))
    }
  }
}
