<template>
  <v-app id="app">
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>
      <loading
        loader='dots'
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :width="100"
        :height="100"
        color="blue">
        <img src="@/assets/images/common/loading-Ellipsis-2s-200px.svg" height="120px">
      </loading>
    </v-main>
  </v-app>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
  name: 'App',
  components: {
    Loading
  },
  created () {
    // let locale = navigator.language || navigator.userLanguage
    // locale = locale.substring(0, 2)
    // if (locale !== 'ko' && locale !== 'en') {
    //   locale = 'en'
    // }
    // this.$i18n.locale = locale
  },
  mounted () {
    if (this.$store.getters.loginUser.usrId !== null) {
      this.$store.dispatch('checkSession')
      setTimeout(() => {
        this.$store.dispatch('renewToken')
      }, process.env.VUE_APP_RENEW_TOKEN_SECONDS * 1000)
    } else {
      clearTimeout(this.$store.sessionTimer)
      clearTimeout(this.$store.renewTokenTimer)
    }
  },
  data () {
    return {
    }
  },
  computed: {
    loading () {
      return this.$store.getters.loading
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
.toasted.toasted-outline {
  border-radius:30px;
  min-height:38px;
  line-height:1.1em;
  background-color:#fff;
  border:2px solid #676767;
  padding:0 20px;
  font-size:15px;
  color:#676767;
  box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);
  font-weight:700;
}
.toasted.toasted-outline.toasted-info {
  color:#42A5F5;
  border-color:#42A5F5;
}
.toasted.toasted-outline.toasted-success {
  color:#43A047;
  border-color:#43A047;
}
.toasted.toasted-outline.toasted-warn {
  color:#FB8C00;
  border-color:#FB8C00;
}
.toasted.toasted-outline.toasted-error {
  color:#F44336;
  border-color:#F44336;
}
.vue-notifyjs.notifications{
  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }
  .list-item {
    display: inline-block;
    margin-right: 10px;

  }
  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }
  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }

  .list-enter {
    opacity: 0;
    transform: scale(1.1);

  }
  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}
  .logo {
    width: 100%;
    height: 100%;
    margin-right: 10px;
  }
</style>
