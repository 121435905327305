<template>
  <v-container fluid class="pa-0">
    <!-- 왼쪽 메뉴 -->
<!--    <v-navigation-drawer-->
<!--      :clipped="$vuetify.breakpoint.lgAndDown"-->
<!--      v-model="drawer"-->
<!--      :mini-variant.sync="mini"-->
<!--      app-->
<!--      permanent-->
<!--      dark-->
<!--      :width="240">-->
      <v-navigation-drawer
        v-model="drawer"
        clipped
        app
        dark
        :width="240">
    <v-list dense>
        <template v-for="item in items">
          <template v-if="$isNull(item.menuUrl)">
            <v-subheader :key="item.menuId" class="pl-4">
              <v-list-item-subtitle style="font-size: 11pt; font-weight: bolder;">{{ item.menuNm }}</v-list-item-subtitle>
            </v-subheader>
          </template>
          <template v-else>
            <v-list-item
              :key="item.menuId"
              :to='item.menuUrl'
              color="">
              <v-list-item-action>
                <v-icon>{{ item.menuIcon }}</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ item.menuNm }}</v-list-item-title>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>
    <!-- 탑바 -->
    <v-app-bar
      clipped-left
      elevation="4"
      color="deep-purple"
      dark
      app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <a @click="handleTopbarClicked"><img src="@/assets/images/common/cjenmLabs_logo.png" height="42px" class="ml-1"></a>
      <v-spacer></v-spacer>
      <v-menu
        transition="slide-y-transition"
        bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            icon
            v-on="on"
            v-bind="attrs">
            <span class="material-icons">language</span>
          </v-btn>
        </template>
        <v-list
          dense
          :min-width="200">
          <v-list-item @click="changeKor">
            <v-list-item-title class="text-center">
              {{ $t('word.korean') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="changeEng">
            <v-list-item-title class="text-center">
              {{ $t('word.en') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
<!--        <template>-->
<!--          <li class="nav-item dropdown">-->
<!--            <a-->
<!--              href="#"-->
<!--              role="button"-->
<!--              id="navbarDropdown"-->
<!--              aria-haspopup="true"-->
<!--              aria-expanded="false"-->
<!--              data-toggle="dropdown"-->
<!--              class="nav-link dropdown-toggle"-->
<!--            >-->
<!--              <img-->
<!--                :src="icons[currentLocale]"-->
<!--                class="country-icon as-toggle"-->
<!--              />-->
<!--            </a>-->
<!--            <div-->
<!--              aria-labelledby="navbarDropdown"-->
<!--              class="dropdown-menu dropdown-menu-right"-->
<!--            >-->
<!--              <router-link-->
<!--                v-for="locale in locales"-->
<!--                :key="locale.code"-->
<!--                :to="`/${locale.code}`"-->
<!--                class="dropdown-item"-->
<!--              >-->
<!--                <img :src="icons[locale.code]" class="country-icon" />-->
<!--                <span class="locale-name">{{locale.name}}</span>-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </li>-->
<!--        </template>-->
      </v-menu>
      <v-menu
        transition="slide-y-transition"
        bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            icon
            v-on="on"
            v-bind="attrs">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-list
          dense
          :min-width="200">
          <v-list-item>
            <v-list-item-title class="text-center subtitle-2 grey--text">
              {{ this.getLoginUsrNm() }}{{ $t('word.postUsrNm') }}
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="handleMyPageClicked">
            <v-list-item-title class="text-center">
              {{ $t('word.mypage') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="handleLogoutClicked">
            <v-list-item-title class="text-center">
              {{ $t('word.logout') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- 메인 -->
    <v-container fluid>
      <v-fade-transition mode="out-in">
        <router-view :key="$route.fullPath"></router-view>
      </v-fade-transition>
    </v-container>
    <!-- 푸터 -->
<!--    <v-footer padless>-->
<!--      <v-col-->
<!--        class="text-center"-->
<!--        cols="12">-->
<!--        {{ new Date().getFullYear() }} ⓒ Xosoft — <strong>IPTOWN</strong>-->
<!--      </v-col>-->
<!--    </v-footer>-->
  </v-container>
</template>

<script>
import { stores } from '@/mixins/storeMixin'
import i18n from './i18n/messages'
import { locales } from '@/i18n/i18n'

export default {
  name: 'AppLayout',
  mixins: [stores],
  components: {
  },
  mounted () {
    const auth = this.getLoginUsrAuth()
    const allRoutes = this.$router.options.routes
    const mainRoutes = allRoutes.find(elem => elem.path === '/main').children
    let routeInfo
    let targetRoles
    let roleIdx
    // alert(this.$i18n.locale)
    for (const item of this.allItems) {
      // 디스플레이 여부 체크
      if (!item.display) {
        continue
      }
      // 카테고리인 경우
      if (this.$isNull(item.menuUrl)) {
        targetRoles = item.roles
      } else {
        // 라우트 정보 검색
        routeInfo = mainRoutes.find(elem => elem.path === item.menuUrl)
        if (this.$isNull(routeInfo)) {
          continue
        }
        targetRoles = routeInfo.meta.roles
      }
      // 권한 일치여부 확인
      roleIdx = targetRoles.findIndex(elem => elem === auth)
      if (roleIdx >= 0) {
        this.items.push(item)
      }
    }
    this.fetchList()
  },
  data () {
    return {
      drawer: true,
      items: [],
      icons: {
        ko: 'Korean',
        en: 'English'
      },
      locales,
      allItems: [
        { menuId: 100, menuNm: this.$t('word.stat'), display: false, roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE'] },
        { menuId: 110, menuNm: this.$t('word.dashboard'), menuIcon: 'mdi-view-dashboard', menuUrl: '/main/dashboard', roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE'], display: true },
        { menuId: 200, menuNm: this.$t('word.proj'), display: true, roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE'] },
        { menuId: 210, menuNm: this.$t('word.allProj'), menuIcon: 'mdi-plus-circle-outline', menuUrl: '/main/project/all', roles: ['ROLE_ADMIN', 'ROLE_REVIEWER'], display: true },
        { menuId: 220, menuNm: this.$t('word.newProj'), menuIcon: 'mdi-plus-circle-outline', menuUrl: '/main/project/new', display: true },
        { menuId: 230, menuNm: this.$t('word.onProj'), menuIcon: 'mdi-arrow-right-circle-outline', menuUrl: '/main/project/on', display: true },
        { menuId: 240, menuNm: this.$t('word.modifiedProj'), menuIcon: 'mdi-arrow-left-circle-outline', menuUrl: '/main/project/modified', display: true },
        { menuId: 250, menuNm: this.$t('word.completedProj'), menuIcon: 'mdi-checkbox-marked-circle-outline', menuUrl: '/main/project/completed', display: true },
        { menuId: 260, menuNm: this.$t('word.canceledProj'), menuIcon: 'mdi-close-circle-outline', menuUrl: '/main/project/canceled', display: true },
        // { menuId: 260, menuNm: '반려 프로젝트', menuIcon: 'mdi-alert-circle-outline', menuUrl: '/main/project/rejected', display: true },
        { menuId: 270, menuNm: this.$t('word.manageStmpAppl'), menuIcon: 'mdi-postage-stamp', menuUrl: '/main/stamp', newIcon: '', display: true },
        { menuId: 300, menuNm: this.$t('word.sysManage'), display: true, roles: ['ROLE_ADMIN', 'ROLE_REVIEWER'] },
        { menuId: 310, menuNm: this.$t('word.manageProdTyp'), menuIcon: 'mdi-tag', menuUrl: '/main/productType', display: true },
        { menuId: 320, menuNm: this.$t('word.manageIp'), menuIcon: 'mdi-ghost', menuUrl: '/main/ip', display: true },
        { menuId: 330, menuNm: this.$t('word.manageLiceComp'), menuIcon: 'mdi-domain', menuUrl: '/main/company', display: true },
        { menuId: 340, menuNm: this.$t('word.manageCntr'), menuIcon: 'mdi-file-document', menuUrl: '/main/contract', display: true },
        { menuId: 350, menuNm: this.$t('word.manageLiceUsr'), menuIcon: 'mdi-account', menuUrl: '/main/licensee', display: true },
        { menuId: 360, menuNm: this.$t('word.manageLicrUsr'), menuIcon: 'mdi-account-tie', menuUrl: '/main/licensor', display: true },
        // { menuId: 400, menuNm: this.$t('word.opManage'), display: true, roles: ['ROLE_ADMIN, ROLE_REVIEWER'] },
        { menuId: 400, menuNm: this.$t('word.opManage'), display: true, roles: ['ROLE_ADMIN', 'ROLE_REVIEWER'] },
        { menuId: 401, menuNm: this.$t('word.useGuide'), display: true, roles: ['ROLE_LICENSEE'] },
        { menuId: 410, menuNm: this.$t('word.manageNtc'), menuIcon: 'mdi-bulletin-board', menuUrl: '/main/ntc', roles: ['ROLE_ADMIN', 'ROLE_REVIEWER'], display: true },
        { menuId: 411, menuNm: this.$t('word.ntc'), menuIcon: 'mdi-bulletin-board', menuUrl: '/main/licentc', roles: ['ROLE_LICENSEE'], display: true },
        { menuId: 420, menuNm: this.$t('word.manageFaq'), menuIcon: 'mdi-frequently-asked-questions', menuUrl: '/main/faq', roles: ['ROLE_ADMIN', 'ROLE_REVIEWER'], display: true },
        { menuId: 421, menuNm: this.$t('word.faq'), menuIcon: 'mdi-frequently-asked-questions', menuUrl: '/main/licefaq', roles: ['ROLE_LICENSEE'], display: true },
        { menuId: 430, menuNm: this.$t('word.statsRead'), menuIcon: 'mdi-chart-bar', menuUrl: '/main/statistics', roles: ['ROLE_ADMIN', 'ROLE_REVIEWER'], display: true }
      ]
    }
  },
  computed: {
    currentLocale () {
      return this.$route.params.locale
    }
  },
  methods: {
    handleTopbarClicked () {
      this.$router.push('/main/project/all')
        .catch(() => {
        })
    },
    handleMyPageClicked () {
      this.$router.push('/main/mypage')
        .catch(() => {
        })
    },
    handleLogoutClicked () {
      this.$store.dispatch('logout')
        .then(() => {
          this.$toasted.global.success(this.$t('AppLayout.txt001'))
          this.$router.push('/login')
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    changeKor () {
      if (this.$i18n.locale === 'en') {
        this.$i18n.locale = 'ko'
        this.allItems[2].menuNm = i18n.t('word.proj')
        this.allItems[3].menuNm = i18n.t('word.allProj')
        this.allItems[4].menuNm = i18n.t('word.newProj')
        this.allItems[5].menuNm = i18n.t('word.onProj')
        this.allItems[6].menuNm = i18n.t('word.modifiedProj')
        this.allItems[7].menuNm = i18n.t('word.completedProj')
        this.allItems[8].menuNm = i18n.t('word.canceledProj')
        this.allItems[9].menuNm = i18n.t('word.manageStmpAppl')
        this.allItems[10].menuNm = i18n.t('word.sysManage')
        this.allItems[11].menuNm = i18n.t('word.manageProdTyp')
        this.allItems[12].menuNm = i18n.t('word.manageIp')
        this.allItems[13].menuNm = i18n.t('word.manageLiceComp')
        this.allItems[14].menuNm = i18n.t('word.manageCntr')
        this.allItems[15].menuNm = i18n.t('word.manageLiceUsr')
        this.allItems[16].menuNm = i18n.t('word.manageLicrUsr')
        this.allItems[17].menuNm = i18n.t('word.opManage')
        this.allItems[18].menuNm = i18n.t('word.useGuide')
        this.allItems[19].menuNm = i18n.t('word.manageNtc')
        this.allItems[20].menuNm = i18n.t('word.ntc')
        this.allItems[21].menuNm = i18n.t('word.manageFaq')
        this.allItems[22].menuNm = i18n.t('word.faq')
        this.allItems[22].menuNm = i18n.t('word.statsRead')
        this.$router.go(0)
      }
    },
    changeEng () {
      this.$nextTick(function () {
        if (this.$i18n.locale === 'ko') {
          this.$i18n.locale = 'en'
          this.allItems[2].menuNm = i18n.t('word.proj')
          this.allItems[3].menuNm = i18n.t('word.allProj')
          this.allItems[4].menuNm = i18n.t('word.newProj')
          this.allItems[5].menuNm = i18n.t('word.onProj')
          this.allItems[6].menuNm = i18n.t('word.modifiedProj')
          this.allItems[7].menuNm = i18n.t('word.completedProj')
          this.allItems[8].menuNm = i18n.t('word.canceledProj')
          this.allItems[9].menuNm = i18n.t('word.manageStmpAppl')
          this.allItems[10].menuNm = i18n.t('word.sysManage')
          this.allItems[11].menuNm = i18n.t('word.manageProdTyp')
          this.allItems[12].menuNm = i18n.t('word.manageIp')
          this.allItems[13].menuNm = i18n.t('word.manageLiceComp')
          this.allItems[14].menuNm = i18n.t('word.manageCntr')
          this.allItems[15].menuNm = i18n.t('word.manageLiceUsr')
          this.allItems[16].menuNm = i18n.t('word.manageLicrUsr')
          this.allItems[17].menuNm = i18n.t('word.opManage')
          this.allItems[18].menuNm = i18n.t('word.useGuide')
          this.allItems[19].menuNm = i18n.t('word.manageNtc')
          this.allItems[20].menuNm = i18n.t('word.ntc')
          this.allItems[21].menuNm = i18n.t('word.manageFaq')
          this.allItems[22].menuNm = i18n.t('word.faq')
          this.allItems[22].menuNm = i18n.t('word.statsRead')
        }
      })
    }
  }
}
</script>

<style lang="css">
.v-main {
  background-color: #F9F9F9 !important;
}
</style>
