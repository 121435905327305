export const defaultLocale = 'ko'

export const locales = [
  {
    code: 'ko',
    name: 'Korean',
    dir: '@/i18n/ko.json'
  },
  {
    code: 'en',
    name: 'English',
    dir: '@/i18n/en.json'
  }
]
