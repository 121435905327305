<template>
<v-container class="fill-height">
  <v-row align="center" justify="center">
    <v-col cols="10" sm="12" style="max-width: 700px">
      <v-card dark outlined elevation="5" class="pa-8">
        <v-card-title>
          <v-toolbar color="#1e1e1e" flat style="padding-left: 65px">
            <v-toolbar-title class="primary--text">
              <img src="@/assets/images/common/cjenmLabs_logo.png" height="60px" class="mt-1">
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="text-center" style="padding-left: 100px">
          <v-form ref="frm" style="width: 400px">
            <v-text-field
              v-model="item.email"
              :placeholder="$t('word.email')"
              :maxlength="100"
              prepend-inner-icon="mdi-account"
              filled
              :rules="[ requiredRule, emailRule ]"
              @keypress.enter="handleLoginClicked()">
            </v-text-field>
            <v-text-field
              v-model="item.pwd"
              type="password"
              :placeholder="$t('word.pwd')"
              :maxlength="100"
              prepend-inner-icon="mdi-lock"
              filled
              :rules="[ requiredRule, passwordRule ]"
              @keypress.enter="handleLoginClicked()">
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end mr-10" style="padding-right: 60px">
          <v-btn tile large min-width="180" color="primary" class="justify-center" @click="handleLoginClicked()">
            <h3 style="font-size: 16pt; font-weight: normal">{{ $t('word.login') }}</h3>
          </v-btn>
        </v-card-actions>
      </v-card>
      <div class="ma-4">
        <router-link to="/find/email" class="grey--text text--darken-1">{{ $t('word.findEmail') }}</router-link>
        <span class="grey--text text--lighten-1"> | </span>
        <router-link to="/find/password" class="grey--text text--darken-1">{{ $t('word.resetPassword') }}</router-link>
      </div>
      <!--<v-footer padless color="#f9f9f9">
        <v-col
          style="background-color: #f9f9f9"
          class="ma-4 text-center"
          cols="12">
          {{ new Date().getFullYear() }} ⓒ Xosoft — <strong>IPTOWN</strong>
        </v-col>
      </v-footer>
      -->
      <!--<br>
      <hr>
      <v-footer padless>
        <v-row justify="center" no-gutters style="background-color: #f9f9f9">
          <v-btn  href="https://cjenm-labs.iptown.net/login" color="#1e1e1e" text rounded class="my-2">
            Home
          </v-btn>
          <v-btn  href="http://www.xosoft.kr/main.jsp" color="#1e1e1e" text rounded class="my-2">
            About Us
          </v-btn>
          <v-btn  href="http://www.xosoft.kr/main.jsp" color="#1e1e1e" text rounded class="my-2">
            Team
          </v-btn>
          <v-btn  href="http://www.xosoft.kr/main.jsp" color="#1e1e1e" text rounded class="my-2">
            Services
          </v-btn>
          <v-btn  href="http://www.xosoft.kr/main.jsp" color="#1e1e1e" text rounded class="my-2">
            Contact Us
          </v-btn>
          <v-col
            style="background-color: #f9f9f9; color: #1e1e1e"
            class="lighten-2 py-4 text-center"
            cols="12"
          >
            {{ new Date().getFullYear() }} ⓒ Xosoft — <strong>IPTOWN</strong>
          </v-col>
        </v-row>
      </v-footer>-->
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import { forms } from '@/mixins/formMixin'
import { stores } from '@/mixins/storeMixin'

export default {
  name: 'LoginView',
  mixins: [forms, stores],
  components: {
  },
  props: {
  },
  created () {
  },
  mounted () {
  },
  data () {
    return {
      // 로그인 폼
      item: {
        email: null,
        pwd: null
      }
    }
  },
  computed: {
  },
  methods: {
    handleLoginClicked () {
      if (!this.$refs.frm.validate(true)) {
        return
      }
      this.$store.dispatch('login', this.item)
        .then((result) => {
          this.$toasted.global.success(this.$t('LoginView.txt001', [this.getLoginUsrNm()]))
          this.$router.replace(result)
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>

<style lang="css" scoped>
a { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:focus { text-decoration: none; }
a:hover, a:active { text-decoration: none; }
ul {
  list-style: none;
}
</style>
