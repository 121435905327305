<template>
  <div>
    <v-card
      v-if="!$isEmptyArray(items)"
      outlined
      class="mb-4">
      <v-card-subtitle class="py-3">{{ $t('word.atchFile') }}</v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text class="pa-3">
        <ul>
          <li
            v-for="(item, index) in items"
            :key="index">
            <a v-if="!$isNull(item.faqNo)"
              @click="handleInlineDownloadClicked(item)"  class="black--text">
              {{ item.mngFileNm }}
            </a>
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { searches } from '@/mixins/searchMixin'
import fileDownload from 'js-file-download'

export default {
  name: 'FaqLicenseeFileListUnit',
  mixins: [searches],
  props: {
    faqNo: {
      type: Number,
      required: false
    }
  },
  mounted () {
    this.fetchList()
  },
  data () {
    return {
      // 첨부파일 목록
      items: [],
      // 첨부파일 목록에서 선택된 아이템
      selectedItem: null
    }
  },
  methods: {
    // 목록 조회
    fetchList () {
      if (this.$isNull(this.faqNo)) {
        return
      }
      this.$http.get(this.toRequestUrl(`/api/1/faq/${this.faqNo}/file`))
        .then((response) => {
          const page = response.data.result.faqFilePage
          this.items = page.list
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleInlineDownloadClicked (item) {
      this.selectedItem = item
      this.$nextTick(() => {
        this.handleDownloadClicked()
      })
    },
    handleDownloadClicked () {
      if (this.$isNull(this.selectedItem)) {
        this.$toasted.global.warn(this.$t('msg.chooseDownloadFile'))
        return
      }
      this.$http.get(`/api/1/faqFile/${this.faqNo}/file/${this.selectedItem.faqFileNo}/download`, { responseType: 'blob' })
        .then((response) => {
          // 파일이름 설정
          const contentDisposition = response.headers['content-disposition']
          let downFileNm = null
          if (!this.$isNull(contentDisposition)) {
            const idx = contentDisposition.indexOf(';filename*=UTF-8\'\'')
            if (idx >= 0) {
              downFileNm = decodeURIComponent(contentDisposition.substring(idx + 18))
            }
          }
          if (this.$isNull(downFileNm)) {
            downFileNm = this.fileNm
          }
          fileDownload(response.data, downFileNm)
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>
