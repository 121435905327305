<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="slide-y-transition"
    offset-y
    min-width="290px"
    max-width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        slot="activator"
        v-model="baseYmd"
        v-bind="attrs"
        v-on="on"
        :style="style"
        :label="label"
        :background-color="backgroundColor"
        :outlined="outlined"
        :filled="filled"
        :dense="dense"
        :disabled="disabled"
        :clearable="clearable"
        readonly
        :hide-details="hideDetails"
        :rules="rules">
      </v-text-field>
    </template>
    <v-date-picker
      v-model="baseYmd"
      locale="ko-kr"
      no-title
      @input="menu=false">
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false
    },
    style: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    backgroundColor: {
      type: String,
      required: false
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    filled: {
      type: Boolean,
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    rules: {
      type: Array,
      required: false
    }
  },
  watch: {
    value (to) {
      this.baseYmd = this.$formatYmd(this.$trimDash(to))
    },
    baseYmd (to) {
      this.$emit('input', this.$trimDash(to))
    }
  },
  data () {
    return {
      menu: false,
      baseYmd: this.$formatYmd(this.$trimDash(this.value))
    }
  }
}
</script>
