export const pages = {
  data () {
    return {
      comTtlCnt: 0,
      comItemCntPerPage: 20,
      comPageNo: 1,
      comPageCnt: 1,
      comSrtBy: [],
      comSrtDesc: [],
      comWaitingFetch: false
    }
  },
  methods: {
    bindComPage (result) {
      this.comTtlCnt = result.comTtlCnt
      this.comItemCntPerPage = result.comItemCntPerPage
      this.comPageNo = result.comPageNo
      this.comPageCnt = result.comPageCnt
    },
    clearComPage (itemCntPerPage) {
      this.comTtlCnt = 0
      this.comItemCntPerPage = this.$isNull(itemCntPerPage) ? 20 : itemCntPerPage
      this.comPageNo = 1
      this.comPageCnt = 1
      this.comSrtBy = []
      this.comSrtDesc = []
    },
    // IMPORTANT:
    // 화면에서 발생한 한번의 이벤트 (테이블 헤더 클릭)가 comSrtBy 와 comSrtDesc 의 변경을 각각 발생시키기 때문에 중복해서 서버에 데이터 요청이 전달되게 된다.
    // 따라서 comSrtBy 와 comSrtDesc 변경사항 이벤트 핸들러는 처리에 딜레이를 줘서 가능하면 서버에 한번만 데이터 요청을 하도록 처리한다
    delayedCall (callback) {
      if (this.$isNull(callback)) {
        return
      }
      if (this.comWaitingFetch) {
        return
      }
      this.comWaitingFetch = true
      setTimeout(() => {
        this.comWaitingFetch = false
        callback()
      }, 100)
    }
  }
}
