import BaseInput from '@/components/inputs/BaseInput.vue'
import BaseCheckbox from '@/components/inputs/BaseCheckbox.vue'
import BaseRadio from '@/components/inputs/BaseRadio.vue'
import BaseDropdown from '@/components/BaseDropdown.vue'
import Card from '@/components/cards/Card'

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install (Vue) {
    Vue.component(BaseInput.name, BaseInput)
    Vue.component(BaseCheckbox.name, BaseCheckbox)
    Vue.component(BaseRadio.name, BaseRadio)
    Vue.component(BaseDropdown.name, BaseDropdown)
    Vue.component('card', Card)
  }
}

export default GlobalComponents
