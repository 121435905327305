<template>
  <v-dialog v-model="dialogModel" persistent scrollable :max-width="$const.maxWidthLayout.lg" :retain-focus="false">
    <v-card flat>
      <!-- 타이틀바 -->
      <title-bar :title="$t('word.chooseIp')" color="primary" dark>
        <template v-slot:action>
          <v-btn icon large dark @click="handleApplyClicked"><v-icon>mdi-check</v-icon></v-btn>
          <v-btn icon large dark @click="fetchList"><v-icon>mdi-magnify</v-icon></v-btn>
          <v-btn icon large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
        </template>
      </title-bar>
      <!-- 검색조건 -->
      <field-bar :field-size="2" :lg="2" :md="3">
        <template v-slot:field-1>
          <v-select
            v-model="srchDiv"
            :items="srchDivList"
            :label="$t('word.srchDiv')"
            background-color="white"
            outlined
            dense
            hide-details>
          </v-select>
        </template>
        <template v-slot:field-2>
          <v-text-field
            v-model="kwd"
            :label="$t('word.kwd')"
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @keypress.enter="fetchList(true)">
          </v-text-field>
        </template>
      </field-bar>
      <!-- 테이블 -->
      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="items"
        item-key="ipId"
        height="72vh"
        class="text-truncate"
        fixed-header
        show-select
        single-select
        disable-filtering
        disable-pagination
        loading-text=""
        no-data-text=""
        no-results-text=""
        hide-default-footer
        :server-items-length="comTtlCnt"
        :sort-by.sync="comSrtBy"
        :sort-desc.sync="comSrtDesc"
        @click:row="handleRowClicked"
        @update:sort-by="delayedCall(fetchList)"
        @update:sort-desc="delayedCall(fetchList)">
        <template v-slot:item.modDt="{ value }">
          <td>{{ $timeToMediumDate(value) }}</td>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn x-small depressed color="secondary" @click="handleInlineApplyClicked(item)">{{ $t('word.apply') }}</v-btn>
        </template>
        <template v-slot:body.append="{ headers }">
          <tr>
            <td :colspan="headers.length">
              {{ $t('word.total') }} {{ $formatNumber(comTtlCnt) }}{{ $t('word.count') }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <!-- 페이징 -->
      <paging-bar
        :page-no.sync="comPageNo"
        :page-cnt.sync="comPageCnt"
        :item-cnt-per-page.sync="comItemCntPerPage"
        @paging-changed="fetchList">
      </paging-bar>
    </v-card>
  </v-dialog>
</template>

<script>
import { sorts } from '@/mixins/sortMixin'
import { pages } from '@/mixins/pageMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'
import FieldBar from '@/components/FieldBar'
import PagingBar from '@/components/PagingBar'

export default {
  name: 'IpListView',
  mixins: [sorts, pages, searches, stores],
  components: {
    TitleBar,
    FieldBar,
    PagingBar
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    ipId: {
      type: String,
      required: false,
      default: null
    },
    ipNm: {
      type: String,
      required: false,
      default: null
    },
    useYn: {
      type: String,
      required: false,
      default: null
    }
  },
  mounted () {
    this.fetchList()
  },
  data () {
    return {
      // 테이블 헤더
      headers: [
        { text: this.$t('word.ipCd'), value: 'ipCd' },
        { text: this.$t('word.ipNm'), value: 'ipNm' },
        { text: this.$t('word.ipEngNm'), value: 'ipEngNm' },
        { text: this.$t('word.useYn'), value: 'useYn', sortable: false },
        { text: this.$t('word.modDt'), value: 'modDt', sortable: false },
        { text: '', value: 'action', width: '1px', sortable: false }
      ],
      // 조회결과 목록
      items: [],
      srchDiv: 'ipNm',
      kwd: null,
      // 선택 아이템
      selectedItems: []
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    },
    ipIdModel: {
      get () {
        return this.ipId
      },
      set (value) {
        this.$emit('update:ipId', value)
      }
    },
    ipNmModel: {
      get () {
        return this.ipNm
      },
      set (value) {
        this.$emit('update:ipNm', value)
      }
    },
    srchDivList () {
      return [
        { text: this.$t('word.ipCd'), value: 'ipCd' },
        { text: this.$t('word.ipNm'), value: 'ipNm' }
      ]
    }
  },
  methods: {
    // 목록조회
    fetchList (shouldClear) {
      // 검색 초기화
      if (shouldClear) {
        this.comPageNo = 1
        this.selectedItems = []
      }
      // 검색 파라미터 할당
      this.clearComParam()
      this.appendParam('useYn', this.useYn)
      if (!this.$isEmpty(this.kwd)) {
        this.appendParam(this.srchDiv, this.kwd)
      }
      // 검색
      this.$http.get(this.toRequestUrl('/api/1/ip'))
        .then((response) => {
          const page = response.data.result.ipPage
          this.bindComPage(page)
          this.items = page.list
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleInlineApplyClicked (item) {
      if (this.$isNull(item) || (item instanceof Event)) {
        return
      }
      this.selectedItems = [item]
      this.$nextTick(() => {
        this.handleApplyClicked()
      })
    },
    handleApplyClicked () {
      if (this.$isEmptyArray(this.selectedItems)) {
        this.$toasted.global.warn(this.$t('IpListPopup.txt001'))
        return
      }
      const myIp = this.selectedItems[0]
      this.ipIdModel = myIp.ipId
      this.ipNmModel = myIp.ipNm
      this.dialogModel = false
    },
    // 테이블 로우 클릭시
    handleRowClicked (item) {
      this.selectedItems = [item]
    }
  }
}
</script>
