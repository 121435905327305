<template>
  <div>
    <v-card tile>
      <!-- 검색조건 -->
      <five-field-bar :field-size="5" >
        <template v-slot:field-1>
          <date-field
            v-model="strYmd"
            :label="$t('word.inspApplYmd') + ' (FROM)'"
            background-color="white"
            outlined
            dense
            hide-details>
          </date-field>
        </template>
        <template v-slot:field-2>
          <date-field
            v-model="endYmd"
            :label="$t('word.inspApplYmd') + ' (TO)'"
            background-color="white"
            outlined
            dense
            hide-details>
          </date-field>
        </template>
        <template v-slot:field-3>
          <v-select
            v-model="inspStgCd"
            :items="inspStgCdList"
            :label="$t('word.inspStg')"
            background-color="white"
            outlined
            dense
            hide-details>
          </v-select>
        </template>
        <template v-slot:field-4>
          <v-select
            v-model="inspStsCd"
            :items="inspStsCdList"
            :label="$t('word.inspSts')"
            background-color="white"
            outlined
            dense
            hide-details>
          </v-select>
        </template>
        <template v-slot:field-5>
          <v-text-field
            v-model="inspNo"
            :label="$t('word.inspNo')"
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @keypress.enter="fetchList(true)">
          </v-text-field>
        </template>
      </five-field-bar>
      <!-- 테이블 -->
      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="items"
        item-key="inspNo"
        height="29vh"
        class="text-truncate"
        fixed-header
        show-select
        single-select
        disable-filtering
        disable-pagination
        loading-text=""
        no-data-text=""
        no-results-text=""
        hide-default-footer
        :server-items-length="comTtlCnt"
        :sort-by.sync="comSrtBy"
        :sort-desc.sync="comSrtDesc"
        @click:row="handleRowClicked"
        @update:sort-by="delayedCall(fetchList)"
        @update:sort-desc="delayedCall(fetchList)">
        <template v-slot:item.inspApplDt="{ value }">
          <td>{{ $timeToShortDate(value )}}</td>
        </template>
        <template v-slot:item.inspDt="{ value }">
          <td>{{ $timeToShortDate(value )}}</td>
        </template>
        <template v-slot:item.modDt="{ value }">
          <td>{{ $timeToMediumDate(value) }}</td>
        </template>
        <template v-slot:body.append="{ headers }">
          <tr>
            <td :colspan="headers.length">
              {{ $t('word.total') }} {{ $formatNumber(comTtlCnt) }}{{ $t('word.count') }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <!-- 페이징 -->
      <paging-bar
        :page-no.sync="comPageNo"
        :page-cnt.sync="comPageCnt"
        :item-cnt-per-page.sync="comItemCntPerPage"
        @paging-changed="fetchList">
      </paging-bar>
    </v-card>
    <!-- 검수 상세 팝업 -->
    <inspection-detail-popup
      v-if="showInspectionDetailPopup"
      :dialog.sync="showInspectionDetailPopup"
      :proj-cd="projCd"
      :insp-no="selectedItems.length === 0 ? null : selectedItems[0].inspNo"
      @item-changed="handleInspectionDetailChanged">
    </inspection-detail-popup>
    <!-- 검수 등록 팝업 -->
    <inspection-regist-popup
      v-if="showInspectionRegistPopup"
      :dialog.sync="showInspectionRegistPopup"
      :comp-id="compId"
      :proj-cd="projCd"
      :insp-no="selectedItems.length === 0 ? null : selectedItems[0].inspNo"
      @item-changed="handleInspectionRegistChanged">
    </inspection-regist-popup>
  </div>
</template>

<script>
import { sorts } from '@/mixins/sortMixin'
import { pages } from '@/mixins/pageMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import FiveFieldBar from '@/components/FiveFieldBar'
import DateField from '@/components/DateField'
import PagingBar from '@/components/PagingBar'
import InspectionDetailPopup from '@/views/project/popup/InspectionDetailPopup'
import InspectionRegistPopup from '@/views/project/popup/InspectionRegistPopup'

export default {
  name: 'InspectionListUnit',
  mixins: [sorts, pages, searches, stores],
  components: {
    FiveFieldBar,
    DateField,
    PagingBar,
    InspectionDetailPopup,
    InspectionRegistPopup
  },
  props: {
    compId: {
      type: String,
      required: false
    },
    projCd: {
      type: String,
      required: false
    }
  },
  mounted () {
    // 공통코드 조회
    this.$getCodes(['PT0000', 'IS0000'], (result) => {
      this.inspStgCdList = this.$toSelectableCodes(result.PT0000)
      this.inspStsCdList = this.$toSelectableCodes(result.IS0000)
      this.fetchList()
    })
  },
  data () {
    return {
      inspStgCdList: [],
      inspStsCdList: [],
      // 테이블 헤더
      headers: [
        { text: this.$t('word.inspNo'), value: 'inspNo' },
        { text: this.$t('word.inspStg'), value: 'inspStgCdNm', sortable: false },
        { text: this.$t('word.nxtInspStg'), value: 'nxtInspStgCdNm', sortable: false },
        { text: this.$t('word.inspSts'), value: 'inspStsCdNm', sortable: false },
        { text: this.$t('word.inspApplYmd'), value: 'inspApplDt' },
        { text: this.$t('word.insprNm'), value: 'insprNm' },
        { text: this.$t('word.inspYmd'), value: 'inspDt' },
        { text: this.$t('word.modDt'), value: 'modDt', sortable: false }
      ],
      // 조회결과 목록
      items: [],
      // 검색조건
      strYmd: null,
      endYmd: null,
      inspStgCd: null,
      inspStsCd: null,
      inspNo: null,
      // 선택 아이템
      selectedItems: [],
      showInspectionDetailPopup: false,
      showInspectionRegistPopup: false
    }
  },
  methods: {
    // 목록조회
    fetchList (shouldClear) {
      // 검색 초기화
      if (shouldClear) {
        this.comPageNo = 1
        this.selectedItems = []
      }
      // 검색 파라미터 할당
      this.clearComParam()
      this.appendParam('strYmd', this.strYmd)
      this.appendParam('endYmd', this.endYmd)
      this.appendParam('inspStgCd', this.inspStgCd)
      this.appendParam('inspStsCd', this.inspStsCd)
      this.appendParam('inspNo', this.inspNo)
      // 검색
      this.$http.get(this.toRequestUrl(`/api/1/projects/${this.projCd}/inspection`))
        .then((response) => {
          const page = response.data.result.dsgnInspPage
          this.bindComPage(page)
          this.items = page.list
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 초기화 버튼 클릭시
    handleClearClicked () {
      this.strYmd = null
      this.endYmd = null
      this.inspStgCd = null
      this.inspStsCd = null
      this.inspNo = null
      this.fetchList(true)
    },
    // 테이블 로우 클릭시
    handleRowClicked (item) {
      this.selectedItems = [item]
      if (item.inspStsCd === 'IS0100') { // 작성중
        if (this.isLicensee) {
          this.showInspectionRegistPopup = true
        } else {
          this.$toasted.global.info(this.$t('InspectionListUnit.txt001'))
        }
      } else {
        this.showInspectionDetailPopup = true
      }
    },
    // 디자인검수 추가 버튼 클릭 시
    handleAddClicked () {
      this.selectedItems = []
      this.showInspectionRegistPopup = true
    },
    // 디자인검수 상세 팝업 변경시 (조건부승인/승인/반려)
    handleInspectionDetailChanged () {
      this.$router.replace('/main/project/on?bc=1')
    },
    // 디자인검수 추가 팝업 변경시 (임시저장/승인요청/삭제)
    handleInspectionRegistChanged (item) {
      if (!this.$isNull(item) && item.inspStsCd === 'IS0200') { // 승인요청
        this.$router.replace('/main/project/modified?bc=1')
      } else {
        this.fetchList()
      }
    }
  }
}
</script>
