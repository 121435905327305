<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="8">
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" md="6">
                  <label-field
                    :label="$t('word.cntrNo')"
                    :value="item.cntrNo">22
                  </label-field>
                </v-col>
                <v-col cols="12" md="6">
                  <label-field
                    :label="$t('word.cntrNm')"
                    :value="item.cntrNm">
                  </label-field>
                </v-col>
                <v-col cols="12" md="6">
                  <label-field
                    label="IP"
                    :value="item.ipNm">
                  </label-field>
                </v-col>
                <v-col cols="12" md="6">
                  <label-field
                    :label="$t('word.liceComp')"
                    :value="item.compNm">
                    <template
                      v-slot:append>
                      <v-btn x-small depressed color="action" class="mb-1" @click.prevent="showCompanyDetailPopup = true">{{ $t('word.dtl') }}</v-btn>
                    </template>
                  </label-field>
                </v-col>
                <v-col cols="12" md="6">
                  <label-field
                    :label="$t('word.cntrStrYmd')"
                    :value="$formatYmd(item.cntrStrYmd)">
                  </label-field>
                </v-col>
                <v-col cols="12" md="6">
                  <label-field
                    :label="$t('word.cntrEndYmd')"
                    :value="$formatYmd(item.cntrEndYmd)">
                  </label-field>
                </v-col>
                <v-col cols="12" md="6">
                  <label-field
                    :label="$t('word.cntrYmd')"
                    :value="$formatYmd(item.cntrYmd)">
                  </label-field>
                </v-col>
                <v-col cols="12" md="6">
                  <label-field
                    :label="$t('word.cntrVldYmd')"
                    :value="$formatYmd(item.cntrVldYmd)">
                  </label-field>
                </v-col>
              </v-row>
            </v-container>
            <v-row dense class="mt-1">
              <v-col v-if="isLicensor" cols="12">
                <v-textarea
                  v-model="item.rmk"
                  :label="$t('word.rmk')"
                  filled
                  rows="3"
                  :rules="[ length2000Rule ]"
                  readonly
                  hide-details>
                </v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="4">
            <v-row>
              <v-col cols="12">
                <contract-file-list-unit
                  :cntr-id="cntrId"
                  :uploadable="false"
                  :modifiable="false"
                  :deletable="false">
                </contract-file-list-unit>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--  회사 상세 팝업 -->
    <company-detail-popup
      v-if="showCompanyDetailPopup"
      :dialog.sync="showCompanyDetailPopup"
      :comp-id="item.compId"
      @item-changed="handleCompanyItemChanged">
    </company-detail-popup>
  </div>
</template>

<script>
import { forms } from '@/mixins/formMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import LabelField from '@/components/LabelField'
import ContractFileListUnit from '@/views/contract/unit/ContractFileListUnit'
import CompanyDetailPopup from '@/views/company/popup/CompanyDetailPopup'

export default {
  name: 'ContractDetailUnit',
  mixins: [forms, searches, stores],
  components: {
    LabelField,
    ContractFileListUnit,
    CompanyDetailPopup
  },
  props: {
    cntrId: {
      type: String,
      required: false
    }
  },
  mounted () {
    this.fetchItem()
  },
  data () {
    return {
      item: {},
      showCompanyDetailPopup: false
    }
  },
  methods: {
    // 데이터조회
    fetchItem () {
      this.$http.get(`/api/1/contracts/${this.cntrId}`)
        .then((response) => {
          this.item = response.data.result.cntr
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleCompanyItemChanged (obj) {
      this.item.compNm = obj.compNm
      this.showCompanyDetailPopup = false
    }
  }
}
</script>
