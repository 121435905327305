<template>
  <div>
    <v-card outlined>
      <!-- 타이틀바 -->
      <title-bar :title="title">
        <template v-slot:action>
          <v-btn small tile :min-width="$const.minWidthButton" color="secondary" class="mr-1" @click="handleClearClicked">{{ $t('word.clear') }}</v-btn>
          <v-btn small tile :min-width="$const.minWidthButton" color="secondary" class="mr-1" @click="fetchList(true)">{{ $t('word.search') }}</v-btn>
          <v-btn v-if="isLicensee && projStsCd === 'PS0200'" small tile :min-width="$const.minWidthButton" color="primary" class="mr-1" @click="handleAddClicked">{{ $t('word.add') }}</v-btn>
          <v-btn v-if="isLicensee && projStsCd === 'PS0200'" small tile :min-width="$const.minWidthButton" color="success" @click="showDraftProjectListPopup = true">{{ $t('word.draftProj') }}</v-btn>
        </template>
      </title-bar>
      <!-- 검색조건 -->
      <five-field-bar :field-size="10" :md="2">
        <template v-slot:field-1>
          <date-field
            v-model="strYmd"
            :label="`${srchYmdNm} (FROM)`"
            background-color="white"
            outlined
            dense
            hide-details>
          </date-field>
        </template>
        <template v-slot:field-2>
          <date-field
            v-model="endYmd"
            :label="`${srchYmdNm} (TO)`"
            background-color="white"
            outlined
            dense
            hide-details>
          </date-field>
        </template>
        <template v-slot:field-3>
          <v-text-field
            v-model="ipNm"
            label="IP"
            readonly
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @click="showIpListPopup = true">
            <template v-slot:append>
              <v-btn x-small depressed color="action" @click="showIpListPopup = true">{{ $t('word.choose') }}</v-btn>
            </template>
          </v-text-field>
        </template>
        <template v-slot:field-4>
          <v-text-field
            v-model="cntrNo"
            :label="$t('word.cntr')"
            readonly
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @click="showContractListPopup = true">
            <template v-slot:append>
              <v-btn x-small depressed color="action" @click="showContractListPopup = true">{{ $t('word.choose') }}</v-btn>
            </template>
          </v-text-field>
        </template>
        <template v-slot:field-5>
          <v-text-field
            v-model="compNm"
            :label="$t('word.liceComp')"
            readonly
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @click="showCompanyListPopup = true">
            <template v-slot:append>
              <v-btn x-small depressed color="action" @click="showCompanyListPopup = true">{{ $t('word.choose') }}</v-btn>
            </template>
          </v-text-field>
        </template>
        <template v-slot:field-6>
          <v-select
            v-model="topProdTypId"
            :items="topProdTypList"
            :label="$t('word.prodTyp1')"
            background-color="white"
            outlined
            dense
            hide-details>
          </v-select>
        </template>
        <template v-slot:field-7>
          <v-select
            v-model="prodTypId"
            :items="prodTypList"
            :label="$t('word.prodTyp2')"
            background-color="white"
            outlined
            dense
            hide-details
            :disabled="$isNull(topProdTypId)">
          </v-select>
        </template>
        <template v-slot:field-8>
          <v-select
            v-model="projStsCd"
            :items="projStsCdList"
            :label="$t('word.projSts')"
            background-color="white"
            outlined
            dense
            hide-details>
          </v-select>
        </template>
        <template v-slot:field-9>
          <v-select
            v-model="srchDiv"
            :items="srchDivList"
            :label="$t('word.srchDiv')"
            background-color="white"
            outlined
            dense
            hide-details>
          </v-select>
        </template>
        <template v-slot:field-10>
          <v-text-field
            v-model="kwd"
            :label="$t('word.kwd')"
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @keypress.enter="fetchList(true)">
          </v-text-field>
        </template>
      </five-field-bar>
      <!-- 테이블 -->
      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="items"
        item-key="projCd"
        height="65vh"
        class="text-truncate"
        fixed-header
        show-select
        single-select
        disable-filtering
        disable-pagination
        loading-text=""
        no-data-text=""
        no-results-text=""
        hide-default-footer
        :server-items-length="comTtlCnt"
        :sort-by.sync="comSrtBy"
        :sort-desc.sync="comSrtDesc"
        @click:row="handleRowClicked"
        @update:sort-by="delayedCall(fetchList)"
        @update:sort-desc="delayedCall(fetchList)">
        <template v-slot:item.projCrtDt="{ value }">
          <td>{{ $timeToShortDate(value) }}</td>
        </template>
        <template v-slot:item.projApvReqDt="{ value }">
          <td>{{ $timeToShortDate(value) }}</td>
        </template>
        <template v-slot:item.projApvDt="{ value }">
          <td>{{ $timeToShortDate(value) }}</td>
        </template>
        <template v-slot:item.projCmplDt="{ value }">
          <td>{{ $timeToShortDate(value) }}</td>
        </template>
        <template v-slot:item.projRejtDt="{ value }">
          <td>{{ $timeToShortDate(value) }}</td>
        </template>
        <template v-slot:item.projCnclDt="{ value }">
          <td>{{ $timeToShortDate(value) }}</td>
        </template>
        <template v-slot:item.modDt="{ value }">
          <td>{{ $timeToMediumDate(value) }}</td>
        </template>
        <template v-slot:body.append="{ headers }">
          <tr>
            <td :colspan="headers.length">
              {{ $t('word.total') }} {{ $formatNumber(comTtlCnt) }}{{ $t('word.count') }}
            </td>
          </tr>
        </template>
        <template v-slot:item.projStsCd="{ value }">
          <td v-if="value ==='PS0100'">{{ $t('word.wrtProjStat') }}</td>
          <td v-if="value ==='PS0200'">{{ $t('word.newProjStat') }}</td>
          <td v-if="value === 'PS0300'">{{ $t('word.onProjStat') }}</td>
          <td v-if="value === 'PS0400'">{{ $t('word.modProjStat') }}</td>
          <td v-if="value === 'PS0500'">{{ $t('word.cmplProjStat') }}</td>
          <td v-if="value === 'PS0700'">{{ $t('word.cnclProjStat') }}</td>
        </template>
      </v-data-table>
      <!-- 페이징 -->
      <paging-bar
        :page-no.sync="comPageNo"
        :page-cnt.sync="comPageCnt"
        :item-cnt-per-page.sync="comItemCntPerPage"
        @paging-changed="fetchList">
      </paging-bar>
    </v-card>
    <!-- IP 목록 팝업 -->
    <ip-list-popup
      v-if="showIpListPopup"
      :dialog.sync="showIpListPopup"
      :ip-id.sync="ipId"
      :ip-nm.sync="ipNm">
    </ip-list-popup>
    <!-- 계약 목록 팝업 -->
    <contract-list-popup
      v-if="showContractListPopup"
      :dialog.sync="showContractListPopup"
      :cntr-id.sync="cntrId"
      :cntr-no.sync="cntrNo">
    </contract-list-popup>
    <!-- 회사 목록 팝업 -->
    <company-list-popup
      v-if="showCompanyListPopup"
      :dialog.sync="showCompanyListPopup"
      :comp-id.sync="compId"
      :comp-nm.sync="compNm">
    </company-list-popup>
    <!-- 작성중 프로젝트 목록 팝업 -->
    <draft-project-list-popup
      v-if="showDraftProjectListPopup"
      :dialog.sync="showDraftProjectListPopup"
      @item-selected="handleDraftProjectSelected">
    </draft-project-list-popup>
    <!-- 프로젝트 디자인검수 탭 팝업 -->
    <project-design-tab-popup
      v-if="showProjectDesignTabPopup"
      :dialog.sync="showProjectDesignTabPopup"
      :proj-cd="draftProjCd"
      @item-changed="fetchList">
    </project-design-tab-popup>
  </div>
</template>

<script>
import { sorts } from '@/mixins/sortMixin'
import { pages } from '@/mixins/pageMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import { caches } from '@/mixins/cacheMixin'
import TitleBar from '@/components/TitleBar'
import FiveFieldBar from '@/components/FiveFieldBar'
import DateField from '@/components/DateField'
import PagingBar from '@/components/PagingBar'
import IpListPopup from '@/views/ip/popup/IpListPopup'
import ContractListPopup from '@/views/contract/popup/ContractListPopup'
import CompanyListPopup from '@/views/company/popup/CompanyListPopup'
import DraftProjectListPopup from '@/views/project/popup/DraftProjectListPopup'
import ProjectDesignTabPopup from '@/views/project/popup/ProjectDesignTabPopup'

export default {
  name: 'ProjectListView',
  mixins: [sorts, pages, searches, stores, caches],
  components: {
    TitleBar,
    FiveFieldBar,
    DateField,
    PagingBar,
    IpListPopup,
    ContractListPopup,
    CompanyListPopup,
    DraftProjectListPopup,
    ProjectDesignTabPopup
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'NO TITLE'
    },
    menuPath: {
      type: String,
      required: false
    },
    srchYmdNm: {
      type: String,
      required: false
    },
    projStsCd: {
      type: String,
      required: false
    }
  },
  mounted () {
    // 테이블 헤더 조정
    if (this.projStsCd === 'PS0200') {
      this.headers.push({ text: this.$t('word.apvReqYmd'), value: 'projApvReqDt' })
    } else if (this.projStsCd === 'PS0300' || this.projStsCd === 'PS0400') {
      this.headers.push({ text: this.$t('word.apvReqYmd'), value: 'projApvReqDt' })
      this.headers.push({ text: this.$t('word.apvYmd'), value: 'projApvDt' })
    } else if (this.projStsCd === 'PS0500') {
      this.headers.push({ text: this.$t('word.cmplYmd'), value: 'projCmplDt' })
    } else if (this.projStsCd === 'PS0700') {
      this.headers.push({ text: this.$t('word.cnclYmd'), value: 'projCnclDt' })
    }
    this.headers.push({ text: this.$t('word.modDt'), value: 'modDt', sortable: false })

    // 공통코드 조회
    this.$getCodes(['PT0000', 'PS0000'], (result) => {
      this.projStsCdList = this.$toSelectableCodes(result.PS0000)
      // 상품유형 1단계 조회
      this.$getProductTypes(1, null, (result) => {
        this.topProdTypList = this.$toSelectableProductTypes(result.prodTypList)
        // 테이블 목록 조회
        this.fetchList()
      })
    })
  },
  watch: {
    topProdTypId (to) {
      // 캐시에서 검색조건을 불러오는 경우에는 상품유형ID를 초기화 하지 않는다.
      if (!this.needRestoreProdTypId) {
        this.prodTypId = null
      } else {
        this.needRestoreProdTypId = false
      }
      if (this.$isNull(to)) {
        this.prodTypList = []
      } else {
        this.$getProductTypes(2, to, (result) => {
          this.prodTypList = this.$toSelectableProductTypes(result.prodTypList)
        })
      }
    },
    showProjectDesignTabPopup (to) {
      // 팝업이 닫힐때 작성중프로젝트코드를 초기화 한다
      if (!to) {
        this.draftProjCd = null
      }
    }
  },
  data () {
    return {
      projStsCdList: [],
      topProdTypList: [],
      prodTypList: [],
      // 테이블 헤더
      headers: [
        { text: this.$t('word.projSts'), value: 'projStsCd' },
        { text: this.$t('word.projCd'), value: 'projCd' },
        { text: this.$t('word.projNm'), value: 'projNm' },
        { text: this.$t('word.ipNm'), value: 'ipNm' },
        { text: this.$t('word.liceComp'), value: 'compNm' },
        { text: this.$t('word.cntrNo'), value: 'cntrNo' },
        { text: this.$t('word.prodTyp'), value: 'prodTypNm', sortable: false },
        { text: this.$t('word.projStg'), value: 'projStgCdNm', sortable: false },
        { text: this.$t('word.crtYmd'), value: 'projCrtDt' }
      ],
      // 조회결과 목록
      items: [],
      // 검색조건
      strYmd: null,
      endYmd: null,
      ipId: null,
      ipNm: null,
      cntrId: null,
      cntrNo: null,
      compId: null,
      compNm: null,
      topProdTypId: null,
      prodTypId: null,
      projStgCd: null,
      srchDiv: 'projNm',
      kwd: null,
      // 상위상품유형ID 변경에 따라 상품유형ID 선택값이 초기화 되는데, 캐쉬에서 불려질 때 등 이전 선택값을 유지해야 하는 경우에 사용됨
      needRestoreProdTypId: false,
      // 작성중 프로젝트 목록에서 선택된 프로젝트코드
      draftProjCd: null,
      // 선택 아이템
      selectedItems: [],
      // IP 목록 팝업 오픈 여부
      showIpListPopup: false,
      // 계약 목록 팝업 오픈 여부
      showContractListPopup: false,
      // 회사 목록 팝업 오픈 여부
      showCompanyListPopup: false,
      // 작성중 프로젝트 목록 팝업 오픈 여부
      showDraftProjectListPopup: false,
      // 프로젝트 디자인검수 탭 팝업 오픈 여부
      showProjectDesignTabPopup: false
    }
  },
  computed: {
    srchDivList () {
      return [
        { text: this.$t('word.projCd'), value: 'projCd' },
        { text: this.$t('word.projNm'), value: 'projNm' }
      ]
    }
  },
  methods: {
    // 목록조회
    fetchList (shouldClear) {
      // 검색 초기화
      if (shouldClear) {
        this.comPageNo = 1
        this.selectedItems = []
      }
      // 검색 파라미터 할당
      this.clearComParam()
      this.appendParam('projStsCd', this.projStsCd)
      this.appendParam('strCrtYmd', this.strYmd)
      this.appendParam('endCrtYmd', this.endYmd)
      if (this.projStsCd === 'PS0200') {
        this.appendParam('strCrtYmd', this.strYmd)
        this.appendParam('endCrtYmd', this.endYmd)
      } else if (this.projStsCd === 'PS0300' || this.projStsCd === 'PS0400') {
        this.appendParam('strApvYmd', this.strYmd)
        this.appendParam('endApvYmd', this.endYmd)
      } else if (this.projStsCd === 'PS0500') {
        this.appendParam('strCmplYmd', this.strYmd)
        this.appendParam('endCmplYmd', this.endYmd)
      } else if (this.projStsCd === 'PS0700') {
        this.appendParam('strCnclYmd', this.strYmd)
        this.appendParam('endCnclYmd', this.endYmd)
      }
      if (!this.$isEmpty(this.ipNm)) {
        this.appendParam('ipId', this.ipId)
      }
      if (!this.$isEmpty(this.cntrNo)) {
        this.appendParam('cntrId', this.cntrId)
      }
      if (!this.$isEmpty(this.compNm)) {
        this.appendParam('compId', this.compId)
      }
      if (!this.$isEmpty(this.prodTypId)) {
        this.appendParam('prodTypId', this.prodTypId)
      } else if (!this.$isEmpty(this.topProdTypId)) {
        this.appendParam('topProdTypId', this.topProdTypId)
      }
      if (!this.$isEmpty(this.projStgCd)) {
        this.appendParam('projStgCd', this.projStgCd)
      }
      if (!this.$isEmpty(this.kwd)) {
        this.appendParam(this.srchDiv, this.kwd)
      }
      // 검색
      this.$http.get(this.toRequestUrl('/api/1/projects'))
        .then((response) => {
          const page = response.data.result.projPage
          this.bindComPage(page)
          this.items = page.list
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 초기화 버튼 클릭시
    handleClearClicked () {
      this.strYmd = null
      this.endYmd = null
      this.ipId = null
      this.ipNm = null
      this.cntrId = null
      this.cntrNo = null
      this.compId = null
      this.compNm = null
      this.topProdTypId = null
      this.prodTypId = null
      this.projStgCd = null
      this.projStsCd = null
      this.srchDiv = 'projNm'
      this.kwd = null
      this.fetchList(true)
    },
    // 추가 버튼 클릭시
    handleAddClicked () {
      this.selectedItems = []
      // this.$toasted.global.info('준비중 입니다.')
      this.showProjectDesignTabPopup = true
    },
    // 테이블 로우 클릭시
    handleRowClicked (item) {
      this.selectedItems = [item]
      this.needRestoreProdTypId = true
      this.cacheData(['strYmd', 'endYmd', 'ipId', 'ipNm', 'cntrId', 'cntrNo', 'compId', 'compNm', 'topProdTypId', 'prodTypId', 'projStgCd', 'projStsCd', 'srchDiv', 'kwd', 'needRestoreProdTypId', 'selectedItems'])
      this.$router.push(`/main/project/${this.menuPath}/${item.projCd}/detail`)
    },
    //
    //
    // 작성중 프로젝트 선택시
    handleDraftProjectSelected (item) {
      if (this.$isNull(item)) {
        return
      }
      this.draftProjCd = item.projCd
      this.showProjectDesignTabPopup = true
    },
    // 프로젝트 상태 클릭시
    handleProjectStsClicked (item) {
      this.selectedItems = [item]
      this.needRestoreProdTypId = true
      this.cacheData(['strYmd', 'endYmd', 'ipId', 'ipNm', 'cntrId', 'cntrNo', 'compId', 'compNm', 'topProdTypId', 'prodTypId', 'projStgCd', 'projStsCd', 'srchDiv', 'kwd', 'needRestoreProdTypId', 'selectedItems'])
      alert(item.compNm)
    }
  }
}
</script>
