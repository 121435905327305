export const sorts = {
  data () {
    return {
      comSrtBy: [],
      comSrtDesc: [],
      comWaitingFetch: false
    }
  },
  methods: {
    // IMPORTANT:
    // 화면에서 발생한 한번의 이벤트 (테이블 헤더 클릭)가 comSrtBy 와 comSrtDesc 의 변경을 각각 발생시키기 때문에 중복해서 서버에 데이터 요청이 전달되게 된다.
    // 따라서 comSrtBy 와 comSrtDesc 변경사항 이벤트 핸들러는 처리에 딜레이를 줘서 가능하면 서버에 한번만 데이터 요청을 하도록 처리한다
    delayedCall (callback) {
      if (this.$isNull(callback)) {
        return
      }
      if (this.comWaitingFetch) {
        return
      }
      this.comWaitingFetch = true
      setTimeout(() => {
        this.comWaitingFetch = false
        callback()
      }, 100)
    }
  }
}
