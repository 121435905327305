import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import i18n from '@/i18n/messages'

import AppLayout from '@/AppLayout'
import LoginView from '@/pages/LoginView'
import EmailFindView from '@/pages/EmailFindView'
import PasswordFindView from '@/pages/PasswordFindView'
import PasswordResetView from '@/pages/PasswordResetView'
import UserJoinPasswordView from '@/pages/UserJoinPasswordView'
import UserJoinInfoView from '@/pages/UserJoinInfoView'
import NotFoundView from '@/pages/NotFoundView'
import MyPageView from '@/views/user/MyPageView'
import DashboardView from '@/views/dashboard/DashboardView'
import LicensorUserListView from '@/views/user/LicensorUserListView'
import LicenseeUserListView from '@/views/user/LicenseeUserListView'
import CompanyListView from '@/views/company/CompanyListView'
import ProductTypeLevel1ListView from '@/views/productType/ProductTypeLevel1ListView'
import IpListView from '@/views/ip/IpListView'
import ContractListView from '@/views/contract/ContractListView'
import StampListView from '@/views/stamp/StampListView'
import ProjectListView from '@/views/project/ProjectListView'
import ProjectDetailView from '@/views/project/ProjectDetailView'
import FaqListView from '@/views/faq/FaqListView'
import FaqLicenseeView from '@/views/faq/FaqLicenseeView'
import StatisticsListView from '@/views/statistics/StatisticsListView'
import NtcListView from '@/views/ntc/NtcListView'
import NtcLiceListView from '@/views/ntc/NtcLiceListView'
// import { defaultLocale } from '@/i18n/i18n'

Vue.use(VueRouter)

// function locale () {
//   let currLocale = navigator.language || navigator.userLanguage
//   currLocale = currLocale.substring(0, 2)
//   if (currLocale !== 'ko') {
//     currLocale = 'en'
//   }
//   return currLocale
// }

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: LoginView,
    beforeEnter: (to, from, next) => {
      if (store.getters.loginUser.usrId !== null) {
        next('/main/project/new')
      } else {
        next()
      }
    }
  },
  {
    path: '/find/email',
    component: EmailFindView,
    beforeEnter: (to, from, next) => {
      if (store.getters.loginUser.usrId !== null) {
        next('/main/project/new')
      } else {
        next()
      }
    }
  },
  {
    path: '/find/password',
    component: PasswordFindView,
    beforeEnter: (to, from, next) => {
      if (store.getters.loginUser.usrId !== null) {
        next('/main/project/new')
      } else {
        next()
      }
    }
  },
  {
    path: '/reset/password',
    component: PasswordResetView,
    props (route) {
      return {
        encodedKey: route.query.k
      }
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.loginUser.usrId !== null) {
        next('/main/project/new')
      } else {
        next()
      }
    }
  },
  {
    path: '/join/password',
    component: UserJoinPasswordView,
    props (route) {
      return {
        encodedKey: route.query.k
      }
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.loginUser.usrId !== null) {
        next('/main/project/new')
      } else {
        next()
      }
    }
  },
  {
    path: '/join/info',
    component: UserJoinInfoView,
    meta: {
      roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
    }
  },
  {
    path: '/404',
    component: NotFoundView
  },
  {
    path: '/main',
    redirect: '/main/dashboard',
    component: AppLayout,
    children: [
      // 마이페이지
      {
        path: '/main/mypage',
        component: MyPageView,
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
        }
      },
      // 대시보드
      {
        path: '/main/dashboard',
        component: DashboardView,
        props: {
          // title: i18n.t('word.dashboard', locale())
          title: i18n.t('word.dashboard')
        },
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
        }
      },
      // 라이선서 사용자 관리
      {
        path: '/main/licensor',
        component: LicensorUserListView,
        props: {
          // title: i18n.t('word.manageLicrUsr', locale())
          title: i18n.t('word.manageLicrUsr')
        },
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_REVIEWER']
        }
      },
      // 라이선시 사용자 관리
      {
        path: '/main/licensee',
        component: LicenseeUserListView,
        props: {
          // title: i18n.t('word.manageLiceUsr', locale())
          title: i18n.t('word.manageLiceUsr')
        },
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_REVIEWER']
        }
      },
      // 라이선시 회사 관리
      {
        path: '/main/company',
        component: CompanyListView,
        props: {
          // title: i18n.t('word.manageLiceComp', locale())
          title: i18n.t('word.manageLiceComp')
        },
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_REVIEWER']
        }
      },
      // 상품유형 관리
      {
        path: '/main/productType',
        component: ProductTypeLevel1ListView,
        props: {
          // title: i18n.t('word.manageProdTyp', locale())
          title: i18n.t('word.manageProdTyp')
        },
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_REVIEWER']
        }
      },
      // IP 관리
      {
        path: '/main/ip',
        component: IpListView,
        props: {
          // title: i18n.t('word.manageIp', locale())
          title: i18n.t('word.manageIp')
        },
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_REVIEWER']
        }
      },
      // 계약 관리
      {
        path: '/main/contract',
        component: ContractListView,
        props: {
          // title: i18n.t('word.manageCntr', locale())
          title: i18n.t('word.manageCntr')
        },
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_REVIEWER']
        }
      },
      // 증지 신청 관리
      {
        path: '/main/stamp',
        component: StampListView,
        props: {
          // title: i18n.t('word.manageStmpAppl', locale())
          title: i18n.t('word.manageStmpAppl')
        },
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
        }
      },
      // FAQ 관리
      {
        path: '/main/faq',
        component: FaqListView,
        props: {
          // title: i18n.t('word.manageFaq', locale())
          title: i18n.t('word.manageFaq')
        },
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_REVIEWER']
        }
      },
      // 라이선시 FAQ
      {
        path: '/main/licefaq',
        component: FaqLicenseeView,
        props: {
          // title: i18n.t('word.faq', locale())
          title: i18n.t('word.faq')
        },
        meta: {
          roles: ['ROLE_LICENSEE']
        }
      },
      // 공지사항 관리
      {
        path: '/main/ntc',
        component: NtcListView,
        props: {
          // title: i18n.t('word.manageNtc', locale())
          title: i18n.t('word.manageNtc')
        },
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_REVIEWER']
        }
      },
      // 라이선시 공지사항 관리
      {
        path: '/main/licentc',
        component: NtcLiceListView,
        props: {
          // title: i18n.t('word.ntc', locale())
          title: i18n.t('word.ntc')
        },
        meta: {
          roles: ['ROLE_LICENSEE']
        }
      },

      // 통계 조회
      {
        path: '/main/statistics',
        component: StatisticsListView,
        props: {
          // title: i18n.t('word.statsRead', locale())
          title: i18n.t('word.statsRead')
        },
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
        }
      },
      // 전체 프로젝트
      {
        path: '/main/project/all',
        component: ProjectListView,
        props (route) {
          const bindCache = route.query.bc !== null && route.query.bc !== undefined && (route.query.bc === 1 || route.query.bc === '1')
          return {
            // title: i18n.t('word.allProj', locale()),
            title: i18n.t('word.allProj'),
            menuPath: 'all',
            // srchYmdNm: i18n.t('word.crtYmd', locale()),
            srchYmdNm: i18n.t('word.crtYmd'),
            comScrNm: 'AllProjectListView',
            bindCache: bindCache
          }
        },
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_REVIEWER']
        }
      },
      // 신규 프로젝트
      {
        path: '/main/project/new',
        component: ProjectListView,
        props (route) {
          const bindCache = route.query.bc !== null && route.query.bc !== undefined && (route.query.bc === 1 || route.query.bc === '1')
          return {
            // title: i18n.t('word.newProj', locale()),
            title: i18n.t('word.newProj'),
            menuPath: 'new',
            projStsCd: 'PS0200',
            // srchYmdNm: i18n.t('word.crtYmd', locale()),
            srchYmdNm: i18n.t('word.crtYmd'),
            comScrNm: 'NewProjectListView',
            bindCache: bindCache
          }
        },
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
        }
      },
      // 진행 프로젝트
      {
        path: '/main/project/on',
        component: ProjectListView,
        props (route) {
          const bindCache = route.query.bc !== null && route.query.bc !== undefined && (route.query.bc === 1 || route.query.bc === '1')
          return {
            // title: i18n.t('word.onProj', locale()),
            title: i18n.t('word.onProj'),
            menuPath: 'on',
            projStsCd: 'PS0300',
            // srchYmdNm: i18n.t('word.apvYmd', locale()),
            srchYmdNm: i18n.t('word.apvYmd'),
            comScrNm: 'OnProjectListView',
            bindCache: bindCache
          }
        },
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
        }
      },
      // 수정 프로젝트
      {
        path: '/main/project/modified',
        component: ProjectListView,
        props (route) {
          const bindCache = route.query.bc !== null && route.query.bc !== undefined && (route.query.bc === 1 || route.query.bc === '1')
          return {
            // title: i18n.t('word.modifiedProj', locale()),
            title: i18n.t('word.modifiedProj'),
            menuPath: 'modified',
            projStsCd: 'PS0400',
            // srchYmdNm: i18n.t('word.apvYmd', locale()),
            srchYmdNm: i18n.t('word.apvYmd'),
            comScrNm: 'ModifiedProjectListView',
            bindCache: bindCache
          }
        },
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
        }
      },
      // 완료 프로젝트
      {
        path: '/main/project/completed',
        component: ProjectListView,
        props (route) {
          const bindCache = route.query.bc !== null && route.query.bc !== undefined && (route.query.bc === 1 || route.query.bc === '1')
          return {
            // title: i18n.t('word.completedProj', locale()),
            title: i18n.t('word.completedProj'),
            menuPath: 'completed',
            projStsCd: 'PS0500',
            // srchYmdNm: i18n.t('word.cmplYmd', locale()),
            srchYmdNm: i18n.t('word.cmplYmd'),
            comScrNm: 'CompletedProjectListView',
            bindCache: bindCache
          }
        },
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
        }
      },
      // 취소 프로젝트
      {
        path: '/main/project/canceled',
        component: ProjectListView,
        props (route) {
          const bindCache = route.query.bc !== null && route.query.bc !== undefined && (route.query.bc === 1 || route.query.bc === '1')
          return {
            // title: i18n.t('word.canceledProj', locale()),
            title: i18n.t('word.canceledProj'),
            menuPath: 'canceled',
            projStsCd: 'PS0700',
            // srchYmdNm: i18n.t('word.cnclYmd', locale()),
            srchYmdNm: i18n.t('word.cnclYmd'),
            comScrNm: 'CanceledProjectListView',
            bindCache: bindCache
          }
        },
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
        }
      },
      // 프로젝트 상세
      {
        path: '/main/project/:menuPath/:projCd/detail',
        component: ProjectDetailView,
        props (route) {
          return {
            projCd: route.params.projCd,
            menuPath: route.params.menuPath
          }
        },
        meta: {
          roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
        }
      }
    ]
  },
  // 미정의
  {
    path: '*',
    redirect: '/404'
  }
]

// const routes = [
//   {
//     path: '/',
//     redirect: `/${defaultLocale}`
//   },
//   {
//     path: ':locale',
//     component: {
//       template: '<router-view />'
//     },
//     children: [
//       {
//         path: '/login',
//         component: LoginView,
//         beforeEnter: (to, from, next) => {
//           if (store.getters.loginUser.usrId !== null) {
//             next('/main/project/new')
//           } else {
//             next()
//           }
//         }
//       },
//       {
//         path: '/find/email',
//         component: EmailFindView,
//         beforeEnter: (to, from, next) => {
//           if (store.getters.loginUser.usrId !== null) {
//             next('/main/project/new')
//           } else {
//             next()
//           }
//         }
//       },
//       {
//         path: '/find/password',
//         component: PasswordFindView,
//         beforeEnter: (to, from, next) => {
//           if (store.getters.loginUser.usrId !== null) {
//             next('/main/project/new')
//           } else {
//             next()
//           }
//         }
//       },
//       {
//         path: '/reset/password',
//         component: PasswordResetView,
//         props (route) {
//           return {
//             encodedKey: route.query.k
//           }
//         },
//         beforeEnter: (to, from, next) => {
//           if (store.getters.loginUser.usrId !== null) {
//             next('/main/project/new')
//           } else {
//             next()
//           }
//         }
//       },
//       {
//         path: '/join/password',
//         component: UserJoinPasswordView,
//         props (route) {
//           return {
//             encodedKey: route.query.k
//           }
//         },
//         beforeEnter: (to, from, next) => {
//           if (store.getters.loginUser.usrId !== null) {
//             next('/main/project/new')
//           } else {
//             next()
//           }
//         }
//       },
//       {
//         path: '/join/info',
//         component: UserJoinInfoView,
//         meta: {
//           roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
//         }
//       },
//       {
//         path: '/404',
//         component: NotFoundView
//       },
//       {
//         path: '/main',
//         redirect: '/main/dashboard',
//         component: AppLayout,
//         children: [
//           // 마이페이지
//           {
//             path: '/main/mypage',
//             component: MyPageView,
//             meta: {
//               roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
//             }
//           },
//           // 대시보드
//           {
//             path: '/main/dashboard',
//             component: DashboardView,
//             props: {
//               // title: i18n.t('word.dashboard', locale())
//               title: i18n.t('word.dashboard')
//             },
//             meta: {
//               roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
//             }
//           },
//           // 라이선서 사용자 관리
//           {
//             path: '/main/licensor',
//             component: LicensorUserListView,
//             props: {
//               // title: i18n.t('word.manageLicrUsr', locale())
//               title: i18n.t('word.manageLicrUsr')
//             },
//             meta: {
//               roles: ['ROLE_ADMIN', 'ROLE_REVIEWER']
//             }
//           },
//           // 라이선시 사용자 관리
//           {
//             path: '/main/licensee',
//             component: LicenseeUserListView,
//             props: {
//               // title: i18n.t('word.manageLiceUsr', locale())
//               title: i18n.t('word.manageLiceUsr')
//             },
//             meta: {
//               roles: ['ROLE_ADMIN', 'ROLE_REVIEWER']
//             }
//           },
//           // 라이선시 회사 관리
//           {
//             path: '/main/company',
//             component: CompanyListView,
//             props: {
//               // title: i18n.t('word.manageLiceComp', locale())
//               title: i18n.t('word.manageLiceComp')
//             },
//             meta: {
//               roles: ['ROLE_ADMIN', 'ROLE_REVIEWER']
//             }
//           },
//           // 상품유형 관리
//           {
//             path: '/main/productType',
//             component: ProductTypeLevel1ListView,
//             props: {
//               // title: i18n.t('word.manageProdTyp', locale())
//               title: i18n.t('word.manageProdTyp')
//             },
//             meta: {
//               roles: ['ROLE_ADMIN', 'ROLE_REVIEWER']
//             }
//           },
//           // IP 관리
//           {
//             path: '/main/ip',
//             component: IpListView,
//             props: {
//               // title: i18n.t('word.manageIp', locale())
//               title: i18n.t('word.manageIp')
//             },
//             meta: {
//               roles: ['ROLE_ADMIN', 'ROLE_REVIEWER']
//             }
//           },
//           // 계약 관리
//           {
//             path: '/main/contract',
//             component: ContractListView,
//             props: {
//               // title: i18n.t('word.manageCntr', locale())
//               title: i18n.t('word.manageCntr')
//             },
//             meta: {
//               roles: ['ROLE_ADMIN', 'ROLE_REVIEWER']
//             }
//           },
//           // 증지 신청 관리
//           {
//             path: '/main/stamp',
//             component: StampListView,
//             props: {
//               // title: i18n.t('word.manageStmpAppl', locale())
//               title: i18n.t('word.manageStmpAppl')
//             },
//             meta: {
//               roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
//             }
//           },
//           // FAQ 관리
//           {
//             path: '/main/faq',
//             component: FaqListView,
//             props: {
//               // title: i18n.t('word.manageFaq', locale())
//               title: i18n.t('word.manageFaq')
//             },
//             meta: {
//               roles: ['ROLE_ADMIN', 'ROLE_REVIEWER']
//             }
//           },
//           // 라이선시 FAQ
//           {
//             path: '/main/licefaq',
//             component: FaqLicenseeView,
//             props: {
//               // title: i18n.t('word.faq', locale())
//               title: i18n.t('word.faq')
//             },
//             meta: {
//               roles: ['ROLE_LICENSEE']
//             }
//           },
//           // 공지사항 관리
//           {
//             path: '/main/ntc',
//             component: NtcListView,
//             props: {
//               // title: i18n.t('word.manageNtc', locale())
//               title: i18n.t('word.manageNtc')
//             },
//             meta: {
//               roles: ['ROLE_ADMIN', 'ROLE_REVIEWER']
//             }
//           },
//           // 라이선시 공지사항 관리
//           {
//             path: '/main/licentc',
//             component: NtcLiceListView,
//             props: {
//               // title: i18n.t('word.ntc', locale())
//               title: i18n.t('word.ntc')
//             },
//             meta: {
//               roles: ['ROLE_LICENSEE']
//             }
//           },
//
//           // 통계 조회
//           {
//             path: '/main/statistics',
//             component: StatisticsListView,
//             props: {
//               // title: i18n.t('word.statsRead', locale())
//               title: i18n.t('word.statsRead')
//             },
//             meta: {
//               roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
//             }
//           },
//           // 전체 프로젝트
//           {
//             path: '/main/project/all',
//             component: ProjectListView,
//             props (route) {
//               const bindCache = route.query.bc !== null && route.query.bc !== undefined && (route.query.bc === 1 || route.query.bc === '1')
//               return {
//                 // title: i18n.t('word.allProj', locale()),
//                 title: i18n.t('word.allProj'),
//                 menuPath: 'all',
//                 // srchYmdNm: i18n.t('word.crtYmd', locale()),
//                 srchYmdNm: i18n.t('word.crtYmd'),
//                 comScrNm: 'AllProjectListView',
//                 bindCache: bindCache
//               }
//             },
//             meta: {
//               roles: ['ROLE_ADMIN', 'ROLE_REVIEWER']
//             }
//           },
//           // 신규 프로젝트
//           {
//             path: '/main/project/new',
//             component: ProjectListView,
//             props (route) {
//               const bindCache = route.query.bc !== null && route.query.bc !== undefined && (route.query.bc === 1 || route.query.bc === '1')
//               return {
//                 // title: i18n.t('word.newProj', locale()),
//                 title: i18n.t('word.newProj'),
//                 menuPath: 'new',
//                 projStsCd: 'PS0200',
//                 // srchYmdNm: i18n.t('word.crtYmd', locale()),
//                 srchYmdNm: i18n.t('word.crtYmd'),
//                 comScrNm: 'NewProjectListView',
//                 bindCache: bindCache
//               }
//             },
//             meta: {
//               roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
//             }
//           },
//           // 진행 프로젝트
//           {
//             path: '/main/project/on',
//             component: ProjectListView,
//             props (route) {
//               const bindCache = route.query.bc !== null && route.query.bc !== undefined && (route.query.bc === 1 || route.query.bc === '1')
//               return {
//                 // title: i18n.t('word.onProj', locale()),
//                 title: i18n.t('word.onProj'),
//                 menuPath: 'on',
//                 projStsCd: 'PS0300',
//                 // srchYmdNm: i18n.t('word.apvYmd', locale()),
//                 srchYmdNm: i18n.t('word.apvYmd'),
//                 comScrNm: 'OnProjectListView',
//                 bindCache: bindCache
//               }
//             },
//             meta: {
//               roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
//             }
//           },
//           // 수정 프로젝트
//           {
//             path: '/main/project/modified',
//             component: ProjectListView,
//             props (route) {
//               const bindCache = route.query.bc !== null && route.query.bc !== undefined && (route.query.bc === 1 || route.query.bc === '1')
//               return {
//                 // title: i18n.t('word.modifiedProj', locale()),
//                 title: i18n.t('word.modifiedProj'),
//                 menuPath: 'modified',
//                 projStsCd: 'PS0400',
//                 // srchYmdNm: i18n.t('word.apvYmd', locale()),
//                 srchYmdNm: i18n.t('word.apvYmd'),
//                 comScrNm: 'ModifiedProjectListView',
//                 bindCache: bindCache
//               }
//             },
//             meta: {
//               roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
//             }
//           },
//           // 완료 프로젝트
//           {
//             path: '/main/project/completed',
//             component: ProjectListView,
//             props (route) {
//               const bindCache = route.query.bc !== null && route.query.bc !== undefined && (route.query.bc === 1 || route.query.bc === '1')
//               return {
//                 // title: i18n.t('word.completedProj', locale()),
//                 title: i18n.t('word.completedProj'),
//                 menuPath: 'completed',
//                 projStsCd: 'PS0500',
//                 // srchYmdNm: i18n.t('word.cmplYmd', locale()),
//                 srchYmdNm: i18n.t('word.cmplYmd'),
//                 comScrNm: 'CompletedProjectListView',
//                 bindCache: bindCache
//               }
//             },
//             meta: {
//               roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
//             }
//           },
//           // 취소 프로젝트
//           {
//             path: '/main/project/canceled',
//             component: ProjectListView,
//             props (route) {
//               const bindCache = route.query.bc !== null && route.query.bc !== undefined && (route.query.bc === 1 || route.query.bc === '1')
//               return {
//                 // title: i18n.t('word.canceledProj', locale()),
//                 title: i18n.t('word.canceledProj'),
//                 menuPath: 'canceled',
//                 projStsCd: 'PS0700',
//                 // srchYmdNm: i18n.t('word.cnclYmd', locale()),
//                 srchYmdNm: i18n.t('word.cnclYmd'),
//                 comScrNm: 'CanceledProjectListView',
//                 bindCache: bindCache
//               }
//             },
//             meta: {
//               roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
//             }
//           },
//           // 프로젝트 상세
//           {
//             path: '/main/project/:menuPath/:projCd/detail',
//             component: ProjectDetailView,
//             props (route) {
//               return {
//                 projCd: route.params.projCd,
//                 menuPath: route.params.menuPath
//               }
//             },
//             meta: {
//               roles: ['ROLE_ADMIN', 'ROLE_REVIEWER', 'ROLE_LICENSEE']
//             }
//           }
//         ]
//       },
//       // 미정의
//       {
//         path: '*',
//         redirect: '/404'
//       }
//     ]
//   }
// ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'open active',
  routes,
  scrollBehavior: () => ({ y: 0 })
})

export default router
