<template>
  <v-dialog v-model="dialogModel" persistent width="auto " :max-width="$const.maxWidthLayout.lg" :retain-focus="false">
    <v-card flat>
      <!-- 타이틀바 -->
      <v-card-title class="pa-0">
        <title-bar :title="$t('word.preview')" color="primary" dark>
          <template v-slot:action>
            <v-btn icon large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
          </template>
        </title-bar>
      </v-card-title>
      <!-- 컨텐츠 -->
      <v-card-text class="pt-4" style="background-color:#E0E0E0">
        <v-img :src="dataUrl" max-height="80vh" contain></v-img>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import * as blobUtil from 'blob-util'

export default {
  name: 'PreviewImagePopup',
  components: {
    TitleBar
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    url: {
      type: String,
      required: false
    }
  },
  mounted () {
    this.fetchItem()
  },
  data () {
    return {
      dataUrl: null
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    }
  },
  methods: {
    fetchItem () {
      if (this.$isNull(this.url)) {
        return
      }
      this.$http.get(this.url, { responseType: 'blob' })
        .then((response) => {
          blobUtil.blobToDataURL(response.data)
            .then((result) => {
              this.dataUrl = result
            })
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>
