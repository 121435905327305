<template>
  <v-dialog v-model="dialogModel" persistent :max-width="$const.maxWidthLayout.md" :retain-focus="false">
    <v-card flat>
      <!-- 타이틀바 -->
      <title-bar :title="$t('word.renameProj')" color="primary" dark>
        <template v-slot:action>
          <v-btn icon rounded large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
        </template>
      </title-bar>
      <!-- 컨텐츠 -->
      <v-card>
        <v-card-text class="pb-0">
          <v-form ref="frm">
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="projNm"
                    :label="$t('word.projNm') + ' *'"
                    maxlength="100"
                    :rules="[ requiredRule, nameRule ]">
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="cnts"
                    :label="$t('word.chngCnts') + ' *'"
                    filled
                    rows="3"
                    :readonly="readonly"
                    :rules="[ requiredRule, length2000Rule ]">
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text large color="secondary" @click="dialogModel = false">{{ $t('word.close') }}</v-btn>
          <v-btn v-if="!readonly" text large color="primary" @click="handleSaveClicked">{{ $t('word.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { forms } from '@/mixins/formMixin'
import TitleBar from '@/components/TitleBar'

export default {
  name: 'ProjectRenamePopup',
  mixins: [forms],
  components: {
    TitleBar
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    asisProjNm: {
      type: String,
      required: false
    },
    readonly: {
      type: Boolean,
      required: false
    }
  },
  mounted () {
    this.projNm = this.asisProjNm
    this.$refs.frm.resetValidation()
  },
  data () {
    return {
      projNm: null,
      cnts: null
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    }
  },
  methods: {
    handleSaveClicked () {
      if (this.readonly) {
        return
      }
      if (!this.$refs.frm.validate(true)) {
        return
      }
      if (this.asisProjNm === this.projNm) {
        this.$toasted.global.info(this.$t('ProjectRenamePopup.txt001'))
        return
      }
      this.$emit('item-changed', {
        tobeProjNm: this.projNm,
        tobeHistRsnCnts: this.cnts
      })
      this.dialogModel = false
    }
  }
}
</script>
