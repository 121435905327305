<template>
  <div>
    <v-card outlined>
      <!-- 타이틀바 -->
      <title-bar :title="title">
        <template v-slot:action>
          <v-btn small tile :min-width="$const.minWidthButton" color="secondary" class="mr-1" @click="handleClearClicked">{{ $t('word.clear') }}</v-btn>
          <v-btn small tile :min-width="$const.minWidthButton" color="secondary" class="mr-1" @click="fetchList(true)">{{ $t('word.search') }}</v-btn>
          <v-btn v-if="isAdmin" small tile :min-width="$const.minWidthButton" color="primary" @click="handleAddClicked">{{ $t('word.add') }}</v-btn>
        </template>
      </title-bar>
      <!-- 검색조건 -->
      <field-bar :field-size="6" :md="2">
        <template v-slot:field-1>
          <date-field
            v-model="strYmd"
            :label="$t('word.cntrYmd') + ' (FROM)'"
            background-color="white"
            outlined
            dense
            hide-details>
          </date-field>
        </template>
        <template v-slot:field-2>
          <date-field
            v-model="endYmd"
            :label="$t('word.cntrYmd') + ' (TO)'"
            background-color="white"
            outlined
            dense
            hide-details>
          </date-field>
        </template>
        <template v-slot:field-3>
          <v-text-field
            v-model="ipNm"
            label="IP"
            readonly
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @click="showIpListPopup = true">
            <template v-slot:append>
              <v-btn x-small depressed color="action" @click="showIpListPopup = true">{{ $t('word.choose') }}</v-btn>
            </template>
          </v-text-field>
        </template>
        <template v-slot:field-4>
          <v-text-field
            v-model="compNm"
            :label="$t('word.liceComp')"
            readonly
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @click="showCompanyListPopup = true">
            <template v-slot:append>
              <v-btn x-small depressed color="action" @click="showCompanyListPopup = true">{{ $t('word.choose') }}</v-btn>
            </template>
          </v-text-field>
        </template>
        <template v-slot:field-5>
          <v-select
            v-model="srchDiv"
            :items="srchDivList"
            :label="$t('word.srchDiv')"
            background-color="white"
            outlined
            dense
            hide-details>
          </v-select>
        </template>
        <template v-slot:field-6>
          <v-text-field
            v-model="kwd"
            :label="$t('word.kwd')"
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @keypress.enter="fetchList(true)">
          </v-text-field>
        </template>
      </field-bar>
      <!-- 테이블 -->
      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="items"
        item-key="cntrId"
        height="69vh"
        class="text-truncate"
        fixed-header
        show-select
        single-select
        disable-filtering
        disable-pagination
        loading-text=""
        no-data-text=""
        no-results-text=""
        hide-default-footer
        :server-items-length="comTtlCnt"
        :sort-by.sync="comSrtBy"
        :sort-desc.sync="comSrtDesc"
        @click:row="handleRowClicked"
        @update:sort-by="delayedCall(fetchList)"
        @update:sort-desc="delayedCall(fetchList)">
        <template v-slot:item.cntrYmd="{ value }">
          <td>{{ $formatYmd(value )}}</td>
        </template>
        <template v-slot:item.cntrStrYmd="{ value }">
          <td>{{ $formatYmd(value )}}</td>
        </template>
        <template v-slot:item.cntrEndYmd="{ value }">
          <td>{{ $formatYmd(value )}}</td>
        </template>
        <template v-slot:item.modDt="{ value }">
          <td>{{ $timeToMediumDate(value) }}</td>
        </template>
        <template v-slot:body.append="{ headers }">
          <tr>
            <td :colspan="headers.length">
              {{ $t('word.total') }} {{ $formatNumber(comTtlCnt) }}{{ $t('word.count') }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <!-- 페이징 -->
      <paging-bar
        :page-no.sync="comPageNo"
        :page-cnt.sync="comPageCnt"
        :item-cnt-per-page.sync="comItemCntPerPage"
        @paging-changed="fetchList">
      </paging-bar>
    </v-card>
    <!-- IP 목록 팝업 -->
    <ip-list-popup
      v-if="showIpListPopup"
      :dialog.sync="showIpListPopup"
      :ip-id.sync="ipId"
      :ip-nm.sync="ipNm">
    </ip-list-popup>
    <!-- 회사 목록 팝업 -->
    <company-list-popup
      v-if="showCompanyListPopup"
      :dialog.sync="showCompanyListPopup"
      :comp-id.sync="compId"
      :comp-nm.sync="compNm">
    </company-list-popup>
    <!--  계약 상세 팝업 -->
    <contract-detail-popup
      v-if="showContractDetailPopup"
      :dialog.sync="showContractDetailPopup"
      :cntr-id="selectedItems.length === 0 ? null : selectedItems[0].cntrId"
      @item-changed="handleContractItemChanged">
    </contract-detail-popup>
  </div>
</template>

<script>
import { sorts } from '@/mixins/sortMixin'
import { pages } from '@/mixins/pageMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'
import FieldBar from '@/components/FieldBar'
import DateField from '@/components/DateField'
import PagingBar from '@/components/PagingBar'
import IpListPopup from '@/views/ip/popup/IpListPopup'
import CompanyListPopup from '@/views/company/popup/CompanyListPopup'
import ContractDetailPopup from '@/views/contract/popup/ContractDetailPopup'

export default {
  name: 'ContractListView',
  mixins: [sorts, pages, searches, stores],
  components: {
    TitleBar,
    FieldBar,
    DateField,
    PagingBar,
    IpListPopup,
    CompanyListPopup,
    ContractDetailPopup
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'NO TITLE'
    }
  },
  mounted () {
    this.fetchList()
  },
  data () {
    return {
      // 테이블 헤더
      headers: [
        { text: this.$t('word.cntrNo'), value: 'cntrNo' },
        { text: this.$t('word.cntrNm'), value: 'cntrNm' },
        { text: this.$t('word.ipNm'), value: 'ipNm' },
        { text: this.$t('word.liceComp'), value: 'compNm' },
        { text: this.$t('word.cntrYmd'), value: 'cntrYmd' },
        { text: this.$t('word.cntrStrYmd'), value: 'cntrStrYmd' },
        { text: this.$t('word.cntrEndYmd'), value: 'cntrEndYmd' },
        { text: this.$t('word.modDt'), value: 'modDt', sortable: false }
      ],
      // 조회결과 목록
      items: [],
      // 검색조건
      strYmd: null,
      endYmd: null,
      ipId: null,
      ipNm: null,
      compId: null,
      compNm: null,
      srchDiv: 'cntrNm',
      kwd: null,
      // 선택 아이템
      selectedItems: [],
      // IP 목록 팝업 오픈 여부
      showIpListPopup: false,
      // 회사 목록 팝업 오픈 여부
      showCompanyListPopup: false,
      // 계약 상세 팝업 오픈 여부
      showContractDetailPopup: false
    }
  },
  computed: {
    srchDivList () {
      return [
        { text: this.$t('word.cntrNo'), value: 'cntrNo' },
        { text: this.$t('word.cntrNm'), value: 'cntrNm' }
      ]
    }
  },
  methods: {
    // 목록조회
    fetchList (shouldClear) {
      // 검색 초기화
      if (shouldClear) {
        this.comPageNo = 1
        this.selectedItems = []
      }
      // 검색 파라미터 할당
      this.clearComParam()
      this.appendParam('strYmd', this.strYmd)
      this.appendParam('endYmd', this.endYmd)
      if (!this.$isEmpty(this.ipNm)) {
        this.appendParam('ipId', this.ipId)
      }
      if (!this.$isEmpty(this.compNm)) {
        this.appendParam('compId', this.compId)
      }
      if (!this.$isEmpty(this.kwd)) {
        this.appendParam(this.srchDiv, this.kwd)
      }
      // 검색
      this.$http.get(this.toRequestUrl('/api/1/contracts'))
        .then((response) => {
          const page = response.data.result.cntrPage
          this.bindComPage(page)
          this.items = page.list
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 초기화 버튼 클릭시
    handleClearClicked () {
      this.strYmd = null
      this.endYmd = null
      this.ipId = null
      this.ipNm = null
      this.compId = null
      this.compNm = null
      this.useYn = null
      this.srchDiv = 'cntrNm'
      this.kwd = null
      this.fetchList(true)
    },
    // 추가 버튼 클릭시
    handleAddClicked () {
      this.selectedItems = []
      this.showContractDetailPopup = true
    },
    // 테이블 로우 클릭시
    handleRowClicked (item) {
      this.selectedItems = [item]
      this.showContractDetailPopup = true
    },
    // 계약 상세 팝업 데이터 변경시
    handleContractItemChanged (item) {
      if (this.$isNull(item)) {
        this.fetchList(true)
      } else {
        this.fetchList()
      }
    }
  }
}
</script>
