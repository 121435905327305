<template>
  <div>
    <v-dialog v-model="dialogModel" persistent :max-width="$const.maxWidthLayout.lg" :retain-focus="false">
      <v-card flat>
        <!-- 타이틀바 -->
        <title-bar :title="`${$t('word.registDsgnInsp')} : ${this.projCd}`" color="primary" dark>
          <template v-slot:action>
            <v-btn icon large dark @click="handleCloseClicked"><v-icon>mdi-close</v-icon></v-btn>
          </template>
        </title-bar>
        <!-- 컨텐츠 -->
        <v-card min-height="736">
          <v-card-text class="pt-1 pb-0">
            <!-- 디자인검수 정보 -->
            <v-form ref="frm">
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="dsgnInsp.inspStgCd"
                      :label="$t('word.inspStg') + ' *'"
                      :items="projStgCdList"
                      :rules="[ requiredRule ]"
                      small-chips
                      disabled
                      hide-details>
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="dsgnInsp.inspApplCnts"
                      :label="$t('word.inspApplCnts') + ' *'"
                      filled
                      rows="5"
                      :rules="[ requiredRule, length2000Rule ]">
                    </v-textarea>
                  </v-col>
                </v-row>
                <!-- 첨부 파일 -->
                <v-card outlined min-height="440px">
                  <title-bar :title="`${$t('word.atchFile')} (${inspFileList.length})`" sub>
                    <template v-slot:action>
                      <v-btn small tile color="secondary" :ripple="false" @click="showInspectionFileRegistPopup = true">{{ $t('word.registFile') }}</v-btn>
                    </template>
                  </title-bar>
                  <v-divider></v-divider>
                  <template v-if="inspFileList.length > 0">
                    <v-list three-line max-height="372px" class="pt-0 overflow-y-auto">
                      <template v-for="(inspFile, index) in inspFileList">
                        <v-list-item :key="index">
                          <v-list-item-content>
                            <v-row>
                              <v-col cols="12" md="4">
                                <div class="d-flex justify-start">
                                  <span class="font-weight-black text-h4 primary--text text--lighten-2 mt-2 mr-4">{{ $lpad(index + 1, 2, '0') }}</span>
                                  <template v-if="$isNull(tumbDataList[index])">
                                    <v-avatar size="56" color="grey lighten-3" class="mr-4">
                                      <v-icon>mdi-paperclip</v-icon>
                                    </v-avatar>
                                  </template>
                                  <template v-else>
                                    <v-avatar size="56" class="mr-4">
                                      <v-img :src="`data:image/${inspFile.fileExtNm};base64,${tumbDataList[index]}`"></v-img>
                                    </v-avatar>
                                  </template>
                                  <v-select
                                    v-model="inspFile.inspFileDivCd"
                                    :label="$t('word.fileDiv') + ' *'"
                                    :items="inspFileDivCdList"
                                    filled
                                    small-chips
                                    :rules="[ requiredRule ]">
                                  </v-select>
                                </div>
                              </v-col>
                              <v-col cols="12" md="8">
                                <v-textarea
                                  v-model="inspFile.inspApplCnts"
                                  :label="inspFile.mngFileNm"
                                  filled
                                  rows="1"
                                  auto-grow
                                  :rules="[ length2000Rule ]"
                                  hide-details>
                                </v-textarea>
                              </v-col>
                            </v-row>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn icon color="error" :ripple="false" @click.stop="handleFileDeleteClicked(index)" class="mt-2"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider
                          :key="`line-${index}`">
                        </v-divider>
                      </template>
                    </v-list>
                  </template>
                  <template v-else>
                    <v-card-text class="text-center">
                      {{ $t('msg.notExistsFile') }}
                    </v-card-text>
                  </template>
                </v-card>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-end align-end">
            <v-btn text large color="secondary" @click="handleCloseClicked">{{ $t('word.close') }}</v-btn>
            <v-btn v-if="isLicensee" text large color="info" @click="handleSaveDraftClicked">{{ $t('word.draftSave') }}</v-btn>
            <v-btn v-if="modifying && isLicensee" text large color="primary" @click="handleRequestForApprovalClicked">{{ $t('word.requestApv') }}</v-btn>
            <v-btn v-if="modifying && isLicensee" text large color="error" @click="handleDeleteClicked">{{ $t('word.delete') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
    <!-- 첨부 파일 등록 팝업 -->
    <inspection-file-regist-popup
      v-if="showInspectionFileRegistPopup"
      :dialog.sync="showInspectionFileRegistPopup"
      :comp-id="compId"
      :proj-cd="projCd"
      @item-changed="handleFileAdded">
    </inspection-file-regist-popup>
  </div>
</template>

<script>
import { forms } from '@/mixins/formMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'
import InspectionFileRegistPopup from '@/views/project/popup/InspectionFileRegistPopup'

export default {
  name: 'InspectionRegistPopup',
  mixins: [forms, searches, stores],
  components: {
    TitleBar,
    InspectionFileRegistPopup
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    compId: {
      type: String,
      required: false
    },
    projCd: {
      type: String,
      required: false
    },
    inspNo: {
      type: Number,
      required: false
    }
  },
  mounted () {
    // 공통코드 조회 (프로젝트단계, 검수파일구분 순)
    this.$getCodes(['PT0000', 'IF0000'], (result) => {
      this.projStgCdList = this.$toSelectableCodes(result.PT0000)
      this.inspFileDivCdList = this.$toSelectableCodes(result.IF0000)
      if (this.modifying) {
        // 디자인검수 조회
        this.fetchItem()
      } else {
        this.fetchInspStgCd()
      }
    })
  },
  watch: {
    dsgnInsp: {
      deep: true,
      handler () {
        this.somethingChanged = true
      }
    },
    inspFileList: {
      deep: true,
      handler () {
        this.somethingChanged = true
      }
    }
  },
  data () {
    return {
      projStgCdList: [],
      inspFileDivCdList: [],
      proj: {},
      dsgnInsp: {},
      inspFileList: [],
      tumbDataList: [],
      somethingChanged: false,
      showInspectionFileRegistPopup: false
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    },
    modifying () {
      if (!this.$isNull(this.inspNo)) {
        return true
      } else {
        return !this.$isNull(this.dsgnInsp.inspNo)
      }
    }
  },
  methods: {
    // 데이터조회
    fetchItem () {
      if (!this.modifying) {
        return
      }
      this.$http.get(`/api/1/projects/${this.projCd}/inspection/${this.inspNo}`)
        .then((response) => {
          const projUnion = response.data.result.projUnion
          this.proj = projUnion.proj
          this.dsgnInsp = projUnion.dsgnInsp
          this.inspFileList = projUnion.inspFileList
          this.tumbDataList = response.data.result.tumbDataList
          // 값 할당후 watch 가 뒤늦게 실행되므로 nextTick 에서 변경사항이 없음을 할당한다.
          this.$nextTick(() => {
            this.somethingChanged = false
            // 신청내용을 입력하지 않고 임시 저장한 경우 null 값이 할당되면서 검증기가 작동한다.
            // 따라서 여기서 검증상태를 초기화 한다.
            this.$refs.frm.resetValidation()
          })
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 검수단계 조회
    fetchInspStgCd () {
      if (this.modifying) {
        return
      }
      this.$http.get(`/api/1/projects/${this.projCd}/inspection/next`)
        .then((response) => {
          this.dsgnInsp = {
            projCd: this.projCd,
            inspNo: this.inspNo,
            inspStgCd: response.data.result.inspStgCd,
            inspStsCd: 'IS0100' // 작성중
          }
          // 값 할당후 watch 가 뒤늦게 실행되므로 nextTick 에서 변경사항이 없음을 할당한다.
          this.$nextTick(() => {
            this.somethingChanged = false
            // 디자인 검수 신청이 불가능한 경우 검수단계코드가 null 값으로 리턴되며, 이경우 메시지 처리 후 팝업창을 닫는다.
            if (this.$isNull(this.dsgnInsp.inspStgCd)) {
              this.$toasted.global.warn(this.$t('InspectionRegistPopup.txt001'))
              this.dialogModel = false
            }
          })
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleSaveDraftClicked () {
      const item = {
        dsgnInsp: this.dsgnInsp,
        inspFileList: this.inspFileList
      }
      if (this.modifying) {
        this.$http.put(`/api/1/projects/${this.dsgnInsp.projCd}/inspection/${this.dsgnInsp.inspNo}/draft`, item)
          .then((response) => {
            this.$toasted.global.success(this.$t('msg.draftSaved'))
            const dsgnInspUnion = response.data.result.dsgnInspUnion
            this.dsgnInsp = dsgnInspUnion.dsgnInsp
            this.inspFileList = dsgnInspUnion.inspFileList === null ? [] : dsgnInspUnion.inspFileList
            this.$emit('item-changed', this.dsgnInsp)
            // 값 할당후 watch 가 뒤늦게 실행되므로 nextTick 에서 변경사항이 없음을 할당한다.
            this.$nextTick(() => {
              this.somethingChanged = false
            })
          })
          .catch((error) => {
            this.$showError(error)
          })
      } else {
        this.$http.post(`/api/1/projects/${this.projCd}/inspection`, item)
          .then((response) => {
            this.$toasted.global.success(this.$t('msg.draftSaved'))
            const dsgnInspUnion = response.data.result.dsgnInspUnion
            this.dsgnInsp = dsgnInspUnion.dsgnInsp
            this.inspFileList = dsgnInspUnion.inspFileList === null ? [] : dsgnInspUnion.inspFileList
            this.$emit('item-changed', this.dsgnInsp)
            // 값 할당후 watch 가 뒤늦게 실행되므로 nextTick 에서 변경사항이 없음을 할당한다.
            this.$nextTick(() => {
              this.somethingChanged = false
            })
          })
          .catch((error) => {
            this.$showError(error)
          })
      }
    },
    handleRequestForApprovalClicked () {
      if (!this.modifying) {
        return
      }
      if (!this.$refs.frm.validate(true)) {
        return
      }
      if (this.$isEmptyArray(this.inspFileList)) {
        this.$toasted.global.warn(this.$t('InspectionRegistPopup.txt002'))
        return
      }
      if (!confirm(this.$t('InspectionRegistPopup.txt003'))) {
        return
      }
      const item = {
        dsgnInsp: this.dsgnInsp,
        inspFileList: this.inspFileList
      }
      this.$http.put(`/api/1/projects/${this.dsgnInsp.projCd}/inspection/${this.dsgnInsp.inspNo}/request`, item)
        .then((response) => {
          this.$toasted.global.success(this.$t('msg.requestedForApproval'))
          const dsgnInspUnion = response.data.result.dsgnInspUnion
          this.$emit('item-changed', dsgnInspUnion.dsgnInsp)
          this.dialogModel = false
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleDeleteClicked () {
      if (!this.modifying) {
        return
      }
      if (!confirm(this.$t('InspectionRegistPopup.txt004'))) {
        return
      }
      this.$http.delete(`/api/1/projects/${this.dsgnInsp.projCd}/inspection/${this.dsgnInsp.inspNo}`)
        .then(() => {
          this.$toasted.global.success(this.$t('msg.deleted'))
          this.$emit('item-changed')
          this.dialogModel = false
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleFileAdded (obj) {
      // 검수번호 할당
      obj.inspFile.inspNo = this.dsgnInsp.inspNo
      this.inspFileList.push(obj.inspFile)
      this.tumbDataList.push(obj.tumbData)
    },
    handleFileDeleteClicked (index) {
      if (index >= this.inspFileList.length) {
        return
      }
      if (!confirm(this.$t('InspectionRegistPopup.txt005'))) {
        return
      }
      this.inspFileList.splice(index, 1)
      this.tumbDataList.splice(index, 1)
    },
    handleCloseClicked () {
      if (this.somethingChanged) {
        if (!confirm(this.$t('msg.detectChange'))) {
          return
        }
      }
      this.dialogModel = false
    }
  }
}
</script>
