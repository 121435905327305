<template>
  <div>
    <v-dialog v-model="dialogModel" persistent :max-width="maxWidth" :retain-focus="false">
      <v-card flat>
        <!-- 타이틀바 -->
        <title-bar :title="title" color="primary" dark>
          <template v-slot:action>
            <v-btn icon large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
          </template>
        </title-bar>
        <!-- 컨텐츠 -->
        <v-card>
          <v-card-text class="pb-0">
              <v-container fluid>
                <v-row>
                  <v-col cols="12" :md="modifying ? 8 : 12">
                    <v-form ref="frm">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            ref="compCd"
                            v-model="item.compCd"
                            :label="$t('word.compCd') + ' *'"
                            maxlength="3"
                            :readonly="modifying"
                            persistent-hint
                            :hint="$t('CompanyDetailPopup.txt009')"
                            :rules="[ requiredRule, compCdRule ]"
                            @input="compCdChecked = false">
                            <template v-if="!modifying" v-slot:prepend>
                              <v-icon :color="compCdChecked ? 'success' : 'grey'">mdi-check-circle</v-icon>
                            </template>
                            <template v-if="!modifying" v-slot:append>
                              <v-btn depressed x-small color="action" @click="handleCheckCompCdClicked">{{ $t('word.checkDuplicate') }}</v-btn>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="item.ntnCd"
                            :label="$t('word.ntn') + ' *'"
                            :items="ntnCdList"
                            :rules="[ requiredRule ]"
                            small-chips>
                          </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="item.compNm"
                            :label="$t('word.compNm') + ' *'"
                            maxlength="20"
                            persistent-hint
                            :hint="$t('CompanyDetailPopup.txt010')"
                            :rules="[ requiredRule, nameRule ]">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            ref="bizno"
                            v-model="item.bizno"
                            :label="$t('word.bizno') + ' *'"
                            maxlength="10"
                            :readonly="modifying"
                            persistent-hint
                            :hint="$t('CompanyDetailPopup.txt011')"
                            :rules="[ requiredRule, biznoRule ]">
                          </v-text-field>
                        </v-col>
                        <template v-if="isKorNtnCd">
                          <v-col cols="12">
                            <v-text-field
                              key="txtAddr"
                              v-model="item.addr"
                              :label="$t('word.addr')"
                              maxlength="500"
                              readonly
                              @click="showAddressListPopup = true">
                              <template v-slot:append>
                                <v-btn depressed x-small color="action" @click="showAddressListPopup = true">{{ $t('word.searchAddr') }}</v-btn>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="item.dtlAddr"
                              :label="$t('word.dtlAddr')"
                              maxlength="500">
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              key="txtPostNo"
                              v-model="item.postNo"
                              :label="$t('word.postNo')"
                              maxlength="8"
                              readonly>
                            </v-text-field>
                          </v-col>
                        </template>
                        <template v-else>
                          <v-col cols="12">
                            <v-text-field
                              key="txtAddrEng"
                              v-model="item.addr"
                              :label="$t('word.addr')"
                              maxlength=500
                              :disabled="$isNull(item.ntnCd)">
                            </v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              key="txtPostNoEng"
                              v-model="item.postNo"
                              :label="$t('word.postNo')"
                              maxlength="8"
                              :disabled="$isNull(item.ntnCd)">
                            </v-text-field>
                          </v-col>
                        </template>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="item.ceoNm"
                            :label="$t('word.ceoNm') + ' *'"
                            maxlength="20"
                            :rules="[ requiredRule, nameRule ]">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="item.repTelno"
                            :label="$t('word.repTelno') + ' *'"
                            maxlength="20"
                            persistent-hint
                            :hint="$t('CompanyDetailPopup.txt012')"
                            :rules="[ requiredRule, telnoRule ]">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            v-model="item.rmk"
                            :label="$t('word.rmk')"
                            filled
                            rows="3"
                            persistent-hint
                            :hint="$t('CompanyDetailPopup.txt008')"
                            :rules="[ length2000Rule ]">
                          </v-textarea>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                  <v-col v-if="modifying" cols="12" md="4">
                    <v-row>
                      <v-col cols="12">
                        <company-file-list-unit
                          :comp-id="$isNull(compId) ? item.compId : compId">
                        </company-file-list-unit>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text large color="secondary" @click="dialogModel = false">{{ $t('word.close') }}</v-btn>
            <v-btn v-if="isLicensor || (isLicensee && isLoginUsrCompSameToComp(compId))" text large color="primary" @click="handleSaveClicked">{{ $t('word.save') }}</v-btn>
            <v-btn v-if="modifying && isLicensor" text large color="error" @click="handleDeleteClicked">{{ $t('word.delete') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
    <!-- 주소 검색 팝업 -->
    <address-list-popup
      v-if="showAddressListPopup"
      :dialog.sync="showAddressListPopup"
      :postNo.sync="item.postNo"
      :addr.sync="item.addr">
    </address-list-popup>
  </div>
</template>

<script>
import { forms } from '@/mixins/formMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'
import CompanyFileListUnit from '@/views/company/unit/CompanyFileListUnit.vue'
import AddressListPopup from '@/components/AddressListPopup'

export default {
  name: 'CompanyDetailPopup',
  mixins: [forms, searches, stores],
  components: {
    TitleBar,
    CompanyFileListUnit,
    AddressListPopup
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    compId: {
      type: String,
      required: false
    },
    fixedTitle: {
      type: String,
      required: false
    }
  },
  mounted () {
    this.$getCodes(['SN0000'], (result) => {
      this.ntnCdList = this.$toSelectableCodes(result.SN0000)
      this.fetchItem()
    })
  },
  watch: {
    'item.ntnCd' (to, from) {
      if (this.$isNull(from) || this.$isNull(to)) {
        return
      }
      this.item.addr = null
      this.item.dtlAddr = null
      this.item.postNo = null
    }
  },
  data () {
    return {
      ntnCdList: [],
      item: {
        ntnCd: null,
        compDivCd: 'CD0200'
      },
      compCdChecked: false,
      showAddressListPopup: false
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    },
    modifying () {
      if (!this.$isNull(this.compId)) {
        return true
      } else {
        return !this.$isNull(this.item.compId)
      }
    },
    title () {
      if (this.$isNull(this.fixedTitle)) {
        return this.modifying ? this.$t('word.liceCompDtl') : this.$t('word.registLiceComp')
      } else {
        return this.fixedTitle
      }
    },
    maxWidth () {
      return this.modifying ? this.$const.maxWidthLayout.lg : this.$const.maxWidthLayout.md
    },
    isKorNtnCd () {
      return this.item.ntnCd === 'SNKR00'
    }
  },
  methods: {
    // 데이터조회
    fetchItem () {
      if (!this.modifying) {
        return
      }
      this.$http.get(`/api/1/companies/${this.compId}`)
        .then((response) => {
          this.item = response.data.result.comp
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 회사코드 중복여부 확인
    handleCheckCompCdClicked () {
      if (this.modifying || !this.$refs.compCd.validate(true)) {
        return
      }
      this.$http.get(`/api/1/companies/code?compCd=${this.item.compCd}`)
        .then((response) => {
          const comp = response.data.result.comp
          if (this.$isNull(comp)) {
            this.compCdChecked = true
            this.$toasted.global.success(this.$t('CompanyDetailPopup.txt001'))
          } else {
            this.compCdChecked = false
            this.$toasted.global.warn(this.$t('CompanyDetailPopup.txt002'))
          }
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleSaveClicked () {
      if (!this.$refs.frm.validate(true)) {
        return
      }
      if (this.modifying) {
        this.$http.put(`/api/1/companies/${this.item.compId}`, this.item)
          .then((response) => {
            this.$toasted.global.success(this.$t('msg.saved'))
            this.item = response.data.result.comp
            this.$emit('item-changed', this.item)
          })
          .catch((error) => {
            this.$showError(error)
          })
      } else {
        if (!this.compCdChecked) {
          this.$toasted.global.warn(this.$t('CompanyDetailPopup.txt005'))
          return
        }
        this.$http.post('/api/1/companies', this.item)
          .then((response) => {
            this.$toasted.global.success(this.$t('msg.registed'))
            this.item = response.data.result.comp
            this.$emit('item-changed', this.item)
          })
          .catch((error) => {
            this.$showError(error)
          })
      }
    },
    handleDeleteClicked () {
      if (!this.modifying || this.$isNull(this.item.compId)) {
        return
      }
      if (!confirm(this.$t('CompanyDetailPopup.txt007', [this.item.compNm]))) {
        return
      }
      this.$http.delete(`/api/1/companies/${this.item.compId}`)
        .then(() => {
          this.$toasted.global.success(this.$t('msg.deleted'))
          this.dialogModel = false
          this.$emit('item-changed')
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>
