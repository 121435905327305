<template>
  <v-container fluid class="fill-height">
    <v-row justify="center">
      <v-col cols="12">
        <div class="text-center">
          <v-chip small color="primary" class="font-weight-bold">IPTOWN</v-chip>
          <div class="mt-2">
            <h1>{{ $t('word.liceInfoCnfm') }}</h1>
          </div>
          <p class="mx-6 mt-4 mb-8 grey--text text--darken-1">
            {{ $t('UserJoinInfoView.txt001') }}
          </p>
        </div>
      </v-col>
      <v-col cols="12">
        <my-page-view
          :hide-title="true"
          :hide-action-button="true"
          :hide-go-to-main-button="false">
        </my-page-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MyPageView from '@/views/user/MyPageView.vue'

export default {
  name: 'UserJoinInfoView',
  components: {
    MyPageView
  }
}
</script>
