<template>
  <v-container fluid class="fill-height">
    <v-row justify="center">
      <v-col cols="12" sm="8" lg="4">
        <div class="text-center">
          <v-chip small color="primary" class="font-weight-bold">IPTOWN</v-chip>
          <div class="mt-2">
            <h1>{{ showForm ? $t('word.findEmail') : $t('word.findEmailResult') }}</h1>
          </div>
          <p class="mx-6 mt-4 mb-8 grey--text text--darken-1">
            <span v-if="showForm">
              {{ $t('EmailFindView.txt001') }} <br/>
              {{ $t('EmailFindView.txt002') }}
            </span>
            <span v-else>
              {{ $t('EmailFindView.txt003', [ usrNm, hnpno ]) }}
            </span>
          </p>
        </div>
        <v-card outlined elevation="0" class="pa-8">
          <v-card-title class="justify-center">
            <v-avatar size="60" color="grey lighten-1">
              <v-icon x-large dark>
                mdi-email-search
              </v-icon>
            </v-avatar>
          </v-card-title>
          <v-card-text v-if="showForm" class="text-center mt-10">
            <v-form ref="frm">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    v-model="hnpno"
                    :label="$t('word.hnpno')"
                    :maxlength="20"
                    filled
                    :rules="[ requiredRule, telnoRule ]"
                    @keypress.enter="fetchList()">
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="usrNm"
                    :label="$t('word.usrNm')"
                    :maxlength="100"
                    filled
                    :rules="[ requiredRule, nameRule ]"
                    @keypress.enter="fetchList()">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-text v-else class="text-left mt-10">
            <v-alert
              color="grey"
              outlined
              text>
              <v-list>
                <template v-for="(item, index) in items">
                  <v-list-item :key="index">
                    <v-list-item-content>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-alert>
          </v-card-text>
          <v-card-actions class="justify-end mr-2">
            <v-btn v-if="showForm" tile large min-width="180" color="primary" @click="fetchList">
              {{ $t('word.search') }}
            </v-btn>
            <v-btn v-else tile large min-width="180"  color="primary" @click="handlePrevClicked">
              {{ $t('word.back') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <div class="ma-4">
          <router-link to="/login" class="grey--text text--darken-1">{{ $t('word.doLogin') }}</router-link>
          <span class="grey--text text--lighten-1"> | </span>
          <router-link to="/find/password" class="grey--text text--darken-1">{{ $t('word.resetPassword') }}</router-link>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { forms } from '@/mixins/formMixin'

export default {
  name: 'EmailFindView',
  mixins: [forms],
  components: {
  },
  data () {
    return {
      showForm: true,
      hnpno: null,
      usrNm: null,
      items: []
    }
  },
  computed: {
  },
  methods: {
    fetchList () {
      if (!this.$refs.frm.validate(true)) {
        return
      }
      this.$http.get(encodeURI(`/api/1/users/email/miss?hnpno=${this.hnpno}&usrNm=${this.usrNm}`))
        .then((response) => {
          this.items = response.data.result.emailList
          if (this.items.length > 0) {
            this.showForm = false
          } else {
            this.$toasted.global.warn(this.$t('EmailFindView.txt004'))
          }
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handlePrevClicked () {
      this.hnpno = null
      this.usrNm = null
      this.showForm = true
    }
  }
}
</script>

<style lang="css" scoped>
a { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:focus { text-decoration: none; }
a:hover, a:active { text-decoration: none; }
</style>
