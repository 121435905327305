<template>
  <div>
    <v-dialog v-model="dialogModel" persistent :max-width="$const.maxWidthLayout.md" :retain-focus="false">
      <v-card flat>
        <!-- 타이틀바 -->
        <title-bar :title="title" color="primary" dark>
          <template v-slot:action>
            <v-btn icon large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
          </template>
        </title-bar>
        <!-- 컨텐츠 -->
        <v-card>
          <v-card-text class="pb-0">
              <v-form ref="frm">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        ref="ipCd"
                        v-model="item.ipCd"
                        :label="$t('word.ipCd') + ' *'"
                        maxlength="3"
                        :readonly="modifying"
                        persistent-hint
                        :hint="$t('IpDetailPopup.txt005')"
                        :rules="[ requiredRule, ipCdRule ]"
                        @input="ipCdChecked = false">
                        <template v-if="!modifying" v-slot:prepend>
                          <v-icon :color="ipCdChecked ? 'success' : 'grey'">mdi-check-circle</v-icon>
                        </template>
                        <template v-if="!modifying" v-slot:append>
                          <v-btn depressed x-small color="action" @click="handleCheckIpCdClicked">{{ $t('word.checkDuplicate') }}</v-btn>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="item.ipNm"
                        :label="$t('word.ipNm') + ' *'"
                        maxlength="100"
                        :rules="[ requiredRule, nameRule ]">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="item.ipEngNm"
                        :label="$t('word.ipEngNm') + ' *'"
                        maxlength="100"
                        :rules="[ requiredRule, nameRule ]">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="item.srtOrdno"
                        :label="$t('word.srtOrdno') + ' *'"
                        maxlength="9"
                        :rules="[ requiredRule, numericRule ]">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <label class="caption grey--text text--darken-2">{{ $t('word.useYn') }} *</label>
                      <v-radio-group
                        v-model="item.useYn"
                        row
                        mandatory
                        class="mt-0">
                        <v-radio
                          :label="$t('word.yes')"
                          value="Y"></v-radio>
                        <v-radio
                          :label="$t('word.no')"
                          value="N"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="item.dtlDesc"
                        :label="$t('word.dtlDesc')"
                        filled
                        rows="3"
                        :rules="[ length2000Rule ]">
                      </v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="item.engDtlDesc"
                        :label="$t('word.engDtlDesc')"
                        filled
                        rows="3"
                        persistent-hint
                        :hint="$t('IpDetailPopup.txt006')"
                        :rules="[ length2000Rule ]">
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text large color="secondary" @click="dialogModel = false">{{ $t('word.close') }}</v-btn>
            <v-btn v-if="isAdmin" text large color="primary" @click="handleSaveClicked">{{ $t('word.save') }}</v-btn>
            <v-btn v-if="modifying && isAdmin" text large color="error" @click="handleDeleteClicked">{{ $t('word.delete') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { forms } from '@/mixins/formMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'

export default {
  name: 'IpDetailPopup',
  mixins: [forms, searches, stores],
  components: {
    TitleBar
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    ipId: {
      type: String,
      required: false
    }
  },
  mounted () {
    this.fetchItem()
  },
  data () {
    return {
      item: {
        useYn: 'Y',
        srtOrdno: 1
      },
      ipCdChecked: false
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    },
    modifying () {
      if (!this.$isNull(this.ipId)) {
        return true
      } else {
        return !this.$isNull(this.item.ipId)
      }
    },
    title () {
      return this.modifying ? this.$t('word.ipDtl') : this.$t('word.registIp')
    }
  },
  methods: {
    // 데이터조회
    fetchItem () {
      if (!this.modifying) {
        return
      }
      this.$http.get(`/api/1/ip/${this.ipId}`)
        .then((response) => {
          this.item = response.data.result.ip
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // IP코드 중복여부 확인
    handleCheckIpCdClicked () {
      if (this.modifying || !this.$refs.ipCd.validate(true)) {
        return
      }
      this.$http.get(`/api/1/ip/code?ipCd=${this.item.ipCd}`)
        .then((response) => {
          const ip = response.data.result.ip
          if (this.$isNull(ip)) {
            this.ipCdChecked = true
            this.$toasted.global.success(this.$t('IpDetailPopup.txt001'))
          } else {
            this.ipCdChecked = false
            this.$toasted.global.warn(this.$t('IpDetailPopup.txt002'))
          }
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleSaveClicked () {
      if (!this.$refs.frm.validate(true)) {
        return
      }
      if (this.modifying) {
        this.$http.put(`/api/1/ip/${this.item.ipId}`, this.item)
          .then((response) => {
            this.$toasted.global.success(this.$t('msg.saved'))
            this.item = response.data.result.ip
            this.$emit('item-changed', this.item)
          })
          .catch((error) => {
            this.$showError(error)
          })
      } else {
        if (!this.ipCdChecked) {
          this.$toasted.global.warn(this.$t('IpDetailPopup.txt003'))
          return
        }
        this.$http.post('/api/1/ip', this.item)
          .then((response) => {
            this.$toasted.global.success(this.$t('msg.registed'))
            this.item = response.data.result.ip
            this.$emit('item-changed', this.item)
          })
          .catch((error) => {
            this.$showError(error)
          })
      }
    },
    handleDeleteClicked () {
      if (!this.modifying || this.$isNull(this.item.ipId)) {
        return
      }
      if (!confirm(this.$t('IpDetailPopup.txt004', [this.item.ipNm]))) {
        return
      }
      this.$http.delete(`/api/1/ip/${this.item.ipId}`)
        .then(() => {
          this.$toasted.global.success(this.$t('msg.deleted'))
          this.dialogModel = false
          this.$emit('item-changed')
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>
