<template>
  <div>
    <v-dialog v-model="dialogModel" persistent :max-width="$const.maxWidthLayout.lg" :retain-focus="false">
      <v-card flat>
        <!-- 타이틀바 -->
        <title-bar :title="title" color="primary" dense dark show-extension>
          <template v-slot:action>
            <v-btn icon large dark @click="handleCloseClicked"><v-icon>mdi-close</v-icon></v-btn>
          </template>
          <!-- 탭 정의 -->
          <template v-slot:extension>
            <v-tabs
              v-model="tab"
              slider-color="amber"
              align-with-title>
              <v-tab>{{ $t('word.proj') }}</v-tab>
              <v-tab>{{ $t('word.chrgrInfo') }}</v-tab>
              <v-tab>{{ $t('word.applDtl') }}</v-tab>
            </v-tabs>
          </template>
        </title-bar>
        <!-- 컨텐츠 -->
        <v-card min-height="740px">
          <v-tabs-items v-model="tab">
            <!-- 프로젝트 탭 -->
            <v-tab-item key="proj">
              <!-- 상단 프로젝트 요약 정보 -->
              <div class="mx-3">
                <project-detail-unit
                  :proj-cd="stmpAppl.projCd">
                </project-detail-unit>
              </div>
              <v-divider></v-divider>
              <v-container fluid>
                <v-row >
                  <v-btn tile depressed small readonly :ripple="false" style="cursor:default;">{{ $t('word.cntr') }}</v-btn>
                </v-row>
              </v-container>
              <div class="mx-3">
                <contract-detail-unit
                  :cntr-id="cntrId">
                </contract-detail-unit>
              </div>
            </v-tab-item>
            <!-- 담당자 정보 탭 -->
            <v-tab-item key="chrgrInfo">
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <!-- 라이선서 정보 -->
                    <v-col cols="12" md="6">
                      <v-sheet color="grey lighten-4">
                        <v-container fluid>
                          <v-row dense>
                            <v-col cols="12">
                              <label-field
                                :label="$t('word.licr')"
                                :value="stmpAppl.licrCompNm">
                              </label-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <label-field
                                :label="$t('word.chrgr')"
                                :value="stmpAppl.licrChrgrNm">
                              </label-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <label-field
                                :label="$t('word.telno')"
                                :value="stmpAppl.licrChrgrTelno">
                              </label-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <label-field
                                :label="$t('word.hnpno')"
                                :value="stmpAppl.licrChrgrHnpno">
                              </label-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <label-field
                                :label="$t('word.email')"
                                :value="stmpAppl.licrChrgrEmail">
                              </label-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-sheet>
                    </v-col>
                    <!-- 증지 제작 업체 정보 -->
                    <v-col cols="12" md="6">
                      <v-sheet color="grey lighten-4">
                        <v-container fluid>
                          <v-row dense>
                            <v-col cols="12">
                              <label-field
                                :label="$t('word.stmpMnfcComp')"
                                :value="stmpAppl.stmpMnfcCompNm">
                              </label-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <label-field
                                :label="$t('word.chrgr')"
                                :value="stmpAppl.stmpMnfcChrgrNm">
                              </label-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <label-field
                                :label="$t('word.telno')"
                                :value="stmpAppl.stmpMnfcChrgrTelno">
                              </label-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <label-field
                                :label="$t('word.hnpno')"
                                :value="stmpAppl.stmpMnfcChrgrHnpno">
                              </label-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <label-field
                                :label="$t('word.email')"
                                :value="stmpAppl.stmpMnfcChrgrEmail">
                              </label-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-form ref="frm1">
                <!-- 라이선시 담당자 정보 -->
                <v-container fluid class="pb-0">
                  <v-row>
                    <v-btn tile depressed small readonly :ripple="false" style="cursor:default;">{{ $t('word.liceChrgr') }}</v-btn>
                  </v-row>
                </v-container>
                <v-card-text class="mb-3">
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" md="4" class="py-2">
                        <v-text-field
                          v-model="stmpAppl.stmpApplCompNm"
                          :label="$t('word.comp')"
                          readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" class="py-2">
                        <v-text-field
                          v-model="stmpAppl.stmpApplrNm"
                          :label="$t('word.chrgr') + ' *'"
                          maxlength="100"
                          :rules="[ requiredRule, nameRule ]"
                          :readonly="isLicensee && modifying">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" class="py-2">
                        <v-text-field
                          v-model="stmpAppl.stmpApplrTelno"
                          :label="$t('word.telno') + ' *'"
                          maxlength="20"
                          persistent-hint
                          :hint="$t('msg.guideTelno')"
                          :rules="[ requiredRule, telnoRule ]"
                          :readonly="isLicensee && modifying">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" class="py-2">
                        <v-text-field
                          v-model="stmpAppl.stmpApplrHnpno"
                          :label="$t('word.hnpno') + ' *'"
                          maxlength="20"
                          persistent-hint
                          :hint="$t('msg.guideHnpno')"
                          :rules="[ requiredRule, telnoRule ]"
                          :readonly="isLicensee && modifying">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" class="py-2">
                        <v-text-field
                          v-model="stmpAppl.stmpApplrEmail"
                          :label="$t('word.email') + ' *'"
                          maxlength="100"
                          :rules="[ requiredRule, emailRule ]"
                          :readonly="isLicensee && modifying">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" class="py-2">
                        <v-text-field
                          v-model="stmpAppl.billEmail"
                          :label="$t('word.billEmail') + ' *'"
                          maxlength="100"
                          :rules="[ requiredRule, emailRule ]"
                          :readonly="isLicensee && modifying">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <!-- 라이선시 증지 수령자 정보 -->
                <v-container fluid class="pb-0">
                  <v-row>
                    <v-btn tile depressed small readonly :ripple="false" style="cursor:default;">{{ $t('word.liceStmpRecp') }}</v-btn>
                  </v-row>
                </v-container>
                <v-card-text>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" md="8" class="py-2">
                        <v-text-field
                          key="txtAddr"
                          v-model="stmpAppl.stmpRecpAddr"
                          :label="$t('word.addr') + ' *'"
                          maxlength="500"
                          readonly
                          :rules="[ requiredRule ]"
                          @click="!(isLicensee && modifying) ? showAddressListPopup = true : ''">
                          <template v-if="!(isLicensee && modifying)" v-slot:append>
                            <v-btn depressed x-small color="action" @click="showAddressListPopup = true">{{ $t('word.searchAddr') }}</v-btn>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" class="py-2">
                        <v-text-field
                          v-model="stmpAppl.stmpRecpDtlAddr"
                          :label="$t('word.dtlAddr') + ' *'"
                          maxlength="500"
                          :rules="[ requiredRule ]"
                          :readonly="isLicensee && modifying">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" class="py-2">
                        <v-text-field
                          key="txtPostNo"
                          v-model="stmpAppl.stmpRecpPostNo"
                          :label="$t('word.postNo')"
                          maxlength="8"
                          readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" class="py-2">
                        <v-text-field
                          v-model="stmpAppl.stmpRecpNm"
                          :label="$t('word.recpr') + ' *'"
                          maxlength="100"
                          :rules="[ requiredRule, nameRule ]"
                          :readonly="isLicensee && modifying">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" class="py-2">
                        <v-text-field
                          v-model="stmpAppl.stmpRecpTelno"
                          :label="$t('word.contacts') + ' *'"
                          maxlength="20"
                          persistent-hint
                          :hint="$t('msg.guideTelno')"
                          :rules="[ requiredRule, telnoRule ]"
                          :readonly="isLicensee && modifying">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-form>
              <br>
            </v-tab-item>
            <!-- 신청 내역 탭 -->
            <v-tab-item key="applDtl" eager>
              <!-- 상단 품목 합계 요약 정보 -->
              <v-container fluid>
                <v-row>
                  <v-col cols="9">
                    <v-card-text>
                      <v-sheet color="grey lighten-4">
                        <v-container fluid>
                          <v-row dense>
                            <v-col cols="12" md="4">
                              <label-field
                                :label="$t('word.projCd')"
                                :value="stmpAppl.projCd">
                              </label-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <label-field
                                :label="$t('word.stmpApplNo')"
                                :value="stmpAppl.stmpApplNo">
                              </label-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <label-field
                                :label="$t('word.stmpApplYmd')"
                                :value="$timeToShortDate(stmpAppl.stmpApplDt)">
                              </label-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <label-field
                                :label="$t('word.stmpStdAmt')"
                                :value="$formatNumber(stmpAppl.stmpStdAmt)">
                              </label-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <label-field
                                :label="$t('word.prdcCntSum')"
                                :value="$formatNumber(stmpAppl.prdcCntSum)">
                              </label-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <label-field
                                :label="$t('word.ryltAmtSum')"
                                :value="$formatNumber(stmpAppl.ryltAmtSum)">
                              </label-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <label-field
                                :label="$t('word.stmpAmtSum')"
                                :value="$formatNumber(stmpAppl.stmpAmtSum)">
                              </label-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <label-field
                                :label="$t('word.dpsTgtAmtSum')"
                                :value="$formatNumber(stmpAppl.dpsTgtAmtSum)"
                                value-class="primary--text font-weight-bold">
                              </label-field>
                              <v-row style="color: red; font-weight: bolder">
                                &nbsp;&nbsp;&nbsp; 반드시 VAT 포함 금액으로 입금.
                              </v-row>
                              <!--<label-field
                                :value="$t('word.dpsTgtAmtSumMsg')"
                                value-class="error--text font-weight-bold">
                              </label-field>-->
                            </v-col>
                            <v-col cols="12" md="4">
                              <label-field
                                :label="$t('word.stmpMnfcCompAcctInfo')"
                                :value="stmpAppl.stmpMnfcCompAcctInfo"
                                value-class="primary--text font-weight-bold">
                              </label-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-sheet>
                    </v-card-text>
                  </v-col>
                  <v-col cols="3" v-if="modifying" style="padding-top: 2%">
                    <stamp-file-list-unit
                      :stmp-appl-no="stmpApplNo"
                      :proj-cd="projCd"
                      @item-changed="fetchItem">
                    </stamp-file-list-unit>
                  </v-col>
                </v-row>
              </v-container>
              <!-- 테이블 -->
              <v-container v-if="isLicensor || (isLicensee && !modifying)" fluid>
                <v-row class="justify-end mr-4 mb-1">
                  <!--<v-btn small tile :min-width="$const.minWidthButton" color="primary" @click="handleStampFileRegistAdd">{{ $t('word.depoConf') }}</v-btn>-->
                  <v-btn small tile :min-width="$const.minWidthButton" color="primary" @click="handleStampLotAddClicked">{{ $t('word.add') }}</v-btn>
                </v-row>
              </v-container>
              <v-divider></v-divider>
              <v-data-table
                v-model="selectedItems"
                :headers="headers"
                :items="stmpLotList"
                item-key="vrtlId"
                height="441px"
                class="text-truncate"
                fixed-header
                show-select
                single-select
                disable-filtering
                disable-pagination
                loading-text=""
                no-data-text=""
                no-results-text=""
                hide-default-footer
                @click:row="handleStampLotRowClicked"
                @update:sort-by="delayedCall(fetchList)"
                @update:sort-desc="delayedCall(fetchList)">
                <template v-slot:item.cnsmAmt="{ value }">
                  <td>{{ $formatNumber(value) }}</td>
                </template>
                <template v-slot:item.rlsAmt="{ value }">
                  <td>{{ $formatNumber(value) }}</td>
                </template>
                <template v-slot:item.prdcCnt="{ value }">
                  <td>{{ $formatNumber(value) }}</td>
                </template>
                <template v-slot:item.ryltAmt="{ value }">
                  <td>{{ $formatNumber(value) }}</td>
                </template>
                <template v-slot:item.stmpAmt="{ value }">
                  <td>{{ $formatNumber(value) }}</td>
                </template>
                <template v-slot:body.append>
                  <tr class="font-weight-bold" style="background-color:#FAFAFA">
                    <td :colspan="6" class="text-center">합계</td>
                    <td>{{ $formatNumber(stmpAppl.prdcCntSum) }}</td>
                    <td>{{ $formatNumber(stmpAppl.ryltAmtSum) }}</td>
                    <td></td>
                    <td>{{ $formatNumber(stmpAppl.stmpAmtSum) }}</td>
                  </tr>
                  <v-divider></v-divider>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
          <v-card-actions style="position: absolute; bottom: 0; right: 0">
            <v-btn text large color="secondary" @click="handleCloseClicked">{{ $t('word.close') }}</v-btn>
            <template v-if="tab > 0">
              <v-btn v-if="modifying" text large color="info" @click="handlePrdcRptClicked">{{ $t('word.prdcRpt') }}</v-btn>
              <v-btn v-if="modifying" text large color="info" @click="handleStmpApplRptClicked">{{ $t('word.stmpApplRpt') }}</v-btn>
              <v-btn v-if="!modifying && isLicensee" text large color="primary" @click="handleRegistClicked">{{ $t('word.submit') }}</v-btn>
              <v-btn v-if="modifying && isLicensor" text large color="primary" @click="handleModifyClicked">{{ $t('word.save') }}</v-btn>
              <v-btn v-if="stmpAppl.stmpConf === '0' && isLicensor" text large color="primary" @click="handleConfrimClicked">{{ $t('word.confirm') }}</v-btn>
              <v-btn v-if="modifying && isLicensor" text large color="error" @click="handleDeleteClicked">{{ $t('word.delete') }}</v-btn>
            </template>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
    <!-- 주소 검색 팝업 -->
    <address-list-popup
      v-if="showAddressListPopup"
      :dialog.sync="showAddressListPopup"
      :postNo.sync="stmpAppl.stmpRecpPostNo"
      :addr.sync="stmpAppl.stmpRecpAddr">
    </address-list-popup>
    <!-- 증지품목 상세 팝업 -->
    <stamp-lot-detail-popup
      v-if="showStampLotDetailPopup"
      :dialog.sync="showStampLotDetailPopup"
      :stmp-std-amt="stmpAppl.stmpStdAmt"
      :stmp-lot="workingStmpLot"
      :editable="editableStampLotDetailPopup"
      @item-added="handleStampLotAdded"
      @item-changed="handleStampLotChanged"
      @item-deleted="handleStampLotDeleted">
    </stamp-lot-detail-popup>
    <!-- 서명 팝업 -->
    <stamp-sign-regist-popup
      v-if="showStampSignRegistPopup"
      :dialog.sync="showStampSignRegistPopup"
      @sign-finished="handleStampSignFinished">
    </stamp-sign-regist-popup>
    <stamp-file-regist-popup
      v-if="showStampFileRegistPopup"
      :dialog.sync="showStampFileRegistPopup"
    >
    </stamp-file-regist-popup>
  </div>
</template>

<script>
import { forms } from '@/mixins/formMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'
import LabelField from '@/components/LabelField'
import ProjectDetailUnit from '@/views/project/unit/ProjectDetailUnit'
import ContractDetailUnit from '@/views/contract/unit/ContractDetailUnit'
import AddressListPopup from '@/components/AddressListPopup'
import StampLotDetailPopup from '@/views/stamp/popup/StampLotDetailPopup'
import StampSignRegistPopup from '@/views/stamp/popup/StampSignRegistPopup'
import StampFileRegistPopup from '@/views/stamp/popup/StampFileRegistPopup'
import StampFileListUnit from '@/views/stamp/unit/StampFileListUnit'
import fileDownload from 'js-file-download'

export default {
  name: 'StampDetailTabPopup',
  mixins: [forms, searches, stores],
  components: {
    TitleBar,
    LabelField,
    AddressListPopup,
    ProjectDetailUnit,
    ContractDetailUnit,
    StampLotDetailPopup,
    StampSignRegistPopup,
    StampFileRegistPopup,
    StampFileListUnit
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    projCd: {
      type: String,
      required: false
    },
    cntrId: {
      type: String,
      required: false
    },
    stmpConf: {
      type: String,
      required: false
    },
    stmpApplNo: {
      type: Number,
      required: false
    }
  },
  mounted () {
    if (this.modifying) {
      this.stmpAppl.projCd = this.projCd
      this.stmpAppl.stmpApplNo = this.stmpApplNo
    }
    // 증지신청 조회 
    this.fetchItem()
  },
  watch: {
    stmpAppl: {
      deep: true,
      handler () {
        this.somethingChanged = true
      }
    },
    stmpLotList: {
      deep: true,
      handler () {
        this.somethingChanged = true
        this.autoCalculate()
      }
    }
  },
  data () {
    return {
      // 증지품목 테이블 헤더
      headers: [
        { text: this.$t('word.lotNm'), value: 'lotNm', sortable: false },
        { text: this.$t('word.cnsmAmt'), value: 'cnsmAmt', sortable: false },
        { text: this.$t('word.rlsAmt'), value: 'rlsAmt', sortable: false },
        { text: this.$t('word.ryltStd'), value: 'ryltStdCdNm', sortable: false },
        { text: this.$t('word.ryltRt'), value: 'ryltRt', sortable: false },
        { text: this.$t('word.prdcCnt'), value: 'prdcCnt', sortable: false },
        { text: this.$t('word.ryltAmt'), value: 'ryltAmt', sortable: false },
        { text: this.$t('word.stmpKnd'), value: 'stmpKndVal', sortable: false },
        { text: this.$t('word.stmpAmt'), value: 'stmpAmt', sortable: false }
      ],
      stmpAppl: {},
      stmpLotList: [],
      workingStmpLot: null,
      editableStampLotDetailPopup: false,
      tab: 1,
      somethingChanged: false,
      // 선택 아이템
      selectedItems: [],
      showAddressListPopup: false,
      showStampLotDetailPopup: false,
      showStampSignRegistPopup: false,
      showStampFileRegistPopup: false,
      showStampFileListUnit: false
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    },
    modifying () {
      if (!this.$isNull(this.stmpApplNo)) {
        return true
      } else {
        return !this.$isNull(this.stmpAppl.stmpApplNo)
      }
    },
    title () {
      if (this.modifying) {
        return this.$t('word.stmpApplDtl')
      } else {
        return this.$t('word.stmpAppl')
      }
    }
  },
  methods: {
    // 데이터조회
    fetchItem () {
      let url
      if (this.modifying) {
        if (this.$isNull(this.stmpAppl.projCd) || this.$isNull(this.stmpAppl.stmpApplNo)) {
          return
        }
        url = `/api/1/projects/${this.stmpAppl.projCd}/stamps/${this.stmpAppl.stmpApplNo}`
      } else {
        url = `/api/1/projects/${this.projCd}/stamps/init`
      }
      this.$http.get(url)
        .then((response) => {
          const stmpApplUnion = response.data.result.stmpApplUnion
          this.stmpAppl = stmpApplUnion.stmpAppl
          // 증지품목의 등록, 수정, 삭제 등의 처리를 위해 증지품목의 가상키를 생성한다.
          // frontend에서만 사용되며, db에 저장되지 않는다
          const tmpStmpLotList = stmpApplUnion.stmpLotList
          for (const stmpLot of tmpStmpLotList) {
            stmpLot.vrtlId = 'L' + stmpLot.lotNo
          }
          this.stmpLotList = tmpStmpLotList
          this.$refs.frm1.resetValidation()
          // 값 할당후 watch 가 뒤늦게 실행되므로 nextTick 에서 변경사항이 없음을 할당한다.
          this.$nextTick(() => {
            this.somethingChanged = false
          })
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 증지품목 목록 데이터 변경시 합계 값 계산
    autoCalculate () {
      let tmpPrdcCntSum = 0
      let tmpRyltAmtSum = 0
      let tmpStmpAmtSum = 0
      for (const obj of this.stmpLotList) {
        tmpPrdcCntSum += Number(obj.prdcCnt)
        tmpRyltAmtSum += Number(obj.ryltAmt)
        tmpStmpAmtSum += Number(obj.stmpAmt)
      }
      this.stmpAppl.prdcCntSum = tmpPrdcCntSum
      this.stmpAppl.ryltAmtSum = tmpRyltAmtSum
      this.stmpAppl.stmpAmtSum = tmpStmpAmtSum
      this.stmpAppl.stmpVatSum = Math.round(tmpStmpAmtSum * 0.1)
      this.stmpAppl.dpsTgtAmtSum = tmpStmpAmtSum + this.stmpAppl.stmpVatSum
    },
    // 증지품목 추가 버튼 클릭 시
    handleStampLotAddClicked () {
      if (!this.$isEmptyArray(this.stmpLotList) && this.stmpLotList.length > 20) {
        this.$toasted.global.info(this.$t('StampDetailTabPopup.txt006'))
        return
      }
      this.workingStmpLot = null
      this.editableStampLotDetailPopup = true
      this.showStampLotDetailPopup = true
    },
    // 증지품목 테이블 로우 클릭시
    handleStampLotRowClicked (item) {
      this.selectedItems = [item]
      this.workingStmpLot = item
      // 라이선서는 항상 수정이 가능하며, 라이선시는 증지신청 후 수정이 불가함
      if (this.isLicensor) {
        this.editableStampLotDetailPopup = true
      } else {
        this.editableStampLotDetailPopup = !this.modifying
      }
      this.showStampLotDetailPopup = true
    },
    // 증지품목 팝업에서 품목 추가 시
    handleStampLotAdded (item) {
      if (this.$isNull(item) || this.$isNull(item.vrtlId)) {
        return
      }
      this.stmpLotList.push(item)
    },
    // 증지품목 팝업에서 품목 변경 시
    handleStampLotChanged (item) {
      if (this.$isNull(item) || this.$isNull(item.vrtlId)) {
        return
      }
      const idx = this.stmpLotList.findIndex(o => o.vrtlId === item.vrtlId)
      if (idx >= 0) {
        this.stmpLotList.splice(idx, 1, item)
      }
    },
    // 증지품목 팝업에서 품목 삭제 시
    handleStampLotDeleted (item) {
      if (this.$isNull(item) || this.$isNull(item.vrtlId)) {
        return
      }
      const idx = this.stmpLotList.findIndex(o => o.vrtlId === item.vrtlId)
      if (idx >= 0) {
        this.stmpLotList.splice(idx, 1)
      }
    },
    // 생산보고서 엑셀 다운로드 클릭 시
    handlePrdcRptClicked () {
      if (!this.modifying) {
        return
      }
      if (this.somethingChanged) {
        if (!confirm(this.$t('StampDetailTabPopup.txt007'))) {
          return
        }
      }
      this.$http.get(`/api/1/projects/${this.stmpAppl.projCd}/stamps/${this.stmpAppl.stmpApplNo}/reports/product`, { responseType: 'blob' })
        .then((response) => {
          // 파일이름 설정
          const contentDisposition = response.headers['content-disposition']
          let downFileNm = null
          if (!this.$isNull(contentDisposition)) {
            const idx = contentDisposition.indexOf(';filename*=UTF-8\'\'')
            if (idx >= 0) {
              downFileNm = decodeURIComponent(contentDisposition.substring(idx + 18))
            }
          }
          if (this.$isNull(downFileNm)) {
            downFileNm = this.fileNm
          }
          fileDownload(response.data, downFileNm)
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 증지신청서 엑셀 다운로드 클릭 시
    handleStmpApplRptClicked () {
      if (!this.modifying) {
        return
      }
      if (this.somethingChanged) {
        if (!confirm(this.$t('StampDetailTabPopup.txt008'))) {
          return
        }
      }
      this.$http.get(`/api/1/projects/${this.stmpAppl.projCd}/stamps/${this.stmpAppl.stmpApplNo}/reports/application`, { responseType: 'blob' })
        .then((response) => {
          // 파일이름 설정
          const contentDisposition = response.headers['content-disposition']
          let downFileNm = null
          if (!this.$isNull(contentDisposition)) {
            const idx = contentDisposition.indexOf(';filename*=UTF-8\'\'')
            if (idx >= 0) {
              downFileNm = decodeURIComponent(contentDisposition.substring(idx + 18))
            }
          }
          if (this.$isNull(downFileNm)) {
            downFileNm = this.fileNm
          }
          fileDownload(response.data, downFileNm)
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 신청 버튼 클릭 시
    handleRegistClicked () {
      if (!this.$refs.frm1.validate(true)) {
        this.tab = 1
        return
      }
      if (this.$isEmptyArray(this.stmpLotList)) {
        this.$toasted.global.warn(this.$t('StampDetailTabPopup.txt001'))
        this.tab = 2
        return
      }
      this.showStampSignRegistPopup = true
    },
    // 서명 완료 시
    handleStampSignFinished (sign) {
      if (!confirm(this.$t('StampDetailTabPopup.txt003'))) {
        return
      }
      if (!confirm(this.$t('StampDetailTabPopup.txt009'))) {
        return
      }
      this.stmpAppl.stmpApplrSignVal = sign
      const item = {
        stmpAppl: this.stmpAppl,
        stmpLotList: this.stmpLotList
      }
      this.$http.post(`/api/1/projects/${this.projCd}/stamps`, item)
        .then((response) => {
          this.$toasted.global.success(this.$t('StampDetailTabPopup.txt002'))
          const stmpApplUnion = response.data.result.stmpApplUnion
          this.stmpAppl = stmpApplUnion.stmpAppl
          this.stmpLotList = stmpApplUnion.stmpLotList === null ? [] : stmpApplUnion.stmpLotList
          // 값 할당후 watch 가 뒤늦게 실행되므로 nextTick 에서 변경사항이 없음을 할당한다.
          this.$nextTick(() => {
            this.somethingChanged = false
            this.dialogModel = false
            this.$emit('item-changed', true)
          })
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 수정 버튼 클릭 시
    handleModifyClicked () {
      if (!this.modifying || this.$isNull(this.stmpAppl.projCd) || this.$isNull(this.stmpAppl.stmpApplNo)) {
        return
      }
      if (!this.$refs.frm1.validate(true)) {
        this.tab = 1
        return
      }
      if (this.$isEmptyArray(this.stmpLotList)) {
        this.$toasted.global.warn(this.$t('StampDetailTabPopup.txt001'))
        this.tab = 2
        return
      }
      const item = {
        stmpAppl: this.stmpAppl,
        stmpLotList: this.stmpLotList
      }
      this.$http.put(`/api/1/projects/${this.stmpAppl.projCd}/stamps/${this.stmpAppl.stmpApplNo}`, item)
        .then((response) => {
          this.$toasted.global.success(this.$t('msg.saved'))
          const stmpApplUnion = response.data.result.stmpApplUnion
          this.$emit('item-changed', stmpApplUnion)
          this.dialogModel = false
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 삭제 버튼 클릭 시
    handleDeleteClicked () {
      if (!this.modifying || this.$isNull(this.stmpAppl.projCd) || this.$isNull(this.stmpAppl.stmpApplNo)) {
        return
      }
      if (!confirm(this.$t('StampDetailTabPopup.txt004'))) {
        return
      }
      this.$http.delete(`/api/1/projects/${this.stmpAppl.projCd}/stamps/${this.stmpAppl.stmpApplNo}`)
        .then(() => {
          this.$toasted.global.success(this.$t('msg.deleted'))
          this.dialogModel = false
          this.$emit('item-changed')
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 확인 버튼 클릭 시
    handleConfrimClicked () {
      if (!this.modifying || this.$isNull(this.stmpAppl.projCd) || this.$isNull(this.stmpAppl.stmpApplNo)) {
        return
      }
      if (!confirm(this.$t('StampDetailTabPopup.txt011'))) {
        return
      }
      this.$http.post(`/api/1/projects/${this.stmpAppl.projCd}/stamps/${this.stmpAppl.stmpApplNo}`)
        .then(() => {
          this.$toasted.global.success(this.$t('msg.confirm'))
          this.dialogModel = false
          this.$emit('item-changed')
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 닫기 버튼 클릭 시
    handleCloseClicked () {
      if (this.somethingChanged) {
        if (!confirm(this.$t('msg.detectChange'))) {
          return
        }
      }
      this.dialogModel = false
    }
  }
}
</script>
