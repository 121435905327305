<template>
  <v-dialog v-model="dialogModel" persistent :max-width="600" :width="600">
    <v-card flat>
      <!-- 타이틀바 -->
      <title-bar :title="$t('word.sign')" color="primary" dark>
        <template v-slot:action>
          <v-btn icon rounded large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
        </template>
      </title-bar>
      <!-- 컨텐츠 -->
      <v-card-text>
        <v-card-subtitle>{{ $t('StampSignRegistPopup.txt001') }}</v-card-subtitle>
        <div style="border: solid lightgrey;">
          <vue-signature
            ref="signature"
            :sig-option="option"
            h="250px">
          </vue-signature>
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text large color="secondary" @click="dialogModel = false">{{ $t('word.close') }}</v-btn>
        <v-btn text large color="secondary" @click="handleClearClicked">{{ $t('word.clear') }}</v-btn>
        <v-btn text large color="primary" @click="handleSubmitClicked">{{ $t('word.submit') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import TitleBar from '@/components/TitleBar'
import VueSignature from 'vue-signature'

export default {
  name: 'StampSignRegistPopup',
  components: {
    TitleBar,
    VueSignature
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    }
  },
  data () {
    return {
      option: {
        penColor: 'rgb(0,0,0)',
        backgroundColor: 'rgba(0,0,0,0)',
        clearOnResize: true,
        disabled: false,
        dataUrl: ''
      }
    }
  },
  methods: {
    handleClearClicked () {
      this.$refs.signature.clear()
    },
    handleSubmitClicked () {
      if (this.$refs.signature.isEmpty()) {
        this.$toasted.global.warn(this.$t('StampSignRegistPopup.txt002'))
        return
      }
      // 저장결과가 png 포맷의 dataurl 로 리턴된다.
      const result = this.$refs.signature.save()
      this.$emit('sign-finished', result)
      this.dialogModel = false
    }
  }
}
</script>
