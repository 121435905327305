<template>
  <div>
    <v-card tile>
      <!-- 검색조건 -->
      <field-bar :field-size="4" :lg="3" :md="3">
        <template v-slot:field-1>
          <date-field
            v-model="strYmd"
            :label="$t('word.regYmd') + ' (FROM)'"
            background-color="white"
            outlined
            dense
            hide-details>
          </date-field>
        </template>
        <template v-slot:field-2>
          <date-field
            v-model="endYmd"
            :label="$t('word.regYmd') + ' (TO)'"
            background-color="white"
            outlined
            dense
            hide-details>
          </date-field>
        </template>
        <template v-slot:field-3>
          <v-select
            v-model="projHistDivCd"
            :items="projHistDivCdList"
            :label="$t('word.histDiv')"
            background-color="white"
            outlined
            dense
            hide-details>
          </v-select>
        </template>
        <template v-slot:field-4>
          <v-text-field
            v-model="projHistNo"
            :label="$t('word.histNo')"
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @keypress.enter="fetchList(true)">
          </v-text-field>
        </template>
      </field-bar>
      <!-- 테이블 -->
      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="items"
        item-key="projHistNo"
        height="29vh"
        class="text-truncate"
        fixed-header
        show-select
        single-select
        disable-filtering
        disable-pagination
        loading-text=""
        no-data-text=""
        no-results-text=""
        hide-default-footer
        :single-expand="true"
        :expanded="expandedItems"
        :server-items-length="comTtlCnt"
        :sort-by.sync="comSrtBy"
        :sort-desc.sync="comSrtDesc"
        @click:row="handleRowClicked"
        @update:sort-by="delayedCall(fetchList)"
        @update:sort-desc="delayedCall(fetchList)">
        <template v-slot:item.regDt="{ value }">
          <td>{{ $timeToMediumDate(value) }}</td>
        </template>
        <template v-slot:expanded-item="{ headers, item }" class="elevation-0">
          <td :colspan="headers.length">
            <v-textarea
              v-model="item.histRsnCnts"
              :label="$t('word.dtlDesc')"
              filled
              rows="1"
              auto-grow
              :readonly="true"
              class="my-3"
              hide-details>
            </v-textarea>
          </td>
        </template>
        <template v-slot:body.append="{ headers }">
          <tr>
            <td :colspan="headers.length">
              {{ $t('word.total') }} {{ $formatNumber(comTtlCnt) }}{{ $t('word.count') }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <!-- 페이징 -->
      <paging-bar
        :page-no.sync="comPageNo"
        :page-cnt.sync="comPageCnt"
        :item-cnt-per-page.sync="comItemCntPerPage"
        @paging-changed="fetchList">
      </paging-bar>
    </v-card>
  </div>
</template>

<script>
import { sorts } from '@/mixins/sortMixin'
import { pages } from '@/mixins/pageMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import FieldBar from '@/components/FieldBar'
import DateField from '@/components/DateField'
import PagingBar from '@/components/PagingBar'

export default {
  name: 'ProjectChangeHistoryListUnit',
  mixins: [sorts, pages, searches, stores],
  components: {
    FieldBar,
    DateField,
    PagingBar
  },
  props: {
    projCd: {
      type: String,
      required: false
    }
  },
  mounted () {
    // 공통코드 조회
    this.$getCodes(['PH0000'], (result) => {
      this.projHistDivCdList = this.$toSelectableCodes(result.PH0000)
      this.fetchList()
    })
  },
  data () {
    return {
      projHistDivCdList: [],
      // 테이블 헤더
      headers: [
        { text: this.$t('word.histNo'), value: 'projHistNo' },
        { text: this.$t('word.histDiv'), value: 'projHistDivCdNm', sortable: false },
        { text: this.$t('word.befVal'), value: 'befVal', sortable: false },
        { text: this.$t('word.aftVal'), value: 'aftVal', sortable: false },
        { text: this.$t('word.regrNm'), value: 'regrNm', sortable: false },
        { text: this.$t('word.regDt'), value: 'regDt', sortable: false },
        { text: '', value: 'data-table-expand', sortable: false }
      ],
      // 조회결과 목록
      items: [],
      // 검색조건
      strYmd: null,
      endYmd: null,
      projHistDivCd: null,
      projHistNo: null,
      // 선택 아이템
      selectedItems: [],
      // 확장된 테이블 로우 아이템
      expandedItems: []
    }
  },
  methods: {
    // 목록조회
    fetchList (shouldClear) {
      // 검색 초기화
      if (shouldClear) {
        this.comPageNo = 1
        this.selectedItems = []
      }
      // 검색 파라미터 할당
      this.clearComParam()
      this.appendParam('strYmd', this.strYmd)
      this.appendParam('endYmd', this.endYmd)
      this.appendParam('projHistDivCd', this.projHistDivCd)
      this.appendParam('projHistNo', this.projHistNo)
      // 검색
      this.$http.get(this.toRequestUrl(`/api/1/projects/${this.projCd}/history`))
        .then((response) => {
          const page = response.data.result.projChgHistPage
          this.bindComPage(page)
          this.items = page.list
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 초기화 버튼 클릭시
    handleClearClicked () {
      this.strYmd = null
      this.endYmd = null
      this.projHistDivCd = null
      this.projHistNo = null
      this.fetchList(true)
    },
    // 테이블 로우 클릭시
    handleRowClicked (item) {
      this.selectedItems = [item]
      if (this.$isNull(item.histRsnCnts)) {
        this.expandedItems = []
      } else {
        this.expandedItems = [item]
      }
    }
  }
}
</script>

<style>
/* 확장 테이블 로우 박스 그림자 제거 */
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}
</style>
