<template>
  <v-dialog v-model="dialogModel" persistent scrollable :max-width="$const.maxWidthLayout.lg" :retain-focus="false">
      <v-card flat>
        <!-- 타이틀바 -->
        <title-bar :title="$t('word.searchAddr')" color="primary" dark>
          <template v-slot:action>
            <v-btn icon large dark @click="handleApplyClicked"><v-icon>mdi-check</v-icon></v-btn>
            <v-btn icon large dark @click="fetchList"><v-icon>mdi-magnify</v-icon></v-btn>
            <v-btn icon large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
          </template>
        </title-bar>
        <!-- 검색조건 -->
        <line-bar>
          <template v-slot:line-1>
            <v-text-field
              v-model="kwd"
              :label="$t('word.kwd')"
              background-color="white"
              outlined
              dense
              clearable
              hide-details
              @keypress.enter="fetchList(true)">
            </v-text-field>
          </template>
        </line-bar>
        <!-- 테이블 -->
        <v-data-table
          v-model="selectedItems"
          :headers="headers"
          :items="items"
          item-key="id"
          height="67vh"
          class="text-truncate"
          fixed-header
          show-select
          single-select
          disable-sort
          disable-filtering
          disable-pagination
          loading-text=""
          no-data-text=""
          no-results-text=""
          hide-default-footer
          @click:row="handleRowClicked">
          <template v-slot:item.action="{ item }">
            <v-btn x-small depressed color="secondary" @click="handleInlineApplyClicked(item)">{{ $t('word.apply') }}</v-btn>
          </template>
          <template v-slot:body.append="{ headers }">
            <tr>
              <td :colspan="headers.length">
                {{ $t('word.total') }} {{ $formatNumber(comTtlCnt) }}{{$t('word.count')}}
              </td>
            </tr>
          </template>
        </v-data-table>
        <!-- 페이징 -->
        <paging-bar
          :page-no.sync="comPageNo"
          :page-cnt.sync="comPageCnt"
          :item-cnt-per-page.sync="comItemCntPerPage"
          @paging-changed="fetchList">
        </paging-bar>
      </v-card>
  </v-dialog>
</template>

<script>
import { pages } from '@/mixins/pageMixin'
import { searches } from '@/mixins/searchMixin'
import TitleBar from '@/components/TitleBar'
import LineBar from '@/components/LineBar'
import PagingBar from '@/components/PagingBar'

export default {
  name: 'AddressListPopup',
  mixins: [pages, searches],
  components: {
    TitleBar,
    LineBar,
    PagingBar
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    postNo: {
      type: String,
      required: false,
      default: null
    },
    addr: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      // 테이블 데이터
      headers: [
        { text: this.$t('word.postNo'), value: 'postNo', sortable: false },
        { text: this.$t('word.roadAddr'), value: 'addr', sortable: false },
        { text: this.$t('word.jibunAddr'), value: 'jibunAddr', sortable: false },
        { text: '', value: 'action', width: '1px', sortable: false }
      ],
      // 조회결과 목록
      items: [],
      // 테이블 로우에서 선택된 아이템
      selectedItems: [],
      // 검색어
      kwd: null
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    },
    postNoModel: {
      get () {
        return this.postNo
      },
      set (value) {
        this.$emit('update:postNo', value)
      }
    },
    addrModel: {
      get () {
        return this.addr
      },
      set (value) {
        this.$emit('update:addr', value)
      }
    }
  },
  methods: {
    // 목록조회
    fetchList (shouldClear) {
      // 입력값 체크
      if (this.$isEmpty(this.kwd) || this.kwd.length < 2) {
        this.$toasted.global.warn(this.$t('AddressListPopup.txt001'))
        return
      }
      if (/[%=><]/.test(this.kwd)) {
        this.$toasted.global.warn(this.$t('AddressListPopup.txt002'))
        return
      }
      const sqlList = ['OR', 'SELECT', 'INSERT', 'DELETE', 'UPDATE', 'CREATE', 'DROP', 'EXEC', 'UNION', 'FETCH', 'DECLARE', 'TRUNCATE']
      let find = false
      for (const sql of sqlList) {
        if (new RegExp(sql, 'gi').test(this.kwd)) {
          find = true
          break
        }
      }
      if (find) {
        this.$toasted.global.warn(this.$t('AddressListPopup.txt003'))
        return
      }
      // 페이지번호 초기화
      if (shouldClear) {
        this.comPageNo = 1
        this.selectedItems = []
      }
      // 조회
      this.clearComParam()
      this.appendParam('kwd', this.kwd)
      this.$http.get(this.toRequestUrl('/api/1/addresses'))
        .then((response) => {
          const page = response.data.result.addrPage
          this.bindComPage(page)
          // 목록 key 생성
          const list = page.list
          const indexedList = []
          for (let i = 0; i < list.length; i++) {
            indexedList.push({
              id: (page.comItemCntPerPage * (page.comPageNo - 1)) + i,
              ...list[i]
            })
          }
          this.items = indexedList
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleInlineApplyClicked (item) {
      if (this.$isNull(item) || (item instanceof Event)) {
        return
      }
      this.selectedItems = [item]
      this.$nextTick(() => {
        this.handleApplyClicked()
      })
    },
    handleApplyClicked () {
      if (this.$isEmptyArray(this.selectedItems)) {
        this.$toasted.global.warn(this.$t('AddressListPopup.txt004'))
        return
      }
      const myAddr = this.selectedItems[0]
      this.postNoModel = myAddr.postNo
      this.addrModel = myAddr.dtlAddr1
      this.dialogModel = false
    },
    handleRowClicked (item) {
      this.selectedItems = [item]
    }
  }
}
</script>
