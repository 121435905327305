<template>
  <v-card outlined>
    <title-bar :title="title">
      <template v-slot:action>
      </template>
    </title-bar>
      <template>
    <div class="content">
      <div class="container-fluid">
        <!-- 대시보드 게이지 요소 -->
        <div class="row">
          <div class="col-xl-1 col-md-3">
            <stats-card>
              <div slot="header" class="icon-warning">
                <i v-if="isLicensor" class="nc-icon nc-paper-2 text-success"></i>
                <i v-if="isLicensee" class="nc-icon nc-bullet-list-67 text-warning"></i>
              </div>
              <div v-if="isLicensor" slot="content">
                <p  class="card-category">라이선시 <br> 회사 </p>
                <h4  class="card-title" >{{ this.dashValues[0] }}</h4>
              </div>
              <div v-if="isLicensee" slot="content">
                <p class="card-category">등록한 IP</p>
                <h4 class="card-title">1</h4>
              </div>
              <div slot="footer">
                <i class="fa fa-refresh"></i>Updated now
              </div>
            </stats-card>
            <stats-card>
              <div slot="header" class="icon-warning">
                <i v-if="isLicensor" class="nc-icon nc-bullet-list-67 text-success"></i>
                <i v-if="isLicensee" class="nc-icon nc-bullet-list-67 text-warning"></i>
              </div>
              <div v-if="isLicensor" slot="content">
                <p  class="card-category">전체 프로젝트</p>
                <h4  class="card-title" >{{ this.dashValues[4] }}</h4>
              </div>
              <div v-if="isLicensee" slot="content">
                <p class="card-category">등록한 IP</p>
                <h4 class="card-title">1</h4>
              </div>
              <div slot="footer">
                <i class="fa fa-refresh"></i>Updated now
              </div>
            </stats-card>
          </div>

          <div class="col-xl-1 col-md-3">
            <stats-card>
              <div slot="header" class="icon-success">
                <i v-if="isLicensor" class="nc-icon nc-badge text-warning"></i>
                <i v-if="isLicensee" class="nc-icon nc-money-coins text-success"></i>
              </div>
              <div v-if="isLicensor" slot="content">
                <p class="card-category">라이선시 <br> 사용자 </p>
                <h4 class="card-title">{{ this.dashValues[1] }}</h4>
              </div>
              <div v-if="isLicensee" slot="content">
                <p class="card-category">증지 신청</p>
                <h4 class="card-title">1</h4>
              </div>
              <div slot="footer">
                <i class="fa fa-calendar-o"></i>Last day
              </div>
            </stats-card>
            <stats-card>
              <div slot="header" class="icon-success">
                <i v-if="isLicensor" class="nc-icon nc-settings-tool-66 text-warning"></i>
                <i v-if="isLicensee" class="nc-icon nc-money-coins text-success"></i>
              </div>
              <div v-if="isLicensor" slot="content">
                <p class="card-category">수정 프로젝트 </p>
                <h4 class="card-title">{{ this.dashValues[5] }}</h4>
              </div>
              <div v-if="isLicensee" slot="content">
                <p class="card-category">증지 신청</p>
                <h4 class="card-title">1</h4>
              </div>
              <div slot="footer">
                <i class="fa fa-calendar-o"></i>Last day
              </div>
            </stats-card>
          </div>

          <div class="col-xl-1 col-md-3">
            <stats-card>
              <div slot="header" class="icon-danger">
                <i class="nc-icon nc-album-2 text-danger"></i>
              </div>
              <div v-if="isLicensor" slot="content">
                <p class="card-category">등록된 IP <br> &nbsp;</p>
                <h4 class="card-title">{{ this.dashValues[2] }}</h4>
              </div>
              <div v-if="isLicensee" slot="content">
                <p class="card-category">진행 프로젝트</p>
                <h4 class="card-title">1</h4>
              </div>
              <div slot="footer">
                <i class="fa fa-clock-o"></i>Last day
              </div>
            </stats-card>
            <stats-card>
              <div slot="header" class="icon-danger">
                <i class="nc-icon nc-button-play text-danger"></i>
              </div>
              <div v-if="isLicensor" slot="content">
                <p class="card-category">진행 프로젝트</p>
                <h4 class="card-title">{{ this.dashValues[6] }}</h4>
              </div>
              <div v-if="isLicensee" slot="content">
                <p class="card-category">진행 프로젝트</p>
                <h4 class="card-title">1</h4>
              </div>
              <div slot="footer">
                <i class="fa fa-clock-o"></i>Last day
              </div>
            </stats-card>
          </div>

          <div class="col-xl-1 col-md-3">
            <stats-card>
              <div slot="header" class="icon-info">
                <i class="nc-icon nc-credit-card text-primary"></i>
              </div>
              <div v-if="isLicensor" slot="content">
                <p class="card-category"> 등록된 계약<br> &nbsp;</p>
                <h4 class="card-title">{{ this.dashValues[3] }}</h4>
              </div>
              <div v-if="isLicensee" slot="content">
                <p class="card-category">완료 프로젝트</p>
                <h4 class="card-title">2</h4>
              </div>
              <div slot="footer">
                <i class="fa fa-refresh"></i>Updated now
              </div>
            </stats-card>
            <stats-card>
              <div slot="header" class="icon-info">
                <i class="nc-icon nc-check-2 text-primary"></i>
              </div>
              <div v-if="isLicensor" slot="content">
                <p class="card-category">완료 프로젝트</p>
                <h4 class="card-title">{{ this.dashValues[7] }}</h4>
              </div>
              <div v-if="isLicensee" slot="content">
                <p class="card-category">완료 프로젝트</p>
                <h4 class="card-title">2</h4>
              </div>
              <div slot="footer">
                <i class="fa fa-refresh"></i>Updated now
              </div>
            </stats-card>
          </div>
        </div>

        <!-- 대시보드 차트 요소 -->
        <div v-if="isLicensor" class="row">
          <div class="col-md-7">
            <chart-card :chart-data="lineChart_Licensor.data"
                        :chart-options="lineChart_Licensor.options"
                        :responsive-options="lineChart_Licensor.responsiveOptions">
              <template slot="header">
                <div>
                  <h4 class="card-title">라이선시별 로열티 (상위 3개)</h4>
                  <p class="card-category">Annual performance</p>
                </div>
              </template>
              <template slot="footer">
                <div class="legend">
                  <i class="fa fa-circle text-info"></i> Open
                  <i class="fa fa-circle text-danger"></i> Click
                  <i class="fa fa-circle text-warning"></i> Click Second Time
                </div>
                <hr>
                <div class="stats">
                  <i class="fa fa-history"></i> Updated 3 minutes ago
                </div>
              </template>
            </chart-card>
          </div>
          <div class="col-md-5">
            <chart-card :chart-data="pieChart_Licensor.data" chart-type="Pie">
              <template slot="header">
                <h4 class="card-title">프로젝트 현황</h4>
                <p class="card-category">weekly performance</p>
              </template>
              <template slot="footer">
                <div class="legend">
                  <i class="fa fa-circle text-info"></i> 신규
                  <i class="fa fa-circle text-danger"></i> 취소
                  <i class="fa fa-circle text-success"></i> 진행
                  <i class="fa fa-circle text-warning"></i> 수정
                  <i class="fa fa-circle text" style="color: #9368e9"></i> 완료
                </div>
                <hr>
                <div class="stats">
                  <i class="fa fa-clock-o"></i> Campaign sent 2 days ago
                </div>
              </template>
            </chart-card>
          </div>
        </div>
        <div v-if="isLicensee" class="row">
          <div class="col-md-7">
            <chart-card :chart-data="lineChart_Licensee.data"
                        :chart-options="lineChart_Licensee.options"
                        :responsive-options="lineChart_Licensee.responsiveOptions">
              <template slot="header">
                <h4 class="card-title">IP별 로열티 (상위 3개)</h4>
                <p class="card-category">Annual performance</p>
              </template>
              <template slot="footer">
                <div class="legend">
                  <i class="fa fa-circle text-info"></i> Open
                  <i class="fa fa-circle text-danger"></i> Click
                  <i class="fa fa-circle text-warning"></i> Click Second Time
                </div>
                <hr>
                <div class="stats">
                  <i class="fa fa-history"></i> Updated 3 minutes ago
                </div>
              </template>
            </chart-card>
          </div>
        <div class="col-md-5">
          <chart-card :chart-data="pieChart_Licensee.data" chart-type="Pie">
            <template slot="header">
              <h4 class="card-title">프로젝트 현황</h4>
              <p class="card-category">Last Campaign Performance</p>
            </template>
            <template slot="footer">
              <div class="legend">
                <i class="fa fa-circle text-info"></i> Open
                <i class="fa fa-circle text-danger"></i> Bounce
                <i class="fa fa-circle text-warning"></i> Unsubscribe
              </div>
              <hr>
              <div class="stats">
                <i class="fa fa-clock-o"></i> Campaign sent 2 days ago
              </div>
            </template>
          </chart-card>
        </div>
      </div>

      <div v-if="isLicensor" class="row">
        <div class="col-md-6">
          <chart-card
            :chart-data="barChart_Licensor.data"
            :chart-options="barChart_Licensor.options"
            :chart-responsive-options="barChart_Licensor.responsiveOptions"
            chart-type="Bar">
            <template slot="header">
              <h4 class="card-title">2014 Sales</h4>
              <p class="card-category">All products including Taxes</p>
            </template>
            <template slot="footer">
              <div class="legend">
                <i class="fa fa-circle text-info"></i> Tesla Model S
                <i class="fa fa-circle text-danger"></i> BMW 5 Series
              </div>
              <hr>
              <div class="stats">
                <i class="fa fa-check"></i> Data information certified
              </div>
            </template>
          </chart-card>
        </div>
        <div class="col-md-6">
          <card>
            <template slot="header">
              <h5 class="title">Tasks</h5>
              <p class="category">Backend development</p>
            </template>
            <l-table :data="tableData_Licensor.data"
                     :columns="tableData_Licensor.columns">
              <template slot="columns"></template>

              <template slot-scope="{row}">
                <td>
                  <base-checkbox v-model="row.checked"></base-checkbox>
                </td>
                <td>{{row.title}}</td>
                <td class="td-actions text-right">
                  <button type="button" class="btn-simple btn btn-xs btn-info" v-tooltip.top-center="editTooltip">
                    <i class="fa fa-edit"></i>
                  </button>
                  <button type="button" class="btn-simple btn btn-xs btn-danger" v-tooltip.top-center="deleteTooltip">
                    <i class="fa fa-times"></i>
                  </button>
                </td>
              </template>
            </l-table>
            <div class="footer">
              <hr>
              <div class="stats">
                <i class="fa fa-history"></i> Updated 3 minutes ago
              </div>
            </div>
          </card>
        </div>
      </div>
      <div v-if="isLicensee" class="row">
        <div class="col-md-6">
          <chart-card
            :chart-data="barChart_Licensee.data"
            :chart-options="barChart_Licensee.options"
            :chart-responsive-options="barChart_Licensee.responsiveOptions"
            chart-type="Bar">
            <template slot="header">
              <hr>
              <h4 class="card-title">2014 Sales</h4>
              <p class="card-category">All products including Taxes</p>
            </template>
            <template slot="footer">
              <div class="legend">
                <i class="fa fa-circle text-info"></i> Tesla Model S
                <i class="fa fa-circle text-danger"></i> BMW 5 Series
              </div>
              <hr>
              <div class="stats">
                <i class="fa fa-check"></i> Data information certified
              </div>
            </template>
          </chart-card>
        </div>
        <div class="col-md-6">
          <card>
            <template slot="header">
              <h5 class="title">Tasks</h5>
              <p class="category">Backend development</p>
            </template>
            <l-table :data="tableData_Licensee.data"
                     :columns="tableData_Licensee.columns">
              <template slot="columns"></template>

              <template slot-scope="{row}">
                <td>
                  <base-checkbox v-model="row.checked"></base-checkbox>
                </td>
                <td>{{row.title}}</td>
                <td class="td-actions text-right">
                  <button type="button" class="btn-simple btn btn-xs btn-info" v-tooltip.top-center="editTooltip">
                    <i class="fa fa-edit"></i>
                  </button>
                  <button type="button" class="btn-simple btn btn-xs btn-danger" v-tooltip.top-center="deleteTooltip">
                    <i class="fa fa-times"></i>
                  </button>
                </td>
              </template>
            </l-table>
            <div class="footer">
              <hr>
              <div class="stats">
                <i class="fa fa-history"></i> Updated 3 minutes ago
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
  </template>
  </v-card>
</template>
<script>
import { forms } from '@/mixins/formMixin'
import { searches } from '@/mixins/searchMixin'
import { sorts } from '@/mixins/sortMixin'
import { pages } from '@/mixins/pageMixin'
import { stores } from '@/mixins/storeMixin'
import ChartCard from '@/components/cards/ChartCard'
import StatsCard from '@/components/cards/StatsCard'
import LTable from '@/components/Table.vue'
import TitleBar from '@/components/TitleBar'
export default {
  name: 'DashboardView',
  mixins: [sorts, pages, forms, searches, stores],
  props: {
    dashValues: {
      type: Array,
      required: false
    },
    dashChart: {
      type: Array,
      required: false
    },
    title: {
      type: String,
      required: false,
      default: 'NO TITLE'
    },
    title1: {
      type: String,
      required: false,
      default: ''
    }
  },
  mounted () {
    this.fetchList()
  },
  components: {
    TitleBar,
    LTable,
    ChartCard,
    StatsCard
  },
  data () {
    return {
      // 조회결과 목록
      items: [],
      strYmd: null,
      endYmd: null,
      editTooltip: 'Edit Task',
      deleteTooltip: 'Remove',
      pieChart_Licensor: {
        data: {
          labels: ['40%', '20%', '30%', '5%', '5%'],
          series: [40, 20, 30, 5, 5]
        }
      },
      pieChart_Licensee: {
        data: {
          labels: ['40%', '20%', '40%'],
          series: [40, 20, 40]
        }
      },
      lineChart_Licensor: {
        data: {
          labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월', '']
        },
        options: {
          low: 0,
          high: 1000,
          showArea: false,
          height: '245px',
          axisX: {
            showGrid: true
          },
          lineSmooth: true,
          showLine: true,
          showPoint: true,
          fullWidth: true,
          chartPadding: {
            right: 50
          }
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            axisX: {
              labelInterpolationFnc (value) {
                return value[0]
              }
            }
          }]
        ]
      },
      lineChart_Licensee: {
        data: {
          labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월', ''],
          series: [
            [67, 152, 143, 240, 287, 335, 435, 800, 500, 400, 300, 200],
            [23, 113, 67, 108, 190, 239, 307, 420, 510, 620, 720, 800]
          ]
        },
        options: {
          low: 0,
          high: 1000,
          showArea: false,
          height: '245px',
          axisX: {
            showGrid: false
          },
          lineSmooth: true,
          showLine: true,
          showPoint: true,
          fullWidth: true,
          chartPadding: {
            right: 50
          }
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            axisX: {
              labelInterpolationFnc (value) {
                return value[0]
              }
            }
          }]
        ]
      },
      barChart_Licensor: {
        data: {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
            [412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636, 695]
          ]
        },
        options: {
          seriesBarDistance: 10,
          axisX: {
            showGrid: false
          },
          height: '245px'
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc (value) {
                return value[0]
              }
            }
          }]
        ]
      },
      barChart_Licensee: {
        data: {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
            [412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636, 695]
          ]
        },
        options: {
          seriesBarDistance: 10,
          axisX: {
            showGrid: false
          },
          height: '245px'
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc (value) {
                return value[0]
              }
            }
          }]
        ]
      },
      tableData_Licensor: {
        data: [
          { title: 'Sign contract for "What are conference organizers afraid of?"', checked: false },
          { title: 'Lines From Great Russian Literature? Or E-mails From My Boss?', checked: true },
          {
            title: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
            checked: true
          },
          { title: 'Create 4 Invisible User Experiences you Never Knew About', checked: false },
          { title: 'Read "Following makes Medium better"', checked: false },
          { title: 'Unfollow 5 enemies from twitter', checked: false }
        ]
      },
      tableData_Licensee: {
        data: [
          { title: 'Sign contract for "What are conference organizers afraid of?"', checked: false },
          { title: 'Lines From Great Russian Literature? Or E-mails From My Boss?', checked: true },
          {
            title: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
            checked: true
          },
          { title: 'Create 4 Invisible User Experiences you Never Knew About', checked: false },
          { title: 'Read "Following makes Medium better"', checked: false },
          { title: 'Unfollow 5 enemies from twitter', checked: false }
        ]
      }
    }
  },
  methods: {
    // 조회
    fetchList () {
      this.$http.get(this.toRequestUrl('/api/1/dashboard'))
        .then((response) => {
          this.dashValues = response.data.result.dashList
        })
        .catch((error) => {
          this.$showError(error)
        })
      this.$set(this.lineChart_Licensor.data,
        'series', [
          [],
          [],
          []
        ]
      )
    }
    // setLineChart () {
    //   this.$set(this.lineChart_Licensor.data, 'series', [[100, 200, 300], [], []])
    // }
  }
}
</script>
<style>

</style>
