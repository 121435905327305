<template>
  <v-dialog v-model="dialogModel" persistent :max-width="$const.maxWidthLayout.md" :retain-focus="false">
    <v-card flat>
      <!-- 타이틀바 -->
      <title-bar :title="$t('word.projCncl')" color="primary" dark>
        <template v-slot:action>
          <v-btn icon rounded large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
        </template>
      </title-bar>
      <!-- 컨텐츠 -->
      <v-card>
        <v-card-text class="pb-0">
          <v-form ref="frm">
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="cnts"
                    :label="$t('word.cnclCnts') + ' *'"
                    filled
                    rows="5"
                    :rules="[ requiredRule, length2000Rule ]">
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text large color="secondary" @click="dialogModel = false">{{ $t('word.close') }}</v-btn>
          <v-btn text large color="primary" @click="handleSaveClicked">{{ $t('word.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { forms } from '@/mixins/formMixin'
import TitleBar from '@/components/TitleBar'

export default {
  name: 'ProjectCancelPopup',
  mixins: [forms],
  components: {
    TitleBar
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },
  mounted () {
    this.$refs.frm.resetValidation()
  },
  data () {
    return {
      cnts: null
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    }
  },
  methods: {
    handleSaveClicked () {
      if (!this.$refs.frm.validate(true)) {
        return
      }
      this.$emit('item-changed', {
        tobeStsRsnCnts: this.cnts
      })
      this.dialogModel = false
    }
  }
}
</script>
