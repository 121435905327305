import Vue from 'vue'
import App from '@/App.vue'

// LightBootstrap plugin
import LightBootstrap from '@/plugins/light-bootstrap-main'

import Toasted from 'vue-toasted'
import axios from 'axios'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import common from '@/plugins/common'
import VueKonva from 'vue-konva'
import i18n from '@/i18n/messages'

Vue.use(common, { store })
Vue.use(VueKonva)
Vue.use(LightBootstrap)

// vue-toasted 설정
Vue.use(Toasted, {
  position: 'top-center',
  theme: 'toasted-outline',
  iconPack: 'mdi'
})
Vue.toasted.register('info', message => message, {
  duration: 6000,
  className: 'toasted-info',
  icon: 'mdi-minus-circle'
})
Vue.toasted.register('success', message => message, {
  duration: 3000,
  className: 'toasted-success',
  icon: 'mdi-check-circle'
})
Vue.toasted.register('warn', message => message, {
  duration: 6000,
  className: 'toasted-warn',
  icon: 'mdi-alert-circle'
})
Vue.toasted.register('error', message => message, {
  duration: 8000,
  className: 'toasted-error',
  icon: 'mdi-close-circle',
  action: {
    text: '닫기',
    className: 'toasted-error',
    onClick: (e, toastObject) => {
      toastObject.goAway(0)
    }
  }
})

// router navigation guard
router.beforeEach(function (to, from, next) {
  const roles = to.meta.roles
  if (roles === null || roles === undefined || roles.length === 0) {
    next()
  } else {
    const auth = store.getters.loginUser.auth
    const idx = roles.findIndex(o => o === auth)
    if (idx >= 0) {
      next()
    } else {
      next('/login')
    }
  }
})

// axios settings
if (location.protocol.toLowerCase() === 'https:') {
  axios.defaults.baseURL = process.env.VUE_APP_API_HTTPS_URL
} else {
  axios.defaults.baseURL = process.env.VUE_APP_API_HTTP_URL
}
// siteCd 설정
if (location.hostname.toLowerCase().indexOf('cjenm-labs.') === 0) {
  axios.defaults.headers.common['XOIP-SiteCd'] = 'CJE'
} else {
  axios.defaults.headers.common['XOIP-SiteCd'] = 'CJE'
}
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Cache-Control'] = 'no-cache'
axios.defaults.headers.common['Pragma'] = 'no-cache'
// axios.defaults.headers.common['Accept-Language'] = 'ko-KR'
axios.defaults.withCredentials = true
axios.defaults.crossDomain = true

// request interceptor
axios.interceptors.request.use(
  (request) => {
    // 현재 브라우저의 언어값 확인
    // let locale = navigator.language || navigator.userLanguage
    // locale = locale.substring(0, 2)
    // if (locale !== 'ko' && locale !== 'en') {
    //   locale = 'en'
    // }
    // if (i18n.locale !== locale) {
    //   i18n.locale = locale
    // }
    // Accept-Language 헤더 할당
    // request.headers['Accept-Language'] = locale
    // Authorization 헤더 할당
    const accsToken = store.getters.loginUser.accsToken
    if (accsToken !== null && accsToken !== undefined) {
      request.headers['Authorization'] = `Bearer ${accsToken}`
    }
    if (request.url.indexOf('/auth/tokens') < 0) {
      // 토큰 갱신중일때는 로딩바를 표시하지 않으며, 마지막 엑세스 시간도 기록하지 않는다.
      store.commit('SET_LOADING', true)
      store.commit('SET_LAST_ACCESS_TIME', Date.now())
    }
    console.log({ request })
    for (const p in request.params) {
      console.log(p + '=' + request.params[p])
    }
    return request
  },
  (error) => {
    store.commit('SET_LOADING', false)
    console.log({ error })
    return Promise.reject(error)
  })

// response interceptor
axios.interceptors.response.use(
  (response) => {
    store.commit('SET_LOADING', false)
    console.log({ response })
    return response
  },
  (error) => {
    console.log({ error })
    store.commit('SET_LOADING', false)
    if (error.response && error.response.status === 401) {
      store.dispatch('forceLogout')
        .then(() => {
          router.push('/login').catch(() => {})
        })
        .catch(() => {
        })
    }
    return Promise.reject(error)
  })

Vue.prototype.$http = axios
Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  vuetify,
  VueKonva,
  render: h => h(App)
}).$mount('#app')
