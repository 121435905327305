export const searches = {
  data () {
    return {
      comParamKeys: [],
      comParamVals: []
    }
  },
  methods: {
    appendParam (paramKey, paramVal) {
      if (this.$isNull(paramVal)) {
        return
      }
      this.comParamKeys.push(paramKey)
      this.comParamVals.push(paramVal)
    },
    toRequestUrl (uri) {
      if (this.comParamKeys.length !== this.comParamVals.length) {
        console.log(`parameter keys length(${this.comParamKeys.length}) is difference from parameter values length(${this.comParamVals.length})`)
        return uri
      }
      const query = []
      if (!this.$isNull(this.comSrtBy) && this.comSrtBy instanceof Array && this.comSrtBy.length > 0) {
        query.push(`comSrtBy=${this.comSrtBy[0]}`)
      }
      if (!this.$isNull(this.comSrtDesc) && this.comSrtDesc instanceof Array && this.comSrtDesc.length > 0) {
        query.push(`comSrtDesc=${this.comSrtDesc[0]}`)
      }
      if (!this.$isNull(this.comPageNo)) {
        query.push(`comPageNo=${this.comPageNo}`)
      }
      if (!this.$isNull(this.comItemCntPerPage)) {
        query.push(`comItemCntPerPage=${this.comItemCntPerPage}`)
      }
      if (this.comParamKeys.length > 0) {
        query.push(`comParamKeys=${this.comParamKeys.join(',')}`)
        query.push(`comParamVals=${this.comParamVals.join(',')}`)
      }
      return encodeURI(`${uri}?${query.join('&')}`)
    },
    clearComParam () {
      this.comParamKeys = []
      this.comParamVals = []
    }
  }
}
