<template>
  <v-dialog v-model="dialogModel" persistent :max-width="$const.maxWidthLayout.lg" :retain-focus="false" scrollable>
    <v-card flat>
      <!-- 타이틀바 -->
      <v-card-title class="pa-0">
        <title-bar :title="$t('word.preview')" color="primary" dark>
          <template v-slot:action>
            <v-btn icon large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
          </template>
        </title-bar>
      </v-card-title>
      <!-- 컨텐츠 -->
      <v-card-text ref="myContainer" class="pt-4" style="background-color:#E0E0E0">
        <pdf
          ref="myPdf"
          v-if="!$isNull(objectUrl)"
          :src="objectUrl"
          :page="currPageNo"
          @num-pages="pageCnt = $event"
          @page-loaded="handlePageLoaded"></pdf>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-chip class="mr-2">{{ currPageNo }} / {{ pageCnt }}</v-chip>
        <v-btn text large color="secondary" @click="dialogModel = false">{{ $t('word.close') }}</v-btn>
        <v-btn :disabled="currPageNo <= 1" text large color="primary" @click="currPageNo = currPageNo - 1">{{ $t('word.prev') }}</v-btn>
        <v-btn :disabled="currPageNo >= pageCnt" text large color="primary" @click="currPageNo = currPageNo + 1">{{ $t('word.next') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import pdf from 'vue-pdf'
import * as blobUtil from 'blob-util'

export default {
  name: 'PreviewPdfPopup',
  components: {
    TitleBar,
    pdf
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    url: {
      type: String,
      required: false
    }
  },
  mounted () {
    this.fetchItem()
  },
  beforeDestroy () {
    if (!this.$isNull(this.objectUrl)) {
      blobUtil.revokeObjectURL(this.objectUrl)
    }
  },
  data () {
    return {
      objectUrl: null,
      currPageNo: 1,
      pageCnt: null
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    }
  },
  methods: {
    fetchItem () {
      if (this.$isNull(this.url)) {
        return
      }
      this.$http.get(this.url, { responseType: 'blob' })
        .then((response) => {
          this.objectUrl = blobUtil.createObjectURL(response.data)
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handlePageLoaded () {
      const container = this.$refs.myContainer
      if (container) {
        container.scrollTop = 0
      }
    }
  }
}
</script>
