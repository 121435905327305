<template>
  <div>
    <v-card outlined>
      <!-- 타이틀바 -->
      <title-bar :title="$t('word.faq')">
        <template v-slot:action>
          <v-btn v-if="isLicensee" small tile :min-width="$const.minWidthButton" color="secondary" class="mr-1" @click="fetchList(true)">{{ $t('word.search') }}</v-btn>
        </template>
      </title-bar>
      <v-divider v-if="isLicensee"></v-divider>
      <field-bar :field-size="1" :lg="12" :md="12">
        <template v-slot:field-1>
          <v-text-field
            v-model="kwd"
            :label="$t('word.kwd')"
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @keypress.enter="fetchList(true)">
          </v-text-field>
        </template>
      </field-bar>
      <!-- 탭 설정 -->
      <v-tabs
        v-model="tab"
        slider-color="primary"
        align-with-title
        @change="fetchList">
        <v-tab>{{ $t('word.total') }}</v-tab>
        <v-tab
          v-for="(item, index) in faqDivCdList"
          :key="index">
          {{ item.faqDivCdNm }}
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
        <!-- show-select -->
      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="items"
        item-key="faqNo"
        height="64vh"
        fixed-header
        hide-default-header
        show-expand
        single-select
        disable-filtering
        disable-pagination
        loading-text=""
        no-data-text=""
        no-results-text=""
        hide-default-footer
        :single-expand="true"
        :expanded="expandedItems"
        :server-items-length="comTtlCnt"
        :sort-by.sync="comSrtBy"
        :sort-desc.sync="comSrtDesc"
        @click:row="handleRowClicked">
        <template v-slot:item.qstn="{ item }">
          <div class="d-flex flex-row" style="padding-top:8px; padding-bottom:8px;">
            <div style="width:100px;">
              <v-chip small class="mr-3">
                {{ item.faqDivCdNm }}
              </v-chip>
            </div>
            <div>{{ item.qstn }}</div>
          </div>
        </template>
        <template
          v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pb-9">
            <faq-licensee-file-list-unit
              :key="`file-${item.faqNo}`"
              v-model="item.faqNo"
              :faq-no="item.faqNo"
              class="pt-4">
            </faq-licensee-file-list-unit>
            <v-textarea
              v-if="!$isNull(item.faqNo)"
              v-model="item.ansr"
              solo
              flat
              rows="1"
              auto-grow
              :readonly="true"
              hide-details>
            </v-textarea>
          </td>
        </template>
        <template v-slot:body.append="{ headers }">
          <tr>
            <td :colspan="headers.length">
              {{ $t('word.total') }} {{ $formatNumber(comTtlCnt) }}{{ $t('word.count') }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <!-- 페이징 -->
      <paging-bar
        :page-no.sync="comPageNo"
        :page-cnt.sync="comPageCnt"
        :item-cnt-per-page.sync="comItemCntPerPage"
        @paging-changed="fetchList">
      </paging-bar>
    </v-card>
  </div>
</template>

<script>
import { sorts } from '@/mixins/sortMixin'
import { pages } from '@/mixins/pageMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'
import FieldBar from '@/components/FieldBar'
import PagingBar from '@/components/PagingBar'
import FaqLicenseeFileListUnit from '@/views/faq/unit/FaqLicenseeFileListUnit.vue'

export default {
  name: 'FaqLicenseeView',
  mixins: [sorts, pages, searches, stores],
  components: {
    TitleBar,
    FieldBar,
    PagingBar,
    FaqLicenseeFileListUnit
  },
  mounted () {
    this.faqDivCdList()
    this.fetchList()
  },
  data () {
    return {
      tab: 0,
      headers: [
        { text: '', value: 'qstn' },
        { text: '', value: 'data-table-expand', align: 'end' }
      ],
      // 조회결과 목록
      items: [],
      // 검색조건
      kwd: null,
      // 선택 아이템
      selectedItems: [],
      // 확장된 테이블 로우 아이템
      expandedItems: []
    }
  },
  methods: {
    // FAQ 구분코드 조회
    faqDivCdList () {
      this.$http.get('/api/1/faq/code')
        .then((response) => {
          this.faqDivCdList = response.data.result.faqDivCdList
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 목록조회
    fetchList (shouldClear) {
      // 검색 초기화
      if (shouldClear) {
        this.comPageNo = 1
        this.selectedItems = []
        this.expandedItems = []
      }
      // 검색 파라미터 할당
      this.clearComParam()
      // FAQ 구분코드 할당
      if (this.tab > 0) {
        const faqDivCd = this.faqDivCdList[this.tab - 1].faqDivCd
        this.appendParam('faqDivCd', faqDivCd)
      }
      if (!this.$isEmpty(this.kwd)) {
        this.appendParam('qtas', this.kwd)
      }
      // 검색
      this.$http.get(this.toRequestUrl('/api/1/licefaq'))
        .then((response) => {
          const page = response.data.result.liceFaqPage
          this.bindComPage(page)
          this.items = page.list
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 테이블 로우 클릭시
    handleRowClicked (item) {
      this.selectedItems = [item]
      if (this.$isNull(item.faqNo)) {
        this.expandedItems = []
      } else {
        this.expandedItems = [item]
      }
    },
    getColor () {
      return 'lightgray'
    }
  }
}
</script>
