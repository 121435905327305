<template>
  <div>
    <v-dialog v-model="dialogModel" persistent fullscreen hide-overlay :max-width="$const.maxWidthLayout.xl" :retain-focus="false">
      <v-card flat>
        <!-- 타이틀바 -->
        <title-bar :title="$t('word.markup')" color="primary" dark show-extension>
          <template v-slot:action>
            <v-btn icon large dark @click="handleCloseClicked"><v-icon>mdi-close</v-icon></v-btn>
          </template>
          <!-- 편집툴 버튼 -->
          <template v-if="!this.readonly" v-slot:extension>
              <v-row>
                <v-col cols="12" class="py-2">
                  <v-btn text @click="handleAddComponentClicked('rect')">
                    <span class="hidden-sm-and-down">{{ $t('OpinionMarkUpPopup.txt006') }}</span>
                    <v-icon class="ml-1 mr-1">
                      mdi-checkbox-blank-outline
                    </v-icon>
                  </v-btn>
                  <v-btn text @click="handleAddComponentClicked('eclipse')">
                    <span class="hidden-sm-and-down">{{ $t('OpinionMarkUpPopup.txt007') }}</span>
                    <v-icon class="ml-1 mr-1">
                      mdi-checkbox-blank-circle-outline
                    </v-icon>
                  </v-btn>
                  <v-menu offset-y max-width="82px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        text
                        :ripple="false"
                        v-bind="attrs"
                        v-on="on"
                        @click="handleShowColorClicked">
                        <span class="hidden-sm-and-down">{{ $t('OpinionMarkUpPopup.txt008') }}</span>
                        <v-icon :color="strokeColor" class="ml-1 mr-1">
                          mdi-checkbox-blank
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item
                        v-for="(color, index) in palette"
                        :key="index">
                        <v-list-item-title>
                          <v-btn :color="color" small @click="handleChangeStrokeColor(color)"/>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn text @click="handleDeleteComponentClicked">
                    <span class="hidden-sm-and-down">{{ $t('OpinionMarkUpPopup.txt009') }}</span>
                    <v-icon class="ml-1 mr-1">
                      mdi-close-circle-outline
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
          </template>
        </title-bar>
        <!-- 컨텐츠 -->
        <v-card class="card-body">
          <v-card-text>
            <v-row>
              <!-- 검수툴 뷰어 -->
              <v-col cols="12" md="9">
                <v-card-text class="fill-height" style="height:730px; background-color:#E0E0E0" ref="container">
                  <!-- 검수툴 스테이지 -->
                  <v-stage ref="stage"
                    :config="stageConfig"
                    @wheel="Wheel($event)"
                    @mousedown="handleStageMouseDown">
                    <!-- 이미지 레이어 -->
                    <v-layer ref="layer"
                      @dragstart="handleDragStart"
                      @dragend="handleDragEnd">
                      <v-image ref="image" :config="imageConfig"/>
                      <v-rect
                        v-for="item in rectangles"
                        :key="item.id"
                        :config="item"
                        @transformend="handleTransformEnd($event)"
                        @mouseenter="pointerCursor"
                        @mouseleave="defaultCursor"/>
                      <v-circle
                        v-for="item in eclipses"
                        :key="item.id"
                        :config="item"
                        @transformend="handleTransformEnd($event)"
                        @mouseenter="pointerCursor"
                        @mouseleave="defaultCursor"/>
                      <!-- 도형 편집 태그 -->
                      <v-transformer v-if="!this.readonly" ref="transformer"/>
                    </v-layer>
                  </v-stage>
                </v-card-text>
              </v-col>
              <!-- 검수 의견 추가하기 -->
              <v-col cols="12" md="3" @mousedown="changeMode">
                <v-card outlined>
                  <title-bar :title="$t('word.inspOpinCnts')" sub>
                    <template v-slot:action v-if="!readonly">
                        <template>
                          <v-btn small tile color="secondary" :ripple="false" @click="handleRegistOpinionClicked" @mousedown="changeMode">
                            {{ $t('word.add') }}
                          </v-btn>
                        </template>
                    </template>
                  </title-bar>
                  <v-list class="py-0">
                    <!-- 검수 의견 목록 -->
                    <template v-for="(opinion, index) in opinionList">
                      <v-list-item :key="index" :selectable="false">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on }">
                            <v-list-item-avatar
                              class="opinionColor"
                              :color="opinion.clrVal"
                              v-on="on"
                              @click="setIndex(index)"
                              @mousedown="changeMode"/>
                          </template>
                          <v-list dense v-if="!readonly">
                            <v-list-item
                              v-for="(color, index) in palette"
                              :key="index">
                              <v-list-item-title>
                                <v-btn :color="color" small @click="handleChangeOpinionColorClicked(color)"/>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                          <v-list-item-content>
                            <v-textarea
                              v-model="opinionList[index].markOpinCnts"
                              filled
                              rows="1"
                              auto-grow
                              hide-details
                              :readonly="readonly"
                              @mousedown="changeMode"/>
                          </v-list-item-content>
                          <!-- 검수 의견 삭제 -->
                          <v-list-item-action v-if="!readonly">
                            <v-btn icon @click="handleDeleteOpinionClicked(index)" @mousedown="changeMode">
                              <v-icon :color="'#FF0000'">mdi-close-circle-outline</v-icon>
                            </v-btn>
                          </v-list-item-action>
                      </v-list-item>
                    </template>
                    <template v-if="opinionList.length === 0">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t('OpinionMarkUpPopup.txt001') }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
         </v-card-text>
        </v-card>
        <!-- 액션 -->
        <v-footer padless class="justify-end">
          <v-card-actions>
            <v-btn text large color="secondary" @click="handleCloseClicked">{{ $t('word.close') }}</v-btn>
            <v-btn v-if="!readonly" text large color="info" @click="handleSaveMarkUpClicked">{{ $t('word.save') }}</v-btn>
            <v-btn v-if="!readonly" text large color="error" @click="handleDeleteMarkUpClicked('delete')">{{ $t('word.delete') }}</v-btn>
          </v-card-actions>
        </v-footer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'

const image = new window.Image()
const DELTA = 4

export default {
  name: 'OpinionMarkUpPopup',
  mixins: [stores],
  components: {
    TitleBar
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    projCd: {
      type: String,
      required: false
    },
    inspNo: {
      type: Number,
      required: false
    },
    inspFileNo: {
      type: Number,
      required: false
    },
    markNo: {
      type: Number,
      required: false
    },
    dataUrl: {
      type: String,
      required: false
    },
    readonly: {
      type: Boolean,
      required: true
    },
    metaRectangles: {
      type: Array,
      required: false
    },
    metaEclipses: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      stageConfig: {
        width: 0,
        height: 0,
        scale: {
          x: 1,
          y: 1
        },
        draggable: true
      },
      imageConfig: {
        width: 0,
        height: 0,
        x: 0,
        image: image
      },
      isDragging: false,
      image: null,
      scaleBy: 1.10,
      reverseScale: 1.10,
      oldScale: null,
      pointer: null,
      mousePointTo: {
        x: 0,
        y: 0
      },
      newScale: null,
      newPos: {
        x: 0,
        y: 0
      },
      componentNumber: 0,
      rectangles: [
      ],
      eclipses: [
      ],
      meta: [
      ],
      metaOpin: [
      ],
      selectedShapeName: '',
      keyEventItem: '',
      strokeColor: '',
      imageStroke: 'lightgray',
      colors: '',
      palette: [
        '#F44336', '#FF9800', '#FFEB3B', '#4CAF50',
        '#2196F3', '#283593', '#9C27B0', '#000000'
      ],
      showColor: false,
      somethingChanged: false,
      panel: 0,
      icon: null,
      opinionList: [
      ],
      opinionIndex: null,
      opinionColorExist: false,
      modifying: false
    }
  },
  mounted () {
    console.log(this.projCd)
    console.log(this.inspNo)
    console.log(this.inspFileNo)
    console.log(this.markNo)
    console.log(this.readonly)
    image.src = this.dataUrl
    image.onload = () => {
      // set image only when it is loaded
      this.image = image
    }
    if (this.metaRectangles !== null && this.metaRectangles !== undefined && this.metaEclipses !== null && this.metaEclipses !== undefined) {
      this.rectangles = JSON.parse(JSON.stringify(this.metaRectangles))
      this.eclipses = JSON.parse(JSON.stringify(this.metaEclipses))
    }
    window.addEventListener('resize', this.changeStageSize)
    window.addEventListener('keydown', this.keydownEvent)
    this.setStageSize()
    this.fetchItem()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.changeStageSize)
    window.removeEventListener('keydown', this.keydownEvent)
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    }
  },
  watch: {
    rectangles: {
      deep: true,
      handler () {
        this.somethingChanged = true
      }
    },
    eclipses: {
      deep: true,
      handler () {
        this.somethingChanged = true
      }
    },
    opinionList: {
      deep: true,
      handler () {
        this.somethingChanged = true
      }
    }
  },
  methods: {
    // 데이터조회
    fetchItem () {
      this.$http.get(`/api/1/projects/${this.projCd}/inspection/${this.inspNo}/file/${this.inspFileNo}/markup`)
        .then((response) => {
          const result = response.data.result
          let markMeta = []
          let rectMeta = []
          let eclipseMeta = []
          if ((markMeta !== null && markMeta !== undefined && markMeta !== '' && (rectMeta.length !== 0 || eclipseMeta.length !== 0)) || result.markUnion.markOpinList.length !== 0) {
            markMeta = result.markUnion.mark.markMeta
            rectMeta = JSON.parse(markMeta)[0]
            eclipseMeta = JSON.parse(markMeta)[1]
            this.modifying = true
          }
          if (markMeta !== null && markMeta !== undefined && markMeta !== '') {
            this.rectangles = rectMeta
            this.eclipses = eclipseMeta
          }
          // 읽기전용일 경우 도형 이동 불가
          if (this.readonly) {
            let i = 0
            for (i = 0; i < this.rectangles.length; i++) {
              this.rectangles[i].draggable = false
            }
            for (i = 0; i < this.eclipses.length; i++) {
              this.eclipses[i].draggable = false
            }
          }
          this.opinionList = result.markUnion.markOpinList
          // 값 할당후 watch 가 뒤늦게 실행되므로 nextTick 에서 변경사항이 없음을 할당한다.
          this.$nextTick(() => {
            this.somethingChanged = false
          })
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 삭제 버튼 클릭 행동
    handleDeleteMarkUpClicked () {
      if (!confirm(this.$t('msg.confirmDelete', [this.$t('word.markup')]))) {
        return
      }
      this.$http.delete(`/api/1/projects/${this.projCd}/inspection/${this.inspNo}/file/${this.inspFileNo}/markup/${this.markNo}`)
        .then(() => {
          this.$toasted.global.success(this.$t('msg.deleted'))
          const inspFile = {
            projCd: this.projCd,
            inspNo: this.inspNo,
            inspFileNo: this.inspFileNo,
            markNo: this.markNo,
            markExistYn: 'N'
          }
          this.$emit('item-changed', inspFile)
          this.dialogModel = false
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 저장 버튼 클릭 행동
    handleSaveMarkUpClicked () {
      // 의견과 도형의 색상 일치 여부 판단
      let i = 0
      const componentColors = []
      const opinionColors = []
      for (i = 0; i < this.rectangles.length; i++) {
        componentColors.push(this.rectangles[i].stroke)
      }
      for (i = 0; i < this.eclipses.length; i++) {
        componentColors.push(this.eclipses[i].stroke)
      }
      for (i = 0; i < this.opinionList.length; i++) {
        opinionColors.push(this.opinionList[i].clrVal)
      }
      if (componentColors.filter(x => !opinionColors.includes(x)).length > 0 || opinionColors.filter(x => !componentColors.includes(x)).length > 0) {
        this.$toasted.global.warn(this.$t('OpinionMarkUpPopup.txt004'))
        return
      }
      // 의견의 내용값 확인
      for (i = 0; i < this.opinionList.length; i++) {
        if (this.opinionList[i].markOpinCnts === '') {
          this.$toasted.global.warn(this.$t('OpinionMarkUpPopup.txt005'))
          return
        }
      }
      this.meta[0] = this.rectangles
      this.meta[1] = this.eclipses
      this.metaOpin = {
        mark: {
          projCd: this.projCd,
          inspNo: this.inspNo,
          inspFileNo: this.inspFileNo,
          markNo: this.markNo,
          markMeta: JSON.stringify(this.meta)
        },
        markOpinList: this.opinionList
      }
      if (this.modifying === true) {
        this.$http.put(`/api/1/projects/${this.projCd}/inspection/${this.inspNo}/file/${this.inspFileNo}/markup/${this.markNo}`, this.metaOpin)
          .then(() => {
            this.$toasted.global.success(this.$t('msg.saved'))
            this.somethingChanged = false
            const inspFile = {
              projCd: this.projCd,
              inspNo: this.inspNo,
              inspFileNo: this.inspFileNo,
              markNo: this.markNo,
              markExistYn: 'Y'
            }
            this.$emit('item-changed', inspFile)
            this.dialogModel = false
          })
          .catch((error) => {
            this.$showError(error)
          })
      } else {
        this.$http.post(`/api/1/projects/${this.projCd}/inspection/${this.inspNo}/file/${this.inspFileNo}/markup`, this.metaOpin)
          .then(() => {
            this.$toasted.global.success(this.$t('msg.saved'))
            this.somethingChanged = false
            const inspFile = {
              projCd: this.projCd,
              inspNo: this.inspNo,
              inspFileNo: this.inspFileNo,
              markNo: null,
              markExistYn: 'Y'
            }
            this.$emit('item-changed', inspFile)
            this.dialogModel = false
          })
          .catch((error) => {
            this.$showError(error)
          })
      }
    },
    // 편집툴 스테이지 사이즈를 v-col의 사이즈에 맞춤
    setStageSize () {
      const container = this.$refs.container

      this.stageConfig.width = container.offsetWidth - 30
      this.stageConfig.height = container.offsetHeight - 30
      this.fillImage()
    },
    // 편집툴 스테이지 사이즈를 v-col의 사이즈에 맞춤
    changeStageSize () {
      const container = this.$refs.container

      this.stageConfig.width = container.offsetWidth - 30
      this.stageConfig.height = container.offsetHeight - 30
    },
    // 초기 이미지 크기 및 위치 설정
    fillImage () {
      const container = this.$refs.container
      const widthRatio = container.offsetWidth / image.width
      const heighRatio = container.offsetHeight / image.height
      const ratio = (widthRatio < heighRatio) ? widthRatio : heighRatio

      this.imageConfig.width = (image.width * ratio)
      this.imageConfig.height = (image.height * ratio)
      this.imageConfig.x = (container.offsetWidth / 2) - (this.imageConfig.width / 2)
    },
    // 도형 추가
    handleAddComponentClicked (type) {
      if (this.strokeColor === '') {
        this.strokeColor = '#000000'
      }
      if (type === 'rect') {
        if (this.rectangles.length !== 0 && this.rectangles[0] !== '') {
          const split = this.rectangles[this.rectangles.length - 1].name.split('-')
          this.componentNumber = 1 + parseInt(split[1])
        } else {
          this.componentNumber = 1
        }
        this.rectangles.push({
          rotation: 0,
          x: window.innerWidth / 4,
          y: window.innerHeight / 4,
          width: 100,
          height: 100,
          scaleX: 1,
          scaleY: 1,
          stroke: this.strokeColor,
          name: 'rect-' + this.componentNumber,
          draggable: true
        })
      } else {
        if (this.eclipses.length !== 0 && this.eclipses[0] !== '') {
          const split = this.eclipses[this.eclipses.length - 1].name.split('-')
          this.componentNumber = 1 + parseInt(split[1])
        } else {
          this.componentNumber = 1
        }
        this.eclipses.push({
          rotation: 0,
          x: window.innerWidth / 4,
          y: window.innerHeight / 4,
          width: 100,
          height: 100,
          scaleX: 1,
          scaleY: 1,
          stroke: this.strokeColor,
          name: 'eclipse-' + this.componentNumber,
          draggable: true
        })
      }
      this.$nextTick(() => {
        this.selectedShapeName = type + '-' + this.componentNumber
        const transformerNode = this.$refs.transformer.getNode()
        const stage = transformerNode.getStage()
        const selectedNode = stage.findOne('.' + this.selectedShapeName)

        if (selectedNode === transformerNode.node()) {
          return
        }
        if (selectedNode) {
          transformerNode.nodes([selectedNode])
        } else {
          transformerNode.nodes([])
        }
        this.updateTransformer()
      })
    },
    // 도형 색상 버튼 클릭 시
    handleShowColorClicked () {
      this.showColor = !this.showColor
    },
    // 도형 테두리 색 변경
    handleChangeStrokeColor (value) {
      this.strokeColor = value
      if (this.selectedShapeName !== '') {
        const rect = this.rectangles.find((r) => r.name === this.selectedShapeName)
        const eclipse = this.eclipses.find((r) => r.name === this.selectedShapeName)
        if (rect !== undefined) {
          rect.stroke = this.strokeColor
        } else {
          eclipse.stroke = this.strokeColor
        }
      }
    },
    // 도형 삭제
    handleDeleteComponentClicked () {
      const name = this.selectedShapeName
      if (name === '') {
        return
      }
      let index = this.rectangles.findIndex(function (item) { return item.name === name })
      if (index < 0) {
        index = this.eclipses.findIndex(function (item) { return item.name === name })
        this.eclipses.splice(index, 1)
      } else {
        this.rectangles.splice(index, 1)
      }
      this.selectedShapeName = ''
      this.updateTransformer()
    },
    // 검수의견 추가
    handleRegistOpinionClicked () {
      // 검수의견 색상 선택 시 이미 선택한 색상 여부 판단
      let i = 0
      let j = 0
      let apply = true
      for (i = 0; i < this.palette.length; i++) {
        apply = true
        for (j = 0; j < this.opinionList.length; j++) {
          if (this.palette[i] === this.opinionList[j].clrVal) {
            apply = false
          }

          if (j === this.opinionList.length && apply === true) {
            break
          }
        }
        if (i === this.palette.length - 1 && apply === false) {
          this.$toasted.global.warn(this.$t('OpinionMarkUpPopup.txt003', [this.palette.length]))
          return
        }
        if (apply === false) {
          continue
        }
        this.opinionList.push({
          projCd: this.projCd,
          inspNo: this.inspNo,
          inspFileNo: this.inspFileNo,
          markNo: this.markNo,
          markOpinCnts: '',
          clrVal: this.palette[i]
        })
        break
      }
    },
    // 검수의견 단건 삭제
    handleDeleteOpinionClicked (index) {
      this.opinionList.splice(index, 1)
    },
    // 의견 목록과 해당 의견 색상의 인덱스 설정
    setIndex (index) {
      this.opinionIndex = index
    },
    // 검수의견 색상 변경
    handleChangeOpinionColorClicked (color) {
      let i = 0
      for (i = 0; i < this.opinionList.length; i++) {
        if (this.opinionList[i].clrVal === color) {
          this.$toasted.global.warn(this.$t('OpinionMarkUpPopup.txt002'))
          this.opinionColorExist = true
          return
        }
      }
      this.opinionList[this.opinionIndex].clrVal = color
    },
    pointerCursor () {
      const stage = this.$refs.stage.getNode()
      stage.container().style.cursor = 'pointer'
    },
    defaultCursor () {
      const stage = this.$refs.stage.getNode()
      stage.container().style.cursor = 'default'
    },
    handleTransformEnd (e) {
      const rect = this.rectangles.find(
        (r) => r.name === this.selectedShapeName
      )
      const eclipse = this.eclipses.find(
        (r) => r.name === this.selectedShapeName
      )

      if (rect !== null && rect !== undefined) {
        rect.x = e.target.x()
        rect.y = e.target.y()
        rect.rotation = e.target.rotation()
        rect.scaleX = e.target.scaleX()
        rect.scaleY = e.target.scaleY()
      } else {
        eclipse.x = e.target.x()
        eclipse.y = e.target.y()
        eclipse.rotation = e.target.rotation()
        eclipse.scaleX = e.target.scaleX()
        eclipse.scaleY = e.target.scaleY()
      }
    },
    // 스테이지 클릭 행동
    handleStageMouseDown (e) {
      // 스테이지를 클릭했을 때
      if (e.target === e.target.getStage() || e.target.className === 'Image') {
        this.showColor = false
        this.selectedShapeName = ''
        if (!this.readonly) {
          this.updateTransformer()
        }
        return
      }

      // 도형이 Transformer상태일 때 아무것도 하지 않음
      const clickedOnTransformer = e.target.getParent().className === 'Transformer'
      if (clickedOnTransformer) {
        return
      }

      // 도형 정보 변경 사항 반영
      if (!this.readonly) {
        const name = e.target.name()
        const rect = this.rectangles.find((r) => r.name === name)
        const eclipse = this.eclipses.find((r) => r.name === name)
        if (rect === undefined) {
          this.keyEventItem = eclipse
        } else {
          this.keyEventItem = rect
        }
        if (rect || eclipse) {
          this.selectedShapeName = name
        } else {
          this.selectedShapeName = ''
        }
        this.updateTransformer()
      }
    },
    changeMode () {
      this.selectedShapeName = ''
      this.updateTransformer()
    },
    // 도형 메타정보 업데이트
    updateTransformer () {
      const transformerNode = this.$refs.transformer.getNode()
      const stage = transformerNode.getStage()
      const { selectedShapeName } = this
      const selectedNode = stage.findOne('.' + selectedShapeName)
      if (selectedNode === transformerNode.node()) {
        return
      }

      if (selectedNode) {
        transformerNode.nodes([selectedNode])
      } else {
        transformerNode.nodes([])
      }
    },
    handleDragStart () {
      this.isDragging = true
    },
    handleDragEnd (a) {
      if (this.selectedShapeName !== null && this.selectedShapeName !== undefined && this.selectedShapeName !== '') {
        const transformerNode = this.$refs.transformer.getNode()
        const stage = transformerNode.getStage()
        const selectedNode = stage.findOne('.' + this.selectedShapeName)
        const { selectedShapeName } = this

        let target = this.rectangles.find(
          (r) => r.name === selectedShapeName
        )
        if (target === null || target === undefined) {
          target = this.eclipses.find(
            (r) => r.name === selectedShapeName
          )
          target.x = selectedNode.attrs.x
          target.y = selectedNode.attrs.y
        } else {
          target.x = selectedNode.attrs.x
          target.y = selectedNode.attrs.y
        }
      }
      this.isDragging = false
    },
    // 마우스 휠 줌 이벤트(마우스 포인터를 기준으로 줌 계산)
    Wheel (e) {
      const stage = this.$refs.stage.getNode()
      e.evt.preventDefault()
      this.oldScale = stage.scaleX()
      this.pointer = stage.getPointerPosition()
      this.mousePointTo = {
        x: (this.pointer.x - stage.x()) / this.oldScale,
        y: (this.pointer.y - stage.y()) / this.oldScale
      }

      this.newScale = e.evt.deltaY > 0 ? this.oldScale / this.scaleBy : this.oldScale * this.scaleBy
      this.reverseScale = e.evt.deltaY > 0 ? this.oldScale * this.scaleBy : this.oldScale / this.scaleBy

      stage.scale({ x: this.newScale, y: this.newScale })

      this.newPos = {
        x: this.pointer.x - this.mousePointTo.x * this.newScale,
        y: this.pointer.y - this.mousePointTo.y * this.newScale
      }
      stage.position(this.newPos)
    },
    handleCloseClicked () {
      if (this.somethingChanged) {
        if (!confirm(this.$t('msg.detectChange'))) {
          return
        }
      }
      this.dialogModel = false
    },
    keydownEvent (event) {
      if (event.keyCode === 37) {
        this.keyEventItem.x = (this.keyEventItem.x - DELTA)
      } else if (event.keyCode === 38) {
        this.keyEventItem.y = (this.keyEventItem.y - DELTA)
      } else if (event.keyCode === 39) {
        this.keyEventItem.x = (this.keyEventItem.x + DELTA)
      } else if (event.keyCode === 40) {
        this.keyEventItem.y = (this.keyEventItem.y + DELTA)
      } else if (event.keyCode === 8 || event.keyCode === 46) {
        this.handleDeleteComponentClicked()
      }
    }
  }
}
</script>

<style scoped>
.card-body {
  overflow-y: auto;
}
a { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:focus { text-decoration: none; }
a:hover, a:active { text-decoration: underline; }

.opinionColor:hover {
  cursor: pointer
}
</style>
