<template>
  <div>
    <v-dialog v-model="dialogModel" persistent :max-width="maxWidth" :retain-focus="false">
      <v-card flat>
        <!-- 타이틀바 -->
        <title-bar :title="title" color="primary" dark>
          <template v-slot:action>
            <v-btn icon large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
          </template>
        </title-bar>
        <!-- 컨텐츠 -->
        <v-card>
          <v-card-text class="pb-0">
              <v-container fluid>
                <v-row>
                  <v-col cols="12" :md="modifying ? 8 : 12">
                    <v-form ref="frm">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            ref="cntrNo"
                            v-model="item.cntrNo"
                            :label="$t('word.cntrNo') + ' *'"
                            maxlength="50"
                            :readonly="modifying"
                            :rules="[ requiredRule, cntrNoRule ]"
                            persistent-hint
                            :hint="$t('ContractDetailPopup.txt006')"
                            @input="cntrNoChecked = false">
                            <template v-if="!modifying" v-slot:prepend>
                              <v-icon :color="cntrNoChecked ? 'success' : 'grey'">mdi-check-circle</v-icon>
                            </template>
                            <template v-if="!modifying" v-slot:append>
                              <v-btn depressed x-small color="action" @click="handleCheckCntrNoClicked">{{ $t('word.checkDuplicate') }}</v-btn>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="item.cntrNm"
                            :label="$t('word.cntrNm')"
                            maxlength="100"
                            :rules="[ nameRule ]">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="item.ipNm"
                            label="IP *"
                            readonly
                            :rules="[ requiredRule ]"
                            @click="modifying ? null : showIpListPopup = true">
                            <template v-if="!modifying" v-slot:append>
                              <v-btn depressed x-small color="action" @click="showIpListPopup = true">선택</v-btn>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="item.compNm"
                            :label="$t('word.liceComp') + ' *'"
                            readonly
                            :rules="[ requiredRule ]"
                            @click="modifying ? null : showCompanyListPopup = true">
                            <template v-if="!modifying" v-slot:append>
                              <v-btn depressed x-small color="action" @click="showCompanyListPopup = true">선택</v-btn>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <date-field
                            v-model="item.cntrStrYmd"
                            :label="$t('word.cntrStrYmd')">
                          </date-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <date-field
                            v-model="item.cntrEndYmd"
                            :label="$t('word.cntrEndYmd')">
                          </date-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <date-field
                            v-model="item.cntrYmd"
                            :label="$t('word.cntrYmd')">
                          </date-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <date-field
                            v-model="item.cntrVldYmd"
                            :label="$t('word.cntrVldYmd')">
                          </date-field>
                        </v-col>
                        <v-col v-if="isLicensor" cols="12">
                          <v-textarea
                            v-model="item.rmk"
                            :label="$t('word.rmk')"
                            filled
                            rows="3"
                            :rules="[ length2000Rule ]">
                          </v-textarea>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                  <v-col v-if="modifying" cols="12" md="4">
                    <v-row>
                      <v-col cols="12">
                        <contract-file-list-unit
                          :cntr-id="$isNull(cntrId) ? item.cntrId : cntrId">
                        </contract-file-list-unit>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text large color="secondary" @click="dialogModel = false">{{ $t('word.close') }}</v-btn>
            <v-btn v-if="isAdmin" text large color="primary" @click="handleSaveClicked">{{ $t('word.save') }}</v-btn>
            <v-btn v-if="modifying && isAdmin" text large color="error" @click="handleDeleteClicked">{{ $t('word.delete') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
    <!-- IP 목록 팝업 -->
    <ip-list-popup
      v-if="showIpListPopup"
      :dialog.sync="showIpListPopup"
      :ip-id.sync="item.ipId"
      :ip-nm.sync="item.ipNm"
      use-yn="Y">
    </ip-list-popup>
    <!-- 회사 목록 팝업 -->
    <company-list-popup
      v-if="showCompanyListPopup"
      :dialog.sync="showCompanyListPopup"
      :comp-id.sync="item.compId"
      :comp-nm.sync="item.compNm">
    </company-list-popup>
  </div>
</template>

<script>
import { forms } from '@/mixins/formMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'
import DateField from '@/components/DateField'
import IpListPopup from '@/views/ip/popup/IpListPopup'
import CompanyListPopup from '@/views/company/popup/CompanyListPopup'
import ContractFileListUnit from '@/views/contract/unit/ContractFileListUnit.vue'

export default {
  name: 'ContractDetailPopup',
  mixins: [forms, searches, stores],
  components: {
    TitleBar,
    DateField,
    IpListPopup,
    CompanyListPopup,
    ContractFileListUnit
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    cntrId: {
      type: String,
      required: false
    }
  },
  mounted () {
    this.fetchItem()
  },
  data () {
    return {
      item: {},
      cntrNoChecked: false,
      showIpListPopup: false,
      showCompanyListPopup: false
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    },
    modifying () {
      if (!this.$isNull(this.cntrId)) {
        return true
      } else {
        return !this.$isNull(this.item.cntrId)
      }
    },
    title () {
      return this.modifying ? this.$t('word.cntrDtl') : this.$t('word.registCntr')
    },
    maxWidth () {
      return this.modifying ? this.$const.maxWidthLayout.lg : this.$const.maxWidthLayout.md
    }
  },
  methods: {
    // 데이터조회
    fetchItem () {
      if (!this.modifying) {
        return
      }
      this.$http.get(`/api/1/contracts/${this.cntrId}`)
        .then((response) => {
          this.item = response.data.result.cntr
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 계약번호 중복여부 확인
    handleCheckCntrNoClicked () {
      if (this.modifying || !this.$refs.cntrNo.validate(true)) {
        return
      }
      this.$http.get(`/api/1/contracts/no?cntrNo=${this.item.cntrNo}`)
        .then((response) => {
          const cntr = response.data.result.cntr
          if (this.$isNull(cntr)) {
            this.cntrNoChecked = true
            this.$toasted.global.success(this.$t('ContractDetailPopup.txt001'))
          } else {
            this.cntrNoChecked = false
            this.$toasted.global.warn(this.$t('ContractDetailPopup.txt002'))
          }
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleSaveClicked () {
      if (!this.$refs.frm.validate(true)) {
        return
      }
      if (!this.$isNull(this.item.cntrStrYmd) && !this.$isNull(this.item.cntrEndYmd) && this.item.cntrStrYmd >= this.item.cntrEndYmd) {
        this.$toasted.global.warn(this.$t('ContractDetailPopup.txt003'))
        return
      }
      if (this.modifying) {
        this.$http.put(`/api/1/contracts/${this.item.cntrId}`, this.item)
          .then((response) => {
            this.$toasted.global.success(this.$t('msg.saved'))
            this.item = response.data.result.cntr
            this.$emit('item-changed', this.item)
          })
          .catch((error) => {
            this.$showError(error)
          })
      } else {
        if (!this.cntrNoChecked) {
          this.$toasted.global.warn(this.$t('ContractDetailPopup.txt004'))
          return
        }
        this.$http.post('/api/1/contracts', this.item)
          .then((response) => {
            this.$toasted.global.success(this.$t('msg.registed'))
            this.item = response.data.result.cntr
            this.$emit('item-changed', this.item)
          })
          .catch((error) => {
            this.$showError(error)
          })
      }
    },
    handleDeleteClicked () {
      if (!this.modifying || this.$isNull(this.item.cntrId)) {
        return
      }
      if (!confirm(this.$t('ContractDetailPopup.txt005', [this.item.cntrNo]))) {
        return
      }
      this.$http.delete(`/api/1/contracts/${this.item.cntrId}`)
        .then(() => {
          this.$toasted.global.success(this.$t('msg.deleted'))
          this.dialogModel = false
          this.$emit('item-changed')
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>
