import VTooltip from 'v-tooltip'
// Notifications plugin
import Notifications from '@/components/notificationplugin'
// A plugin file where you could register global components used across the app
import GlobalComponents from '@/plugins/globalComponents'
// A plugin file where you could register global directives
import GlobalDirectives from '@/plugins/globalDirectives'

// asset imports
import 'bootstrap/dist/css/bootstrap.css'
import '@/sass/light-bootstrap-dashboard.scss'
import '@/css/demo.css'

/**
 * This is the main Light Bootstrap Dashboard Vue plugin where dashboard related plugins are registerd.
 */
export default {
  install (Vue) {
    Vue.use(GlobalComponents)
    Vue.use(GlobalDirectives)
    Vue.use(Notifications)
    Vue.use(VTooltip)
  }
}
