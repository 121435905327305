<template>
  <div>
    <v-dialog v-model="dialogModel" persistent fullscreen hide-overlay :max-width="$const.maxWidthLayout.xl" :retain-focus="false">
      <v-card flat>
        <!-- 타이틀바 -->
        <v-app-bar color="primary" dark>
          <v-btn icon large dark @click="handleCloseClicked"><v-icon>mdi-arrow-left</v-icon></v-btn>

          <v-toolbar-title style="font-weight: bolder"> {{ $t('word.dsgnInsp') }} </v-toolbar-title>
          <v-col class="text-right">
          <v-btn icon large dark @click="handleCloseClicked"><v-icon>mdi-close</v-icon></v-btn>
          </v-col>
        </v-app-bar>
        <!-- 컨텐츠 -->
        <v-card flat class="card-body">
          <v-card-text class="pb-0">
            <v-card outlined>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <label-field
                      :label="$t('word.inspNo')"
                      :value="dsgnInsp.inspNo">
                    </label-field>
                  </v-col>
                  <v-col cols="2">
                    <label-field
                      :label="$t('word.inspStg')"
                      :value="dsgnInsp.inspStgCdNm">
                    </label-field>
                  </v-col>
                  <v-col cols="2">
                    <label-field
                      :label="$t('word.inspSts')"
                      :value="dsgnInsp.inspStsCdNm">
                    </label-field>
                  </v-col>
                  <v-col cols="2">
                    <label-field
                      :label="$t('word.inspApplDt')"
                      :value="$timeToMediumDate(dsgnInsp.inspApplDt)">
                    </label-field>
                  </v-col>
                  <v-col cols="2">
                    <label-field
                      :label="$t('word.insprNm')"
                      :value="dsgnInsp.insprNm">
                    </label-field>
                  </v-col>
                  <v-col cols="2">
                    <label-field
                      :label="$t('word.inspDt')"
                      :value="$timeToMediumDate(dsgnInsp.inspDt)">
                    </label-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-text>
            <v-row>
              <!-- 이미지 뷰어 -->
              <v-col cols="12" md="9">
                <v-card-text class="fill-height" style="height:684px; background-color:#E0E0E0">
                  <v-img :src="dataUrl" max-height="654px" contain></v-img>
                </v-card-text>
              </v-col>
              <!-- 검수파일 목록 -->
              <v-col cols="12" md="3">
                <v-expansion-panels v-model="panel" accordion>
                  <!-- 종합 의견 -->
                  <v-expansion-panel
                    key="cnts">
                    <v-expansion-panel-header>
                      <div>
                        <v-icon color="primary lighten-2 " class="mr-3">mdi-comment-text-outline</v-icon>
                        <span class="font-weight-bold">{{ $t('word.totOpinCnts') }}</span>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="pt-4">
                        <v-select
                          v-model="dsgnInsp.nxtInspStgCd"
                          :label="$t('word.nxtInspStg') + ' *'"
                          :items="nxtInspStgCdList"
                          filled
                          small-chips
                          :readonly="readonly"
                          hide-details>
                        </v-select>
                      </v-row>
                      <v-row class="pt-4">
                        <v-textarea
                          v-model="dsgnInsp.inspApplCnts"
                          :label="$t('word.inspApplCnts')"
                          filled
                          rows="1"
                          auto-grow
                          readonly
                          hide-details>
                        </v-textarea>
                      </v-row>
                      <v-row class="pt-4 mb-1">
                        <v-textarea
                          v-model="dsgnInsp.inspOpinCnts"
                          :label="$t('word.inspOpinCnts') + ' *'"
                          filled
                          rows="1"
                          auto-grow
                          :readonly="readonly"
                          hide-details>
                        </v-textarea>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!-- 디자인검수 파일 -->
                  <v-expansion-panel
                    v-for="(inspFile, index) in inspFileList"
                    :key="`data-${index}`">
                    <v-expansion-panel-header>
                      <v-container fluid class="pa-0">
                        <v-row no-gutters align="center">
                          <v-col class="shrink">
                            <span class="font-weight-black text-h4 primary--text text--lighten-2 mt-2 mr-4">{{ $lpad(index + 1, 2, '0') }}</span>
                          </v-col>
                          <v-col class="shrink">
                            <template v-if="$isNull(tumbDataList[index])">
                              <v-avatar size="56" color="grey lighten-3" class="mr-4">
                                <v-icon>mdi-paperclip</v-icon>
                              </v-avatar>
                            </template>
                            <template v-else>
                              <v-avatar size="56" class="mr-4">
                                <v-img :src="`data:image/${inspFile.fileExtNm};base64,${tumbDataList[index]}`"></v-img>
                              </v-avatar>
                            </template>
                          </v-col>
                          <v-col grow>
                            <label-field
                              :label="inspFile.inspFileDivCdNm"
                              :value="inspFile.mngFileNm"
                              class="mt-1">
                              <template v-slot:append>
                                <v-btn small text color="secondary" class="ma-0 py-0" @click.stop="handleInspFileDownloadClicked(inspFile)">{{ $t('word.download') }}</v-btn>
                              </template>
                            </label-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-container fluid>
                        <v-row v-if="!$isNull(inspFile.inspApplCnts)" class="pt-4">
                          <v-textarea
                            v-model="inspFile.inspApplCnts"
                            :label="$t('word.inspApplCnts')"
                            filled
                            rows="1"
                            auto-grow
                            readonly
                            hide-details>
                          </v-textarea>
                        </v-row>
                        <v-row class="pt-4">
                          <v-textarea
                            v-model="inspFile.inspOpinCnts"
                            :label="$t('word.inspOpinCnts')"
                            filled
                            rows="1"
                            auto-grow
                            :readonly="readonly"
                            hide-details>
                          </v-textarea>
                        </v-row>
                        <v-row v-if="!readonly" class="pt-4 justify-end">
                          <v-btn small outlined color="primary" @click.stop="showOpinionFileRegistPopup = true">{{ $t('word.addInspOpinFile') }}</v-btn>
                        </v-row>
                        <!-- 검수의견파일 -->
                        <v-row v-if="!$isEmptyArray(opinFileList)" class="pt-4">
                          <div>
                            <ul>
                              <li v-for="(opinFile, opinIndex) in opinFileList[index]" :key="`opin-${opinIndex}`">
                                <a @click="handleOpinFileDownloadClicked(opinFile)" class="black--text">
                                  {{ opinFile.mngFileNm }}
                                </a>
                                <v-btn v-if="!readonly" x-small icon color="error" class="ma-0 pa-0" @click.stop="handleOpinFileDeleteClicked(index, opinIndex)"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
                              </li>
                            </ul>
                          </div>
                        </v-row>
                        <!-- 마크업 추가하기 / 마크업 상세보기 -->
                        <v-row v-if="!$isNull(markupText(inspFile))">
                          <div>
                            <ul>
                              <li>
                                <a @click="handleMarkupClicked(inspFile)" class="black--text">
                                  {{ markupText(inspFile) }}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </v-row>
                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!-- 액션 -->
        <v-footer padless class="justify-end">
          <v-card-actions>
            <v-btn text large color="secondary" @click="handleCloseClicked">{{ $t('word.close') }}</v-btn>
            <v-btn v-if="!readonly" text large color="info" @click="handleSaveDraftClicked">{{ $t('word.draftSave') }}</v-btn>
            <v-btn v-if="!readonly" text large color="info" @click="handleSaveCompleteClicked('change')">{{ $t('word.apvChg') }}</v-btn>
            <v-btn v-if="!readonly" text large color="primary" @click="handleSaveCompleteClicked('approve')">{{ $t('word.apv') }}</v-btn>
            <v-btn v-if="!readonly" text large color="error" @click="handleSaveCompleteClicked('reject')">{{ $t('word.rejt') }}</v-btn>
          </v-card-actions>
        </v-footer>
      </v-card>
    </v-dialog>
    <!-- 의견파일 등록 팝업 -->
    <opinion-file-regist-popup
      v-if="showOpinionFileRegistPopup"
      :dialog.sync="showOpinionFileRegistPopup"
      :comp-id="proj.compId"
      :proj-cd="projCd"
      :insp-no="inspNo"
      :insp-file-no="openedInspFileNo"
      @item-changed="handleOpinionFileAdded">
    </opinion-file-regist-popup>
    <!-- 마크업 팝업 -->
    <opinion-mark-up-popup
      v-if="showOpinionMarkUpPopup"
      :dialog.sync="showOpinionMarkUpPopup"
      :proj-cd="workingInspFile.projCd"
      :insp-no="workingInspFile.inspNo"
      :insp-file-no="workingInspFile.inspFileNo"
      :mark-no="workingInspFile.markNo"
      :readonly="readonly"
      :data-url="dataUrl"
      @item-changed="handleMarkupChanged">
    </opinion-mark-up-popup>
  </div>
</template>

<script>
import { stores } from '@/mixins/storeMixin'
// import TitleBar from '@/components/TitleBar'
import LabelField from '@/components/LabelField'
import OpinionFileRegistPopup from '@/views/project/popup/OpinionFileRegistPopup'
import OpinionMarkUpPopup from '@/views/project/popup/OpinionMarkUpPopup'
import fileDownload from 'js-file-download'
import * as blobUtil from 'blob-util'

export default {
  name: 'InspectionDetailPopup',
  mixins: [stores],
  components: {
    // TitleBar,
    LabelField,
    OpinionFileRegistPopup,
    OpinionMarkUpPopup
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    projCd: {
      type: String,
      required: false
    },
    inspNo: {
      type: Number,
      required: false
    }
  },
  mounted () {
    this.$getCodes(['IT0000'], (result) => {
      this.nxtInspStgCdList = this.$toSelectableCodes(result.IT0000)
      this.fetchItem()
      // 패널이미지 미리보기
      if (!this.$isNull(this.defaultFetchImage)) {
        this.defaultFetchImage(1)
      }
    })
  },
  watch: {
    dsgnInsp: {
      deep: true,
      handler () {
        this.somethingChanged = true
      }
    },
    inspFileList: {
      deep: true,
      handler () {
        this.somethingChanged = true
      }
    },
    opinFileList: {
      deep: true,
      handler () {
        this.somethingChanged = true
      }
    },
    panel (to) {
      if (this.$isNull(to)) {
        this.dataUrl = null
      } else {
        if (to === 0) {
          this.dataUrl = null
        } else {
          const item = this.inspFileList[to - 1]
          let extNm = item.fileExtNm
          if (!this.$isNull(extNm)) {
            extNm = extNm.toLowerCase()
            if (extNm === 'png' || extNm === 'jpg' || extNm === 'jpeg') {
              this.fetchImage(item)
            }
          }
        }
      }
    }
  },
  data () {
    return {
      nxtInspStgCdList: [],
      projStsCd: null,
      proj: {},
      dsgnInsp: {},
      inspFileList: [],
      tumbDataList: [],
      opinFileList: [], // 의견파일 목록 : 검수파일 인덱스별 배열로 할당된다.
      dataUrl: null,
      workingInspFile: {}, // 마크업 팝업에 props 로 전달될 정보
      panel: 0,
      somethingChanged: false,
      showOpinionFileRegistPopup: false,
      showOpinionMarkUpPopup: false
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    },
    readonly () {
      // 로그인 사용자가 라이선서인 경우 : 프로젝트 상태코드가 "진행"이고 디자인검수 상태코드가 "승인요청"인 경우에 한하여 에디팅이 가능함
      // 로그인 사용자가 라이선시인 경우 : 에디팅 불가
      return !(this.isLicensor && this.proj.projStsCd === 'PS0300' && this.dsgnInsp.inspStsCd === 'IS0200')
    },
    openedInspFileNo () {
      if (this.$isNull(this.panel) || this.panel === 0) {
        return null
      }
      const idx = this.panel - 1 // 종합의견도 panel 인덱스에 포함됨
      if (idx >= this.inspFileList.length) {
        return null
      }
      return this.inspFileList[idx].inspFileNo
    }
  },
  methods: {
    // 데이터조회
    fetchItem () {
      this.$http.get(`/api/1/projects/${this.projCd}/inspection/${this.inspNo}`)
        .then((response) => {
          const result = response.data.result
          this.proj = result.projUnion.proj
          this.dsgnInsp = result.projUnion.dsgnInsp
          this.inspFileList = result.projUnion.inspFileList
          this.tumbDataList = result.tumbDataList
          this.opinFileList = this.toDividedOpinFileList(this.inspFileList, result.projUnion.opinFileList)
          // 값 할당후 watch 가 뒤늦게 실행되므로 nextTick 에서 변경사항이 없음을 할당한다.
          this.$nextTick(() => {
            this.somethingChanged = false
          })
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    fetchImage (item) {
      this.$http.get(`/api/1/projects/${this.projCd}/inspection/${this.inspNo}/file/${item.inspFileNo}/preview`, { responseType: 'blob' })
        .then((response) => {
          blobUtil.blobToDataURL(response.data)
            .then((result) => {
              this.dataUrl = result
            })
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    defaultFetchImage (item) {
      this.$http.get(`/api/1/projects/${this.projCd}/inspection/${this.inspNo}/file/1/preview`, { responseType: 'blob' })
        .then((response) => {
          blobUtil.blobToDataURL(response.data)
            .then((result) => {
              this.dataUrl = result
            })
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 2차원 배열로 저장된 의견파일목록을 1차원 배열로 변환후 리턴
    toAllOpinFileList (dividedOpinFileList) {
      const allOpinFileList = []
      for (const arr of dividedOpinFileList) {
        allOpinFileList.push(...arr)
      }
      return allOpinFileList
    },
    // 1차원 배열로 저장된 의견파일목록을 2차원 배열로 변환후 리턴
    toDividedOpinFileList (inspFileList, allOpinFileList) {
      // 의견파일 목록 생성 작업
      const dividedOpinFileList = []
      for (const inspFile of inspFileList) {
        const currOpinFileList = []
        for (const opinFile of allOpinFileList) {
          if (opinFile.inspFileNo === inspFile.inspFileNo) {
            currOpinFileList.push(opinFile)
          }
        }
        dividedOpinFileList.push(currOpinFileList)
      }
      return dividedOpinFileList
    },
    markupText (item) {
      // item : 검수파일 오브젝트
      // < 마크업 텍스트 디스플레이 조건 >
      // 1. 마크업 추가하기 :
      //    - 첨부파일이 이미지
      //    - 편집가능한 상태 (로그인 사용자가 라이선서 & 프로젝트상태코드가 "진행" (PS0300) & 디자인검수상태코드가 "승인요청" (IS0200))
      //    - 현재 등록된 마크업 없음
      // 2. 마크업 상세보기 :
      //    - 첨부파일이 이미지
      //    - 현재 등록된 마크업 존재
      if (this.$isNull(item)) {
        return null
      }
      let extNm = item.fileExtNm
      if (this.$isNull(extNm)) {
        return null
      }
      extNm = extNm.toLowerCase()
      if (extNm !== 'png' && extNm !== 'jpg' && extNm !== 'jpeg') {
        return null
      }
      if (!this.readonly && item.markExistYn === 'N') {
        return this.$t('InspectionDetailPopup.txt017')
      } else if (item.markExistYn === 'Y') {
        return this.$t('InspectionDetailPopup.txt018')
      } else {
        return null
      }
    },
    handleInspFileDownloadClicked (item) {
      if (this.$isNull(item)) {
        return
      }
      this.$http.get(`/api/1/projects/${this.projCd}/inspection/${this.inspNo}/file/${item.inspFileNo}/download`, { responseType: 'blob' })
        .then((response) => {
          // 파일이름 설정
          const contentDisposition = response.headers['content-disposition']
          let downFileNm = null
          if (!this.$isNull(contentDisposition)) {
            const idx = contentDisposition.indexOf(';filename*=UTF-8\'\'')
            if (idx >= 0) {
              downFileNm = decodeURIComponent(contentDisposition.substring(idx + 18))
            }
          }
          if (this.$isNull(downFileNm)) {
            downFileNm = this.fileNm
          }
          fileDownload(response.data, downFileNm)
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleOpinFileDownloadClicked (item) {
      if (this.$isNull(item)) {
        return
      }
      // 파일 업로드는 완료되었으나, 저장이 안된 상태에서는 다운로드가 불가하다.
      if (this.$isNull(item.opinFileNo)) {
        this.$toasted.global.info(this.$t('InspectionDetailPopup.txt001'))
        return
      }
      this.$http.get(`/api/1/projects/${this.projCd}/inspection/${this.inspNo}/file/${item.inspFileNo}/opinion/${item.opinFileNo}/download`, { responseType: 'blob' })
        .then((response) => {
          // 파일이름 설정
          const contentDisposition = response.headers['content-disposition']
          let downFileNm = null
          if (!this.$isNull(contentDisposition)) {
            const idx = contentDisposition.indexOf(';filename*=UTF-8\'\'')
            if (idx >= 0) {
              downFileNm = decodeURIComponent(contentDisposition.substring(idx + 18))
            }
          }
          if (this.$isNull(downFileNm)) {
            downFileNm = this.fileNm
          }
          fileDownload(response.data, downFileNm)
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleOpinFileDeleteClicked (inspFileIndex, opinFileIndex) {
      if (inspFileIndex >= this.inspFileList.length || inspFileIndex >= this.opinFileList.length) {
        return
      }
      const currOpinFileList = this.opinFileList[inspFileIndex]
      if (opinFileIndex >= currOpinFileList.length) {
        return
      }
      if (!confirm(this.$t('InspectionDetailPopup.txt002'))) {
        return
      }
      this.opinFileList[inspFileIndex].splice(opinFileIndex, 1)
    },
    handleMarkupClicked (item) {
      // item : 검수파일
      if (this.$isNull(item)) {
        return
      }
      if (!this.dataUrl) {
        this.$toasted.global.info(this.$t('InspectionDetailPopup.txt016'))
        return
      }
      this.workingInspFile = item
      this.showOpinionMarkUpPopup = true
    },
    handleMarkupChanged (item) {
      // item : 검수파일 키 및 마크업 존재여부 정보 { projCd, inspNo, inspFileNo, markExistYn }
      if (!this.$isNull(item.markNo)) {
        const inspFile = this.inspFileList.find((elem) => {
          return elem.inspFileNo === item.inspFileNo
        })
        if (!this.$isNull(inspFile)) {
          if (item.markExistYn === 'N') {
            inspFile.markNo = null
          }
          inspFile.markExistYn = item.markExistYn
          // 값 할당후 watch 가 뒤늦게 실행되므로 nextTick 에서 변경사항이 없음을 할당한다.
          // 단, 마크업 팝업 오픈 전에 변경사항이 없던 경우에 한함
          if (!this.somethingChanged) {
            this.$nextTick(() => {
              this.somethingChanged = false
            })
          }
        }
      } else {
        // 생성된 마크업번호 조회
        this.$http.get(`/api/1/projects/${item.projCd}/inspection/${item.inspNo}/file/${item.inspFileNo}/markup/current`)
          .then((response) => {
            const mark = response.data.result.mark
            const inspFile = this.inspFileList.find((elem) => {
              return elem.inspFileNo === item.inspFileNo
            })
            if (!this.$isNull(inspFile)) {
              inspFile.markNo = mark.markNo
              inspFile.markExistYn = item.markExistYn
              // 값 할당후 watch 가 뒤늦게 실행되므로 nextTick 에서 변경사항이 없음을 할당한다.
              // 단, 마크업 팝업 오픈 전에 변경사항이 없던 경우에 한함
              if (!this.somethingChanged) {
                this.$nextTick(() => {
                  this.somethingChanged = false
                })
              }
            }
          })
          .catch((error) => {
            this.$showError(error)
          })
      }
    },
    handleSaveDraftClicked () {
      const item = {
        dsgnInsp: this.dsgnInsp,
        inspFileList: this.inspFileList,
        opinFileList: this.toAllOpinFileList(this.opinFileList)
      }
      this.$http.put(`/api/1/projects/${this.proj.projCd}/inspection/${this.dsgnInsp.inspNo}/draft`, item)
        .then((response) => {
          this.$toasted.global.success(this.$t('msg.draftSaved'))
          const dsgnInspUnion = response.data.result.dsgnInspUnion
          this.opinFileList = this.toDividedOpinFileList(this.inspFileList, dsgnInspUnion.opinFileList)
          // 값 할당후 watch 가 뒤늦게 실행되므로 nextTick 에서 변경사항이 없음을 할당한다.
          this.$nextTick(() => {
            this.somethingChanged = false
          })
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleSaveCompleteClicked (cmd) {
      if (this.$isNull(this.dsgnInsp.nxtInspStgCd)) {
        this.$toasted.global.warn(this.$t('InspectionDetailPopup.txt003'))
        return
      }
      if (this.$isEmpty(this.dsgnInsp.inspOpinCnts)) {
        this.$toasted.global.warn(this.$t('InspectionDetailPopup.txt004'))
        return
      }
      let completed = false
      if (this.dsgnInsp.nxtInspStgCd === 'IT0500') { // 프로젝트완료
        if (cmd === 'reject') {
          this.$toasted.global.warn(this.$t('InspectionDetailPopup.txt005'))
          return
        }
        completed = true
      }
      let cnfmMsg, succMsg
      if (cmd === 'change') {
        if (completed) {
          cnfmMsg = this.$t('InspectionDetailPopup.txt006')
          succMsg = this.$t('InspectionDetailPopup.txt007')
        } else {
          cnfmMsg = this.$t('InspectionDetailPopup.txt008')
          succMsg = this.$t('InspectionDetailPopup.txt009')
        }
      } else if (cmd === 'approve') {
        if (completed) {
          cnfmMsg = this.$t('InspectionDetailPopup.txt010')
          succMsg = this.$t('InspectionDetailPopup.txt011')
        } else {
          cnfmMsg = this.$t('InspectionDetailPopup.txt012')
          succMsg = this.$t('InspectionDetailPopup.txt013')
        }
      } else if (cmd === 'reject') {
        cnfmMsg = this.$t('InspectionDetailPopup.txt014')
        succMsg = this.$t('InspectionDetailPopup.txt015')
      } else {
        return
      }
      if (!confirm(cnfmMsg)) {
        return
      }
      const item = {
        dsgnInsp: this.dsgnInsp,
        inspFileList: this.inspFileList,
        opinFileList: this.toAllOpinFileList(this.opinFileList)
      }
      this.$http.put(`/api/1/projects/${this.proj.projCd}/inspection/${this.dsgnInsp.inspNo}/${cmd}`, item)
        .then((response) => {
          this.$toasted.global.success(succMsg)
          const dsgnInspUnion = response.data.result.dsgnInspUnion
          this.opinFileList = this.toDividedOpinFileList(this.inspFileList, dsgnInspUnion.opinFileList)
          this.$emit('item-changed', dsgnInspUnion)
          // 값 할당후 watch 가 뒤늦게 실행되므로 nextTick 에서 변경사항이 없음을 할당한다.
          this.$nextTick(() => {
            this.somethingChanged = false
          })
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleCloseClicked () {
      if (this.somethingChanged) {
        if (!confirm(this.$t('msg.detectChange'))) {
          return
        }
      }
      this.dialogModel = false
    },
    handleOpinionFileAdded (item) {
      if (this.$isNull(item)) {
        return
      }
      // 의견파일이 할당될 배열 인덱스 검색
      let inspFileIndex = 0
      for (const inspFile of this.inspFileList) {
        if (item.inspFileNo === inspFile.inspFileNo) {
          break
        }
        inspFileIndex++
      }
      if (inspFileIndex >= this.opinFileList.length) {
        return
      }
      this.opinFileList[inspFileIndex].push(item)
    }
  }
}
</script>

<style scoped>
.card-body {
  overflow-y: auto;
}
a { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:focus { text-decoration: none; }
a:hover, a:active { text-decoration: underline; }
</style>
