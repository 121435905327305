<template>
  <div>
    <v-card outlined>
      <!-- 타이틀바 -->
      <title-bar :title="$t('word.projDtl')">
        <template v-slot:action>
          <v-btn small tile :min-width="$const.minWidthButton" color="secondary" class="mr-1" :to="`/main/project/${menuPath}?bc=1`">{{ $t('word.list') }}</v-btn>
          <v-btn small tile :min-width="$const.minWidthButton" color="secondary" class="mr-1" @click.prevent="showProjectRenamePopup = true">{{ $t('word.renameProj') }}</v-btn>
          <v-btn v-if="isLicensor && item.projStsCd === 'PS0200'" small tile :min-width="$const.minWidthButton" color="primary" class="mr-1" @click.prevent="handleApproveClicked">{{ $t('word.registProj' )}}</v-btn>
          <v-btn v-if="item.projStsCd === 'PS0200' || item.projStsCd === 'PS0300' || item.projStsCd === 'PS0400'" small tile :min-width="$const.minWidthButton" color="error" class="mr-1" @click.prevent="showProjectCancelPopup = true">{{ $t('word.projCncl') }}</v-btn>
        </template>
      </title-bar>
      <v-divider v-if="!isLicensor || item.projStsCd !== 'PS0200'"></v-divider>
      <!-- 신규 프로젝트인 경우 -->
      <v-alert
        v-if="isLicensor && item.projStsCd === 'PS0200'"
        type="info"
        tile
        text
        class="body-2 mb-0">
        {{ $t('ProjectDetailView.txt001') }}
      </v-alert>
      <v-card flat>
        <v-card-text>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" sm="6" lg="4">
                <label-field
                  :label="$t('word.projCd')"
                  :value="item.projCd">
                </label-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <label-field
                  :label="$t('word.projNm')"
                  :value="item.projNm">
                </label-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <label-field
                  :label="$t('word.projStg')"
                  :value="item.projStgCdNm">
                </label-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <label-field
                  :label="$t('word.projSts')"
                  :value="item.projStsCdNm">
                </label-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <label-field
                  :label="$t('word.prodTyp1')"
                  :value="item.topProdTypNm">
                </label-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <label-field
                  :label="$t('word.prodTyp2')"
                  :value="item.prodTypNm">
                </label-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <label-field
                  :label="$t('word.prodNo')"
                  :value="item.prodNo">
                </label-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <label-field
                  :label="$t('word.estmCnsmAmt') + ' ' + currency"
                  :value="$formatNumber(item.estmCnsmAmt)">
                </label-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <label-field
                  :label="$t('word.estmRlsAmt') + ' ' + currency"
                  :value="$formatNumber(item.estmRlsAmt)">
                  <template v-slot:append>
                    {{ item.rlsAmtRt }}%
                  </template>
                </label-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <label-field
                  :label="$t('word.rlsPlanYm')"
                  :value="item.rlsPlanYm">
                </label-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <label-field
                  :label="$t('word.saleNtn')"
                  :value="$isNull(item.prodSaleNtnComCdList) ? null : item.prodSaleNtnComCdList.map(o => o.comCdNm)">
                </label-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <label-field
                  :label="$t('word.saleChnl')"
                  :value="$isNull(item.prodSaleChnlComCdList) ? null : item.prodSaleChnlComCdList.map(o => o.comCdNm)">
                </label-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <label-field
                  :label="$t('word.sprtLang')"
                  :value="$isNull(item.prodSprtLangComCdList) ? null : item.prodSprtLangComCdList.map(o => o.comCdNm)">
                </label-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <label-field
                  :label="$t('word.tgtAge')"
                  :value="$isNull(item.prodTgtAgeComCdList) ? null : item.prodTgtAgeComCdList.map(o => o.comCdNm)">
                </label-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <label-field
                  :label="$t('word.tgtSex')"
                  :value="$isNull(item.prodTgtSexComCdList) ? null : item.prodTgtSexComCdList.map(o => o.comCdNm)">
                </label-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-card>
    <v-card outlined min-height="440px" class="mt-3">
      <!-- 탭 설정 -->
      <v-tabs
        v-model="tab"
        slider-color="primary"
        align-with-title>
        <v-tab>{{ $t('word.cntr') }}</v-tab>
        <v-tab>{{ $t('word.dsgnInsp') }}</v-tab>
        <v-tab>{{ $t('word.hist') }}</v-tab>
        <v-spacer></v-spacer>
        <div v-if="tab !== 0" class="mt-2 mr-5">
          <v-btn small tile :min-width="$const.minWidthButton" color="secondary" class="mr-1" @click="handleTabClearClicked">{{ $t('word.clear') }}</v-btn>
          <v-btn small tile :min-width="$const.minWidthButton" color="secondary" class="mr-1" @click="handleTabSearchClicked">{{ $t('word.search') }}</v-btn>
          <v-btn v-if="tab === 1 && isLicensee && item.projStsCd === 'PS0400'" small tile :min-width="$const.minWidthButton" color="primary" class="mr-1" @click="handleTabAddClicked">{{ $t('word.add') }}</v-btn>
        </div>
      </v-tabs>
      <v-divider></v-divider>
      <!-- 탭 내용 -->
      <v-tabs-items v-model="tab">
        <!-- 계약 탭 -->
        <v-tab-item key="contract">
          <contract-detail-unit
            v-if="!$isNull(item.cntrId)"
            :cntr-id="item.cntrId">
          </contract-detail-unit>
        </v-tab-item>
        <!-- 디자인검수 탭 -->
        <v-tab-item key="inspection">
          <inspection-list-unit
            ref="inspection"
            :comp-id="item.compId"
            :proj-cd="projCd">
          </inspection-list-unit>
        </v-tab-item>
        <!-- 이력 탭 -->
        <v-tab-item key="history">
          <project-change-history-list-unit
            ref="history"
            :proj-cd="projCd">
          </project-change-history-list-unit>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!-- 프로젝트상태사유 팝업 -->
    <project-cancel-popup
      v-if="showProjectCancelPopup"
      :dialog.sync="showProjectCancelPopup"
      @item-changed="handleProjectCanceled">
    </project-cancel-popup>
    <!-- 프로젝트이름변경 팝업 -->
    <project-rename-popup
      v-if="showProjectRenamePopup"
      :dialog.sync="showProjectRenamePopup"
      :asis-proj-nm="item.projNm"
      @item-changed="handleProjectNameChanged">
    </project-rename-popup>
  </div>
</template>

<script>
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'
import LabelField from '@/components/LabelField'
import ContractDetailUnit from '@/views/contract/unit/ContractDetailUnit'
import InspectionListUnit from '@/views/project/unit/InspectionListUnit.vue'
import ProjectChangeHistoryListUnit from '@/views/project/unit/ProjectChangeHistoryListUnit.vue'
import ProjectCancelPopup from '@/views/project/popup/ProjectCancelPopup'
import ProjectRenamePopup from '@/views/project/popup/ProjectRenamePopup'

export default {
  name: 'ProjectDetailView',
  mixins: [stores],
  components: {
    TitleBar,
    LabelField,
    ContractDetailUnit,
    InspectionListUnit,
    ProjectChangeHistoryListUnit,
    ProjectCancelPopup,
    ProjectRenamePopup
  },
  props: {
    projCd: {
      type: String,
      required: false
    },
    menuPath: {
      type: String,
      required: false
    }
  },
  mounted () {
    this.fetchItem()
  },
  data () {
    return {
      item: {},
      tab: 1,
      showProjectCancelPopup: false,
      showProjectRenamePopup: false
    }
  },
  computed: {
    currency () {
      if (this.$isNull(this.item.ccCd)) {
        return null
      } else {
        return `(${this.item.ccCd.substring(2, 5)})`
      }
    }
  },
  methods: {
    // 데이터조회
    fetchItem () {
      this.$http.get(`/api/1/projects/${this.projCd}`)
        .then((response) => {
          this.item = response.data.result.projMeta
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 프로젝트를 등록(승인)한다.
    handleApproveClicked () {
      if (!confirm(this.$t('ProjectDetailView.txt002', [this.item.projNm]))) {
        return
      }
      this.$http.put(`/api/1/projects/${this.item.projCd}/approve`)
        .then(() => {
          this.$toasted.global.success(this.$t('ProjectDetailView.txt003'))
          this.$router.push(`/main/project/${this.menuPath}?bc=1`)
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 프로젝트를 취소 처리한다.
    handleProjectCanceled (obj) {
      const reqForm = {
        projCd: this.item.projCd,
        stsRsnCnts: obj.tobeStsRsnCnts
      }
      if (!confirm(this.$t('ProjectDetailView.txt004', [this.item.projNm]))) {
        return
      }
      this.$http.put(`/api/1/projects/${this.item.projCd}/cancel`, reqForm)
        .then(() => {
          this.$toasted.global.success(this.$t('ProjectDetailView.txt005'))
          this.$router.push(`/main/project/${this.menuPath}?bc=1`)
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 프로젝트명을 변경한다.
    handleProjectNameChanged (obj) {
      const reqForm = {
        projCd: this.item.projCd,
        projNm: obj.tobeProjNm,
        histRsnCnts: obj.tobeHistRsnCnts
      }
      if (!confirm(this.$t('ProjectDetailView.txt006', [reqForm.projNm]))) {
        return
      }
      this.$http.put(`/api/1/projects/${this.item.projCd}/rename`, reqForm)
        .then((response) => {
          this.item.projNm = response.data.result.proj.projNm
          this.$toasted.global.success(this.$t('ProjectDetailView.txt007'))
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 하단 탭 검색조건 초기화 클릭 시
    handleTabClearClicked () {
      if (this.tab === 1) {
        this.$refs.inspection.handleClearClicked()
      } else if (this.tab === 2) {
        this.$refs.history.handleClearClicked()
      }
    },
    // 하단 탭 목록 조회 버튼 클릭 시
    handleTabSearchClicked () {
      if (this.tab === 1) {
        this.$refs.inspection.fetchList(true)
      } else if (this.tab === 2) {
        this.$refs.history.fetchList(true)
      }
    },
    // 하단 탭 추가 버튼 클릭 시
    handleTabAddClicked () {
      if (this.tab === 1) {
        this.$refs.inspection.handleAddClicked()
      }
    }
  }
}
</script>
