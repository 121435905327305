<template>
  <div>
    <v-card outlined>
      <!-- 타이틀바 -->
      <title-bar :title="$t('word.ntc')">
        <template v-slot:action>
          <v-btn small tile :min-width="$const.minWidthButton" color="secondary" class="mr-1" @click="handleClearClicked">{{ $t('word.clear') }}</v-btn>
          <v-btn small tile :min-width="$const.minWidthButton" color="secondary" class="mr-1" @click="fetchList(true)">{{ $t('word.search') }}</v-btn>
        </template>
      </title-bar>
      <v-divider v-if="isLicensee"></v-divider>
      <field-bar :field-size="2" :md="2">
        <template v-slot:field-1>
          <v-select
            v-model="srchDiv"
            :items="srchDivList"
            :label="$t('word.srchDiv')"
            background-color="white"
            outlined
            dense
            hide-details>
          </v-select>
        </template>
        <template v-slot:field-2>
          <v-text-field
            v-model="kwd"
            :label="$t('word.kwd')"
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @keypress.enter="fetchList(true)">
          </v-text-field>
        </template>
      </field-bar>
      <!-- show-select -->
      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="items"
        item-key="ntcNo"
        height="69vh"
        fixed-header
        show-select
        single-select
        disable-filtering
        disable-pagination
        loading-text=""
        no-data-text=""
        no-results-text=""
        hide-default-footer
        :server-items-length="comTtlCnt"
        :sort-by.sync="comSrtBy"
        :sort-desc.sync="comSrtDesc"
        @click:row="handleRowClicked"
        @update:sort-by="delayedCall(fetchList)"
        @update:sort-desc="delayedCall(fetchList)">>
        <template v-slot:item.regDt="{ value }">
          <td>{{ $timeToShortDate(value) }}</td>
        </template>
         <template v-slot:item.modDt="{ value }">
          <td>{{ $timeToMediumDate(value) }}</td>
        </template>
        <template v-slot:body.append="{ headers }">
          <tr>
            <td :colspan="headers.length">
              {{ $t('word.total') }} {{ $formatNumber(comTtlCnt) }}{{ $t('word.count') }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <!-- 페이징 -->
      <paging-bar
        :page-no.sync="comPageNo"
        :page-cnt.sync="comPageCnt"
        :item-cnt-per-page.sync="comItemCntPerPage"
        @paging-changed="fetchList">
      </paging-bar>
    </v-card>
    <!--  공지사항 상세 팝업 -->
    <ntc-detail-popup
      v-if="showNtcDetailPopup"
      :dialog.sync="showNtcDetailPopup"
      :ntc-no="selectedItems.length === 0 ? null : selectedItems[0].ntcNo">
    </ntc-detail-popup>
  </div>
</template>

<script>
import { sorts } from '@/mixins/sortMixin'
import { pages } from '@/mixins/pageMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'
import FieldBar from '@/components/FieldBar'
import PagingBar from '@/components/PagingBar'
import NtcDetailPopup from '@/views/ntc/popup/NtcDetailPopup'

export default {
  name: 'NtcLicenseeView',
  mixins: [sorts, pages, searches, stores],
  components: {
    TitleBar,
    FieldBar,
    PagingBar,
    NtcDetailPopup
  },
  mounted () {
    this.fetchList()
  },
  watch: {
    showNtcDetailPopup (to, from) {
      this.fetchList()
    }
  },
  data () {
    return {
      headers: [
        { text: this.$t('word.number'), value: 'ntcNo' },
        { text: this.$t('word.title'), value: 'ntcSbj', width: '40%' },
        { text: this.$t('word.regYmd'), value: 'regDt' },
        { text: this.$t('word.readCnt'), value: 'readCnt', sortable: false },
        { text: this.$t('word.modDt'), value: 'modDt', sortable: false }
      ],
      // 조회결과 목록
      items: [],
      // 검색조건
      srchDiv: 'sbjCnts',
      kwd: null,
      // 선택 아이템
      selectedItems: [],
      // 공지사항 상세 팝업 오픈 여부
      showNtcDetailPopup: false
    }
  },
  computed: {
    srchDivList () {
      return [
        { text: this.$t('word.title'), value: 'ntcSbj' },
        { text: this.$t('word.cnts'), value: 'ntcCnts' },
        { text: this.$t('word.sbjcnts'), value: 'sbjCnts' }
      ]
    }
  },
  methods: {
    // 목록조회
    fetchList (shouldClear) {
      // 검색 초기화
      if (shouldClear) {
        this.comPageNo = 1
        this.selectedItems = []
      }
      // 검색 파라미터 할당
      this.clearComParam()
      if (!this.$isEmpty(this.kwd)) {
        this.appendParam(this.srchDiv, this.kwd)
      }
      // 검색
      this.$http.get(this.toRequestUrl('/api/1/licentc'))
        .then((response) => {
          const page = response.data.result.liceNtcPage
          this.bindComPage(page)
          this.items = page.list
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 초기화 버튼 클릭시
    handleClearClicked () {
      this.srchDiv = 'sbjCnts'
      this.kwd = null
      this.fetchList(true)
    },
    // 테이블 로우 클릭시
    handleRowClicked (item) {
      this.selectedItems = [item]
      this.showNtcDetailPopup = true
    }
  }
}
</script>
