<template>
  <v-dialog v-model="dialogModel" persistent :max-width="$const.maxWidthLayout.xs" :retain-focus="false">
    <v-card flat>
      <!-- 타이틀바 -->
      <title-bar :title="$t('word.pwdChng')" color="primary" :dark="true">
        <template v-slot:action>
          <v-btn icon rounded large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
        </template>
      </title-bar>
      <!-- 컨텐츠 -->
      <v-card>
        <v-card-text class="pb-0">
          <v-form ref="frm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="item.currPwd"
                    type="password"
                    :label="$t('word.currPwd') + ' *'"
                    maxlength="20"
                    :rules="[ requiredRule ]">
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="item.chngPwd"
                    type="password"
                    :label="$t('word.chngPwd') + ' *'"
                    maxlength="20"
                    :rules="[ requiredRule, passwordRule ]">
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="item.chngPwdCnfm"
                    type="password"
                    :label="$t('word.chngPwdCnfm') + ' *'"
                    maxlength="20"
                    :rules="[ requiredRule, passwordRule ]">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text large color="secondary" @click="dialogModel = false">{{ $t('word.close') }}</v-btn>
          <v-btn text large color="primary" @click="handleSaveClicked">{{ $t('word.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { forms } from '@/mixins/formMixin'
import TitleBar from '@/components/TitleBar'

export default {
  name: 'PasswordChangePopup',
  mixins: [forms],
  components: {
    TitleBar
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    usrId: {
      type: String,
      required: false
    }
  },
  mounted () {
    this.item.usrId = this.usrId
  },
  data () {
    return {
      item: {}
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    }
  },
  methods: {
    handleSaveClicked () {
      if (!this.$refs.frm.validate(true)) {
        return
      }
      if (this.item.chngPwd !== this.item.chngPwdCnfm) {
        this.$toasted.global.warn(this.$t('PasswordChangePopup.txt001'))
        return
      }
      this.$http.put(`/api/1/users/${this.item.usrId}/password`, this.item)
        .then(() => {
          this.$toasted.global.success(this.$t('PasswordChangePopup.txt002'))
          this.dialogModel = false
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>
