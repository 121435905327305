<template>
  <v-toolbar flat :dense="dense" :color="color" :dark="dark">
    <template v-if="!noTitle">
      <v-toolbar-title>
        <template v-if="sub">
          <!-- <v-icon small color="fourthly" class="mr-2">mdi-chevron-double-right</v-icon> -->
          <span class="text-bottom font-weight-medium text-subtitle-1" :class="fontColorClass">{{ title }}</span>
        </template>
        <template v-else>
          <!-- <v-icon color="secondary" class="pb-1 mr-2">mdi-chevron-right-box</v-icon> -->
          <span class="text-bottom font-weight-bold" :class="fontColorClass">{{ title }}</span>
        </template>
      </v-toolbar-title>
    </template>
    <v-spacer></v-spacer>
    <template v-if="showExtension" v-slot:extension>
      <slot name="extension">
        <!-- 확장 컴포넌트가 들어갈 위치 -->
      </slot>
    </template>
    <slot name="action">
      <!-- 액션 버튼 컴포넌트가 들어갈 위치 -->
    </slot>
  </v-toolbar>
</template>

<script>
export default {
  name: 'TitleBar',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Title'
    },
    noTitle: {
      type: Boolean,
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    sub: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: 'white'
    },
    dark: {
      type: Boolean,
      required: false,
      default: false
    },
    showExtension: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    fontColorClass () {
      if (this.dark) {
        return 'white--text'
      } else {
        if (this.sub) {
          return 'grey--text text--darken-1'
        } else {
          return 'secondary--text'
        }
      }
    }
  }
}
</script>
