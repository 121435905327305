<template>
  <div>
    <v-dialog v-model="dialogModel" persistent :max-width="$const.maxWidthLayout.lg" :retain-focus="false">
      <v-card flat>
        <!-- 타이틀바 -->
        <title-bar :title="title" color="primary" dense dark show-extension>
          <template v-slot:action>
            <v-btn icon large dark @click="handleCloseClicked"><v-icon>mdi-close</v-icon></v-btn>
          </template>
          <!-- 탭 정의 -->
          <template v-slot:extension>
            <v-tabs
              v-model="tab"
              slider-color="amber"
              align-with-title>
              <v-tab>{{ $t('word.projInfo') }}</v-tab>
              <v-tab :disabled="!modifying">{{ $t('word.dsgnInspInfo') }}</v-tab>
            </v-tabs>
          </template>
        </title-bar>
        <!-- 컨텐츠 -->
        <v-card min-height="736">
          <v-card-text class="pb-0">
            <v-tabs-items v-model="tab">
              <!-- 프로젝트 정보 -->
              <v-tab-item key="project">
                <v-form ref="frm1">
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="proj.cntrNo"
                          :label="$t('word.cntr') + ' *'"
                          readonly
                          :rules="[ requiredRule ]"
                          @click="modifying ? null : showContractListPopup = true">
                          <template v-if="!modifying" v-slot:append>
                            <v-btn depressed x-small color="action" @click="showContractListPopup = true">{{ $t('word.choose') }}</v-btn>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="proj.projNm"
                          :label="$t('word.projNm') + ' *'"
                          maxlength="100"
                          persistent-hint
                          :hint="$t('ProjectDesignTabPopup.txt006')"
                          :rules="[ requiredRule, nameRule ]">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="proj.projStgCd"
                          :label="$t('word.projStg') + ' *'"
                          :items="projStgCdList"
                          :rules="[ requiredRule ]"
                          small-chips>
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="proj.prodNo"
                          :label="$t('word.prodNo') + ' *'"
                          maxlength="100"
                          persistent-hint
                          :hint="$t('ProjectDesignTabPopup.txt007')"
                          :rules="[ requiredRule ]">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="proj.topProdTypId"
                          :label="$t('word.prodTyp1') + ' *'"
                          :items="prodTypCdLv1List"
                          :rules="[ requiredRule ]"
                          small-chips>
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="proj.prodTypId"
                          :label="$t('word.prodTyp2') + ' *'"
                          :items="prodTypCdLv2List"
                          :rules="[ requiredRule ]"
                          small-chips
                          :readonly="$isNull(proj.topProdTypId)">
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-select
                          v-model="proj.ccCd"
                          :label="$t('word.cc') + ' *'"
                          :items="ccCdList"
                          :rules="[ requiredRule ]"
                          small-chips>
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="proj.rlsAmtRt"
                          :label="$t('word.rlsAmtRt') + ' *'"
                          maxlength="3"
                          suffix="(%)"
                          :rules="[ requiredRule, numericRule ]">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="proj.estmCnsmAmt"
                          :label="$t('word.estmCnsmAmt') + ' *'"
                          maxlength="12"
                          :suffix="currency"
                          :rules="proj.ccCd === 'CCKRW0' ? [ requiredRule, numericRule ] : [ requiredRule, numericDotRule ]">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="proj.estmRlsAmt"
                          :label="$t('word.estmRlsAmt') + ' *'"
                          maxlength="12"
                          :suffix="currency"
                          disabled>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="proj.rlsPlanYm"
                          :label="$t('word.rlsPlanYm') + ' *'"
                          maxlength="6"
                          persistent-hint
                          :hint="$t('ProjectDesignTabPopup.txt008')"
                          :rules="[ requiredRule, numericRule ]">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="proj.prodSaleNtnCdList"
                          :label="$t('word.saleNtn') + ' *'"
                          :items="prodSaleNtnCdList"
                          multiple
                          small-chips
                          persistent-hint
                          :hint="$t('ProjectDesignTabPopup.txt009')"
                          :rules="[ requiredRule ]">
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="proj.prodSaleChnlCdList"
                          :label="$t('word.saleChnl') + ' *'"
                          :items="prodSaleChnlCdList"
                          multiple
                          small-chips
                          persistent-hint
                          :hint="$t('ProjectDesignTabPopup.txt009')"
                          :rules="[ requiredRule ]">
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="proj.prodSprtLangCdList"
                          :label="$t('word.sprtLang') + ' *'"
                          :items="prodSprtLangCdList"
                          multiple
                          small-chips
                          persistent-hint
                          :hint="$t('ProjectDesignTabPopup.txt009')"
                          :rules="[ requiredRule ]">
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="proj.prodTgtAgeCdList"
                          :label="$t('word.tgtAge') + ' *'"
                          :items="prodTgtAgeCdList"
                          multiple
                          small-chips
                          persistent-hint
                          :hint="$t('ProjectDesignTabPopup.txt009')"
                          :rules="[ requiredRule ]">
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="proj.prodTgtSexCdList"
                          :label="$t('word.tgtSex') + ' *'"
                          :items="prodTgtSexCdList"
                          multiple
                          small-chips
                          persistent-hint
                          :hint="$t('ProjectDesignTabPopup.txt009')"
                          :rules="[ requiredRule ]">
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-tab-item>
              <!-- 디자인검수 정보 -->
              <v-tab-item key="design" eager>
                <v-form ref="frm2">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        v-model="dsgnInsp.inspApplCnts"
                        :label="$t('word.inspApplCnts') + ' *'"
                        filled
                        rows="5"
                        :rules="[ requiredRule, length2000Rule ]">
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <!-- 첨부 파일 -->
                  <v-card outlined min-height="440px">
                    <title-bar :title="`${$t('word.atchFile')} (${inspFileList.length})`" sub>
                      <template v-slot:action>
                        <v-btn small tile color="secondary" :ripple="false" @click="showInspectionFileRegistPopup = true">{{ $t('word.registFile') }}</v-btn>
                      </template>
                    </title-bar>
                    <v-divider></v-divider>
                    <template v-if="inspFileList.length > 0">
                      <v-list three-line max-height="372px" class="pt-0 overflow-y-auto">
                        <template v-for="(inspFile, index) in inspFileList">
                          <v-list-item :key="index">
                            <v-list-item-content>
                              <v-row>
                                <v-col cols="12" md="4">
                                  <div class="d-flex justify-start">
                                    <span class="font-weight-black text-h4 primary--text text--lighten-2 mt-2 mr-4">{{ $lpad(index + 1, 2, '0') }}</span>
                                    <template v-if="$isNull(tumbDataList[index])">
                                      <v-avatar size="56" color="grey lighten-3" class="mr-4">
                                        <v-icon>mdi-paperclip</v-icon>
                                      </v-avatar>
                                    </template>
                                    <template v-else>
                                      <v-avatar size="56" class="mr-4">
                                        <v-img :src="`data:image/${inspFile.fileExtNm};base64,${tumbDataList[index]}`"></v-img>
                                      </v-avatar>
                                    </template>
                                    <v-select
                                      v-model="inspFile.inspFileDivCd"
                                      :label="$t('word.fileDiv') + ' *'"
                                      :items="inspFileDivCdList"
                                      filled
                                      small-chips
                                      :rules="[ requiredRule ]">
                                    </v-select>
                                  </div>
                                </v-col>
                                <v-col cols="12" md="8">
                                  <v-lazy>
                                    <v-textarea
                                      v-model="inspFile.inspApplCnts"
                                      :label="inspFile.mngFileNm"
                                      filled
                                      rows="1"
                                      maxlength="2000"
                                      auto-grow
                                      hide-details>
                                    </v-textarea>
                                  </v-lazy>
                                </v-col>
                              </v-row>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn icon color="error" :ripple="false" @click.stop="handleFileDeleteClicked(index)" class="mt-2"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
                            </v-list-item-action>
                          </v-list-item>
                          <v-divider
                            :key="`line-${index}`">
                          </v-divider>
                        </template>
                      </v-list>
                    </template>
                    <template v-else>
                      <v-card-text class="text-center">
                        {{ $t('msg.notExistsFile') }}
                      </v-card-text>
                    </template>
                  </v-card>
                </v-container>
                </v-form>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions class="justify-end align-end">
            <v-btn text large color="secondary" @click="handleCloseClicked">{{ $t('word.close') }}</v-btn>
            <v-btn v-if="isLicensee" text large color="info" @click="handleSaveDraftClicked">{{ $t('word.draftSave') }}</v-btn>
            <v-btn v-if="modifying && isLicensee" text large color="primary" @click="handleRequestForApprovalClicked">{{ $t('word.requestApv') }}</v-btn>
            <v-btn v-if="modifying && isLicensee" text large color="error" @click="handleDeleteClicked">{{ $t('word.delete') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
    <!-- 계약 목록 팝업 -->
    <contract-list-popup
      v-if="showContractListPopup"
      :dialog.sync="showContractListPopup"
      :cntr-id.sync="proj.cntrId"
      :cntr-no.sync="proj.cntrNo">
    </contract-list-popup>
    <!-- 첨부 파일 등록 팝업 -->
    <inspection-file-regist-popup
      v-if="showInspectionFileRegistPopup"
      :dialog.sync="showInspectionFileRegistPopup"
      :comp-id="proj.compId"
      :proj-cd="proj.projCd"
      @item-changed="handleFileAdded">
    </inspection-file-regist-popup>
  </div>
</template>

<script>
import { forms } from '@/mixins/formMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'
import ContractListPopup from '@/views/contract/popup/ContractListPopup'
import InspectionFileRegistPopup from '@/views/project/popup/InspectionFileRegistPopup'

export default {
  name: 'ProjectDesignTabPopup',
  mixins: [forms, searches, stores],
  components: {
    TitleBar,
    ContractListPopup,
    InspectionFileRegistPopup
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    projCd: {
      type: String,
      required: false
    }
  },
  mounted () {
    if (!this.$isNull(this.projCd)) {
      this.proj.projCd = this.projCd
      this.restoreProdTypIdOnce = true
    }
    // 공통코드 조회 (프로젝트단계, 통화코드, 판매국가, 판매채널, 지원언어, 대상연령, 대상성별, 검수파일구분 순)
    this.$getCodes(['PT0000', 'CC0000', 'ST0000', 'SC0000', 'SL0000', 'SA0000', 'SS0000', 'IF0000'], (result) => {
      this.projStgCdList = this.$toSelectableCodes(result.PT0000)
      this.ccCdList = this.$toSelectableCodes(result.CC0000)
      this.prodSaleNtnCdList = this.$toSelectableCodes(result.ST0000, true)
      this.prodSaleChnlCdList = this.$toSelectableCodes(result.SC0000, true)
      this.prodSprtLangCdList = this.$toSelectableCodes(result.SL0000, true)
      this.prodTgtAgeCdList = this.$toSelectableCodes(result.SA0000, true)
      this.prodTgtSexCdList = this.$toSelectableCodes(result.SS0000, true)
      this.inspFileDivCdList = this.$toSelectableCodes(result.IF0000)
      // 상품유형 1단계 조회
      this.$getProductTypes(1, null, (result) => {
        this.prodTypCdLv1List = this.$toSelectableProductTypes(result.prodTypList)
        // 프로젝트 디자인검수 조회
        this.fetchItem()
      })
    })
  },
  watch: {
    proj: {
      deep: true,
      handler () {
        this.somethingChanged = true
      }
    },
    dsgnInsp: {
      deep: true,
      handler () {
        this.somethingChanged = true
      }
    },
    inspFileList: {
      deep: true,
      handler () {
        this.somethingChanged = true
      }
    },
    'proj.topProdTypId' (to) {
      if (this.$isNull(to)) {
        this.prodTypCdLv2List = []
        this.proj.prodTypId = null
      } else {
        this.$getProductTypes(2, to, (result) => {
          this.prodTypCdLv2List = this.$toSelectableProductTypes(result.prodTypList)
          // IMPORTANT : 작성중 프로젝트를 불러오기 하는 경우,
          // 프로젝트 조회 후 상위프로젝트ID가 변경되면서 조회된 프로젝트유형ID 값을 초기화 하지 않도록 처리한다. (최초 1회에 한함)
          if (this.restoreProdTypIdOnce) {
            this.restoreProdTypIdOnce = false
          } else {
            this.proj.prodTypId = null
          }
        })
      }
    },
    'proj.ccCd' () {
      this.calculateEstmRlsAmt()
    },
    'proj.rlsAmtRt' () {
      this.calculateEstmRlsAmt()
    },
    'proj.estmCnsmAmt' () {
      this.calculateEstmRlsAmt()
    }
  },
  data () {
    return {
      projStgCdList: [],
      ccCdList: [],
      prodSaleNtnCdList: [],
      prodSaleChnlCdList: [],
      prodSprtLangCdList: [],
      prodTgtAgeCdList: [],
      prodTgtSexCdList: [],
      inspFileDivCdList: [],
      prodTypCdLv1List: [],
      prodTypCdLv2List: [],
      proj: {
        projStgCd: null,
        topProdTypId: null,
        prodTypId: null,
        ccCd: null,
        rlsAmtRt: 40,
        prodSaleNtnCdList: null,
        prodSaleChnlCdList: null,
        prodSprtLangCdList: null,
        prodTgtAgeCdList: null,
        prodTgtSexCdList: null
      },
      dsgnInsp: {},
      inspFileList: [],
      tumbDataList: [],
      tab: null,
      restoreProdTypIdOnce: false,
      somethingChanged: false,
      showContractListPopup: false,
      showInspectionFileRegistPopup: false
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    },
    modifying () {
      if (!this.$isNull(this.projCd)) {
        return true
      } else {
        return !this.$isNull(this.proj.projCd)
      }
    },
    title () {
      if (this.$isNull(this.proj.projCd)) {
        return this.$t('word.registProj')
      } else {
        return this.modifying ? `${this.$t('word.registProj')} : ${this.proj.projCd}` : this.$t('word.registProj')
      }
    },
    currency () {
      if (this.$isNull(this.proj.ccCd)) {
        return null
      } else {
        return `(${this.proj.ccCd.substring(2, 5)})`
      }
    }
  },
  methods: {
    // 데이터조회
    fetchItem () {
      if (!this.modifying) {
        return
      }
      this.$http.get(`/api/1/projects/${this.proj.projCd}/draft`)
        .then((response) => {
          const projUnion = response.data.result.projUnion
          this.proj = projUnion.proj
          this.dsgnInsp = this.$isNull(projUnion.dsgnInsp) ? {} : projUnion.dsgnInsp
          this.inspFileList = projUnion.inspFileList
          this.tumbDataList = response.data.result.tumbDataList
          // 값 할당후 watch 가 뒤늦게 실행되므로 nextTick 에서 변경사항이 없음을 할당한다.
          this.$nextTick(() => {
            this.somethingChanged = false
          })
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    calculateEstmRlsAmt () {
      const ccCd = this.proj.ccCd
      const rlsAmtRt = this.proj.rlsAmtRt
      const estmCnsmAmt = this.proj.estmCnsmAmt
      if (this.$isNull(ccCd) || this.$isEmpty(rlsAmtRt) || this.$isEmpty(estmCnsmAmt) || isNaN(rlsAmtRt) || isNaN(estmCnsmAmt)) {
        this.proj.estmRlsAmt = null
        return
      }
      let estmRlsAmt = 0
      if (ccCd === 'CCKRW0') {
        // 원화인 경우 : 소수점 없음
        estmRlsAmt = Math.ceil(estmCnsmAmt * (rlsAmtRt / 100))
      } else {
        // 원화 외 통화인 경우 : 소수점 둘째자리까지 유지
        estmRlsAmt = Math.ceil(estmCnsmAmt * (rlsAmtRt / 100) * 100) / 100
        estmRlsAmt.toFixed(2)
      }
      this.proj.estmRlsAmt = estmRlsAmt
    },
    handleSaveDraftClicked () {
      if (!this.$refs.frm1.validate(true)) {
        this.tab = 0
        return
      }
      if (this.modifying) {
        if (!this.$refs.frm2.validate(true)) {
          this.tab = 1
          return
        }
        const item = {
          proj: this.proj,
          dsgnInsp: this.dsgnInsp,
          inspFileList: this.inspFileList
        }
        this.$http.put(`/api/1/projects/${this.proj.projCd}/draft`, item)
          .then((response) => {
            this.$toasted.global.success(this.$t('msg.draftSaved'))
            const projUnion = response.data.result.projUnion
            this.proj = projUnion.proj
            this.dsgnInsp = projUnion.dsgnInsp
            this.inspFileList = projUnion.inspFileList === null ? [] : projUnion.inspFileList
            // 값 할당후 watch 가 뒤늦게 실행되므로 nextTick 에서 변경사항이 없음을 할당한다.
            this.$nextTick(() => {
              this.somethingChanged = false
            })
          })
          .catch((error) => {
            this.$showError(error)
          })
      } else {
        this.$http.post('/api/1/projects', this.proj)
          .then((response) => {
            this.$toasted.global.success(this.$t('msg.draftSaved'))
            const projUnion = response.data.result.projUnion
            this.proj = projUnion.proj
            this.dsgnInsp = projUnion.dsgnInsp
            this.inspFileList = projUnion.inspFileList === null ? [] : projUnion.inspFileList
            // 값 할당후 watch 가 뒤늦게 실행되므로 nextTick 에서 변경사항이 없음을 할당한다.
            this.$nextTick(() => {
              this.somethingChanged = false
            })
          })
          .catch((error) => {
            this.$showError(error)
          })
      }
    },
    handleRequestForApprovalClicked () {
      if (!this.modifying) {
        return
      }
      if (!this.$refs.frm1.validate(true)) {
        this.tab = 0
        return
      }
      if (this.$isEmpty(this.dsgnInsp.inspApplCnts)) {
        this.$toasted.global.warn(this.$t('ProjectDesignTabPopup.txt001'))
        this.tab = 1
        return
      }
      if (this.$isEmptyArray(this.inspFileList)) {
        this.$toasted.global.warn(this.$t('ProjectDesignTabPopup.txt002'))
        this.tab = 1
        return
      }
      if (!confirm(this.$t('ProjectDesignTabPopup.txt003'))) {
        return
      }
      const item = {
        proj: this.proj,
        dsgnInsp: this.dsgnInsp,
        inspFileList: this.inspFileList
      }
      this.$http.put(`/api/1/projects/${this.proj.projCd}/request`, item)
        .then((response) => {
          this.$toasted.global.success(this.$t('msg.requestedForApproval'))
          const projUnion = response.data.result.projUnion
          this.$emit('item-changed', projUnion)
          this.dialogModel = false
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleDeleteClicked () {
      if (!this.modifying || this.$isNull(this.proj.projCd)) {
        return
      }
      if (!confirm(this.$t('ProjectDesignTabPopup.txt004', [this.proj.projNm]))) {
        return
      }
      this.$http.delete(`/api/1/projects/${this.proj.projCd}`)
        .then(() => {
          this.$toasted.global.success(this.$t('msg.deleted'))
          this.dialogModel = false
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleFileAdded (obj) {
      // 검수번호 할당
      obj.inspFile.inspNo = this.dsgnInsp.inspNo
      this.inspFileList.push(obj.inspFile)
      this.tumbDataList.push(obj.tumbData)
    },
    handleFileDeleteClicked (index) {
      if (index >= this.inspFileList.length) {
        return
      }
      if (!confirm(this.$t('ProjectDesignTabPopup.txt005'))) {
        return
      }
      this.inspFileList.splice(index, 1)
      this.tumbDataList.splice(index, 1)
    },
    handleCloseClicked () {
      if (this.somethingChanged) {
        if (!confirm(this.$t('msg.detectChange'))) {
          return
        }
      }
      this.dialogModel = false
    }
  }
}
</script>
