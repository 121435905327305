<template>
  <div>
    <v-card outlined>
      <!-- 타이틀바 -->
      <title-bar :title="$t('word.atchFile')" sub>
        <template v-slot:action>
          <v-menu
            offset-y
            bottom left
            tile
            min-width="156">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small tile color="secondary"  :ripple="false" v-bind="attrs" v-on="on">
                {{ $t('word.menu') }}<v-icon right dark>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="uploadable" @click="showContractFileRegistPopup = true">
                <v-list-item-avatar>
                  <v-icon color="primary">mdi-upload</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="primary--text">{{ $t('word.registFile') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="handlePreviewClicked">
                <v-list-item-avatar>
                  <v-icon>mdi-eye-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('word.preview') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="handleDownloadClicked">
                <v-list-item-avatar>
                  <v-icon>mdi-download</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('word.download') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="modifiable" @click="handleModifyClicked">
                <v-list-item-avatar>
                  <v-icon>mdi-pencil</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('word.modifyInfo') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="deletable" @click="handleDeleteClicked">
                <v-list-item-avatar>
                  <v-icon color="error">mdi-delete</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="error--text">{{ $t('word.delete') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </title-bar>
      <v-divider></v-divider>
      <!-- 목록 -->
      <v-list class="py-0">
        <v-list-item-group
          v-model="selectedItem"
          color="primary">
          <template v-for="(item, index) in items">
            <v-list-item
              :key="`${item.cntrId}-${item.cntrFileNo}`"
              :value="item"
              two-line>
              <template v-slot:default>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <span>{{ item.cntrFileDivCdNm }}</span>
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ item.mngFileNm }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon rounded :ripple="false" @click.stop="handleInlineDownloadClicked(item)"><v-icon>mdi-download</v-icon></v-btn>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-divider
              :key="index"
              v-if="index < items.length - 1">
            </v-divider>
          </template>
        </v-list-item-group>
        <template v-if="items.length === 0">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('msg.notExistsFile') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <!-- 페이징 -->
      <paging-bar
        :page-no.sync="comPageNo"
        :page-cnt.sync="comPageCnt"
        :visible-page-cnt="5"
        :item-cnt-per-page.sync="comItemCntPerPage"
        :items-per-page="[5]"
        row-justify="center"
        hide-item-cnt-per-page
        @paging-changed="fetchList">
      </paging-bar>
    </v-card>
    <!-- 첨부 파일 등록 팝업 -->
    <contract-file-regist-popup
      v-if="showContractFileRegistPopup"
      :dialog.sync="showContractFileRegistPopup"
      :cntr-id="cntrId"
      @item-changed="fetchList">
    </contract-file-regist-popup>
    <!-- 첨부 파일 수정 팝업 -->
    <contract-file-modify-popup
      v-if="showContractFileModifyPopup"
      :dialog.sync="showContractFileModifyPopup"
      :cntr-id="cntrId"
      :cntr-file-no="$isNull(selectedItem) ? null : selectedItem.cntrFileNo"
      @item-changed="fetchList">
    </contract-file-modify-popup>
    <!-- 미리보기 팝업 -->
    <preview-image-popup
      v-if="showPreviewImagePopup"
      :dialog.sync="showPreviewImagePopup"
      :url="previewPopupUrl">
    </preview-image-popup>
    <!-- pdf 미리보기 팝업 -->
    <preview-pdf-popup
      v-if="showPreviewPdfPopup"
      :dialog.sync="showPreviewPdfPopup"
      :url="previewPopupUrl">
    </preview-pdf-popup>
  </div>
</template>

<script>
import { pages } from '@/mixins/pageMixin'
import { searches } from '@/mixins/searchMixin'
import TitleBar from '@/components/TitleBar'
import PagingBar from '@/components/PagingBar'
import ContractFileRegistPopup from '@/views/contract/popup/ContractFileRegistPopup'
import ContractFileModifyPopup from '@/views/contract/popup/ContractFileModifyPopup'
import PreviewImagePopup from '@/components/PreviewImagePopup'
import PreviewPdfPopup from '@/components/PreviewPdfPopup'
import fileDownload from 'js-file-download'

export default {
  name: 'ContractFileListUnit',
  mixins: [pages, searches],
  components: {
    TitleBar,
    PagingBar,
    ContractFileRegistPopup,
    ContractFileModifyPopup,
    PreviewImagePopup,
    PreviewPdfPopup
  },
  props: {
    cntrId: {
      type: String,
      required: false
    },
    uploadable: {
      type: Boolean,
      required: false,
      default: true
    },
    modifiable: {
      type: Boolean,
      required: false,
      default: true
    },
    deletable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  mounted () {
    // 페이지별 아이템 건수를 5건으로 조정
    this.comItemCntPerPage = 5
    this.fetchList()
  },
  data () {
    return {
      // 첨부파일 목록
      items: [],
      // 첨부파일 목록에서 선택된 아이템
      selectedItem: null,
      previewPopupUrl: null,
      showContractFileRegistPopup: false,
      showContractFileModifyPopup: false,
      showPreviewImagePopup: false,
      showPreviewPdfPopup: false
    }
  },
  methods: {
    // 목록 조회
    fetchList (shouldClear) {
      if (this.$isNull(this.cntrId)) {
        return
      }
      // 페이지번호 초기화
      if (shouldClear) {
        this.comPageNo = 1
      }
      this.selectedItem = null
      this.$http.get(this.toRequestUrl(`/api/1/contracts/${this.cntrId}/file`))
        .then((response) => {
          const page = response.data.result.cntrFilePage
          this.bindComPage(page)
          this.items = page.list
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handlePreviewClicked () {
      if (this.$isNull(this.selectedItem)) {
        this.$toasted.global.warn(this.$t('msg.choosePreviewFile'))
        return
      }
      let extNm = this.selectedItem.fileExtNm
      if (this.$isNull(extNm)) {
        this.$toasted.global.warn(this.$t('msg.notSupportedPreview'))
        return
      }
      extNm = extNm.toLowerCase()
      if (extNm !== 'png' && extNm !== 'jpg' && extNm !== 'jpeg' && extNm !== 'pdf') {
        this.$toasted.global.warn(this.$t('msg.notSupportedPreview'))
        return
      }
      this.previewPopupUrl = `/api/1/contracts/${this.cntrId}/file/${this.selectedItem.cntrFileNo}/preview`
      if (extNm === 'pdf') {
        this.showPreviewPdfPopup = true
      } else {
        this.showPreviewImagePopup = true
      }
    },
    handleInlineDownloadClicked (item) {
      this.selectedItem = item
      this.$nextTick(() => {
        this.handleDownloadClicked()
      })
    },
    handleDownloadClicked () {
      if (this.$isNull(this.selectedItem)) {
        this.$toasted.global.warn(this.$t('msg.chooseDownloadFile'))
        return
      }
      this.$http.get(`/api/1/contracts/${this.cntrId}/file/${this.selectedItem.cntrFileNo}/download`, { responseType: 'blob' })
        .then((response) => {
          // 파일이름 설정
          const contentDisposition = response.headers['content-disposition']
          let downFileNm = null
          if (!this.$isNull(contentDisposition)) {
            const idx = contentDisposition.indexOf(';filename*=UTF-8\'\'')
            if (idx >= 0) {
              downFileNm = decodeURIComponent(contentDisposition.substring(idx + 18))
            }
          }
          if (this.$isNull(downFileNm)) {
            downFileNm = this.fileNm
          }
          fileDownload(response.data, downFileNm)
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleModifyClicked () {
      if (this.$isNull(this.selectedItem)) {
        this.$toasted.global.warn(this.$t('msg.chooseModifyFile'))
        return
      }
      this.showContractFileModifyPopup = true
    },
    handleDeleteClicked () {
      if (this.$isNull(this.selectedItem)) {
        this.$toasted.global.warn(this.$t('msg.chooseDeleteFile'))
        return
      }
      if (!confirm(this.$t('msg.confirmDelete', [this.selectedItem.mngFileNm]))) {
        return
      }
      this.$http.delete(`/api/1/contracts/${this.cntrId}/file/${this.selectedItem.cntrFileNo}`)
        .then(() => {
          this.$toasted.global.success(this.$t('msg.deleted'))
          this.fetchList(true)
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>
