<template>
  <div>
    <v-divider v-if="!hideTopDivider"></v-divider>
    <v-toolbar flat>
      <v-pagination
        v-model="pageNoModel"
        :length="pageCntModel"
        :total-visible="visiblePageCnt"
        :disabled="disabled"
        light
        @input="handlePagingChanged">
      </v-pagination>
      <template v-if="!hideItemCntPerPage">
        <v-spacer></v-spacer>
        <v-btn-toggle
          v-model="itemCntPerPageModel"
          background-color="white"
          color="primary"
          mandatory
          dense
          group
          @change="handlePagingChanged">
          <template v-for="item in itemsPerPage">
            <v-btn :key="item" :value="item" :disabled="disabled">{{ item }}</v-btn>
          </template>
        </v-btn-toggle>
      </template>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: 'PagingBar',
  props: {
    pageNo: {
      type: Number,
      required: true
    },
    pageCnt: {
      type: Number,
      required: true
    },
    visiblePageCnt: {
      type: Number,
      required: false,
      default: 10
    },
    itemCntPerPage: {
      type: Number,
      required: true
    },
    itemsPerPage: {
      type: Array,
      required: false,
      default: () => { return [10, 20, 100] }
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'white'
    },
    rowJustify: {
      type: String,
      required: false,
      default: 'space-between'
    },
    hideTopDivider: {
      type: Boolean,
      required: false,
      default: false
    },
    hideItemCntPerPage: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    pageNoModel: {
      get () {
        return this.pageNo
      },
      set (value) {
        this.$emit('update:pageNo', value)
      }
    },
    pageCntModel: {
      get () {
        return this.pageCnt
      },
      set (value) {
        this.$emit('update:pageCnt', value)
      }
    },
    itemCntPerPageModel: {
      get () {
        return this.itemCntPerPage
      },
      set (value) {
        this.$emit('update:itemCntPerPage', value)
        // 페이지당 아이템 건수 변경시 현재 페이지도 1로 강제로 설정한다
        this.$emit('update:pageNo', 1)
      }
    }
  },
  methods: {
    handlePagingChanged () {
      this.$emit('paging-changed')
    }
  }
}
</script>
