<template>
  <v-dialog v-model="dialogModel" persistent :max-width="$const.maxWidthLayout.sm" :retain-focus="false">
    <v-card flat>
      <!-- 타이틀바 -->
      <title-bar :title="$t('word.guide')" color="primary" dark>
        <template v-slot:action>
          <v-btn icon rounded large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
        </template>
      </title-bar>
      <!-- 컨텐츠 -->
      <v-card>
        <v-card-text class="pb-0">
          <h3>다음 조건을 만족하는 프로젝트만 증지신청이 가능합니다.</h3>
          <p class="mt-3">
            <span class="ml-1 font-weight-bold">1. 프로젝트 상태 조건</span><br/>
            <ul class="mb-2 grey--text">
              <li>완료 프로젝트</li>
              <li>진행 프로젝트 혹은 수정 프로젝트인 경우 : [ 샘플 ] 혹은 [ 최종샘플 ] 단계의 디자인 검수가 [ 승인 ] 혹은 [ 조건부 승인 ] 된 프로젝트</li>
            </ul>
            <span class="ml-1 font-weight-bold">2. 프로젝트 계약의 계약 기간 조건</span>
            <ul class="grey--text">
              <li>[ 계약시작일 ], [ 계약종료일 ] 이 명시 되지 않은 계약</li>
              <li>현재일이 [ 계약시작일 ] ~ [ 계약종료일 ] 범위에 포함되는 계약</li>
              <li>현재일이 [ 계약시작일 ] ~ [ 계약유효일 ] 범위에 포함되는 계약</li>
            </ul>
          </p>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text large color="secondary" @click="dialogModel = false">{{ $t('word.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import TitleBar from '@/components/TitleBar'

export default {
  name: 'StampNotAppliableReasonPopup',
  components: {
    TitleBar
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    }
  }
}
</script>
