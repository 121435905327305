<template>
  <div>
    <v-card outlined>
      <!-- 타이틀바 -->
      <title-bar :title="title">
        <template v-slot:action>
          <v-btn small tile :min-width="$const.minWidthButton" color="secondary" class="mr-1" @click="handleClearClicked">{{ $t('word.clear') }}</v-btn>
          <v-btn small tile :min-width="$const.minWidthButton" color="secondary" class="mr-1" @click="fetchList(true)">{{ $t('word.search') }}</v-btn>
          <v-btn v-if="isAdmin" small tile :min-width="$const.minWidthButton" color="primary" @click="handleAddClicked">{{ $t('word.add') }}</v-btn>
        </template>
      </title-bar>
      <!-- 검색조건 -->
      <five-field-bar :field-size="5">
        <template v-slot:field-1>
          <date-field
            v-model="strYmd"
            label="가입일 (FROM)"
            background-color="white"
            outlined
            dense
            hide-details>
          </date-field>
        </template>
        <template v-slot:field-2>
          <date-field
            v-model="endYmd"
            label="가입일 (TO)"
            background-color="white"
            outlined
            dense
            hide-details>
          </date-field>
        </template>
        <template v-slot:field-3>
          <v-select
            v-model="auth"
            :items="authList"
            label="권한"
            background-color="white"
            outlined
            dense
            hide-details>
          </v-select>
        </template>
        <template v-slot:field-4>
          <v-select
            v-model="srchDiv"
            :items="srchDivList"
            label="조회 구분"
            background-color="white"
            outlined
            dense
            hide-details>
          </v-select>
        </template>
        <template v-slot:field-5>
          <v-text-field
            v-model="kwd"
            label="검색어"
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @keypress.enter="fetchList(true)">
          </v-text-field>
        </template>
      </five-field-bar>
      <!-- 테이블 -->
      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="items"
        item-key="usrId"
        height="69vh"
        class="text-truncate"
        fixed-header
        show-select
        single-select
        disable-filtering
        disable-pagination
        loading-text=""
        no-data-text=""
        no-results-text=""
        hide-default-footer
        :server-items-length="comTtlCnt"
        :sort-by.sync="comSrtBy"
        :sort-desc.sync="comSrtDesc"
        @click:row="handleRowClicked"
        @update:sort-by="delayedCall(fetchList)"
        @update:sort-desc="delayedCall(fetchList)">
        <template v-slot:item.joinDt="{ value }">
          <td>{{ $timeToShortDate(value) }}</td>
        </template>
        <template v-slot:item.auth="{ value }">
          <td>{{ $getAuthNm(value) }}</td>
        </template>
        <template v-slot:item.modDt="{ value }">
          <td>{{ $timeToMediumDate(value) }}</td>
        </template>
        <template v-slot:body.append="{ headers }">
          <tr>
            <td :colspan="headers.length">
              전체 {{ $formatNumber(comTtlCnt) }}건
            </td>
          </tr>
        </template>
      </v-data-table>
      <!-- 페이징 -->
      <paging-bar
        :page-no.sync="comPageNo"
        :page-cnt.sync="comPageCnt"
        :item-cnt-per-page.sync="comItemCntPerPage"
        @paging-changed="fetchList">
      </paging-bar>
    </v-card>
    <!--  사용자상세 팝업 -->
    <licensor-user-detail-popup
      v-if="showLicensorUserDetailPopup"
      :dialog.sync="showLicensorUserDetailPopup"
      :usr-id="selectedItems.length === 0 ? null : selectedItems[0].usrId"
      @item-changed="handleUserItemChanged">
    </licensor-user-detail-popup>
  </div>
</template>

<script>
import { sorts } from '@/mixins/sortMixin'
import { pages } from '@/mixins/pageMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'
import FiveFieldBar from '@/components/FiveFieldBar'
import DateField from '@/components/DateField'
import PagingBar from '@/components/PagingBar'
import LicensorUserDetailPopup from '@/views/user/popup/LicensorUserDetailPopup'

export default {
  name: 'LicensorUserListView',
  mixins: [sorts, pages, searches, stores],
  components: {
    TitleBar,
    FiveFieldBar,
    DateField,
    PagingBar,
    LicensorUserDetailPopup
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '제목 없음'
    }
  },
  mounted () {
    this.fetchList()
  },
  data () {
    return {
      // 테이블 헤더
      headers: [
        { text: '사용자명', value: 'usrNm' },
        { text: '이메일', value: 'email' },
        { text: '전화번호', value: 'telno', sortable: false },
        { text: '핸드폰번호', value: 'hnpno', sortable: false },
        { text: '가입일', value: 'joinDt' },
        { text: '권한', value: 'auth', sortable: false },
        { text: '수정일시', value: 'modDt', sortable: false }
      ],
      // 조회결과 목록
      items: [],
      // 검색조건
      strYmd: null,
      endYmd: null,
      auth: null,
      srchDiv: 'usrNm',
      kwd: null,
      // 선택 아이템
      selectedItems: [],
      // 라이선서 사용자 상세 팝업 오픈 여부
      showLicensorUserDetailPopup: false
    }
  },
  computed: {
    authList () {
      return [
        { text: '- 선택 -', value: null },
        { text: '관리자', value: 'ROLE_ADMIN' },
        { text: '검수자', value: 'ROLE_REVIEWER' }
      ]
    },
    srchDivList () {
      return [
        { text: '이메일', value: 'email' },
        { text: '사용자명', value: 'usrNm' },
        { text: '전화번호', value: 'telno' },
        { text: '핸드폰번호', value: 'hnpno' }
      ]
    }
  },
  methods: {
    // 목록조회
    fetchList (shouldClear) {
      // 검색 초기화
      if (shouldClear) {
        this.comPageNo = 1
        this.selectedItems = []
      }
      // 검색 파라미터 할당
      this.clearComParam()
      this.appendParam('strYmd', this.strYmd)
      this.appendParam('endYmd', this.endYmd)
      this.appendParam('auth', this.auth)
      this.appendParam('compDivCd', 'CD0100') // 라이선서
      if (!this.$isEmpty(this.kwd)) {
        this.appendParam(this.srchDiv, this.kwd)
      }
      // 검색
      this.$http.get(this.toRequestUrl('/api/1/users'))
        .then((response) => {
          const page = response.data.result.usrCompPage
          this.bindComPage(page)
          this.items = page.list
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 초기화 버튼 클릭시
    handleClearClicked () {
      this.strYmd = null
      this.endYmd = null
      this.auth = null
      this.srchDiv = 'usrNm'
      this.kwd = null
      this.fetchList(true)
    },
    // 추가 버튼 클릭시
    handleAddClicked () {
      this.selectedItems = []
      this.showLicensorUserDetailPopup = true
    },
    // 테이블 로우 클릭시
    handleRowClicked (item) {
      this.selectedItems = [item]
      this.showLicensorUserDetailPopup = true
    },
    // 사용자상세 팝업 데이터 변경시
    handleUserItemChanged (item) {
      if (this.$isNull(item)) {
        this.fetchList(true)
      } else {
        this.fetchList()
      }
    }
  }
}
</script>
