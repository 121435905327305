<template>
  <div>
    <v-card outlined>
    <!-- 테이블 -->
      <!-- 타이틀바 -->
      <title-bar :title="title" size="500">
        <template v-slot:action>
          <v-btn v-if="isAdmin" small tile :min-width="$const.minWidthButton" color="primary" @click="handleAddClicked">{{ $t('word.add') }}</v-btn>
        </template>
      </title-bar>
      <v-divider></v-divider>
      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="items"
        item-key="prodTypId"
        height="31vh"
        class="text-truncate"
        fixed-header
        show-select
        single-select
        disable-filtering
        disable-pagination
        loading-text=""
        no-data-text=""
        no-results-text=""
        hide-default-footer
        @click:row="handleRowClicked">
        <template v-slot:item.modDt="{ value }">
          <td>{{ $timeToMediumDate(value) }}</td>
        </template>
        <template v-slot:body.append="{ headers }">
          <tr>
            <td :colspan="headers.length">
              {{ $t('word.total') }} {{items.length}} {{ $t('word.count') }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <!--  상품유형 2단계 상세 팝업 -->
    <productType-level2-detail-popup
      v-if="showProductTypeLevel2DetailPopup"
      :dialog.sync="showProductTypeLevel2DetailPopup"
      :prod-typ-id="selectedItems.length === 0 ? null : selectedItems[0].prodTypId"
      :top-prod-typ-id="this.topProdTypId"
      :top-prod-typ-cd="this.topProdTypCd"
      :top-prod-typ-nm="this.topProdTypNm"
      :top-prod-typ-eng-nm="this.topProdTypEngNm"
      @item-changed="handleProductTypeItemChanged">
    </productType-level2-detail-popup>
  </div>
</template>

<script>
import { sorts } from '@/mixins/sortMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'
import ProductTypeLevel2DetailPopup from '@/views/productType/popup/ProductTypeLevel2DetailPopup'

export default {
  name: 'ProductTypeLevel2ListUnit',
  mixins: [sorts, stores],
  components: {
    TitleBar,
    ProductTypeLevel2DetailPopup
  },
  props: {
    topProdTypId: {
      type: String,
      required: false
    },
    topProdTypCd: {
      type: String,
      required: false
    },
    topProdTypNm: {
      type: String,
      required: false
    },
    topProdTypEngNm: {
      type: String,
      required: false
    }
  },
  watch: {
    topProdTypId (to, from) {
      this.fetchList()
    }
  },
  mounted () {
    this.fetchList()
  },
  computed: {
    title () {
      return this.topProdTypNm + ' (' + this.topProdTypEngNm + ')' + ' 의 하위상품유형'
    }
  },
  data () {
    return {
      // 테이블 헤더
      headers: [
        { text: this.$t('word.prodTypCd'), value: 'prodTypCd' },
        { text: this.$t('word.prodTypNm'), value: 'prodTypNm' },
        { text: this.$t('word.prodTypEngNm'), value: 'prodTypEngNm' },
        { text: this.$t('word.prodTypLv'), value: 'prodTypLv', sortable: false },
        { text: this.$t('word.useYn'), value: 'useYn', sortable: false },
        { text: this.$t('word.srtOrdno'), value: 'srtOrdno' },
        { text: this.$t('word.modDt'), value: 'modDt', sortable: false },
        { text: '', value: 'action', width: '1px', sortable: false }
      ],
      // 조회결과 목록
      items: [],
      // 선택 아이템
      selectedItems: [],
      // 상품유형 2단계 상세 팝업 오픈 여부
      showProductTypeLevel2DetailPopup: false
    }
  },
  methods: {
    // 목록조회
    fetchList () {
      if (this.$isNull(this.topProdTypId)) {
        return
      }
      this.$http.get(`/api/1/products/level2?prodTypLv=2&topProdTypId=${this.topProdTypId}`)
        .then((response) => {
          this.items = response.data.result.prodTypList
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 추가 버튼 클릭시
    handleAddClicked () {
      this.selectedItems = []
      this.showProductTypeLevel2DetailPopup = true
    },
    // 테이블 로우 클릭시
    handleRowClicked (item) {
      this.selectedItems = [item]
      this.showProductTypeLevel2DetailPopup = true
    },
    // 상품유형상세 팝업 데이터 변경시
    handleProductTypeItemChanged () {
      this.fetchList()
    }
  }
}
</script>
