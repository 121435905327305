<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="3">
          <v-card>
            <template v-if="!hideTitle">
              <title-bar :title="$t('word.mypage')"></title-bar>
              <v-divider></v-divider>
            </template>
            <v-card-text>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6">{{ item.email }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-body-1">
                      {{ item.compNm }}
                      <v-btn v-if="isLicensee" x-small color="secondary" class="ml-1 mb-1" @click="showCompanyDetailPopup = true">{{ $t('word.modifyInfo') }}</v-btn>
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-body-2">{{ this.$getAuthNm(item.auth) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <template v-if="!hideGoToMainButton">
              <v-card-actions>
                <v-btn block large dark color="primary" @click="handleMainClicked">{{ $t('word.goToMain') }}</v-btn>
              </v-card-actions>
            </template>
          </v-card>
        </v-col>
        <v-col cols="12" md="5">
          <v-card class="pa-8">
            <v-card-title class="justify-center">
              <v-avatar size="60" color="grey lighten-1">
                <v-icon x-large dark>
                  mdi-account
                </v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text class="mt-5">
              <v-form ref="frm">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="item.usrNm"
                        :label="$t('word.usrNm') + ' *'"
                        maxlength="100"
                        :rules="[ requiredRule, nameRule ]">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="item.usrEngNm"
                        :label="$t('word.usrEngNm')"
                        maxlength="100"
                        :rules="[ nameRule ]">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="item.telno"
                        :label="isLicensee ? $t('word.telno') + ' *' : $t('word.telno')"
                        maxlength="20"
                        persistent-hint
                        :hint="$t('msg.guideTelno')"
                        :rules="isLicensee ? [ requiredRule, telnoRule ] : [ telnoRule ]">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="item.hnpno"
                        :label="isLicensee ? $t('word.hnpno') + ' *' : $t('word.hnpno')"
                        maxlength="20"
                        persistent-hint
                        :hint="$t('msg.guideHnpno')"
                        :rules="isLicensee ? [ requiredRule, telnoRule ] : [ telnoRule ]">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions v-if="!hideActionButton" class="justify-end">
              <v-btn text x-large color="secondary" @click="showPasswordChangePopup = true">{{ $t('word.pwdChng') }}</v-btn>
              <v-btn text x-large color="primary" @click="handleSaveClicked">{{ $t('word.save') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!--  회사 상세 팝업 -->
    <company-detail-popup
      v-if="showCompanyDetailPopup"
      :dialog.sync="showCompanyDetailPopup"
      :comp-id="item.compId"
      :fixed-title="item.compNm"
      @item-changed="handleCompanyItemChanged">
    </company-detail-popup>
    <!--  비밀번호변경 팝업 -->
    <password-change-popup
      v-if="showPasswordChangePopup"
      :dialog.sync="showPasswordChangePopup"
      :usr-id="this.usrId">
    </password-change-popup>
  </div>
</template>

<script>
import { forms } from '@/mixins/formMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar.vue'
import CompanyDetailPopup from '@/views/company/popup/CompanyDetailPopup'
import PasswordChangePopup from '@/views/user/popup/PasswordChangePopup'

export default {
  name: 'MyPageView',
  mixins: [forms, stores],
  components: {
    TitleBar,
    CompanyDetailPopup,
    PasswordChangePopup
  },
  props: {
    hideTitle: {
      type: Boolean,
      requierd: false,
      default: false
    },
    hideActionButton: {
      type: Boolean,
      required: false,
      default: false
    },
    hideGoToMainButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  mounted () {
    this.usrId = this.getLoginUsrId()
    this.fetchItem()
  },
  data () {
    return {
      usrId: null,
      item: {},
      // 회사 상세 팝업 오픈 여부
      showCompanyDetailPopup: false,
      // 비밀번호 변경 팝업 오픈 여부
      showPasswordChangePopup: false
    }
  },
  methods: {
    // 데이터 조회
    fetchItem () {
      if (this.$isNull(this.usrId)) {
        return
      }
      this.$http.get(`/api/1/users/${this.usrId}`)
        .then((response) => {
          this.item = response.data.result.usr
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleSaveClicked (callback) {
      if (!this.$refs.frm.validate(true)) {
        return
      }
      this.$http.put(`/api/1/users/${this.item.usrId}/info`, this.item)
        .then((response) => {
          this.$toasted.global.success(this.$t('msg.saved'))
          this.item = response.data.result.usr
          if (!this.$isNull(callback)) {
            callback()
          }
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 회사 팝업 데이터 변경시
    handleCompanyItemChanged (item) {
      if (!this.$isNull(item)) {
        this.item.compNm = item.compNm
      }
    },
    // 메인 화면으로 버튼 클릭 시
    handleMainClicked () {
      this.handleSaveClicked(() => {
        if (this.isLicensor) {
          this.$router.replace('/main/project/new')
          return
        }
        this.$http.get(`/api/1/users/${this.item.usrId}/check`)
          .then((response) => {
            const requiredList = response.data.result.requiredList
            if (this.$isEmptyArray(requiredList)) {
              this.$router.replace('/main/project/new')
            } else {
              this.$toasted.global.info(requiredList[0])
            }
          })
          .catch((error) => {
            this.$showError(error)
          })
      })
    }
  }
}
</script>
