<template>
<v-container fluid class="fill-height">
  <v-row justify="center">
    <v-col cols="12" sm="8" lg="4">
      <div class="text-center">
        <v-chip small color="primary" class="font-weight-bold">IPTOWN</v-chip>
        <div class="mt-2">
          <h1>{{ $t('word.liceUsrJoin') }}</h1>
        </div>
        <p class="mx-6 mt-4 mb-8 grey--text text--darken-1">
          {{ $t('UserJoinPasswordView.txt001', [item.usrNm]) }} <br/>
          {{ $t('UserJoinPasswordView.txt002') }}
        </p>
      </div>
      <v-card outlined elevation="0" class="pa-8">
        <v-card-title class="justify-center">
          <v-avatar size="60" color="grey lighten-1">
            <v-icon x-large dark>
              mdi-lock
            </v-icon>
          </v-avatar>
        </v-card-title>
        <v-card-text class="text-center mt-10">
          <v-form ref="frm">
            <v-row no-gutters>
              <v-col cols="12" class="mb-10">
                <h1 class="grey--text text--lighten-1">{{ item.email }}</h1>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.pwd"
                  type="password"
                  :label="$t('word.pwd') + ' *'"
                  maxlength="20"
                  filled
                  :rules="[ requiredRule, passwordRule ]"
                  @keypress.enter="handleConfirmClicked()">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.pwdCnfm"
                  type="password"
                  :label="$t('word.pwdCnfm') + ' *'"
                  maxlength="20"
                  filled
                  :rules="[ requiredRule, passwordRule ]"
                  @keypress.enter="handleConfirmClicked()">
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end mr-2">
          <v-btn tile large min-width="180" color="primary" @click="handleConfirmClicked">
            {{ $t('word.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import { forms } from '@/mixins/formMixin'

export default {
  name: 'UserJoinPasswordView',
  mixins: [forms],
  components: {
  },
  props: {
    encodedKey: {
      type: String,
      required: false
    }
  },
  mounted () {
    try {
      const decodedKey = decodeURIComponent(escape(atob(this.encodedKey)))
      console.log(decodedKey)
      const params = decodedKey.split('&')
      for (let i = 0; i < params.length; i++) {
        const keyVals = params[i].split('=')
        if (keyVals.length === 2) {
          this.item[keyVals[0]] = keyVals[1]
        }
      }
      if (this.$isNull(this.item.usrId) ||
          this.$isNull(this.item.usrNm) ||
          this.$isNull(this.item.email) ||
          this.$isNull(this.item.ivtVrfVal)) {
        throw Error(this.$t('UserJoinPasswordView.txt003'))
      }
      this.checkDidInvited()
    } catch (error) {
      if (!this.$isNull(error) && error.message) {
        console.log(error.message)
      }
      this.$router.replace('/login')
    }
  },
  data () {
    return {
      item: {
        usrId: null,
        usrNm: null,
        email: null,
        ivtVrfVal: null,
        pwd: null,
        pwdCnfm: null
      }
    }
  },
  computed: {
  },
  methods: {
    checkDidInvited () {
      this.$http.get(`/api/1/users/${this.item.usrId}/invited`)
        .then((response) => {
          const ivtYn = response.data.result.ivtYn
          if (ivtYn !== 'Y') {
            this.$router.replace('/login')
          }
        })
        .catch(() => {
          this.$router.replace('/login')
        })
    },
    handleConfirmClicked () {
      if (!this.$refs.frm.validate(true)) {
        return
      }
      if (this.item.pwd !== this.item.pwdCnfm) {
        this.$toasted.global.warn(this.$t('UserJoinPasswordView.txt004'))
        return
      }
      this.$http.put(`/api/1/users/${this.item.usrId}/password/join`, this.item)
        .then(() => {
          this.$toasted.global.success(this.$t('UserJoinPasswordView.txt005'))
          const lgnForm = {
            email: this.item.email,
            pwd: this.item.pwd
          }
          this.$store.dispatch('login', lgnForm)
            .then(() => {
              // 최초 가입시에는 모든 필수 정보가 입력되었더라도 무조건 가입을 위한 정보확인 페이지로 이동시킨다.
              this.$router.replace('/join/info')
            })
            .catch((error) => {
              this.$showError(error)
            })
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>

<style lang="css" scoped>
a { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:focus { text-decoration: none; }
a:hover, a:active { text-decoration: none; }
</style>
