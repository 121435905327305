<template>
  <v-dialog v-model="dialogModel" persistent :max-width="$const.maxWidthLayout.sm" :retain-focus="false">
    <v-card flat>
      <!-- 타이틀바 -->
      <title-bar :title="$t('word.registOpinFile')" color="primary" dark>
        <template v-slot:action>
          <v-btn icon rounded large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
        </template>
      </title-bar>
      <!-- 컨텐츠 -->
      <v-card>
        <v-card-text class="pb-0">
          <v-form ref="frm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    v-model="item"
                    :label="$t('word.atchFile') + ' *'"
                    :prepend-icon="null"
                    truncate-length="100"
                    persistent-hint
                    :hint="$t('msg.maxFileSize')"
                    :rules="[ requiredRule ]"
                    clearable>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text large color="secondary" @click="dialogModel = false">{{ $t('word.close') }}</v-btn>
          <v-btn text large color="primary" @click="handleSaveClicked">{{ $t('word.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { forms } from '@/mixins/formMixin'
import TitleBar from '@/components/TitleBar'

export default {
  name: 'OpinionFileRegistPopup',
  mixins: [forms],
  components: {
    TitleBar
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    compId: {
      type: String,
      required: false
    },
    projCd: {
      type: String,
      required: false
    },
    inspNo: {
      type: Number,
      required: false
    },
    inspFileNo: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      item: null
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    }
  },
  methods: {
    handleSaveClicked () {
      if (!this.$refs.frm.validate(true)) {
        return
      }
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      const formData = new FormData()
      formData.append('file', this.item)
      formData.append('compId', this.compId)

      this.$http.post(`/api/1/projects/${this.projCd}/inspection/${this.inspNo}/file/${this.inspFileNo}/opinion`, formData, config)
        .then((response) => {
          this.$emit('item-changed', response.data.result.opinFile)
          this.dialogModel = false
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>
