<template>
  <v-sheet :color="color" class="pa-2 mb-2">
    <v-row
      v-for="line in lines"
      :key="line.id"
      :justify="justify"
      class="pa-1"
      dense>
      <slot :name="line.id">
        <!-- 검색조건 컴포넌트가 들어갈 위치 -->
      </slot>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: 'LineBar',
  props: {
    justify: {
      type: String,
      required: false,
      default: 'start'
    },
    color: {
      type: String,
      required: false,
      default: 'grey lighten-2'
    },
    lineSize: {
      type: Number,
      required: false,
      default: 1
    }
  },
  mounted () {
    for (let i = 0; i < this.lineSize; i++) {
      this.lines.push({
        id: `line-${(i + 1)}`
      })
    }
  },
  data () {
    return {
      lines: []
    }
  }
}
</script>
