<template>
  <div>
    <v-dialog v-model="dialogModel" persistent :max-width="$const.maxWidthLayout.md" :retain-focus="false">
      <v-card flat>
        <!-- 타이틀바 -->
        <title-bar :title="title" color="primary" dark>
          <template v-slot:action>
            <v-btn icon large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
          </template>
        </title-bar>
        <!-- 컨텐츠 -->
        <v-card>
          <v-card-text class="pb-0">
            <v-sheet v-if="!modifying" color="grey lighten-4">
              <v-container>
                <v-row>
                  <v-col cols="12" md="4">
                    <label-field
                      :label="$t('word.prodTypCd1')"
                      :value="this.topProdTypCd">
                    </label-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <label-field
                      :label="$t('word.prodTyp1')"
                      :value="this.topProdTypNm">
                    </label-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <label-field
                      :label="$t('word.prodTypEngNm1')"
                      :value="this.topProdTypEngNm">
                    </label-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
            <v-form ref="frm">
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      ref="prodTypCd"
                      v-model="item.prodTypCd"
                      :placeholder="this.topProdTypCd"
                      :label="$t('word.prodTypCd2') + ' *'"
                      maxlength="6"
                      :readonly="modifying"
                      persistent-hint
                      :hint="$t('ProductTypeLevel2DetailPopup.txt005')"
                      :rules="[ requiredRule, prodTypLv2Rule ]"
                      @input="prodTypCdChecked = false">
                      <template v-if="!modifying" v-slot:prepend>
                        <v-icon :color="prodTypCdChecked ? 'success' : 'grey'">mdi-check-circle</v-icon>
                      </template>
                      <template v-if="!modifying" v-slot:append>
                        <v-btn depressed x-small color="action" @click="handleCheckProdTypCdClicked">{{ $t('word.checkDuplicate') }}</v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="item.prodTypNm"
                      :label="$t('word.prodTyp2') + ' *'"
                      :readonly="!isAdmin"
                      maxlength="100"
                      :rules="[ requiredRule, nameRule ]">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="item.prodTypEngNm"
                      :label="$t('word.prodTypEngNm2') + ' *'"
                      :readonly="!isAdmin"
                      maxlength="100"
                      :rules="[ requiredRule, nameRule ]">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="item.srtOrdno"
                      :label="$t('word.srtOrdno') + ' *'"
                      :readonly="!isAdmin"
                      maxlength="9"
                      :rules="[ requiredRule, numericRule ]">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <label class="caption grey--text text--darken-2">{{ $t('word.useYn') }} *</label>
                    <v-radio-group
                      v-model="item.useYn"
                      :readonly="!isAdmin"
                      row
                      mandatory
                      class="mt-0">
                      <v-radio
                        :label="$t('word.yes')"
                        value="Y"></v-radio>
                      <v-radio
                        :label="$t('word.no')"
                        value="N"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="item.dtlDesc"
                      :label="$t('word.dtlDesc')"
                      :readonly="!isAdmin"
                      filled
                      rows="3"
                      :rules="[ length2000Rule ]">
                    </v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="item.engDtlDesc"
                      :label="$t('word.engDtlDesc')"
                      :readonly="!isAdmin"
                      filled
                      rows="3"
                      persistent-hint
                      :hint="$t('ProductTypeLevel2DetailPopup.txt006')"
                      :rules="[ length2000Rule ]">
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text large color="secondary" @click="dialogModel = false">{{ $t('word.close') }}</v-btn>
            <v-btn v-if="isAdmin" text large color="primary" @click="handleSaveClicked">{{ $t('word.save') }}</v-btn>
            <v-btn v-if="modifying && isAdmin" text large color="error" @click="handleDeleteClicked">{{ $t('word.delete') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { forms } from '@/mixins/formMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'
import LabelField from '@/components/LabelField'

export default {
  name: 'ProductTypeLevel2DetailPopup',
  mixins: [forms, stores],
  components: {
    TitleBar,
    LabelField
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    prodTypId: {
      type: String,
      required: false
    },
    topProdTypId: {
      type: String,
      required: false
    },
    topProdTypCd: {
      type: String,
      required: false
    },
    topProdTypNm: {
      type: String,
      required: false
    },
    topProdTypEngNm: {
      type: String,
      required: false
    }
  },
  mounted () {
    this.fetchItem()
  },
  data () {
    return {
      item: {
        useYn: 'Y',
        srtOrdno: 1,
        prodTypLv: 2,
        topSiteCd: 'CJE',
        topProdTypId: this.topProdTypId
      },
      prodTypCdChecked: false
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    },
    modifying () {
      if (!this.$isNull(this.prodTypId)) {
        return true
      } else {
        return !this.$isNull(this.item.prodTypId)
      }
    },
    title () {
      return this.modifying ? this.$t('word.prodTypDtl2') : this.$t('word.registProdTyp2')
    }
  },
  methods: {
    // 데이터조회
    fetchItem () {
      if (!this.modifying) {
        return
      }
      this.$http.get(`/api/1/products/${this.prodTypId}`)
        .then((response) => {
          this.item = response.data.result.prodTyp
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 상품유형코드 중복여부 확인
    handleCheckProdTypCdClicked () {
      if (this.modifying || !this.$refs.prodTypCd.validate(true)) {
        return
      }
      this.$http.get(`/api/1/products/code?prodTypCd=${this.item.prodTypCd}`)
        .then((response) => {
          const prodTyp = response.data.result.prodTyp
          if (this.$isNull(prodTyp)) {
            this.prodTypCdChecked = true
            this.$toasted.global.success(this.$t('ProductTypeLevel2DetailPopup.txt001'))
          } else {
            this.prodTypCdChecked = false
            this.$toasted.global.warn(this.$t('ProductTypeLevel2DetailPopup.txt002'))
          }
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleSaveClicked () {
      if (!this.$refs.frm.validate(true)) {
        return
      }
      if (this.modifying) {
        this.$http.put(`/api/1/products/${this.item.prodTypId}`, this.item)
          .then((response) => {
            this.$toasted.global.success(this.$t('msg.saved'))
            this.item = response.data.result.prodTyp
            this.$emit('item-changed', this.item)
          })
          .catch((error) => {
            this.$showError(error)
          })
      } else {
        if (!this.prodTypCdChecked) {
          this.$toasted.global.warn(this.$t('ProductTypeLevel2DetailPopup.txt003'))
          return
        }
        if (this.item.prodTypCd.substr(0, 2) !== this.topProdTypCd) {
          this.$toasted.global.warn(this.$t('ProductTypeLevel2DetailPopup.txt005'))
          return
        }
        this.$http.post('/api/1/products/level2', this.item)
          .then((response) => {
            this.$toasted.global.success(this.$t('msg.registed'))
            this.item = response.data.result.prodTyp
            this.$emit('item-changed', this.item)
          })
          .catch((error) => {
            this.$showError(error)
          })
      }
    },
    handleDeleteClicked () {
      if (!this.modifying || this.$isNull(this.item.prodTypId)) {
        return
      }
      if (!confirm(this.$t('ProductTypeLevel2DetailPopup.txt004', [this.item.prodTypNm]))) {
        return
      }
      this.$http.delete(`/api/1/products/${this.item.prodTypId}/level2`)
        .then(() => {
          this.$toasted.global.success(this.$t('msg.deleted'))
          this.dialogModel = false
          this.$emit('item-changed', this.item)
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>
