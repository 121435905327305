import Vue from 'vue'
import VueI18n from 'vue-i18n'

const messages = {
  ko: require('@/i18n/ko.json'),
  en: require('@/i18n/en.json')
}

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'ko',
  fallbackLocale: 'en',
  messages,
  silentTranslationWarn: true
})

export default i18n
