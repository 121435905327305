<template>
  <v-dialog v-model="dialogModel" persistent :max-width="$const.maxWidthLayout.sm" :retain-focus="false">
    <v-card flat>
      <!-- 타이틀바 -->
      <title-bar :title="$t('word.modifyFileInfo')" color="primary" dark>
        <template v-slot:action>
          <v-btn icon rounded large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
        </template>
      </title-bar>
      <!-- 컨텐츠 -->
      <v-card>
        <v-card-text class="pb-0">
          <v-form ref="frm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="item.compFileDivCd"
                    :label="$t('word.fileDiv') + ' *'"
                    :items="compFileDivCdList"
                    :rules="[ requiredRule ]"
                    small-chips>
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="mngFileNmWithoutExt"
                    :label="$t('word.atchFileNm') + ' *'"
                    maxlength="300"
                    :suffix="`.${item.fileExtNm}`"
                    :rules="[ requiredRule, fileNameRule ]">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text large color="secondary" @click="dialogModel = false">{{ $t('word.close') }}</v-btn>
          <v-btn text large color="primary" @click="handleSaveClicked">{{ $t('word.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { forms } from '@/mixins/formMixin'
import { searches } from '@/mixins/searchMixin'
import TitleBar from '@/components/TitleBar'

export default {
  name: 'CompanyFileModifyPopup',
  mixins: [forms, searches],
  components: {
    TitleBar
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    compId: {
      type: String,
      required: false
    },
    compFileNo: {
      type: Number,
      required: false
    }
  },
  mounted () {
    this.$getCodes(['CF0000'], (result) => {
      this.compFileDivCdList = this.$toSelectableCodes(result.CF0000)
      this.fetchItem()
    })
  },
  data () {
    return {
      compFileDivCdList: [],
      mngFileNmWithoutExt: null,
      item: {}
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    }
  },
  methods: {
    // 데이터조회
    fetchItem () {
      this.$http.get(`/api/1/companies/${this.compId}/file/${this.compFileNo}`)
        .then((response) => {
          this.item = response.data.result.compFile
          if (this.$isNull(this.item)) {
            this.$toasted.global.error(this.$t('msg.deletedFile'))
            return
          }
          let endIndex = this.item.mngFileNm.length
          if (!this.$isNull(this.item.fileExtNm)) {
            const extLength = this.item.fileExtNm.length
            if (extLength > 0) {
              endIndex = this.item.mngFileNm.length - (extLength + 1)
            }
          }
          this.mngFileNmWithoutExt = this.item.mngFileNm.substring(0, endIndex)
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleSaveClicked () {
      if (!this.$refs.frm.validate(true)) {
        return
      }
      this.item.mngFileNm = this.mngFileNmWithoutExt + '.' + this.item.fileExtNm
      this.$http.put(`/api/1/companies/${this.compId}/file/${this.compFileNo}`, this.item)
        .then((response) => {
          this.$toasted.global.success(this.$t('msg.saved'))
          this.item = response.data.result.compFile
          this.dialogModel = false
          this.$emit('item-changed', this.item)
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>
