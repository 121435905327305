<template>
  <div>
    <v-card outlined>
      <!-- 타이틀바 -->
      <title-bar :title="title">
        <template v-slot:action>
          <v-btn small tile :min-width="$const.minWidthButton" color="secondary" class="mr-1" @click="handleClearClicked">{{ $t('word.clear') }}</v-btn>
          <v-btn small tile :min-width="$const.minWidthButton" color="secondary" class="mr-1" @click="fetchList(true)">{{ $t('word.search') }}</v-btn>
          <v-btn v-if="isLicensee" small tile :min-width="$const.minWidthButton" color="primary" class="mr-1" @click="handleAddClicked">{{ $t('word.add') }}</v-btn>
        </template>
      </title-bar>
      <!-- 검색조건 -->
      <five-field-bar :field-size="5" :md="2">
        <template v-slot:field-1>
          <date-field
            v-model="strYmd"
            :label="$t('word.stmpApplYmd') + ' (FROM)'"
            background-color="white"
            outlined
            dense
            hide-details>
          </date-field>
        </template>
        <template v-slot:field-2>
          <date-field
            v-model="endYmd"
            :label="$t('word.stmpApplYmd') + ' (TO)'"
            background-color="white"
            outlined
            dense
            hide-details>
          </date-field>
        </template>
        <template v-slot:field-3>
          <v-text-field
            v-model="ipNm"
            label="IP"
            readonly
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @click="showIpListPopup = true">
            <template v-slot:append>
              <v-btn x-small depressed color="action" @click="showIpListPopup = true">{{ $t('word.choose') }}</v-btn>
            </template>
          </v-text-field>
        </template>
        <template v-slot:field-4>
          <v-text-field
            v-model="cntrNo"
            :label="$t('word.cntr')"
            readonly
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @click="showContractListPopup = true">
            <template v-slot:append>
              <v-btn x-small depressed color="action" @click="showContractListPopup = true">{{ $t('word.choose') }}</v-btn>
            </template>
          </v-text-field>
        </template>
        <template v-slot:field-5>
          <v-text-field
            v-model="compNm"
            :label="$t('word.liceComp')"
            readonly
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @click="showCompanyListPopup = true">
            <template v-slot:append>
              <v-btn x-small depressed color="action" @click="showCompanyListPopup = true">{{ $t('word.choose') }}</v-btn>
            </template>
          </v-text-field>
        </template>
      </five-field-bar>
      <!-- 테이블 -->
      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="items"
        item-key="vrtlId"
        height="69vh"
        class="text-truncate"
        fixed-header
        show-select
        single-select
        disable-filtering
        disable-pagination
        loading-text=""
        no-data-text=""
        no-results-text=""
        hide-default-footer
        :server-items-length="comTtlCnt"
        :sort-by.sync="comSrtBy"
        :sort-desc.sync="comSrtDesc"
        @click:row="handleRowClicked"
        @update:sort-by="delayedCall(fetchList)"
        @update:sort-desc="delayedCall(fetchList)">
        <template v-slot:item.projCrtDt="{ value }">
          <td>{{ $timeToShortDate(value) }}</td>
        </template>
        <template v-slot:item.stmpApplDt="{ value }">
          <td>{{ $timeToShortDate(value) }}</td>
        </template>
        <template v-slot:item.prdcCntSum="{ value }">
          <td>{{ $formatNumber(value) }}</td>
        </template>
        <template v-slot:item.ryltAmtSum="{ value }">
          <td>{{ $formatNumber(value) }}</td>
        </template>
        <template v-slot:item.stmpAmtSum="{ value }">
          <td>{{ $formatNumber(value) }}</td>
        </template>
        <template v-slot:item.modDt="{ value }">
          <td>{{ $timeToMediumDate(value) }}</td>
        </template>
        <template v-slot:item.fileDelYn="{ value }">
          <td v-if="value ==='Y'"><span class="material-icons" style="font-size: 24px; color: black">attach_file</span></td>
          <td v-if="value === null"><span class="material-icons" style="font-size: 24px; color: lightgray">attach_file</span></td>
        </template>
        <template v-slot:item.stmpConf="{ value }">
          <td v-if="value === '0'"><span class="material-icons" style="font-size: 22px; color: lightgray">done</span></td>
          <td v-if="value === '1'"><span class="material-icons" style="font-size: 22px; color: black">done</span></td>
        </template>
        <template v-slot:body.append="{ headers }">
          <tr>
            <td :colspan="headers.length">
              {{ $t('word.total') }} {{ $formatNumber(comTtlCnt) }}{{ $t('word.count') }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <!-- 페이징 -->
      <paging-bar
        :page-no.sync="comPageNo"
        :page-cnt.sync="comPageCnt"
        :item-cnt-per-page.sync="comItemCntPerPage"
        @paging-changed="fetchList">
      </paging-bar>
    </v-card>
    <!-- IP 목록 팝업 -->
    <ip-list-popup
      v-if="showIpListPopup"
      :dialog.sync="showIpListPopup"
      :ip-id.sync="ipId"
      :ip-nm.sync="ipNm">
    </ip-list-popup>
    <!-- 계약 목록 팝업 -->
    <contract-list-popup
      v-if="showContractListPopup"
      :dialog.sync="showContractListPopup"
      :cntr-id.sync="cntrId"
      :cntr-no.sync="cntrNo">
    </contract-list-popup>
    <!-- 회사 목록 팝업 -->
    <company-list-popup
      v-if="showCompanyListPopup"
      :dialog.sync="showCompanyListPopup"
      :comp-id.sync="compId"
      :comp-nm.sync="compNm">
    </company-list-popup>
    <!-- 증지신청할 프로젝트 목록 팝업 -->
    <stamp-appliable-project-list-popup
      v-if="showStampAppliableProjectListPopup"
      :dialog.sync="showStampAppliableProjectListPopup"
      :comp-id="getLoginUsrCompId()"
      @item-selected="handleStampAppliableProjectSelected">
    </stamp-appliable-project-list-popup>
    <!-- 증지신청상세 탭 팝업 -->
    <stamp-detail-tab-popup
      v-if="showStampDetailTabPopup"
      :dialog.sync="showStampDetailTabPopup"
      :proj-cd="applyingProjCd"
      :cntr-id="applyingCntrId"
      :stmp-appl-no="applyingStmpApplNo"
      @item-changed="fetchList">
    </stamp-detail-tab-popup>
  </div>
</template>

<script>
import { sorts } from '@/mixins/sortMixin'
import { pages } from '@/mixins/pageMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'
import FiveFieldBar from '@/components/FiveFieldBar'
import DateField from '@/components/DateField'
import PagingBar from '@/components/PagingBar'
import IpListPopup from '@/views/ip/popup/IpListPopup'
import ContractListPopup from '@/views/contract/popup/ContractListPopup'
import CompanyListPopup from '@/views/company/popup/CompanyListPopup'
import StampAppliableProjectListPopup from '@/views/project/popup/StampAppliableProjectListPopup'
import StampDetailTabPopup from '@/views/stamp/popup/StampDetailTabPopup'

export default {
  name: 'StampListView',
  mixins: [sorts, pages, searches, stores],
  components: {
    TitleBar,
    FiveFieldBar,
    DateField,
    PagingBar,
    IpListPopup,
    ContractListPopup,
    CompanyListPopup,
    StampAppliableProjectListPopup,
    StampDetailTabPopup
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'NO TITLE'
    },
    menuPath: {
      type: String,
      required: false
    },
    projStsCd: {
      type: String,
      required: false
    },
    srchYmdNm: {
      type: String,
      required: false
    },
    fileDelYn: {
      type: String,
      required: false
    }
  },
  mounted () {
    // 테이블 목록 조회
    this.fetchList()
  },
  watch: {
    showProjectDesignTabPopup (to) {
      // 팝업이 닫힐때 증지신청할 프로젝트코드를 초기화 한다
      if (!to) {
        this.applyingProjCd = null
      }
    }
  },
  data () {
    return {
      // 테이블 헤더
      headers: [
        { text: this.$t('word.confirmYn'), value: 'stmpConf' },
        { text: this.$t('word.projCd'), value: 'projCd' },
        { text: this.$t('word.stmpApplNo'), value: 'stmpApplNo' },
        { text: this.$t('word.depositYn'), value: 'fileDelYn' },
        { text: this.$t('word.projNm'), value: 'projNm' },
        { text: this.$t('word.ipNm'), value: 'ipNm' },
        { text: this.$t('word.liceComp'), value: 'liceCompNm' },
        { text: this.$t('word.cntrNo'), value: 'cntrNo' },
        { text: this.$t('word.prodTyp1'), value: 'topProdTypNm', sortable: false },
        { text: this.$t('word.prodTyp2'), value: 'prodTypNm', sortable: false },
        { text: this.$t('word.projCrtYmd'), value: 'projCrtDt' },
        { text: this.$t('word.stmpApplYmd'), value: 'stmpApplDt' },
        { text: this.$t('word.prdcCntSum'), value: 'prdcCntSum', sortable: false },
        { text: this.$t('word.ryltAmtSum'), value: 'ryltAmtSum', sortable: false },
        { text: this.$t('word.stmpAmtSum'), value: 'stmpAmtSum', sortable: false },
        { text: this.$t('word.modDt'), value: 'modDt', sortable: false }
      ],
      // 조회결과 목록
      items: [],
      // 검색조건
      strYmd: null,
      endYmd: null,
      ipId: null,
      ipNm: null,
      cntrId: null,
      cntrNo: null,
      compId: null,
      compNm: null,
      // 증지신청할 프로젝트 목록에서 선택된 프로젝트 코드 or 증지신청목록에서 선택된 프로젝트코드
      applyingProjCd: null,
      // 증지신청할 프로젝트 목록에서 선택된 계약ID or 증지신청목록에서 선택된 계약ID
      applyingCntrId: null,
      // 증지신청목록에서 선택된 증지신청번호
      applyingStmpApplNo: null,
      // 선택 아이템
      selectedItems: [],
      // IP 목록 팝업 오픈 여부
      showIpListPopup: false,
      // 계약 목록 팝업 오픈 여부
      showContractListPopup: false,
      // 회사 목록 팝업 오픈 여부
      showCompanyListPopup: false,
      // 증지신청이 가능한 프로젝트 목록 팝업 오픈 여부
      showStampAppliableProjectListPopup: false,
      // 증지신청 상세 탭 팝업 오픈 여부
      showStampDetailTabPopup: false
    }
  },
  computed: {
  },
  methods: {
    // 목록조회
    fetchList (shouldClear) {
      // 검색 초기화
      if (shouldClear) {
        this.comPageNo = 1
        this.selectedItems = []
      }
      // 검색 파라미터 할당
      this.clearComParam()
      this.appendParam('strYmd', this.strYmd)
      this.appendParam('endYmd', this.endYmd)
      if (!this.$isEmpty(this.ipNm)) {
        this.appendParam('ipId', this.ipId)
      }
      if (!this.$isEmpty(this.cntrNo)) {
        this.appendParam('cntrId', this.cntrId)
      }
      if (!this.$isEmpty(this.compNm)) {
        this.appendParam('compId', this.compId)
      }
      // 검색
      this.$http.get(this.toRequestUrl('/api/1/stamps'))
        .then((response) => {
          const page = response.data.result.stmpApplPage
          this.bindComPage(page)
          const stmpApplList = page.list
          for (const stmpAppl of stmpApplList) {
            stmpAppl.vrtlId = `${stmpAppl.projCd}-${stmpAppl.stmpApplNo}`
          }
          this.items = stmpApplList
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 초기화 버튼 클릭시
    handleClearClicked () {
      this.strYmd = null
      this.endYmd = null
      this.ipId = null
      this.ipNm = null
      this.cntrId = null
      this.cntrNo = null
      this.compId = null
      this.compNm = null
      this.fetchList(true)
    },
    // 추가 버튼 클릭시
    handleAddClicked () {
      this.selectedItems = []
      this.showStampAppliableProjectListPopup = true
    },
    // 테이블 로우 클릭시
    handleRowClicked (item) {
      this.selectedItems = [item]
      this.applyingProjCd = item.projCd
      this.applyingCntrId = item.cntrId
      this.applyingStmpApplNo = item.stmpApplNo
      this.showStampDetailTabPopup = true
    },
    // 증지신청이 가능한 프로젝트 선택시
    handleStampAppliableProjectSelected (item) {
      if (this.$isNull(item)) {
        return
      }
      this.applyingProjCd = item.projCd
      this.applyingCntrId = item.cntrId
      this.applyingStmpApplNo = null
      this.showStampDetailTabPopup = true
    }
  }
}
</script>
