<template>
  <v-dialog v-model="dialogModel" persistent :max-width="$const.maxWidthLayout.md" :retain-focus="false">
    <v-card flat>
      <!-- 타이틀바 -->
      <title-bar :title="title" color="primary" dark>
        <template v-slot:action>
          <v-btn icon large dark @click="dialogModel = false"><v-icon>mdi-close</v-icon></v-btn>
        </template>
      </title-bar>
      <!-- 컨텐츠 -->
      <v-card>
        <v-card-text class="pb-0">
          <v-form ref="frm">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="item.lotNm"
                    :label="$t('word.lotNm') + ' *'"
                    maxlength="100"
                    :rules="[ requiredRule, nameRule ]"
                    :readonly="!editable">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="item.ryltStdCd"
                    :label="$t('word.ryltStd') + ' *'"
                    :items="ryltStdCdList"
                    :rules="[ requiredRule ]"
                    :readonly="!editable"
                    small-chips>
                  </v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="item.cnsmAmt"
                    :label="$t('word.cnsmAmt') + ' *'"
                    maxlength="10"
                    :rules="[ requiredRule, numericRule ]"
                    :readonly="!editable">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="item.rlsAmt"
                    :label="$t('word.rlsAmt') + ' *'"
                    maxlength="10"
                    :rules="[ requiredRule, numericRule ]"
                    :readonly="!editable">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="item.ryltRt"
                    :label="$t('word.ryltRt') + ' *'"
                    maxlength="5"
                    :rules="[ requiredRule, numericDotRule, ryltRtRule ]"
                    :readonly="!editable">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="item.prdcCnt"
                    :label="$t('word.prdcCnt') + ' *'"
                    maxlength="8"
                    :rules="[ requiredRule, numericRule ]"
                    :readonly="!editable">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="stmpStdAmt"
                    :label="$t('word.stmpStdAmt')"
                    readonly>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="item.stmpKndVal"
                    :label="$t('word.stmpKnd') + ' *'"
                    :items="stmpKndValList"
                    :rules="[ requiredRule ]"
                    :readonly="isLicensee"
                    small-chips>
                  </v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="item.ryltAmt"
                    :label="$t('word.ryltAmt')"
                    readonly>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="item.stmpAmt"
                    :label="$t('word.stmpAmt')"
                    readonly>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text large color="secondary" @click="dialogModel = false">{{ $t('word.close') }}</v-btn>
          <v-btn v-if="editable" text large color="primary" @click="handleConfirmClicked">{{ $t('word.confirm') }}</v-btn>
          <v-btn v-if="editable && isLicensor" text large color="error" @click="handleDeleteClicked">{{ $t('word.delete') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { forms } from '@/mixins/formMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'

export default {
  name: 'StampLotDetailPopup',
  mixins: [forms, stores],
  components: {
    TitleBar
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    stmpStdAmt: {
      type: Number,
      required: false
    },
    stmpLot: {
      type: Object,
      required: false
    },
    editable: {
      type: Boolean,
      required: false
    }
  },
  mounted () {
    // props 객체를 data 객체로 복사
    if (!this.$isNull(this.stmpLot)) {
      this.item = JSON.parse(JSON.stringify(this.stmpLot))
      // IMPORTANT :
      // 증지종류값은 입력된 소비자가에 따라 자동으로 설정되는데,
      // 라이선서의 경우에는 소비자가와 무관하게 증지종류를 변경할 수 있어야 한다.
      // 그러나 라이선서가 소비자가와 무관하게 증지종류를 변경한 경우
      // 화면 로딩시, 등록된 watch 에 의해 증지종류값이 자동으로 변경되는 문제가 발생한다.
      // 이런 문제 처리를 위해서 화면 로딩 최초 1회에 한하여 증지종류값을 자동설정되지 않도록 처리한다.
      this.restoreStmpKndVal = true
    }
    this.$getCodes(['RS0000'], (result) => {
      this.ryltStdCdList = this.$toSelectableCodes(result.RS0000)
    })
  },
  watch: {
    'item.ryltStdCd': function (to) {
      this.autoCalculate()
      const codeObj = this.ryltStdCdList.find((elem) => {
        return elem.value === to
      })
      if (!this.$isNull(codeObj)) {
        this.item.ryltStdCdNm = codeObj.text
      }
    },
    'item.cnsmAmt': function (to, from) {
      this.autoCalculate()
      // 화면 로딩시 최초 1회에 한하여는 할당된 증지종류값을 그대로 디스플레이 한다. (mounted 코드블럭의 설명 참조)
      if (this.restoreStmpKndVal) {
        this.restoreStmpKndVal = false
      } else {
        this.autoStmpKndVal()
      }
    },
    'item.rlsAmt': function () {
      this.autoCalculate()
    },
    'item.ryltRt': function () {
      this.autoCalculate()
    },
    'item.prdcCnt': function () {
      this.autoCalculate()
    }
  },
  data () {
    return {
      ryltStdCdList: [],
      item: {
        ryltStdCd: null,
        stmpKndVal: null
      },
      restoreStmpKndVal: false
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    },
    stmpKndValList () {
      return [
        { text: this.$t('word.- choose -'), value: null },
        { text: '1', value: '1' },
        { text: '2', value: '2' },
        { text: '3', value: '3' },
        { text: '4', value: '4' },
        { text: '5', value: '5' },
        { text: '6', value: '6' },
        { text: '7', value: '7' },
        { text: '8', value: '8' },
        { text: '9', value: '9' },
        { text: '10', value: '10' }
      ]
    },
    title () {
      return this.editable ? this.$t('word.inputLot') : this.$t('word.lotDtl')
    }
  },
  methods: {
    // 품목 필드 변경시 필드 값 자동 계산
    autoCalculate () {
      // 로열티, 증지대 값 초기화
      this.item.ryltAmt = null
      this.item.stmpAmt = null
      let ryltTgtAmt = 0
      if (this.$isNull(this.item.ryltStdCd) ||
          this.$isNull(this.item.ryltRt) ||
          this.$isNull(this.item.prdcCnt)) {
        return
      }
      if (this.item.ryltStdCd === 'RS0100') {
        if (this.$isNull(this.item.cnsmAmt)) {
          return
        }
        ryltTgtAmt = this.item.cnsmAmt
      } else if (this.item.ryltStdCd === 'RS0200') {
        if (this.$isNull(this.item.rlsAmt)) {
          return
        }
        ryltTgtAmt = this.item.rlsAmt
      }
      const ryltRtRslt = Math.round(this.item.ryltRt / 100 * 10000) / 10000
      this.item.ryltAmt = Math.round(ryltTgtAmt * ryltRtRslt * this.item.prdcCnt)
      this.item.stmpAmt = Math.round(this.stmpStdAmt * this.item.prdcCnt)
    },
    // 증지종류 자동 할당
    autoStmpKndVal () {
      let tmpStmpKndVal = null
      if (!this.$isNull(this.item.cnsmAmt)) {
        const tmpCnsmAmt = Number(this.item.cnsmAmt)

        // 2022. 12. 26 요청 변경
        if (tmpCnsmAmt < 5000) {
          tmpStmpKndVal = '1'
        } else if (tmpCnsmAmt >= 5000 && tmpCnsmAmt < 10000) {
          tmpStmpKndVal = '2'
        } else {
          tmpStmpKndVal = '3'
        }

        /*
        if (tmpCnsmAmt <= 1000) {
          tmpStmpKndVal = '1'
        } else if (tmpCnsmAmt <= 1500) {
          tmpStmpKndVal = '2'
        } else if (tmpCnsmAmt <= 2000) {
          tmpStmpKndVal = '3'
        } else if (tmpCnsmAmt <= 3500) {
          tmpStmpKndVal = '4'
        } else if (tmpCnsmAmt <= 4500) {
          tmpStmpKndVal = '5'
        } else if (tmpCnsmAmt <= 6000) {
          tmpStmpKndVal = '6'
        } else if (tmpCnsmAmt <= 8500) {
          tmpStmpKndVal = '7'
        } else if (tmpCnsmAmt <= 9500) {
          tmpStmpKndVal = '8'
        } else if (tmpCnsmAmt <= 15000) {
          tmpStmpKndVal = '9'
        } else {
          tmpStmpKndVal = '10'
        }
        */
      }
      this.item.stmpKndVal = tmpStmpKndVal
    },
    handleConfirmClicked () {
      if (!this.editable) {
        return
      }
      if (!this.$refs.frm.validate(true)) {
        return
      }
      if (this.$isNull(this.item.vrtlId)) {
        // IMPORTANT :
        // 품목 입력시 DB에 바로 저장하지 않기 때문에 임시 사용을 위한 가상아이디를 타임스탬프 값을 이용하여 채번한다.
        // frontend 에서만 사용되는 값이며, 실제 DB에 저장되지 않는다.
        this.item.vrtlId = 'T' + new Date().getTime()
        this.$emit('item-added', this.item)
      } else {
        this.$emit('item-changed', this.item)
      }
      this.dialogModel = false
    },
    handleDeleteClicked () {
      if (!this.editable) {
        return
      }
      this.$emit('item-deleted', this.item)
      this.dialogModel = false
    }
  }
}
</script>
