<template>
  <div>
    <v-card outlined>
      <!-- 타이틀바 -->
      <title-bar :title="title">
        <template v-slot:action>
          <v-btn small tile :min-width="$const.minWidthButton" color="secondary" class="mr-1" @click="handleClearClicked">{{ $t('word.clear') }}</v-btn>
          <v-btn small tile :min-width="$const.minWidthButton" color="secondary" class="mr-1" @click="[fetchList(true)]">{{ $t('word.search') }}</v-btn>
          <v-btn v-if="isAdmin" small tile :min-width="$const.minWidthButton" color="primary" @click="handleAddClicked">{{ $t('word.add') }}</v-btn>
        </template>
      </title-bar>
      <!-- 검색조건 -->
      <field-bar :field-size="3" :lg="2" :md="3">
        <template v-slot:field-1>
          <v-select
            v-model="useYn"
            :items="$getYesOrNoCodes()"
            :label="$t('word.useYn')"
            background-color="white"
            outlined
            dense
            hide-details>
          </v-select>
        </template>
        <template v-slot:field-2>
          <v-select
            v-model="srchDiv"
            :items="srchDivList"
            :label="$t('word.srchDiv')"
            background-color="white"
            outlined
            dense
            hide-details>
          </v-select>
        </template>
        <template v-slot:field-3>
          <v-text-field
            v-model="kwd"
            :label="$t('word.kwd')"
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @keypress.enter="fetchList(true)">
          </v-text-field>
        </template>
      </field-bar>
      <!-- 테이블 -->
      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="items"
        item-key="prodTypId"
        height="29.7vh"
        class="text-truncate"
        fixed-header
        show-select
        single-select
        disable-filtering
        disable-pagination
        loading-text=""
        no-data-text=""
        no-results-text=""
        hide-default-footer
        :server-items-length="comTtlCnt"
        :sort-by.sync="comSrtBy"
        :sort-desc.sync="comSrtDesc"
        @click:row="handleRowClicked"
        @update:sort-by="delayedCall(fetchList)"
        @update:sort-desc="delayedCall(fetchList)">
        <template v-slot:item.modDt="{ value }">
          <td>{{ $timeToMediumDate(value) }}</td>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn x-small depressed color="secondary" @click="handleInlineDetailClicked(item)">{{ $t('word.dtl') }}</v-btn>
        </template>
        <template v-slot:body.append="{ headers }">
          <tr>
            <td :colspan="headers.length">
              {{ $t('word.total') }} {{ $formatNumber(comTtlCnt) }}{{ $t('word.count') }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <!-- 페이징 -->
      <paging-bar
        :page-no.sync="comPageNo"
        :page-cnt.sync="comPageCnt"
        :item-cnt-per-page.sync="comItemCntPerPage"
        @paging-changed="fetchList">
      </paging-bar>
    </v-card>
    <!-- 상품유형 2단계 리스트 -->
    <ProductType-level2-list-unit class="mt-3"
      v-if="showProductTypeLevel2ListUnit"
      :top-prod-typ-id="this.prodTypId"
      :top-prod-typ-cd="this.prodTypCd"
      :top-prod-typ-nm="this.prodTypNm"
      :top-prod-typ-eng-nm="this.prodTypEngNm">
    </ProductType-level2-list-unit>
    <!-- 상품유형 1단계 상세 팝업 -->
    <productType-level1-detail-popup
      v-if="showProductTypeLevel1DetailPopup"
      :dialog.sync="showProductTypeLevel1DetailPopup"
      :prod-typ-id="selectedItems.length === 0 ? null : selectedItems[0].prodTypId"
      @item-changed="handleProductTypeItemChanged">
    </productType-level1-detail-popup>
  </div>
</template>

<script>
import { sorts } from '@/mixins/sortMixin'
import { pages } from '@/mixins/pageMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'
import FieldBar from '@/components/FieldBar'
import PagingBar from '@/components/PagingBar'
import ProductTypeLevel1DetailPopup from '@/views/productType/popup/ProductTypeLevel1DetailPopup'
import ProductTypeLevel2ListUnit from '@/views/productType/unit/ProductTypeLevel2ListUnit'

export default {
  name: 'ProductTypeLevel1ListView',
  mixins: [sorts, pages, searches, stores],
  components: {
    TitleBar,
    FieldBar,
    PagingBar,
    ProductTypeLevel1DetailPopup,
    ProductTypeLevel2ListUnit
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'NO TITLE'
    }
  },
  mounted () {
    this.fetchList()
  },
  data () {
    return {
      // 테이블 헤더
      headers: [
        { text: this.$t('word.prodTypCd'), value: 'prodTypCd' },
        { text: this.$t('word.prodTypNm'), value: 'prodTypNm' },
        { text: this.$t('word.prodTypEngNm'), value: 'prodTypEngNm' },
        { text: this.$t('word.prodTypLv'), value: 'prodTypLv', sortable: false },
        { text: this.$t('word.useYn'), value: 'useYn', sortable: false },
        { text: this.$t('word.srtOrdno'), value: 'srtOrdno' },
        { text: this.$t('word.modDt'), value: 'modDt', sortable: false },
        { text: '', value: 'action', width: '1px', sortable: false }
      ],
      // 조회결과 목록
      items: [],
      // 검색조건
      useYn: null,
      srchDiv: 'prodTypNm',
      kwd: null,
      // 선택 아이템
      selectedItems: [],
      // 상품유형 상세 팝업 오픈 여부
      showProductTypeLevel1DetailPopup: false,
      // 상품유형ID
      prodTypId: null,
      // 상품유형CD
      prodTypCd: null,
      // 상품유형 1단계
      prodTypNm: null,
      // 상품유형 1단계 영문명
      prodTypEngNm: null
    }
  },
  computed: {
    srchDivList () {
      return [
        { text: this.$t('word.prodTypCd'), value: 'prodTypCd' },
        { text: this.$t('word.prodTypNm'), value: 'prodTypNm' }
      ]
    },
    showProductTypeLevel2ListUnit () {
      return !this.$isEmptyArray(this.selectedItems)
    }
  },
  methods: {
    // 목록조회
    fetchList (shouldClear) {
      // 검색 초기화
      if (shouldClear) {
        this.comPageNo = 1
        this.selectedItems = []
      }
      // 검색 파라미터 할당
      this.clearComParam()
      this.appendParam('useYn', this.useYn)
      if (!this.$isEmpty(this.kwd)) {
        this.appendParam(this.srchDiv, this.kwd)
      }
      // 검색
      this.$http.get(this.toRequestUrl('/api/1/products/level1'))
        .then((response) => {
          const page = response.data.result.prodTypPage
          this.bindComPage(page)
          this.items = page.list
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 초기화 버튼 클릭시
    handleClearClicked () {
      this.useYn = null
      this.srchDiv = 'prodTypNm'
      this.kwd = null
      this.fetchList(true)
    },
    // 추가 버튼 클릭시
    handleAddClicked () {
      this.selectedItems = []
      this.showProductTypeLevel1DetailPopup = true
    },
    // 상세 버튼 클릭시
    handleInlineDetailClicked (item) {
      this.selectedItems = [item]
      this.showProductTypeLevel1DetailPopup = true
    },
    // 테이블 로우 클릭시
    handleRowClicked (item) {
      this.selectedItems = [item]
      this.prodTypId = item.prodTypId
      this.prodTypCd = item.prodTypCd
      this.prodTypNm = item.prodTypNm
      this.prodTypEngNm = item.prodTypEngNm
    },
    // 상품유형상세 팝업 데이터 변경시
    handleProductTypeItemChanged (item) {
      if (this.$isNull(item)) {
        this.fetchList(true)
      } else {
        this.fetchList()
      }
    }
  }
}
</script>
