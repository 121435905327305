<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-container fluid>
          <v-row dense>
            <v-col cols="12" sm="6" lg="4">
              <label-field
                :label="$t('word.projCd')"
                :value="item.projCd">
              </label-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label-field
                :label="$t('word.projNm')"
                :value="item.projNm">
              </label-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label-field
                :label="$t('word.projStg')"
                :value="item.projStgCdNm">
              </label-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label-field
                :label="$t('word.projSts')"
                :value="item.projStsCdNm">
              </label-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label-field
                :label="$t('word.prodTyp1')"
                :value="item.topProdTypNm">
              </label-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label-field
                :label="$t('word.prodTyp2')"
                :value="item.prodTypNm">
              </label-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label-field
                :label="$t('word.prodNo')"
                :value="item.prodNo">
              </label-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label-field
                :label="$t('word.estmCnsmAmt') + ' ' + currency"
                :value="$formatNumber(item.estmCnsmAmt)">
              </label-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label-field
                :label="$t('word.estmRlsAmt') + ' ' + currency"
                :value="$formatNumber(item.estmRlsAmt)">
                <template v-slot:append>
                  {{ item.rlsAmtRt }}%
                </template>
              </label-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label-field
                :label="$t('word.rlsPlanYm')"
                :value="item.rlsPlanYm">
              </label-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label-field
                :label="$t('word.saleNtn')"
                :value="$isNull(item.prodSaleNtnComCdList) ? null : item.prodSaleNtnComCdList.map(o => o.comCdNm)">
              </label-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label-field
                :label="$t('word.saleChnl')"
                :value="$isNull(item.prodSaleChnlComCdList) ? null : item.prodSaleChnlComCdList.map(o => o.comCdNm)">
              </label-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label-field
                :label="$t('word.sprtLang')"
                :value="$isNull(item.prodSprtLangComCdList) ? null : item.prodSprtLangComCdList.map(o => o.comCdNm)">
              </label-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label-field
                :label="$t('word.tgtAge')"
                :value="$isNull(item.prodTgtAgeComCdList) ? null : item.prodTgtAgeComCdList.map(o => o.comCdNm)">
              </label-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label-field
                :label="$t('word.tgtSex')"
                :value="$isNull(item.prodTgtSexComCdList) ? null : item.prodTgtSexComCdList.map(o => o.comCdNm)">
              </label-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import LabelField from '@/components/LabelField'

export default {
  name: 'ProjectDetailUnit',
  components: {
    LabelField
  },
  props: {
    projCd: {
      type: String,
      required: false
    }
  },
  mounted () {
    this.fetchItem()
  },
  data () {
    return {
      item: {}
    }
  },
  computed: {
    currency () {
      if (this.$isNull(this.item.ccCd)) {
        return null
      } else {
        return `(${this.item.ccCd.substring(2, 5)})`
      }
    }
  },
  methods: {
    // 데이터조회
    fetchItem () {
      this.$http.get(`/api/1/projects/${this.projCd}`)
        .then((response) => {
          this.item = response.data.result.projMeta
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>
