<template>
  <div>
    <v-card outlined>
      <!-- 타이틀바 -->
      <title-bar :title="title">
        <template v-slot:action>
          <v-btn small tile :min-width="$const.minWidthButton" color="secondary" class="mr-1" @click="handleClearClicked">{{ $t('word.clear') }}</v-btn>
          <v-btn small tile :min-width="$const.minWidthButton" color="secondary" class="mr-1" @click="fetchList(true)">{{ $t('word.search') }}</v-btn>
          <v-btn small tile :min-width="$const.minWidthButton" color="info" class="mr-1" @click="handleStatsRptClicked">{{ $t('word.download') }}</v-btn>
        </template>
      </title-bar>
      <!-- 검색조건 -->
      <five-field-bar :field-size="5" :md="2" :show-extension="true">
        <template v-slot:field-1>
          <date-field
            v-model="strYmd"
            :label="$t('word.stdYmd') + ' (FROM)'"
            background-color="white"
            outlined
            dense
            hide-details>
          </date-field>
        </template>
        <template v-slot:field-2>
          <date-field
            v-model="endYmd"
            :label="$t('word.stdYmd') + ' (TO)'"
            background-color="white"
            outlined
            dense
            hide-details>
          </date-field>
        </template>
        <template v-slot:field-3>
          <v-text-field
            v-model="ipNm"
            :label="$t('word.ip')"
            readonly
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @click="showIpListPopup = true">
            <template v-slot:append>
              <v-btn x-small depressed color="action" @click="showIpListPopup = true">{{ $t('word.choose') }}</v-btn>
            </template>
          </v-text-field>
        </template>
        <template v-slot:field-4>
          <v-text-field
            v-model="compNm"
            :label="$t('word.liceComp')"
            readonly
            background-color="white"
            outlined
            dense
            clearable
            hide-details
            @click="showCompanyListPopup = true">
            <template v-slot:append>
              <v-btn x-small depressed color="action" @click="showCompanyListPopup = true">{{ $t('word.choose') }}</v-btn>
            </template>
          </v-text-field>
        </template>
        <template v-slot:field-5>
          <v-select
            v-model="prodTypId"
            :label="$t('word.prodTyp1')"
            :items="prodTypCdLv1List"
            item-text="prodTypNm"
            item-value="prodTypId"
            background-color="white"
            outlined
            dense
            hide-details>
          </v-select>
        </template>
        <template v-slot:extension>
          <v-row justify="start" class="mt-2 mb-1">
            <div class="font-weight-black mt-1 ml-3 pt-1">{{ $t('word.readItem') }} : </div>
            <v-checkbox
              v-model="checkIp"
              :label="$t('word.ip')"
              value="IP"
              color="indigo"
              class="pl-4"
              hide-details
              disabled
              dense>
            </v-checkbox>
            <v-checkbox
              v-model="checkComp"
              :label="$t('word.liceComp')"
              value="COMP"
              color="indigo"
              class="pl-4"
              hide-details
              dense>
            </v-checkbox>
            <v-checkbox
              v-model="checkProdTyp"
              :label="$t('word.prodTyp1')"
              value="PRODTYP"
              color="indigo"
              class="pl-4"
              hide-details
              dense>
            </v-checkbox>
          </v-row>
        </template>
      </five-field-bar>
      <!-- 테이블 -->
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="ipId"
        height="64vh"
        class="text-truncate"
        fixed-header
        disable-filtering
        disable-pagination
        loading-text=""
        no-data-text=""
        no-results-text=""
        hide-default-footer
        :server-items-length="comTtlCnt"
        :sort-by.sync="comSrtBy"
        :sort-desc.sync="comSrtDesc"
        @update:sort-by="delayedCall(fetchList)"
        @update:sort-desc="delayedCall(fetchList)">
        <template v-slot:item.maxCnsmAmt="{ value }">
          <td>{{ $formatNumber(value) }}</td>
        </template>
         <template v-slot:item.minCnsmAmt="{ value }">
          <td>{{ $formatNumber(value) }}</td>
        </template>
         <template v-slot:item.avgCnsmAmt="{ value }">
          <td>{{ $formatNumber(value) }}</td>
        </template>
         <template v-slot:item.maxRlsAmt="{ value }">
          <td>{{ $formatNumber(value) }}</td>
        </template>
         <template v-slot:item.minRlsAmt="{ value }">
          <td>{{ $formatNumber(value) }}</td>
        </template>
         <template v-slot:item.avgRlsAmt="{ value }">
          <td>{{ $formatNumber(value) }}</td>
        </template>
         <template v-slot:item.prdcCnt="{ value }">
          <td>{{ $formatNumber(value) }}</td>
        </template>
         <template v-slot:item.ryltAmt="{ value }">
          <td>{{ $formatNumber(value) }}</td>
        </template>
        <template v-slot:body.append="{ headers }">
          <tr>
            <td :colspan="headers.length">
              {{ $t('word.total') }} {{ $formatNumber(comTtlCnt) }}{{ $t('word.count') }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <!-- 페이징 -->
      <paging-bar
        :page-no.sync="comPageNo"
        :page-cnt.sync="comPageCnt"
        :item-cnt-per-page.sync="comItemCntPerPage"
        @paging-changed="fetchList">
      </paging-bar>
      <!-- IP 목록 팝업 -->
      <ip-list-popup
      v-if="showIpListPopup"
      :dialog.sync="showIpListPopup"
      :ip-id.sync="ipId"
      :ip-nm.sync="ipNm">
      </ip-list-popup>
      <!-- 회사 목록 팝업 -->
      <company-list-popup
      v-if="showCompanyListPopup"
      :dialog.sync="showCompanyListPopup"
      :comp-id.sync="compId"
      :comp-nm.sync="compNm">
      </company-list-popup>
    </v-card>
  </div>
</template>

<script>
import { sorts } from '@/mixins/sortMixin'
import { pages } from '@/mixins/pageMixin'
import { searches } from '@/mixins/searchMixin'
import { stores } from '@/mixins/storeMixin'
import TitleBar from '@/components/TitleBar'
import FiveFieldBar from '@/components/FiveFieldBar'
import PagingBar from '@/components/PagingBar'
import DateField from '@/components/DateField'
import IpListPopup from '@/views/ip/popup/IpListPopup'
import CompanyListPopup from '@/views/company/popup/CompanyListPopup'
import fileDownload from 'js-file-download'

export default {
  name: 'StatisticsListView',
  mixins: [sorts, pages, searches, stores],
  components: {
    TitleBar,
    FiveFieldBar,
    PagingBar,
    DateField,
    IpListPopup,
    CompanyListPopup
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'NO TITLE'
    }
  },
  mounted () {
    this.fetchList()
    this.prodTypList()
  },
  computed: {
    headers () {
      const statsList = [
        { text: this.$t('word.ipCd'), value: 'ipCd', sortable: false },
        { text: this.$t('word.ipNm'), value: 'ipNm', sortable: false }
      ]
      if (this.checkComp === 'COMP') {
        statsList.push({
          text: this.$t('word.compNm'), value: 'compNm', sortable: false
        })
      }
      if (this.checkProdTyp === 'PRODTYP') {
        statsList.push({
          text: this.$t('word.prodTyp1'), value: 'prodTypNm', sortable: false
        })
      }
      return statsList.concat(this.defaultHeaders)
    }
  },
  watch: {
    checkComp: function () {
      this.fetchList()
    },
    checkProdTyp: function () {
      this.fetchList()
    }
  },
  data () {
    return {
      // 체크 박스
      checkIp: 'IP',
      checkComp: 'COMP',
      checkProdTyp: 'PRODTYP',
      // 테이블 헤더
      defaultHeaders: [
        { text: this.$t('word.maxCnsmAmt'), value: 'maxCnsmAmt', sortable: false },
        { text: this.$t('word.minCnsmAmt'), value: 'minCnsmAmt', sortable: false },
        { text: this.$t('word.avgCnsmAmt'), value: 'avgCnsmAmt', sortable: false },
        { text: this.$t('word.maxRlsAmt'), value: 'maxRlsAmt', sortable: false },
        { text: this.$t('word.minRlsAmt'), value: 'minRlsAmt', sortable: false },
        { text: this.$t('word.avgRlsAmt'), value: 'avgRlsAmt', sortable: false },
        { text: this.$t('word.prdcCnt'), value: 'prdcCnt', sortable: false },
        { text: this.$t('word.rylt') + ' (VAT별도)', value: 'ryltAmt', sortable: false }
      ],
      // 조회결과 목록
      items: [],
      // 검색조건
      strYmd: null,
      endYmd: null,
      ipId: null,
      ipNm: null,
      compId: null,
      compNm: null,
      prodTypId: null,
      prodTypNm: null,
      // IP 목록 팝업 오픈 여부
      showIpListPopup: false,
      // 회사 목록 팝업 오픈 여부
      showCompanyListPopup: false,
      // 상품유형 1단계 목록
      prodTypCdLv1List: []
    }
  },
  methods: {
    // 목록조회
    fetchList (shouldClear) {
      // 검색 초기화
      if (shouldClear) {
        this.comPageNo = 1
      }
      // 검색 파라미터 할당
      this.clearComParam()
      let CMD = this.$isNull(this.checkIp) ? '' : this.checkIp
      CMD += this.$isNull(this.checkComp) ? '' : this.checkComp
      CMD += this.$isNull(this.checkProdTyp) ? '' : this.checkProdTyp
      this.appendParam('CMD', CMD)
      this.appendParam('strYmd', this.strYmd)
      this.appendParam('endYmd', this.endYmd)
      if (!this.$isEmpty(this.ipNm)) {
        this.appendParam('ipId', this.ipId)
      }
      if (!this.$isEmpty(this.compNm)) {
        this.appendParam('compId', this.compId)
      }
      if (!this.$isEmpty(this.prodTypId)) {
        this.appendParam('prodTypId', this.prodTypId)
      }
      // 검색
      this.$http.get(this.toRequestUrl('/api/1/statistics'))
        .then((response) => {
          const page = response.data.result.statsPage
          this.bindComPage(page)
          this.items = page.list
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 상품유형 1단계 목록 조회
    prodTypList () {
      this.$http.get(this.toRequestUrl('/api/1/products/level1/all'))
        .then((response) => {
          this.prodTypCdLv1List = response.data.result.prodTypList
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    // 초기화 버튼 클릭시
    handleClearClicked () {
      this.strYmd = null
      this.endYmd = null
      this.ipId = null
      this.ipNm = null
      this.compId = null
      this.compNm = null
      this.prodTypId = null
      this.prodTypNm = null
      this.checkComp = 'COMP'
      this.checkProdTyp = 'PRODTYP'
      this.fetchList(true)
    },
    // 다운로드 클릭 시
    handleStatsRptClicked () {
      // 파라미터 할당
      this.clearComParam()
      let CMD = this.$isNull(this.checkIp) ? '' : this.checkIp
      CMD += this.$isNull(this.checkComp) ? '' : this.checkComp
      CMD += this.$isNull(this.checkProdTyp) ? '' : this.checkProdTyp
      this.appendParam('CMD', CMD)
      this.appendParam('strYmd', this.strYmd)
      this.appendParam('endYmd', this.endYmd)
      if (!this.$isEmpty(this.ipNm)) {
        this.appendParam('ipId', this.ipId)
      }
      if (!this.$isEmpty(this.compNm)) {
        this.appendParam('compId', this.compId)
      }
      if (!this.$isEmpty(this.prodTypId)) {
        this.appendParam('prodTypId', this.prodTypId)
      }
      this.$http.get(this.toRequestUrl('/api/1/statistics/reports'), { responseType: 'blob' })
        .then((response) => {
          // 파일이름 설정
          const contentDisposition = response.headers['content-disposition']
          let downFileNm = null
          if (!this.$isNull(contentDisposition)) {
            const idx = contentDisposition.indexOf(';filename*=UTF-8\'\'')
            if (idx >= 0) {
              downFileNm = decodeURIComponent(contentDisposition.substring(idx + 18))
            }
          }
          if (this.$isNull(downFileNm)) {
            downFileNm = this.$t('word.statsRead') + '.xlsx'
          }
          fileDownload(response.data, downFileNm)
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>
