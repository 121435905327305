<template>
  <div>
    <v-divider></v-divider>
    <v-sheet :color="color" class="pa-2">
      <v-row dense justify="end">
        <v-col
          v-for="field in fields"
          :key="field.id"
          cols="12"
          :sm="sm"
          :md="md"
          :lg="lg">
          <slot :name="field.id">
            <!-- 검색조건 컴포넌트가 들어갈 위치 -->
          </slot>
        </v-col>
        <slot name="custom">
          <!-- 커스텀 영역 -->
        </slot>
      </v-row>
    </v-sheet>
    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  name: 'FieldBar',
  props: {
    color: {
      type: String,
      required: false,
      default: 'grey lighten-3'
    },
    fieldSize: {
      type: Number,
      required: true
    },
    sm: {
      type: Number,
      required: false,
      default: 6
    },
    md: {
      type: Number,
      required: false,
      default: 4
    },
    lg: {
      type: Number,
      required: false,
      default: 2
    }
  },
  mounted () {
    for (let i = 0; i < this.fieldSize; i++) {
      this.fields.push({
        id: `field-${(i + 1)}`
      })
    }
  },
  data () {
    return {
      fields: []
    }
  }
}
</script>
