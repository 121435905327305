<template>
  <div>
    <v-card outlined>
      <!-- 타이틀바 -->
      <title-bar :title="$t('word.depoConf')" sub>
        <template v-slot:action>
          <v-menu
            offset-y
            bottom left
            tile
            min-width="156">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small tile color="secondary"  :ripple="false" v-bind="attrs" v-on="on">
                {{ $t('word.menu') }}<v-icon right dark>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="uploadable" @click="showStampFileRegistPopup = true">
                <v-list-item-avatar>
                  <v-icon color="primary">mdi-upload</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="primary--text">{{ $t('word.registFile') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="handlePreviewClicked">
                <v-list-item-avatar>
                  <v-icon>mdi-eye-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('word.preview') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="handleDownloadClicked">
                <v-list-item-avatar>
                  <v-icon>mdi-download</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('word.download') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!--<v-list-item v-if="modifiable" @click="handleModifyClicked">
                <v-list-item-avatar>
                  <v-icon>mdi-pencil</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('word.modifyInfo') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->
              <v-list-item v-if="deletable" @click="handleDeleteClicked">
                <v-list-item-avatar>
                  <v-icon color="error">mdi-delete</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="error--text">{{ $t('word.delete') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </title-bar>
      <v-divider></v-divider>
      <!-- 목록 -->
      <v-list class="py-0">
        <v-list-item-group
          v-model="selectedItem"
          color="pink">
          <template v-for="(item, index) in items">
            <v-list-item
              :key="`${item.projCd}-${item.stmpApplNo}-${item.stmpFileNo}`"
              :value="item"
              two-line>
              <template v-slot:default>
               <!-- <v-list-item-action>
                  <v-checkbox
                    :input-value="active"
                    color="primary"></v-checkbox>
                </v-list-item-action>-->
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <span>{{ $t('word.deposit') }}</span>
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ item.mngFileNm }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon rounded :ripple="false" @click.stop="handleInlineDownloadClicked(item)"><v-icon>mdi-download</v-icon></v-btn>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-divider
              :key="index"
              v-if="index < items.length - 1">
            </v-divider>
          </template>
        </v-list-item-group>
        <template v-if="items.length === 0">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('msg.notExistsFile') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <!-- 페이징 -->
      <paging-bar
        :page-no.sync="comPageNo"
        :page-cnt.sync="comPageCnt"
        :visible-page-cnt="5"
        :item-cnt-per-page.sync="comItemCntPerPage"
        :items-per-page="[5]"
        row-justify="center"
        hide-item-cnt-per-page
        @paging-changed="fetchList">
      </paging-bar>
    </v-card>
    <!-- 첨부 파일 등록 팝업 -->
    <stamp-file-regist-popup
      v-if="showStampFileRegistPopup"
      :dialog.sync="showStampFileRegistPopup"
      :stmp-appl-no="stmpApplNo"
      :proj-cd="projCd"
      @item-changed="fetchList">
    </stamp-file-regist-popup>
    <!-- 첨부 파일 수정 팝업 -->
    <stamp-file-modify-popup
      v-if="showStampFileModifyPopup"
      :dialog.sync="showStampFileModifyPopup"
      :proj-cd="projCd"
      :stmp-appl-no="stmpApplNo"
      :stmp-file-no="$isNull(selectedItem) ? null : selectedItem.stmpFileNo"
      @item-changed="fetchList">
    </stamp-file-modify-popup>
    <!-- 미리보기 팝업 -->
    <preview-image-popup
      v-if="showPreviewImagePopup"
      :dialog.sync="showPreviewImagePopup"
      :url="previewPopupUrl">
    </preview-image-popup>
    <!-- pdf 미리보기 팝업 -->
    <preview-pdf-popup
      v-if="showPreviewPdfPopup"
      :dialog.sync="showPreviewPdfPopup"
      :url="previewPopupUrl">
    </preview-pdf-popup>
  </div>
</template>

<script>
import { pages } from '@/mixins/pageMixin'
import { searches } from '@/mixins/searchMixin'
import TitleBar from '@/components/TitleBar'
import PagingBar from '@/components/PagingBar'
import StampFileRegistPopup from '@/views/stamp/popup/StampFileRegistPopup'
import StampFileModifyPopup from '@/views/stamp/popup/StampFileModifyPopup'
import PreviewImagePopup from '@/components/PreviewImagePopup'
import PreviewPdfPopup from '@/components/PreviewPdfPopup'
import fileDownload from 'js-file-download'

export default {
  name: 'StampFileListUnit',
  mixins: [pages, searches],
  components: {
    TitleBar,
    PagingBar,
    StampFileRegistPopup,
    StampFileModifyPopup,
    PreviewImagePopup,
    PreviewPdfPopup
  },
  props: {
    stmpApplNo: {
      type: Number,
      required: false
    },
    projCd: {
      type: String,
      required: false
    },
    stmpFileNo: {
      type: Number,
      required: false
    },
    uploadable: {
      type: Boolean,
      required: false,
      default: true
    },
    modifiable: {
      type: Boolean,
      required: false,
      default: true
    },
    deletable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  mounted () {
    this.stmpAppl.projCd = this.projCd
    this.stmpAppl.stmpApplNo = this.stmpApplNo
    this.stmpAppl.stmpFileNo = this.stmpFileNo

    // 페이지별 아이템 건수를 5건으로 조정
    this.comItemCntPerPage = 5
    this.fetchList()
  },
  watch: {
    stmpAppl: {
      deep: true,
      handler () {
        this.somethingChanged = true
      }
    }
  },
  data () {
    return {
      // 첨부파일 목록
      items: [],
      // 첨부파일 목록에서 선택된 아이템
      selectedItem: null,
      stmpAppl: {},
      previewPopupUrl: null,
      showStampFileRegistPopup: false,
      showStampFileModifyPopup: false,
      showPreviewImagePopup: false,
      showPreviewPdfPopup: false
    }
  },
  computed: {
    dialogModel: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update:dialog', value)
      }
    },
    modifying () {
      if (!this.$isNull(this.stmpFileNo)) {
        return true
      } else {
        return !this.$isNull(this.stmpAppl.stmpFileNo)
      }
    },
    title () {
      if (this.modifying) {
        return this.$t('word.stmpApplDtl')
      } else {
        return this.$t('word.stmpAppl')
      }
    }
  },
  methods: {
    // 목록 조회
    fetchList (shouldClear) {
      if (this.$isNull(this.stmpAppl.projCd) || this.$isNull(this.stmpAppl.stmpApplNo)) {
        return
      }
      // 페이지번호 초기화
      if (shouldClear) {
        this.comPageNo = 1
      }
      this.selectedItem = null
      this.$http.get(this.toRequestUrl(`/api/1/projects/${this.projCd}/stamps/${this.stmpApplNo}/file`))
        .then((response) => {
          const page = response.data.result.stmpApplFilePage
          this.bindComPage(page)
          this.items = page.list
          if (this.items.length === 0) {
            this.$toasted.global.warn(this.$t('StampDetailTabPopup.txt010'))
            this.tab = 1
          }
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handlePreviewClicked () {
      if (this.$isNull(this.selectedItem)) {
        this.$toasted.global.warn(this.$t('msg.choosePreviewFile'))
        return
      }
      let extNm = this.selectedItem.fileExtNm
      if (this.$isNull(extNm)) {
        this.$toasted.global.warn(this.$t('msg.notSupportedPreview'))
        return
      }
      extNm = extNm.toLowerCase()
      if (extNm !== 'png' && extNm !== 'jpg' && extNm !== 'jpeg' && extNm !== 'pdf') {
        this.$toasted.global.warn(this.$t('msg.notSupportedPreview'))
        return
      }
      this.previewPopupUrl = `/api/1/projects/${this.projCd}/stamps/${this.stmpApplNo}/file/${this.selectedItem.stmpFileNo}/preview`
      if (extNm === 'pdf') {
        this.showPreviewPdfPopup = true
      } else {
        this.showPreviewImagePopup = true
      }
    },
    handleInlineDownloadClicked (item) {
      this.selectedItem = item
      this.$nextTick(() => {
        this.handleDownloadClicked()
      })
    },
    handleDownloadClicked () {
      if (this.$isNull(this.selectedItem)) {
        this.$toasted.global.warn(this.$t('msg.chooseDownloadFile'))
        return
      }
      this.$http.get(`/api/1/projects/${this.projCd}/stamps/${this.stmpApplNo}/file/${this.selectedItem.stmpFileNo}/download`, { responseType: 'blob' })
        .then((response) => {
          // 파일이름 설정
          const contentDisposition = response.headers['content-disposition']
          let downFileNm = null
          if (!this.$isNull(contentDisposition)) {
            const idx = contentDisposition.indexOf(';filename*=UTF-8\'\'')
            if (idx >= 0) {
              downFileNm = decodeURIComponent(contentDisposition.substring(idx + 18))
            }
          }
          if (this.$isNull(downFileNm)) {
            downFileNm = this.fileNm
          }
          fileDownload(response.data, downFileNm)
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    handleModifyClicked () {
      if (this.$isNull(this.selectedItem)) {
        this.$toasted.global.warn(this.$t('msg.chooseModifyFile'))
        return
      }
      this.showStampFileModifyPopup = true
    },
    handleDeleteClicked () {
      if (this.$isNull(this.selectedItem)) {
        this.$toasted.global.warn(this.$t('msg.chooseDeleteFile'))
        return
      }
      if (!confirm(this.$t('msg.confirmDelete', [this.selectedItem.mngFileNm]))) {
        return
      }
      this.$http.delete(`/api/1/projects/${this.projCd}/stamps/${this.stmpApplNo}/file/${this.selectedItem.stmpFileNo}`)
        .then(() => {
          this.$toasted.global.success(this.$t('msg.deleted'))
          this.fetchList(true)
          this.$router.go()
        })
        .catch((error) => {
          this.$showError(error)
        })
    }
  }
}
</script>
